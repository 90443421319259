import React, { useState, useEffect, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var org = localStorage.getItem("org");

var token = localStorage.getItem("token");
var createOrgUser = localStorage.getItem("createOrgUser");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

var showClients = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function navigateToProUsers(navigate) {
  navigate("/prousers");
}

function checkEndDate(endDate) {
  if (endDate === null) {
    return true;
  }

  var dateNowUtc = new Date().toUTCString();
  var dateNow = new Date(dateNowUtc).valueOf();
  var endDateNow = new Date(endDate).valueOf();

  if (dateNow > endDateNow) {
    return false;
  }

  return true;
}

function editProUser(event, navigate, setNavigateContext) {
  setNavigateContext(true);

  localStorage.setItem("editProUser", true);
  localStorage.setItem("selectedProUserId", event.currentTarget.getAttribute("data-user-id"));
  localStorage.setItem("selectedProUserOrgs", event.currentTarget.getAttribute("data-user-orgs"));
  localStorage.setItem("selectedProUserEmail", event.currentTarget.getAttribute("data-user-email"));
  localStorage.setItem("selectedProUserSettings", event.currentTarget.getAttribute("data-user-settings"));
  localStorage.setItem("selectedProUserRole", event.currentTarget.getAttribute("data-user-role"));
  localStorage.setItem("selectedProUserLocale", event.currentTarget.getAttribute("data-user-locale"));
  localStorage.setItem("selectedProUserName", event.currentTarget.getAttribute("data-user-name"));
  localStorage.setItem("selectedProUserTwoStepVerified", event.currentTarget.getAttribute("data-user-twostep"));

  if (event.currentTarget.getAttribute("data-user-expires") !== null) {
    localStorage.setItem("selectedProUserExpires", event.currentTarget.getAttribute("data-user-expires"));
  }

  navigate("/createoreditprouser");
}

function deleteProUser(event, navigate, setNavigateContext) {
  setNavigateContext(true);

  localStorage.setItem("deleteProUser", true);
  localStorage.setItem("selectedProUserId", event.currentTarget.getAttribute("data-user-id"));
  localStorage.setItem("selectedProUserOrgs", event.currentTarget.getAttribute("data-user-orgs"));
  localStorage.setItem("selectedProUserEmail", event.currentTarget.getAttribute("data-user-email"));
  localStorage.setItem("selectedProUserSettings", event.currentTarget.getAttribute("data-user-settings"));
  localStorage.setItem("selectedProUserRole", event.currentTarget.getAttribute("data-user-role"));
  localStorage.setItem("selectedProUserLocale", event.currentTarget.getAttribute("data-user-locale"));
  localStorage.setItem("selectedProUserName", event.currentTarget.getAttribute("data-user-name"));
  localStorage.setItem("selectedProUserTwoStepVerified", event.currentTarget.getAttribute("data-user-twostep"));

  if (event.currentTarget.getAttribute("data-user-expires") !== null) {
    localStorage.setItem("selectedProUserExpires", event.currentTarget.getAttribute("data-user-expires"));
  }

  navigate("/createoreditprouser");
}

function filterUsers(event) {
  if (event.role === "Pro" || event.role === "Admin" || event.role === "Retailer" || event.role === "Super") {
    return true;
  }

  return false;
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const ProUsers = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const navigate = useNavigate();

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  window.onpopstate=function() {
    setNavigateContext(false);
    window.location.reload();
  }

  if (navigateContext !== true) {
    localStorage.removeItem("selectedProUserId");
    localStorage.removeItem("selectedProUserOrgs");
    localStorage.removeItem("selectedProUserEmail");
    localStorage.removeItem("selectedProUserSettings");
    localStorage.removeItem("selectedProUserRole");
    localStorage.removeItem("selectedProUserLocale");
    localStorage.removeItem("selectedProUserName");
    localStorage.removeItem("selectedProUserExpires");
    localStorage.removeItem("selectedProUserTwoStepVerified");
    localStorage.removeItem("editProUser");
    localStorage.removeItem("deleteProUser");
  }

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  const [proUsers, setProUsers] = useState([]);
 // const [proUserOrgs, setProUserOrgs] = useState([]);
 const [proUserOrg, setProUserOrg] = useState([]);

  const fetchUserData = async () => {
    try {
      if (createOrgUser === "true") {
        org = localStorage.getItem("selectedOrgId");
      }

      const response = await fetch(baseUrl + "/api/v1/organisation/" + org + "/users",
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else {
        const data = await response.json();

        setProUsers(data.users.filter(filterUsers));

        /*var orgs = [];

        for (var i = 0; i < data.users.filter(filterUsers).length; i++) {
          for (var j = 0; j < data.users.filter(filterUsers).orgs.length; j++) {
            orgs.push(getOrg(i, j));
          }
        }

        setProUserOrgs(orgs);*/
      }

    } catch (err) {
      const userFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_list_users")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(userFetchFailedOptions);
    }
  }

  const fetchOrgData = async () => {

    try {
      if (createOrgUser === "true") {
        org = localStorage.getItem("selectedOrgId");
      }

      const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + org,
      {
        headers: { "Authorization": "Bearer " + token,
        "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else {
        const data = await response.json();

        setProUserOrg(data.organisations[0]);
      }

    } catch (err) {
      const orgFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_org_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(orgFetchFailedOptions);
    }
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  //const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (data.role === "Pro") {
          window.location.replace(window.location.origin + "/menu");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        //setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    await fetchUserData();
    await fetchOrgData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*async function getOrg(userIndex, orgIndex) {
    const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + proUsers[userIndex].orgs[orgIndex],
    {
      headers: { "Authorization": "Bearer " + token },
    });

    console.log(response);

    if (response.status === 403) {
      confirmAlert(tokenExpiredOptions);
    }
    else {
      const data = await response.json();

      return data.organisations[0];
    }

    return null;

  }*/

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1);

  return (
    <div className="ProUsers-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        { createOrgUser === "true" && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
              {t("local_organisation")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToProUsers(navigate)}>
          {t("local_pro_users")}
        </button>
      </div>
      <div className="ProUsers-Menu-container">
        <div className="MainMenu2-left">
          <strong className="MainMenu-title underline">{t("local_button_ingame_menu")}</strong>
          <div className="AdminMenu-items-container">
            <p>
              <a className="AdminMenu-item" href="./modules">{t("local_exercise_modules")}</a>
            </p>
            { showClients === "true" && (
              <p>
                <a className="AdminMenu-item" href="./clientselection">{t("local_clients")}</a>
              </p>
            )}
            <p>
              <a className="AdminMenu-item" href="./prousers">{t("local_pro_users")}</a>
            </p>
            <p>
              <a className="AdminMenu-item" href="./organisation">{t("local_organisation")}</a>
            </p>
            <p>
              <a className="License-manager-link AdminMenu-item" href="./">{t("local_license_manager")}</a>
            </p>
          </div>
          <p className="Menu-left-footer"p>{t("local_prousers_footer")}</p>
        </div>
        <div className="ProUsers-Menu-right">
          <div className="Menu-right-top">
            <table className="Menu-right-top-table5">
              <tr>
                <td className="Menu-right-top-header-upper"><strong>{t("local_organisation")}</strong>: {proUserOrg.name}</td>
              </tr>
            </table>
            <table className="Menu-right-top-table">
              <tr>
                <td className="Menu-right-top-header pumrth-users"><strong>{t("local_pro_users")}</strong></td>
                {/*<td className="Menu-right-top-header pumrth-site"><strong>{t("local_site")}</strong></td>*/}
                <td className="Menu-right-top-header pumrth-type"><strong>{t("local_type")}</strong></td>
              </tr>
              <tr>
                <td colspan="5">
                  <span className="ProUsers-Menu-line2"></span>
                </td>
              </tr>
              { proUsers.length > 0 && (
                <React.Fragment>
                  { proUsers.map(user => (
                    <tr className="proUsersFlexRow pud1-row1">
                      { checkEndDate(user.expires) === true ? (
                        <td className="pud1-row1-item1" >{user.full_name}</td>
                      ) : (
                        <td className="Expired-UserLink pud1-row1-item1" >{user.full_name}</td>
                      )}
                      <td className="pud1-row1-item3">{t("local_standard")}</td>
                      <td className="pud1-row1-item4"><button className="editProUserLink btn btn-link btn-link-nopad" data-user-twostep={user.twostep} data-user-id={user.user_id} data-user-orgs={user.orgs} data-user-email={user.email} data-user-settings={user.settings} data-user-role={user.role} data-user-locale={user.locale} data-user-name={user.full_name} data-user-expires={user.expires} onClick={(event) => editProUser(event, navigate, setNavigateContext)}>
                        {t("local_edit")}
                      </button></td>
                      <td className="pud1-row1-item5"><button className="deleteProUserLink btn btn-link btn-link-nopad" data-user-twostep={user.twostep} data-user-id={user.user_id} data-user-orgs={user.orgs} data-user-email={user.email} data-user-settings={user.settings} data-user-role={user.role} data-user-locale={user.locale} data-user-name={user.full_name} data-user-expires={user.expires} onClick={(event) => deleteProUser(event, navigate, setNavigateContext)}>
                        {t("local_delete")}
                      </button></td>
                    </tr>
                  ))}
                </React.Fragment>
              )}
            </table>
          </div>
          <div className="ProUsers-Menu-right-bottom">
            <table className="Menu-right-bottom-table-lower">
              {/*{ proUsers.length > 0 && (
                <React.Fragment>
                  { proUsers.map(user => (
                    <tr className="proUsersFlexRow pud1-row1">
                      <td className="pud1-row1-item1" >{user.full_name}</td>
                      <td className="pud1-row1-item3">{t("local_standard")}</td>
                      <td className="pud1-row1-item4"><button className="editProUserLink btn btn-link btn-link-nopad" data-user-twostep={user.twostep} data-user-id={user.user_id} data-user-orgs={user.orgs} data-user-email={user.email} data-user-settings={user.settings} data-user-role={user.role} data-user-locale={user.locale} data-user-name={user.full_name} data-user-expires={user.expires} onClick={(event) => editProUser(event, navigate, setNavigateContext)}>
                        {t("local_edit")}
                      </button></td>
                      <td className="pud1-row1-item5"><button className="deleteProUserLink btn btn-link btn-link-nopad" data-user-twostep={user.twostep} data-user-id={user.user_id} data-user-orgs={user.orgs} data-user-email={user.email} data-user-settings={user.settings} data-user-role={user.role} data-user-locale={user.locale} data-user-name={user.full_name} data-user-expires={user.expires} onClick={(event) => deleteProUser(event, navigate, setNavigateContext)}>
                        {t("local_delete")}
                      </button></td>
                    </tr>
                  ))}
                </React.Fragment>
              )}*/}
              {/*<tr className="pud1-row1">
                <td className="pud1-row1-item1 underline">Jaana Sarajuuri</td>
                <td className="pud1-row1-item2"><a className="Site-link" href="./">High Street Clinic</a></td>
                <td className="pud1-row1-item3">Admin</td>
              </tr>
              <tr className="pud1-row2">
                <td></td>
                <td className="pud1-row2-item2"><a className="Site-link" href="./">Underwood Hospital</a></td>
              </tr>
              <tr className="pud1-row3">
                <td className="pud1-row3-item1 underline">Sanna Koskinen</td>
                <td className="pud1-row3-item2"><a className="Site-link" href="./">High Street Clinic</a></td>
                <td className="pud1-row3-item3">Standard</td>
              </tr>
              <tr className="pud1-row4">
                <td className="pud1-row4-item1 underline">Henrik Jürgens</td>
                <td className="pud1-row4-item2"><a className="Site-link" href="./">Green Street Infirmary</a></td>
                <td className="pud1-row4-item3">Standard</td>
              </tr>
              <tr className="pud1-row5">
                <td className="pud1-row5-item1 underline">Marko Türklin</td>
                <td className="pud1-row5-item2"><a className="Site-link" href="./">Underwood Hospital</a></td>
                <td className="pud1-row5-item3">Standard</td>
              </tr>
              <tr className="pud1-row6">
                <td className="pud1-row6-item1 underline">Nora Musgrove</td>
                <td className="pud1-row6-item2"><a className="Site-link" href="./">Green Street Infirmary</a></td>
                <td className="pud1-row6-item3">Standard</td>
              </tr>
              <tr className="pud1-row7">
                <td className="pud1-row7-item1 underline">Toni Kankaanpää</td>
                <td className="pud1-row7-item2"><a className="Site-link" href="./">Green Street Infirmary</a></td>
                <td className="pud1-row7-item3">Standard</td>
              </tr>
              <tr className="pud1-row8">
                <td className="pud1-row8-item1 underline">Nepsy Lukkarinen</td>
                <td className="pud1-row8-item2"><a className="Site-link" href="./">Green Street Infirmary</a></td>
                <td className="pud1-row8-item3">Standard</td>
              </tr>*/}
            </table>
          </div>
          <div>
            <span>
              <a className="CreateClient2" href="./createoreditprouser">{t("local_create_new")}</a>
            </span>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default ProUsers;
