import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var org = localStorage.getItem("org");

var createOrgClient = localStorage.getItem("createOrgClient");
var selectedOrgName = localStorage.getItem("selectedOrgName");
var navigateFromClient = localStorage.getItem("navigateFromClient");
var userId = localStorage.getItem("userId");

var showClients = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

var token = localStorage.getItem("token");
var selectedClientName = localStorage.getItem("selectedClientName");
var selectedClientEmail = localStorage.getItem("selectedClientEmail");
var selectedClientUsername = localStorage.getItem("selectedClientUsername");
var selectedClientUserId = localStorage.getItem("selectedClientUserId");
var selectedClientLocale = localStorage.getItem("selectedClientLocale");
var selectedClientExpires = localStorage.getItem("selectedClientExpires");
var selectedClientOrgs = localStorage.getItem("selectedClientOrgs");
var selectedClientTwoStepVerified = localStorage.getItem("selectedClientTwoStepVerified");
var editClient = localStorage.getItem("editClient");
var deleteClient = localStorage.getItem("deleteClient");
var userLanguage = localStorage.getItem("userLanguage");
var selectedClientSettingsPros = localStorage.getItem("selectedClientSettingsPros");
var settingsRestrictedRecipes = localStorage.getItem("selectedClientRestrictToRecipes");

var today = new Date();

var currentYear = today.getFullYear();
var currentMonth = today.getMonth() + 1;
var currentDay = today.getDate();

if (currentDay >= 1 && currentDay <= 9) {
  currentDay = "0" + currentDay;
}

if (currentMonth >= 1 && currentMonth <= 9) {
  currentMonth = "0" + currentMonth;
}

var currentDate = currentYear + "-" + currentMonth + "-" + currentDay;

function checkExpireDate(date) {
  if (date === null || date === undefined) {
    return currentDate;
  }
  else if (date.split("T") === undefined) {
    return date;
  }
  else {
    return date.split("T")[0];
  }
}

function checkClientEmail(selectedClientEmail) {
  if (selectedClientEmail === null || selectedClientEmail === "null") {
    return null;
  }
  else {
    var email = document.getElementById("email");
    var newUserName = document.getElementById("username");

    if (email !== null && email !== undefined && newUserName !== null && newUserName !== undefined) {
      var newUserNameText = newUserName.value;

      if (selectedClientEmail === "" && newUserNameText === "") {
        email.removeAttribute('disabled');
        newUserName.removeAttribute('disabled');
      }
      else if (selectedClientEmail !== "") {
        newUserName.setAttribute('disabled', '');
      }
      else if (newUserNameText !== "") {
        email.setAttribute('disabled', '');
      }
    }

    return selectedClientEmail;
  }
}

function checkClientUsername(selectedClientUsername) {
  if (selectedClientUsername === null || selectedClientUsername === "null") {
    return null;
  }
  else {
    var email = document.getElementById("email");
    var newUserName = document.getElementById("username");

    if (email !== null && email !== undefined && newUserName !== null && newUserName !== undefined) {
      var emailText = email.value;

      if (emailText === "" && selectedClientUsername === "") {
        email.removeAttribute('disabled');
        newUserName.removeAttribute('disabled');
      }
      else if (emailText !== "") {
        newUserName.setAttribute('disabled', '');
      }
      else if (selectedClientUsername !== "") {
        email.setAttribute('disabled', '');
      }
    }

    return selectedClientUsername;
  }
}

function setExpireDate(event) {
  var expireDate = document.getElementById("expireDate");

  if (expireDate !== undefined) {
    if (event.currentTarget.checked) {
      expireDate.setAttribute('disabled', '');
    }
    else {
      expireDate.removeAttribute('disabled');
    }
  }

}

function checkEmailOrUsername() {
  var email = document.getElementById("email");
  var newUserName = document.getElementById("username");

  if (email !== null && email !== undefined && newUserName !== null && newUserName !== undefined) {
    var emailText = email.value;
    var newUserNameText = newUserName.value;

    if (emailText === "" && newUserNameText === "") {
      email.removeAttribute('disabled');
      newUserName.removeAttribute('disabled');
    }
    else if (emailText !== "") {
      email.removeAttribute('disabled');
      newUserName.setAttribute('disabled', '');
    }
    else if (newUserNameText !== "") {
      email.setAttribute('disabled', '');
      newUserName.removeAttribute('disabled');
    }
  }
}

function selectRoleOption(event) {
  var lockGamesCheckBox = document.getElementById("lockGamesCheckBox");
  var lockGamesCheckBoxLabel = document.getElementById("lockGamesCheckBoxLabel");

  if (event.currentTarget.selectedIndex === 0) {
    lockGamesCheckBox.style.display = "inline-block";
    lockGamesCheckBoxLabel.style.display = "inline-block";
  }
  else {
    lockGamesCheckBox.checked = false;
    lockGamesCheckBox.style.display = "none";
    lockGamesCheckBoxLabel.style.display = "none";
  }
}

function navigateToClientSelection2(navigate) {
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToClientCreation(navigate) {
  navigate("/createoreditclient");
}

function navigateToClient(navigate) {
  navigate("/client");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function cancelClientCreation() {
  if (navigateFromClient === "true") {
    window.location.replace(window.location.origin + "/client");
  }
  else {
    window.location.replace(window.location.origin + "/clientselection");
  }
}

function navigateToClientSelection() {
  if (navigateFromClient === "true") {
    window.location.replace(window.location.origin + "/client");
  }
  else {
    window.location.replace(window.location.origin + "/clientselection");
  }
}

function handleKeyPress(event) {
  var keyChar = String.fromCharCode(event.which || event.keyCode);
  var regex = new RegExp(/[a-zA-Z0-9@,.!#$%&'*+-/=?^_`{|}~']/);

  if (regex.test(String(keyChar).toLowerCase()) === false) {
    event.preventDefault();
  }
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const CreateOrEditClient = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  if (createOrgClient === "true") {
    org = localStorage.getItem("selectedOrgId");
  }

  const cannotCreateRecipeOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_client_not_selected1")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const cannotCreateProgressOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_client_not_selected2")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const newUserCreatedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_new_user_created")}</h1>
        <button className="confirmButton" onClick={() => {navigateToClientSelection(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const newUserCreationFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_new_user_creation_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const userDeletedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_deleted")}</h1>
        <button className="confirmButton" onClick={() => {navigateToClientSelection(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const userDeleteFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_delete_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const userEditedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_edited")}</h1>
        <button className="confirmButton" onClick={() => {navigateToClientSelection(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const userEditFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_edit_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  setTimeout( function() {
    var expiresCheckBox = document.getElementById("expiresCheckBox");
    var lockGamesCheckBox = document.getElementById("lockGamesCheckBox");
    var expireDate = document.getElementById("expireDate");

    if (expiresCheckBox !== undefined) {
      if (selectedClientExpires === null) {
        expiresCheckBox.checked = true;
        if (expireDate !== undefined) {
          expireDate.setAttribute('disabled', '');
        }
      }
      else {
        expiresCheckBox.checked = false;
      }
    }

    if (lockGamesCheckBox !== undefined) {
      if (settingsRestrictedRecipes === "true") {
        lockGamesCheckBox.checked = true;
      }
      else {
        lockGamesCheckBox.checked = false;
      }
    }
  }, 10);

  function redirectRecipesIfClient(navigate) {
    if (selectedClientName !== null && selectedClientName !== "") {
      navigate("/createoreditrecipe");
      window.location.reload();
    }
    else {
      confirmAlert(cannotCreateRecipeOptions);
    }
  }

  function redirectProgressIfClient() {
    if (selectedClientName !== null && selectedClientName !== "") {
      window.location.replace(window.location.origin + "/createnewprogress");
    }
    else {
      confirmAlert(cannotCreateProgressOptions);
    }
  }

  async function confirmClientDelete() {
    const confirmClientDeleteOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_confirm_client_delete")}</h1>
          <button className="confirmButton" onClick={() => { confirmClientEdit(); onClose();}}>{t("local_delete")}</button>
          <button className="closeButton" onClick={onClose}>{t("local_cancel")}</button>
        </div>,
      willUnmount: () => {}
    }

    confirmAlert(confirmClientDeleteOptions);
  }

  async function confirmClientEdit() {
    var email = document.getElementById("email");
    var newUserName = document.getElementById("username");
    var fullname = document.getElementById("fullname");
    var roleSelect = document.getElementById("role");
    var languageSelect = document.getElementById("language");
    var lockGamesCheckBox = document.getElementById("lockGamesCheckBox");

    document.getElementById("overlay").style.display = "block";

    var emailText = email.value;
    var newUserNameText = newUserName.value;
    var fullnameText = fullname.value;

    var usernameRegex = /^[a-zA-Z0-9@,.!$%&'*+-/=?^_`{|}~']/;
    var emailRegex = /^[A-Za-z0-9_!$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;

    if ( (emailText !== "" && emailRegex.test(String(emailText).toLowerCase()) === false) || (newUserNameText !== "" && usernameRegex.test(String(newUserNameText).toLowerCase()) === false) ) {
      document.getElementById("overlay").style.display = "none";

      const emailOrUsernameInvalidOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_email_or_username_invalid")}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(emailOrUsernameInvalidOptions);
    }
    else if ( (newUserNameText !== "" || emailText !== "") && fullnameText !== "") {
      var roleSelected = null;

      if (roleSelect !== null && roleSelect !== undefined) {
        roleSelected = roleSelect.options[roleSelect.selectedIndex].value;
      }

      var languageSelected = languageSelect.options[languageSelect.selectedIndex].value;

      var expiresCheckBox = document.getElementById("expiresCheckBox");
      var expireDateInput = document.getElementById("expireDate");
      var expireDate = expireDateInput.value;

      expireDate += "T22:00:00Z";

      var selectedOrgs = '"' + org + '"';

      if (selectedClientOrgs !== null) {
        selectedOrgs = "";

        var orgList = selectedClientOrgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (selectedClientSettingsPros !== null) {
        pros = "";

        var prosList = selectedClientSettingsPros.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var twoStepVerify = (selectedClientTwoStepVerified === "true");

      var jsonBody = "";

      var userNameOrEmail = '"email": "' + emailText + '"';

      if (emailText !== "") {
        userNameOrEmail = '"email": "' + emailText + '"';
      }
      else if (newUserNameText !== "") {
        userNameOrEmail = '"username": "' + newUserNameText + '"';
      }

      if (lockGamesCheckBox !== undefined && lockGamesCheckBox !== null && lockGamesCheckBox.checked === true) {
        if (expiresCheckBox.checked === true && (editClient === "true" || deleteClient === "true")) {
          jsonBody = '{ "user_id": "' + selectedClientUserId + '", "twostep": ' + twoStepVerify + ', ' + userNameOrEmail + ', "full_name": "' + fullnameText + '", "expires": null, "locale": "' + languageSelected + '", "role": "Client", "active": true, "settings": "{\\"RestrictToRecipes\\":true,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
        }
        else if (editClient === "true" || deleteClient === "true") {
          jsonBody = '{ "user_id": "' + selectedClientUserId + '", "twostep": ' + twoStepVerify + ', ' + userNameOrEmail + ', "full_name": "' + fullnameText + '", "expires": "' + expireDate + '", "locale": "' + languageSelected + '", "role": "Client", "active": true, "settings": "{\\"RestrictToRecipes\\":true,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
        }
        else if (expiresCheckBox.checked === true) {
          jsonBody = '{ ' + userNameOrEmail + ', "activated": "' + username + '", "name": "' + fullnameText + '", "expires": null, "locale": "' + languageSelected + '", "role": "' + roleSelected + '", "active": true, "settings": "{\\"RestrictToRecipes\\":true,\\"Pros\\":[]}", "organisation": "' + org + '" }';
        }
        else {
          jsonBody = '{ ' + userNameOrEmail + ', "activated": "' + username + '", "name": "' + fullnameText + '", "expires": "' + expireDate + '", "locale": "' + languageSelected + '", "role": "' + roleSelected + '", "active": true, "settings": "{\\"RestrictToRecipes\\":true,\\"Pros\\":[]}", "organisation": "' + org + '" }';
        }
      }
      else {
        if (expiresCheckBox.checked === true && (editClient === "true" || deleteClient === "true")) {
          jsonBody = '{ "user_id": "' + selectedClientUserId + '", "twostep": ' + twoStepVerify + ', ' + userNameOrEmail + ', "full_name": "' + fullnameText + '", "expires": null, "locale": "' + languageSelected + '", "role": "Client", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
        }
        else if (editClient === "true" || deleteClient === "true") {
          jsonBody = '{ "user_id": "' + selectedClientUserId + '", "twostep": ' + twoStepVerify + ', ' + userNameOrEmail + ', "full_name": "' + fullnameText + '", "expires": "' + expireDate + '", "locale": "' + languageSelected + '", "role": "Client", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
        }
        else if (expiresCheckBox.checked === true) {
          jsonBody = '{ ' + userNameOrEmail + ', "activated": "' + username + '", "name": "' + fullnameText + '", "expires": null, "locale": "' + languageSelected + '", "role": "' + roleSelected + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[]}", "organisation": "' + org + '" }';
        }
        else {
          jsonBody = '{ ' + userNameOrEmail + ', "activated": "' + username + '", "name": "' + fullnameText + '", "expires": "' + expireDate + '", "locale": "' + languageSelected + '", "role": "' + roleSelected + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[]}", "organisation": "' + org + '" }';
        }
      }

      var fetchUrl = baseUrl + "/api/v1/account/";

      if (editClient === "true" || deleteClient === "true") {
        fetchUrl += selectedClientUserId;
      }
      else {
        fetchUrl += "new";
      }

      var fetchMethod = "POST";

      if (deleteClient === "true") {
        fetchMethod = "DELETE";
      }

      try {
        const response = await fetch(fetchUrl, {
          method: fetchMethod,
          headers: { "Authorization": "Bearer " + token,
           "Auth-ForceAdminClientAccess": "1",
           "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
          if (editClient === "true") {
            confirmAlert(userEditedOptions);
          }
          else if (deleteClient === "true") {
            confirmAlert(userDeletedOptions);
          }
          else {
            confirmAlert(newUserCreatedOptions);
          }
        }
        else if (response.status === 500) {
          if (editClient === "true") {
            confirmAlert(userEditFailedOptions);
          }
          else if (deleteClient === "true") {
            confirmAlert(userDeleteFailedOptions);
          }
          else {
            confirmAlert(newUserCreationFailedOptions);
          }
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }
    else if (fullnameText === "") {
      document.getElementById("overlay").style.display = "none";

      const nameNotGivenOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_user_name_required")}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(nameNotGivenOptions);
    }
    else if (emailText === "" && newUserNameText === "") {
      document.getElementById("overlay").style.display = "none";

      const emailNotGivenOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_user_email_required")}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(emailNotGivenOptions);
    }
  }

  const [userOrgs, setUserOrgs] = useState([]);

  const fetchUserOrgData = async () => {

    if (createOrgClient === null) {

      try {
        var orgData = null;

        if (selectedClientOrgs != null) {
          var orgList = [];
          var orgIdList = [];

          if (selectedClientOrgs.includes(",")) {
            orgIdList = selectedClientOrgs.split(",");

            var expiredUser = false;

            for (var i = 0; i < orgList.length; i++) {
              const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + orgIdList[i],
              {
                method: "GET",
                headers: { "Authorization": "Bearer " + token },
              });

              console.log(response);

              const data = await response.json();

              if (data.Message !== "AuthError_LicenseExpired") {
                orgData = {"name": "Expired organisation"};
                setUserOrgs(orgData);
                expiredUser = true;
              }
              else {
                orgList.push(data.organisations[0]);
              }
            }

            if (expiredUser === false) {
              setUserOrgs(orgList);
            }
          }
          else {
            const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + selectedClientOrgs,
            {
              method: "GET",
              headers: { "Authorization": "Bearer " + token },
            });

            console.log(response);

            const data2 = await response.json();

            if (data2.Message === "AuthError_LicenseExpired") {
              orgData = {"name": "Expired organisation"};
              setUserOrgs(orgData);
            }
            else {
              setUserOrgs(data2.organisations[0]);
            }
          }
        }
        else {
          const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + org,
          {
            method: "GET",
            headers: { "Authorization": "Bearer " + token },
          });

          console.log(response);

          const data3 = await response.json();

          if (data3.Message === "AuthError_LicenseExpired") {
            orgData = {"name": "Expired organisation"};
            setUserOrgs(orgData);
          }
          else {
            setUserOrgs(data3.organisations[0]);
          }
        }

      } catch (err) {

        const organisationFetchFailedOptions = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_failed_to_fetch_org_data")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(organisationFetchFailedOptions);
      }
    }
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  //const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (showClients !== "true" && data.role !== "Pro") {
          window.location.replace(window.location.origin + "/account");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        //setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    await fetchUserOrgData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1);

  return (
    <div className="CreateOrEditClient-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        { createOrgClient === "true" && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
              {t("local_organisation")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientSelection2(navigate)}>
          {t("local_clients_list")}
        </button>
        <span> – </span>
        { navigateFromClient !== null && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClient(navigate)}>
              {t("local_client")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientCreation(navigate)}>
          {t("local_create_or_edit_client")}
        </button>
      </div>

      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" /> <br />
          <strong>{t("local_clients")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditclient">{t("local_create_new")}</a>
          </span>
          <p className="CreateClient-Menu-left-footer"p>{t("local_create_or_edit_client_footer")}</p>
        </div>
        <div className="Menu-right">
          <div className="Menu-right-top">
            <table className="Menu-right-top-table">
              <tr>
                { (editClient === "true" || deleteClient === "true") ? (
                  <td className="Menu-right-top-header-upper cnmrth-client">{t("local_client")}: {selectedClientName}</td>
                ) : (
                  <td className="Menu-right-top-header-upper cnmrth-client">{t("local_client")}:</td>
                )}
                {/*<td><a className="Menu-right-top-header-upper cnmrth-confirm" href="#">{t("local_confirm_client")}</a></td>*/}
              </tr>
              <tr>
                { createOrgClient === "true" ? (
                  <th className="Menu-right-top-header-upper cnmrth-organisation">{t("local_organisations2")}: {selectedOrgName}</th>
                ) : (
                  <th className="Menu-right-top-header-upper cnmrth-organisation">{t("local_organisations2")}: {userOrgs.name}</th>
                )}
              </tr>
            </table>
            <table className="Menu-right-top-table2">
              <tr className="CreateOrEditClient-menu-right-top-row">
                <td className="Menu-right-top-header cnmrth-general"><a className="Menu-right-top-header-link menu-header-link-selected" href="./createoreditclient">{t("local_general")}</a></td>
                {/*<td className="Menu-right-top-header cnmrth-licences"><a className="Menu-right-top-header-link" href="./">Licences</a></td>*/}
                <td className="Menu-right-top-header cnmrth-recipe"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad" onClick={() => redirectRecipesIfClient(navigate)}>
                  <a className="Menu-right-top-header-link2" href="./createoreditrecipe">{t("local_recipes")}</a>
                </button></td>
                {/*<td className="Menu-right-top-header cnmrth-progress"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad" onClick={redirectProgressIfClient}>
                  <a className="Menu-right-top-header-link2" href="./createnewprogress">{t("local_progress_report")}</a>
                </button></td>*/}
                {/*<td className="Menu-right-top-header cnmrth-status"><a className="Menu-right-top-header-link" href="./">Status</a></td>*/}
              </tr>
            </table>
          </div>
          <span className="Menu-line"></span>
          <div className="CreateOrEditClient-Menu-right-bottom">
            <table className="Menu-right-bottom-table-lower">
              <tr>
                <th className="coecd1-row1-item1">{t("local_name")}</th>
              </tr>
              <tr>
                <td className="coecd1-row1-item3">{t("local_full_name")}</td>
                <input type="text" id="fullname" name="fullname" defaultValue={selectedClientName} disabled={deleteClient}/>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coecd2-row1-item4">{t("local_adress")}</th>
              </tr>
              <tr>
                <td className="coecd2-row1-item5">{t("local_email")}</td>
                <input type="text" onKeyPress={handleKeyPress} id="email" name="email" defaultValue={checkClientEmail(selectedClientEmail)} disabled={editClient || deleteClient} onChange={checkEmailOrUsername} />
              </tr>
              <tr>
                <td className="coecd1-row1-item2">{t("local_username")}</td>
                <input type="text" onKeyPress={handleKeyPress} id="username" name="username" defaultValue={checkClientUsername(selectedClientUsername)} disabled={editClient || deleteClient} onChange={checkEmailOrUsername} />
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coecd3-row1-item6">{t("local_settings_button_language")}</th>
                <select className="CreateOrEditClient-language-selection" name="language" id="language" defaultValue={selectedClientLocale} disabled={deleteClient} >
                  <option value="EN">{t("local_english")}</option>
                  <option value="FI">{t("local_finnish")}</option>
                  <option value="SE">{t("local_swedish")}</option>
                </select>
              </tr>
              {/*<tr>
                <td className="coecd2-row1-item6">Add1</td>
                <input type="text" id="add1" name="add1" disabled/>
              </tr>
              <tr>
                <td className="coecd2-row1-item7">Add2</td>
                <input type="text" id="add2" name="add2" disabled/>
              </tr>
              <tr>
                <td className="coecd2-row1-item8">Town</td>
                <input type="text" id="town" name="town" disabled/>
              </tr>
              <tr>
                <td className="coecd2-row1-item9">Postcode</td>
                <input type="text" id="postcode" name="postcode" disabled/>
              </tr>
              <tr>
                <td className="coecd2-row1-item10">County</td>
                <input type="text" id="county" name="county" disabled/>
              </tr>
              <tr>
                <td className="coecd2-row1-item11">Country</td>
                <input type="text" id="country" name="country" disabled/>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coecd3-row1-item12">DOB</th>
                <input type="text" id="dob" name="dob" disabled/>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coecd4-row1-item13">Gender</th>
                <input type="text" id="gender" name="gender" disabled/>
              </tr>*/}
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coecd2-row1-item7">{t("local_expires")}</th>
                <input className="expiresCheckBox" type="checkbox" id="expiresCheckBox" name="expires" value="permanent" onChange={setExpireDate} />
                <label className="expiresCheckBoxLabel" htmlFor="expiresCheckBox">{t("local_permanently_active")}</label>
                <input type="date" id="expireDate" name="expireDate" min={currentDate} defaultValue={checkExpireDate(selectedClientExpires)} />
              </tr>
              <tr>
                <br />
              </tr>
              { (editClient === null && deleteClient === null) && (
                <tr>
                  <th className="coecd4-row1-item8">{t("local_role")}</th>
                  <select className="CreateOrEditClient-role-selection" name="role" id="role" onChange={selectRoleOption} disabled={deleteClient}>
                    { role === "Super" ? (
                      <React.Fragment>
                        <option value="Client">{t("local_client")}</option>
                        <option value="Pro">Pro</option>
                        <option value="Admin">Admin</option>
                        <option value="Retailer">{t("local_retailer")}</option>
                      </React.Fragment>
                    ) : role === "Admin" ? (
                      <React.Fragment>
                        <option value="Client">{t("local_client")}</option>
                        <option value="Pro">Pro</option>
                      </React.Fragment>
                    ) : role === "Pro" ? (
                      <React.Fragment>
                        <option value="Client">{t("local_client")}</option>
                      </React.Fragment>
                    ): (
                      <React.Fragment>
                        <option value="Client">{t("local_client")}</option>
                      </React.Fragment>
                    )}
                  </select>
                </tr>
              )}
              <tr className="lockGames-row">
                <React.Fragment>
                  <div className="lockGamesDiv">
                    <input className="checkBox" type="checkbox" id="lockGamesCheckBox" name="lockGames" value="lockGames" />
                    <label className="checkBoxLabel" id="lockGamesCheckBoxLabel" htmlFor="lockGamesCheckBox">{t("local_lock_games")}</label>
                  </div>
                </React.Fragment>
              </tr>
            </table>
            <table className="CreateOrEditClient-links-table">
              { editClient === "true" ? (
                <td><button className="btn btn-primary btn-save" onClick={confirmClientEdit}>
                  {t("local_save")}
                </button></td>
              ) : deleteClient === "true" ? (
                <td><button className="btn btn-primary btn-save" onClick={confirmClientDelete}>
                  {t("local_delete")}
                </button></td>
              ) : (
                <td><button className="btn btn-primary btn-save" onClick={confirmClientEdit}>
                  OK
                </button></td>
              )}
              <td><button className="btn btn-primary btn-cancel" onClick={cancelClientCreation}>
                {t("local_cancel")}
              </button></td>
            </table>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default CreateOrEditClient;
