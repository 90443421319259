import _ from "lodash";

const videos = {};
const texts = {};

const addItems = (r, container) => {
  r.keys().forEach((key) => {
    container[_.last(key.split("/"))] = r(key);
  });
};

addItems(require.context("../tutorialvideos", true, /^.*\.mov$/), videos);

export { videos, texts };