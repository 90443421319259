import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { NavbarContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var token = localStorage.getItem("token");
var createOrgClient = localStorage.getItem("createOrgClient");
var navigateFromClient = localStorage.getItem("navigateFromClient");
var selectedGameId = localStorage.getItem("selectedGameId");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

var showClients = null;
var showParameters = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
  showParameters = localStorage.getItem("showParameters_" + userId);
}

var templateMenuNavigate = localStorage.getItem("templateMenuNavigate");

function navigateToClientSelection(navigate) {
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToClientCreation(navigate) {
  navigate("/createoreditclient");
}

function navigateToRecipes(navigate) {
  navigate("/createoreditrecipe");
}

function navigateToNewRecipe(navigate) {
  navigate("/newrecipe");
}

function navigateToClient(navigate) {
  navigate("/client");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function navigateToParameters(navigate) {
  navigate("/editrecipeparameters")
}

const checkId = (parameter, searchString) => {
  return parameter === searchString;
}

function findTableIndex(table, id) {
  return table.findIndex((parameter) => checkId(parameter, id), id);
}

var selectedGameParameters = null;
var selectedJsonGameParameters = null;

try {
  selectedGameParameters = JSON.parse(localStorage.getItem("selectedGameParameters"));
  selectedJsonGameParameters = JSON.parse(localStorage.getItem("selectedJsonGameParameters"));
} catch (err) {

}

var selectedGameParametersTable = [];
var selectedJsonGameParametersTable = [];

if (selectedGameParameters !== null && selectedGameParameters !== undefined && selectedGameParameters.length !== 0 && selectedGameParameters.length !== undefined) {

  for (var i = 0; i < selectedGameParameters.length; i++) {
    var selectedGameParameter = selectedGameParameters[i];
    selectedGameParametersTable.push(selectedGameParameter.key);
  }

  if (selectedGameParametersTable[0] === undefined) {
    selectedGameParameters = selectedJsonGameParameters;
    selectedGameParametersTable = [];

    for (var j = 0; j < selectedGameParameters.length; j++) {
      var selectedGameParameter2 = selectedGameParameters[j];
      selectedGameParametersTable.push(selectedGameParameter2.key);
    }
  }

  var imageTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "imageTypeRadioButtonGroup");
  var categories1Index = findTableIndex(selectedGameParametersTable, "categories1");
  var categories2Index = findTableIndex(selectedGameParametersTable, "categories2");
  var categories3Index = findTableIndex(selectedGameParametersTable, "categories3");
  var categories4Index = findTableIndex(selectedGameParametersTable, "categories4");
  var categories5Index = findTableIndex(selectedGameParametersTable, "categories5");
  var categories6Index = findTableIndex(selectedGameParametersTable, "categories6");
  var moveDirectionIndex = findTableIndex(selectedGameParametersTable, "moveDirectionOptions");
  var sudokuSizeIndex = findTableIndex(selectedGameParametersTable, "sudokuSizeOptions");
  var choiceCountIndex = findTableIndex(selectedGameParametersTable, "choiceCountOptions");
  var stimulusTypeIndex = findTableIndex(selectedGameParametersTable, "stimulusTypeOptions");
  var typeIndex = findTableIndex(selectedGameParametersTable, "typeOptions");
  var type2Index = findTableIndex(selectedGameParametersTable, "type2Options");
  var type3Index = findTableIndex(selectedGameParametersTable, "type3Options");
  var difficultyIndex = findTableIndex(selectedGameParametersTable, "difficultyOptions");
  var difficultySearchIndex = findTableIndex(selectedGameParametersTable, "difficultySearchOptions");
  var shapeTypeIndex = findTableIndex(selectedGameParametersTable, "shapeTypeOptions");
  var soundTypeIndex = findTableIndex(selectedGameParametersTable, "soundTypeOptions");
  var boardTypeIndex = findTableIndex(selectedGameParametersTable, "boardTypeOptions");
  var soundFeedBackIndex = findTableIndex(selectedGameParametersTable, "soundFeedBackOptions");
  var allowRepeatIndex = findTableIndex(selectedGameParametersTable, "allowRepeatOptions");
  var towerHeightIndex = findTableIndex(selectedGameParametersTable, "towerHeightOptions");
  var pearlAmountIndex = findTableIndex(selectedGameParametersTable, "pearlAmountOptions");
  var availableSlotsIndex = findTableIndex(selectedGameParametersTable, "availableSlotsOptions");
  var towerSizeIndex = findTableIndex(selectedGameParametersTable, "towerSizeOptions");
  var hintNumberIndex = findTableIndex(selectedGameParametersTable, "hintNumberOptions");
  var numbersDeducedIndex = findTableIndex(selectedGameParametersTable, "numbersDeducedOptions");
  var shapesVisibleIndex = findTableIndex(selectedGameParametersTable, "shapesVisibleOptions");
  var shapesDeducedIndex = findTableIndex(selectedGameParametersTable, "shapesDeducedOptions");
  var colorLengthIndex = findTableIndex(selectedGameParametersTable, "colorLengthOptions");
  var colorNumberIndex = findTableIndex(selectedGameParametersTable, "colorNumberOptions");
  var wordLengthIndex = findTableIndex(selectedGameParametersTable, "wordLengthOptions");
  var repeatNumberIndex = findTableIndex(selectedGameParametersTable, "repeatNumberOptions");
  var solvedLettersIndex = findTableIndex(selectedGameParametersTable, "solvedLettersOptions");
  var instantFeedbackIndex = findTableIndex(selectedGameParametersTable, "instantFeedbackOptions");
  var mazeSizeIndex = findTableIndex(selectedGameParametersTable, "mazeSizeOptions");
  var optionsNumberIndex = findTableIndex(selectedGameParametersTable, "optionsNumberOptions");
  var optionsNumber2Index = findTableIndex(selectedGameParametersTable, "optionsNumber2Options");
  var difficultyRadioButtonGroup2Index = findTableIndex(selectedGameParametersTable, "difficultyRadioButtonGroup2");
  var rotatingLineIndex = findTableIndex(selectedGameParametersTable, "rotatingLineOptions");
  var performanceTimeIndex = findTableIndex(selectedGameParametersTable, "performanceTimeOptions");
  var performanceTime2Index = findTableIndex(selectedGameParametersTable, "performanceTime2Options");
  var speedIndex = findTableIndex(selectedGameParametersTable, "speedOptions");
  var stimulusCategoryIndex = findTableIndex(selectedGameParametersTable, "stimulusCategoryOptions");
  var conceptGroupsIndex = findTableIndex(selectedGameParametersTable, "conceptGroupsOptions");
  var newsNumberIndex = findTableIndex(selectedGameParametersTable, "newsNumberOptions");
  var readingTimeIndex = findTableIndex(selectedGameParametersTable, "readingTimeOptions");
  var answerTime2Index = findTableIndex(selectedGameParametersTable, "answerTime2Options");
  var messageNumberIndex = findTableIndex(selectedGameParametersTable, "messageNumberOptions");
  var messageVisibilityIndex = findTableIndex(selectedGameParametersTable, "messageVisibilityOptions");
  var pictureNumberIndex = findTableIndex(selectedGameParametersTable, "pictureNumberOptions");
  var imageTimeIndex = findTableIndex(selectedGameParametersTable, "imageTimeOptions");
  var wordCountIndex = findTableIndex(selectedGameParametersTable, "wordCountOptions");
  var wordVisibilityIndex = findTableIndex(selectedGameParametersTable, "wordVisibilityOptions");
  var wordTimeIndex = findTableIndex(selectedGameParametersTable, "wordTimeOptions");
  var answerTimeIndex = findTableIndex(selectedGameParametersTable, "answerTimeOptions");
  var cardVisibilityIndex = findTableIndex(selectedGameParametersTable, "cardVisibilityOptions");
  var drawNumberIndex = findTableIndex(selectedGameParametersTable, "drawNumberOptions");
  var interferenceDurationIndex = findTableIndex(selectedGameParametersTable, "interferenceDurationOptions");
  var exerciseTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "exerciseTypeRadioButtonGroup");
  var movementSpeedIndex = findTableIndex(selectedGameParametersTable, "movementSpeedOptions");
  var numberDurationIndex = findTableIndex(selectedGameParametersTable, "numberDurationOptions");
  var localizationIndex = findTableIndex(selectedGameParametersTable, "localizationOptions");
  var figureTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "figureTypeRadioButtonGroup");
  var stackSizeIndex = findTableIndex(selectedGameParametersTable, "stackSizeOptions");
  var gridSizeIndex = findTableIndex(selectedGameParametersTable, "gridSizeOptions");
  var exerciseNumberIndex = findTableIndex(selectedGameParametersTable, "exerciseNumberOptions");
  var beginnerIndex = findTableIndex(selectedGameParametersTable, "beginnerOptions");
  var useOutlinesIndex = findTableIndex(selectedGameParametersTable, "useOutlinesOptions");
  var gridPositionIndex = findTableIndex(selectedGameParametersTable, "gridPositionOptions");
  var gridSize2Index = findTableIndex(selectedGameParametersTable, "gridSize2Options");
  var choiceCount2Index = findTableIndex(selectedGameParametersTable, "choiceCount2Options");
  var numberCountIndex = findTableIndex(selectedGameParametersTable, "numberCountOptions");
  var exerciseLengthIndex = findTableIndex(selectedGameParametersTable, "exerciseLengthOptions");
  var upperRowIndex = findTableIndex(selectedGameParametersTable, "upperRowOptions");
  var upperRow2Index = findTableIndex(selectedGameParametersTable, "upperRow2Options");
  var stringLengthIndex = findTableIndex(selectedGameParametersTable, "stringLengthOptions");
  var letterIntervalIndex = findTableIndex(selectedGameParametersTable, "letterIntervalOptions");
  var stringDirectionRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "stringDirectionRadioButtonGroup");
  var lowerRowIndex = findTableIndex(selectedGameParametersTable, "lowerRowOptions");
  var lowerRow2Index = findTableIndex(selectedGameParametersTable, "lowerRow2Options");
  var letterInterval2Index = findTableIndex(selectedGameParametersTable, "letterInterval2Options");
  var stringDirectionRadioButtonGroup2Index = findTableIndex(selectedGameParametersTable, "stringDirectionRadioButtonGroup2");
  var stringLength2Index = findTableIndex(selectedGameParametersTable, "stringLength2Options");
  var seriesLengthIndex = findTableIndex(selectedGameParametersTable, "seriesLengthOptions");
  var stimulusVisibilityIndex = findTableIndex(selectedGameParametersTable, "stimulusVisibilityOptions");
  var exerciseLength2Index = findTableIndex(selectedGameParametersTable, "exerciseLength2Options");
  var stimulusTimeIndex = findTableIndex(selectedGameParametersTable, "stimulusTimeOptions");
  var additionalStimuliIndex = findTableIndex(selectedGameParametersTable, "additionalStimuliOptions");
  var intervalTimeIndex = findTableIndex(selectedGameParametersTable, "intervalTimeOptions");
  var seriesNumberIndex = findTableIndex(selectedGameParametersTable, "seriesNumberOptions");
  var stimuliNumberIndex = findTableIndex(selectedGameParametersTable, "stimuliNumberOptions");
  var stimuliSizeIndex = findTableIndex(selectedGameParametersTable, "stimuliSizeOptions");
  var intervalBetweenStimuliIndex = findTableIndex(selectedGameParametersTable, "intervalBetweenStimuliOptions");
  var intervalBetweenStimuli2Index = findTableIndex(selectedGameParametersTable, "intervalBetweenStimuli2Options");
  var intervalBetweenInputIndex = findTableIndex(selectedGameParametersTable, "intervalBetweenInputOptions");
  var intervalBetweenInput2Index = findTableIndex(selectedGameParametersTable, "intervalBetweenInput2Options");
  var intervalBetweenAdvanceIndex = findTableIndex(selectedGameParametersTable, "intervalBetweenAdvanceOptions");
  var intervalBetweenAdvance2Index = findTableIndex(selectedGameParametersTable, "intervalBetweenAdvance2Options");
  var intervalBetweenAdvance3Index = findTableIndex(selectedGameParametersTable, "intervalBetweenAdvance3Options");
  var intervalBetweenAdvance4Index = findTableIndex(selectedGameParametersTable, "intervalBetweenAdvance4Options");
  var fieldShapeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "fieldShapeRadioButtonGroup");
  var fieldShapeRadioButtonGroup2Index = findTableIndex(selectedGameParametersTable, "fieldShapeRadioButton2Group");
  var fieldShapeRadioButtonGroup3Index = findTableIndex(selectedGameParametersTable, "fieldShapeRadioButton3Group");
  var fieldShapeRadioButtonGroup4Index = findTableIndex(selectedGameParametersTable, "fieldShapeRadioButton4Group");
  var fieldShapeRadioButtonGroup5Index = findTableIndex(selectedGameParametersTable, "fieldShapeRadioButton5Group");
  var stimuliRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "stimuliRadioButtonGroup");
  var stimuliRandomOptionsIndex = findTableIndex(selectedGameParametersTable, "stimuliRandomOptions");
  var stimulusVisibilityRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "stimulusVisibilityRadioButtonGroup");
  var difficultyRadioButtonGroup3Index = findTableIndex(selectedGameParametersTable, "difficultyRadioButtonGroup3");
  var difficultyRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "difficultyRadioButtonGroup");
  var boardSizeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "boardSizeRadioButtonGroup");
  var boardSizeRadioButtonGroup2Index = findTableIndex(selectedGameParametersTable, "boardSizeRadioButtonGroup2");
  var blackKnightsRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "blackKnightsRadioButtonGroup");
  var stimulusTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "stimulusTypeRadioButtonGroup");
  var exerciseStartedByRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "exerciseStartedByRadioButtonGroup");
  var targetPositionRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "targetPositionRadioButtonGroup");
  var quadrantTransportRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "quadrantTransportRadioButtonGroup");
  var pointSizeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "pointSizeRadioButtonGroup");
  var lineTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "lineTypeRadioButtonGroup");
  var bowlScaleRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "bowlScaleRadioButtonGroup");
  var fishScaleRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "fishScaleRadioButtonGroup");
  var returnTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "returnTypeRadioButtonGroup");
  var presentationTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "presentationTypeRadioButtonGroup");
  var nBackRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "nBackRadioButtonGroup");
  var interferenceTypeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "interferenceTypeRadioButtonGroup");
  var serieRecoveryRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "serieRecoveryRadioButtonGroup");
  var cellSizeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "cellSizeRadioButtonGroup");
  var layoutRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "layoutRadioButtonGroup");
  var letterSizeRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "letterSizeRadioButtonGroup");
  var advancedRadioButtonGroupIndex = findTableIndex(selectedGameParametersTable, "advancedRadioButtonGroup");
  var searchCheckBoxIndex = findTableIndex(selectedGameParametersTable, "searchOptions");
  var lettersIndex = findTableIndex(selectedGameParametersTable, "lettersOptions");
  var letters2Index = findTableIndex(selectedGameParametersTable, "letters2Options");
  var numbersIndex = findTableIndex(selectedGameParametersTable, "numbersOptions");
  var numbers2Index = findTableIndex(selectedGameParametersTable, "numbers2Options");
  var symbolsIndex = findTableIndex(selectedGameParametersTable, "symbolsOptions");
  var symbols2Index = findTableIndex(selectedGameParametersTable, "symbols2Options");
  var gapIndex = findTableIndex(selectedGameParametersTable, "gapOptions");
  var preExerciseCheckBoxIndex = findTableIndex(selectedGameParametersTable, "preExerciseOptions");
  var difficultyRadioButtonGroup4Index = findTableIndex(selectedGameParametersTable, "difficultyRadioButtonGroup4");
  var wordsGroupIndex = findTableIndex(selectedGameParametersTable, "wordsGroupOptions");
  var wordsGroup2Index = findTableIndex(selectedGameParametersTable, "wordsGroup2Options");
  var wordsGroup3Index = findTableIndex(selectedGameParametersTable, "wordsGroup3Options");
  var calculationsGroupIndex = findTableIndex(selectedGameParametersTable, "calculationsGroupOptions");
  var calculationsGroup2Index = findTableIndex(selectedGameParametersTable, "calculationsGroup2Options");
  var calculationsGroup3Index = findTableIndex(selectedGameParametersTable, "calculationsGroup3Options");
  var calculationsGroup4Index = findTableIndex(selectedGameParametersTable, "calculationsGroup4Options");
  var seriesNumber2Index = findTableIndex(selectedGameParametersTable, "seriesNumber2Options");
  var interferenceTypeRadioButtonGroup2Index = findTableIndex(selectedGameParametersTable, "interferenceTypeRadioButtonGroup2");
  var difficultyRadioButtonGroup5Index = findTableIndex(selectedGameParametersTable, "difficultyRadioButtonGroup5");
}

if (selectedJsonGameParameters !== null && selectedJsonGameParameters !== undefined && selectedJsonGameParameters.length !== 0 && selectedJsonGameParameters.length !== undefined) {

  for (var k = 0; k < selectedJsonGameParameters.length; k++) {
    var selectedJsonGameParameter = selectedJsonGameParameters[k];
    selectedJsonGameParametersTable.push(selectedJsonGameParameter.key);
  }

  var imageTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "imageTypeRadioButtonGroup");
  var categories1Index_json = findTableIndex(selectedJsonGameParametersTable, "categories1");
  var categories2Index_json = findTableIndex(selectedJsonGameParametersTable, "categories2");
  var categories3Index_json = findTableIndex(selectedJsonGameParametersTable, "categories3");
  var categories4Index_json = findTableIndex(selectedJsonGameParametersTable, "categories4");
  var categories5Index_json = findTableIndex(selectedJsonGameParametersTable, "categories5");
  var categories6Index_json = findTableIndex(selectedJsonGameParametersTable, "categories6");
  var moveDirectionIndex_json = findTableIndex(selectedJsonGameParametersTable, "moveDirectionOptions");
  var sudokuSizeIndex_json = findTableIndex(selectedJsonGameParametersTable, "sudokuSizeOptions");
  var choiceCountIndex_json = findTableIndex(selectedJsonGameParametersTable, "choiceCountOptions");
  var stimulusTypeIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimulusTypeOptions");
  var typeIndex_json = findTableIndex(selectedJsonGameParametersTable, "typeOptions");
  var type2Index_json = findTableIndex(selectedJsonGameParametersTable, "type2Options");
  var type3Index_json = findTableIndex(selectedJsonGameParametersTable, "type3Options");
  var difficultyIndex_json = findTableIndex(selectedJsonGameParametersTable, "difficultyOptions");
  var difficultySearchIndex_json = findTableIndex(selectedJsonGameParametersTable, "difficultySearchOptions");
  var shapeTypeIndex_json = findTableIndex(selectedJsonGameParametersTable, "shapeTypeOptions");
  var soundTypeIndex_json = findTableIndex(selectedJsonGameParametersTable, "soundTypeOptions");
  var boardTypeIndex_json = findTableIndex(selectedJsonGameParametersTable, "boardTypeOptions");
  var soundFeedBackIndex_json = findTableIndex(selectedJsonGameParametersTable, "soundFeedBackOptions");
  var allowRepeatIndex_json = findTableIndex(selectedJsonGameParametersTable, "allowRepeatOptions");
  var towerHeightIndex_json = findTableIndex(selectedJsonGameParametersTable, "towerHeightOptions");
  var pearlAmountIndex_json = findTableIndex(selectedJsonGameParametersTable, "pearlAmountOptions");
  var availableSlotsIndex_json = findTableIndex(selectedJsonGameParametersTable, "availableSlotsOptions");
  var towerSizeIndex_json = findTableIndex(selectedJsonGameParametersTable, "towerSizeOptions");
  var hintNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "hintNumberOptions");
  var numbersDeducedIndex_json = findTableIndex(selectedJsonGameParametersTable, "numbersDeducedOptions");
  var shapesVisibleIndex_json = findTableIndex(selectedJsonGameParametersTable, "shapesVisibleOptions");
  var shapesDeducedIndex_json = findTableIndex(selectedJsonGameParametersTable, "shapesDeducedOptions");
  var colorLengthIndex_json = findTableIndex(selectedJsonGameParametersTable, "colorLengthOptions");
  var colorNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "colorNumberOptions");
  var wordLengthIndex_json = findTableIndex(selectedJsonGameParametersTable, "wordLengthOptions");
  var repeatNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "repeatNumberOptions");
  var solvedLettersIndex_json = findTableIndex(selectedJsonGameParametersTable, "solvedLettersOptions");
  var instantFeedbackIndex_json = findTableIndex(selectedJsonGameParametersTable, "instantFeedbackOptions");
  var mazeSizeIndex_json = findTableIndex(selectedJsonGameParametersTable, "mazeSizeOptions");
  var optionsNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "optionsNumberOptions");
  var optionsNumber2Index_json = findTableIndex(selectedJsonGameParametersTable, "optionsNumber2Options");
  var difficultyRadioButtonGroup2Index_json = findTableIndex(selectedJsonGameParametersTable, "difficultyRadioButtonGroup2");
  var rotatingLineIndex_json = findTableIndex(selectedJsonGameParametersTable, "rotatingLineOptions");
  var performanceTimeIndex_json = findTableIndex(selectedJsonGameParametersTable, "performanceTimeOptions");
  var performanceTime2Index_json = findTableIndex(selectedJsonGameParametersTable, "performanceTime2Options");
  var speedIndex_json = findTableIndex(selectedJsonGameParametersTable, "speedOptions");
  var stimulusCategoryIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimulusCategoryOptions");
  var conceptGroupsIndex_json = findTableIndex(selectedJsonGameParametersTable, "conceptGroupsOptions");
  var newsNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "newsNumberOptions");
  var readingTimeIndex_json = findTableIndex(selectedJsonGameParametersTable, "readingTimeOptions");
  var answerTime2Index_json = findTableIndex(selectedJsonGameParametersTable, "answerTime2Options");
  var messageNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "messageNumberOptions");
  var messageVisibilityIndex_json = findTableIndex(selectedJsonGameParametersTable, "messageVisibilityOptions");
  var pictureNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "pictureNumberOptions");
  var imageTimeIndex_json = findTableIndex(selectedJsonGameParametersTable, "imageTimeOptions");
  var wordCountIndex_json = findTableIndex(selectedJsonGameParametersTable, "wordCountOptions");
  var wordVisibilityIndex_json = findTableIndex(selectedJsonGameParametersTable, "wordVisibilityOptions");
  var wordTimeIndex_json = findTableIndex(selectedJsonGameParametersTable, "wordTimeOptions");
  var answerTimeIndex_json = findTableIndex(selectedJsonGameParametersTable, "answerTimeOptions");
  var cardVisibilityIndex_json = findTableIndex(selectedJsonGameParametersTable, "cardVisibilityOptions");
  var drawNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "drawNumberOptions");
  var interferenceDurationIndex_json = findTableIndex(selectedJsonGameParametersTable, "interferenceDurationOptions");
  var exerciseTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "exerciseTypeRadioButtonGroup");
  var movementSpeedIndex_json = findTableIndex(selectedJsonGameParametersTable, "movementSpeedOptions");
  var numberDurationIndex_json = findTableIndex(selectedJsonGameParametersTable, "numberDurationOptions");
  var localizationIndex_json = findTableIndex(selectedJsonGameParametersTable, "localizationOptions");
  var figureTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "figureTypeRadioButtonGroup");
  var stackSizeIndex_json = findTableIndex(selectedJsonGameParametersTable, "stackSizeOptions");
  var gridSizeIndex_json = findTableIndex(selectedJsonGameParametersTable, "gridSizeOptions");
  var exerciseNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "exerciseNumberOptions");
  var beginnerIndex_json = findTableIndex(selectedJsonGameParametersTable, "beginnerOptions");
  var useOutlinesIndex_json = findTableIndex(selectedJsonGameParametersTable, "useOutlinesOptions");
  var gridPositionIndex_json = findTableIndex(selectedJsonGameParametersTable, "gridPositionOptions");
  var gridSize2Index_json = findTableIndex(selectedJsonGameParametersTable, "gridSize2Options");
  var choiceCount2Index_json = findTableIndex(selectedJsonGameParametersTable, "choiceCount2Options");
  var numberCountIndex_json = findTableIndex(selectedJsonGameParametersTable, "numberCountOptions");
  var exerciseLengthIndex_json = findTableIndex(selectedJsonGameParametersTable, "exerciseLengthOptions");
  var upperRowIndex_json = findTableIndex(selectedJsonGameParametersTable, "upperRowOptions");
  var upperRow2Index_json = findTableIndex(selectedJsonGameParametersTable, "upperRow2Options");
  var stringLengthIndex_json = findTableIndex(selectedJsonGameParametersTable, "stringLengthOptions");
  var letterIntervalIndex_json = findTableIndex(selectedJsonGameParametersTable, "letterIntervalOptions");
  var stringDirectionRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "stringDirectionRadioButtonGroup");
  var lowerRowIndex_json = findTableIndex(selectedJsonGameParametersTable, "lowerRowOptions");
  var lowerRow2Index_json = findTableIndex(selectedJsonGameParametersTable, "lowerRow2Options");
  var letterInterval2Index_json = findTableIndex(selectedJsonGameParametersTable, "letterInterval2Options");
  var stringDirectionRadioButtonGroup2Index_json = findTableIndex(selectedJsonGameParametersTable, "stringDirectionRadioButtonGroup2");
  var stringLength2Index_json = findTableIndex(selectedJsonGameParametersTable, "stringLength2Options");
  var seriesLengthIndex_json = findTableIndex(selectedJsonGameParametersTable, "seriesLengthOptions");
  var stimulusVisibilityIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimulusVisibilityOptions");
  var exerciseLength2Index_json = findTableIndex(selectedJsonGameParametersTable, "exerciseLength2Options");
  var stimulusTimeIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimulusTimeOptions");
  var additionalStimuliIndex_json = findTableIndex(selectedJsonGameParametersTable, "additionalStimuliOptions");
  var intervalTimeIndex_json = findTableIndex(selectedJsonGameParametersTable, "intervalTimeOptions");
  var seriesNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "seriesNumberOptions");
  var stimuliNumberIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimuliNumberOptions");
  var stimuliSizeIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimuliSizeOptions");
  var intervalBetweenStimuliIndex_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenStimuliOptions");
  var intervalBetweenStimuli2Index_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenStimuli2Options");
  var intervalBetweenInputIndex_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenInputOptions");
  var intervalBetweenInput2Index_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenInput2Options");
  var intervalBetweenAdvanceIndex_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenAdvanceOptions");
  var intervalBetweenAdvance2Index_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenAdvance2Options");
  var intervalBetweenAdvance3Index_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenAdvance3Options");
  var intervalBetweenAdvance4Index_json = findTableIndex(selectedJsonGameParametersTable, "intervalBetweenAdvance4Options");
  var fieldShapeRadioButtonGroup2Index_json = findTableIndex(selectedJsonGameParametersTable, "fieldShapeRadioButton2Group");
  var fieldShapeRadioButtonGroup3Index_json = findTableIndex(selectedJsonGameParametersTable, "fieldShapeRadioButton3Group");
  var fieldShapeRadioButtonGroup4Index_json = findTableIndex(selectedJsonGameParametersTable, "fieldShapeRadioButton4Group");
  var fieldShapeRadioButtonGroup5Index_json = findTableIndex(selectedJsonGameParametersTable, "fieldShapeRadioButton5Group");
  var stimuliRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimuliRadioButtonGroup");
  var stimuliRandomOptionsIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimuliRandomOptions");
  var stimulusVisibilityRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimulusVisibilityRadioButtonGroup");
  var difficultyRadioButtonGroup3Index_json = findTableIndex(selectedJsonGameParametersTable, "difficultyRadioButtonGroup3");
  var difficultyRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "difficultyRadioButtonGroup");
  var boardSizeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "boardSizeRadioButtonGroup");
  var boardSizeRadioButtonGroup2Index_json = findTableIndex(selectedJsonGameParametersTable, "boardSizeRadioButtonGroup2");
  var blackKnightsRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "blackKnightsRadioButtonGroup");
  var stimulusTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "stimulusTypeRadioButtonGroup");
  var exerciseStartedByRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "exerciseStartedByRadioButtonGroup");
  var targetPositionRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "targetPositionRadioButtonGroup");
  var quadrantTransportRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "quadrantTransportRadioButtonGroup");
  var pointSizeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "pointSizeRadioButtonGroup");
  var lineTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "lineTypeRadioButtonGroup");
  var bowlScaleRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "bowlScaleRadioButtonGroup");
  var fishScaleRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "fishScaleRadioButtonGroup");
  var returnTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "returnTypeRadioButtonGroup");
  var presentationTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "presentationTypeRadioButtonGroup");
  var nBackRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "nBackRadioButtonGroup");
  var interferenceTypeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "interferenceTypeRadioButtonGroup");
  var serieRecoveryRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "serieRecoveryRadioButtonGroup");
  var cellSizeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "cellSizeRadioButtonGroup");
  var layoutRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "layoutRadioButtonGroup");
  var letterSizeRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "letterSizeRadioButtonGroup");
  var advancedRadioButtonGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "advancedRadioButtonGroup");
  var searchCheckBoxIndex_json = findTableIndex(selectedJsonGameParametersTable, "searchOptions");
  var lettersIndex_json = findTableIndex(selectedJsonGameParametersTable, "lettersOptions");
  var letters2Index_json = findTableIndex(selectedJsonGameParametersTable, "letters2Options");
  var numbersIndex_json = findTableIndex(selectedJsonGameParametersTable, "numbersOptions");
  var numbers2Index_json = findTableIndex(selectedJsonGameParametersTable, "numbers2Options");
  var symbolsIndex_json = findTableIndex(selectedJsonGameParametersTable, "symbolsOptions");
  var symbols2Index_json = findTableIndex(selectedJsonGameParametersTable, "symbols2Options");
  var gapIndex_json = findTableIndex(selectedJsonGameParametersTable, "gapOptions");
  var preExerciseCheckBoxIndex_json = findTableIndex(selectedJsonGameParametersTable, "preExerciseOptions");
  var difficultyRadioButtonGroup4Index_json = findTableIndex(selectedJsonGameParametersTable, "difficultyRadioButtonGroup4");
  var wordsGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "wordsGroupOptions");
  var wordsGroup2Index_json = findTableIndex(selectedJsonGameParametersTable, "wordsGroup2Options");
  var wordsGroup3Index_json = findTableIndex(selectedJsonGameParametersTable, "wordsGroup3Options");
  var calculationsGroupIndex_json = findTableIndex(selectedJsonGameParametersTable, "calculationsGroupOptions");
  var calculationsGroup2Index_json = findTableIndex(selectedJsonGameParametersTable, "calculationsGroup2Options");
  var calculationsGroup3Index_json = findTableIndex(selectedJsonGameParametersTable, "calculationsGroup3Options");
  var calculationsGroup4Index_json = findTableIndex(selectedJsonGameParametersTable, "calculationsGroup4Options");
  var seriesNumber2Index_json = findTableIndex(selectedJsonGameParametersTable, "seriesNumber2Options");
  var interferenceTypeRadioButtonGroup2Index_json = findTableIndex(selectedJsonGameParametersTable, "interferenceTypeRadioButtonGroup2");
  var difficultyRadioButtonGroup5Index_json = findTableIndex(selectedJsonGameParametersTable, "difficultyRadioButtonGroup5");
}

function showNewRecipe(navigate) {
  navigate("/newrecipe");
}

function saveParameters(navigate, selectedGameParameters, selectedGameParametersTable) {
  for (var i = 0; i < selectedGameParametersTable.length; i++) {
    var gameElement = document.getElementById(selectedGameParametersTable[i]);

    if (gameElement !== undefined && gameElement !== null) {

      var gameElementSliderInput = gameElement.querySelector(".slider");
      var gameElementRadios = gameElement.querySelectorAll(".radioButton");
      var gameElementCheckBoxes = gameElement.querySelectorAll(".checkBox");
      var gameElementSelect = gameElement.querySelector(".EditParameters_select");

      if (gameElementSliderInput !== undefined && gameElementSliderInput !== null) {
        selectedGameParameters[i].default = [ gameElementSliderInput.value.toString() ];
      }
      else if (gameElementRadios !== undefined && gameElementRadios !== null && gameElementRadios.length !== 0) {
        for (var j = 0; j < gameElementRadios.length; j++) {
          if (gameElementRadios[j].checked === true) {
            selectedGameParameters[i].default = [ gameElementRadios[j].value.toString() ];

            break;
          }
        }
      }
      else if (gameElementCheckBoxes !== undefined && gameElementCheckBoxes !== null && gameElementCheckBoxes.length !== 0) {
        var checkedJsonArray = [];

        for (var k = 0; k < gameElementCheckBoxes.length; k++) {
          if (gameElementCheckBoxes[k].checked === true) {
            checkedJsonArray.push(gameElementCheckBoxes[k].value);
          }
        }

        selectedGameParameters[i].default = checkedJsonArray;
      }
      else if (gameElementSelect !== undefined && gameElementSelect !== null) {
        selectedGameParameters[i].default = [ gameElementSelect.options[gameElementSelect.selectedIndex].value.toString() ];
      }


    }
  }

  localStorage.setItem("selectedGameParameters_" + selectedGameId, JSON.stringify(selectedGameParameters));

  navigate("/newrecipe");
}

function resetSettings(t) {
  document.getElementById("overlay").style.display = "block";

  var lettersSelect = document.getElementById("letters");
  var letters2Select = document.getElementById("letters2");
  var numbersSelect = document.getElementById("numbers");
  var numbers2Select = document.getElementById("numbers2");
  var symbolsSelect = document.getElementById("symbols");
  var symbols2Select = document.getElementById("symbols2");
  var gapSelect = document.getElementById("gap");
  var moveDirection = document.getElementById("moveDirection");
  var choiceCount = document.getElementById("choiceCount");
  var stimulusType = document.getElementById("stimulusType");
  var typeOfExerciseSelect = document.getElementById("type");
  var typeOfExerciseSelect2 = document.getElementById("type2");
  var typeOfExerciseSelect3 = document.getElementById("type3");
  var difficultyOfExerciseSelect = document.getElementById("difficulty");
  var difficultyOfSearchExerciseSelect = document.getElementById("difficultySearch");
  var shapeTypeOfExerciseSelect = document.getElementById("shapeType");
  var soundTypeOfExerciseSelect = document.getElementById("soundType");
  var gridPosition = document.getElementById("gridPosition");
  var gridSize = document.getElementById("gridSize");
  var beginner = document.getElementById("beginner");
  var boardType = document.getElementById("boardType");
  var sudokuSize = document.getElementById("sudokuSize");
  var seriesNumberLabel2 = document.getElementById("seriesNumberLabel2");
  var seriesNumberSlider2 = document.getElementById("seriesNumberSlider2");
  var stimuliAdvanceMaxLabel2 = document.getElementById("stimuliAdvanceMaxLabel2");
  var stimuliAdvanceMaxSlider2 = document.getElementById("stimuliAdvanceMaxSlider2");
  var stimuliAdvanceMinLabel2 = document.getElementById("stimuliAdvanceMinLabel2");
  var stimuliAdvanceMinSlider2 = document.getElementById("stimuliAdvanceMinSlider2");
  var stimuliInputMinLabel = document.getElementById("stimuliInputMinLabel");
  var stimuliInputMinSlider = document.getElementById("stimuliInputMinSlider");
  var stimuliInputMaxLabel = document.getElementById("stimuliInputMaxLabel");
  var stimuliInputMaxSlider = document.getElementById("stimuliInputMaxSlider");
  var stimulusVisibilityLabel = document.getElementById("stimulusVisibilityLabel");
  var stimulusVisibilitySlider = document.getElementById("stimulusVisibilitySlider");
  var towerHeightLabel = document.getElementById("towerHeightLabel");
  var towerHeightSlider = document.getElementById("towerHeightSlider");
  var pearlAmountLabel = document.getElementById("pearlAmountLabel");
  var pearlAmountSlider = document.getElementById("pearlAmountSlider");
  var availableSlotsLabel = document.getElementById("availableSlotsLabel");
  var availableSlotsSlider = document.getElementById("availableSlotsSlider");
  var towerSizeLabel = document.getElementById("towerSizeLabel");
  var towerSizeSlider = document.getElementById("towerSizeSlider");
  var solvedLettersLabel = document.getElementById("solvedLettersLabel");
  var solvedLettersSlider = document.getElementById("solvedLettersSlider");
  var hintNumberLabel = document.getElementById("hintNumberLabel");
  var hintNumberSlider = document.getElementById("hintNumberSlider");
  var numbersDeducedLabel = document.getElementById("numbersDeducedLabel");
  var numbersDeducedSlider = document.getElementById("numbersDeducedSlider");
  var shapesVisibleLabel = document.getElementById("shapesVisibleLabel");
  var shapesVisibleSlider = document.getElementById("shapesVisibleSlider");
  var shapesDeducedLabel = document.getElementById("shapesDeducedLabel");
  var shapesDeducedSlider = document.getElementById("shapesDeducedSlider");
  var colorLengthLabel = document.getElementById("colorLengthLabel");
  var colorLengthSlider = document.getElementById("colorLengthSlider");
  var colorNumberLabel = document.getElementById("colorNumberLabel");
  var colorNumberSlider = document.getElementById("colorNumberSlider");
  var wordLengthLabel = document.getElementById("wordLengthLabel");
  var wordLengthSlider = document.getElementById("wordLengthSlider");
  var optionsNumberLabel2 = document.getElementById("optionsNumberLabel2");
  var optionsNumberSlider2 = document.getElementById("optionsNumberSlider2");
  var repeatNumberLabel = document.getElementById("repeatNumberLabel");
  var repeatNumberSlider = document.getElementById("repeatNumberSlider");
  var mazeSizeLabel = document.getElementById("mazeSizeLabel");
  var mazeSizeSlider = document.getElementById("mazeSizeSlider");
  var gridSizeLabel = document.getElementById("gridSizeLabel");
  var gridSizeSlider = document.getElementById("gridSizeSlider");
  var stackSizeLabel = document.getElementById("stackSizeLabel");
  var stackSizeSlider = document.getElementById("stackSizeSlider");
  var choiceCountLabel = document.getElementById("choiceCountLabel");
  var choiceCountSlider = document.getElementById("choiceCountSlider");
  var optionsNumberLabel = document.getElementById("optionsNumberLabel");
  var optionsNumberSlider = document.getElementById("optionsNumberSlider");
  var rotatingLineLabel = document.getElementById("rotatingLineLabel");
  var rotatingLineSlider = document.getElementById("rotatingLineSlider");
  var performanceTimeLabel = document.getElementById("performanceTimeLabel");
  var performanceTimeSlider = document.getElementById("performanceTimeSlider");
  var performanceTimeLabel2 = document.getElementById("performanceTimeLabel2");
  var performanceTimeSlider2 = document.getElementById("performanceTimeSlider2");
  var stimulusCategoryLabel = document.getElementById("stimulusCategoryLabel");
  var stimulusCategorySlider = document.getElementById("stimulusCategorySlider");
  var conceptGroupsLabel = document.getElementById("conceptGroupsLabel");
  var conceptGroupsSlider = document.getElementById("conceptGroupsSlider");
  var newsNumberLabel = document.getElementById("newsNumberLabel");
  var newsNumberSlider = document.getElementById("newsNumberSlider");
  var readingTimeLabel = document.getElementById("readingTimeLabel");
  var readingTimeSlider = document.getElementById("readingTimeSlider");
  var answerTimeLabel2 = document.getElementById("answerTimeLabel2");
  var answerTimeSlider2 = document.getElementById("answerTimeSlider2");
  var messageNumberLabel = document.getElementById("messageNumberLabel");
  var messageNumberSlider = document.getElementById("messageNumberSlider");
  var messageVisibilityLabel = document.getElementById("messageVisibilityLabel");
  var messageVisibilitySlider = document.getElementById("messageVisibilitySlider");
  var additionalStimuliLabel = document.getElementById("additionalStimuliLabel");
  var additionalStimuliSlider = document.getElementById("additionalStimuliSlider");
  var pictureNumberLabel = document.getElementById("pictureNumberLabel");
  var pictureNumberSlider = document.getElementById("pictureNumberSlider");
  var imageTimeLabel = document.getElementById("imageTimeLabel");
  var imageTimeSlider = document.getElementById("imageTimeSlider");
  var wordCountLabel = document.getElementById("wordCountLabel");
  var wordCountSlider = document.getElementById("wordCountSlider");
  var wordVisibilityLabel = document.getElementById("wordVisibilityLabel");
  var wordVisibilitySlider = document.getElementById("wordVisibilitySlider");
  var wordTimeLabel = document.getElementById("wordTimeLabel");
  var wordTimeSlider = document.getElementById("wordTimeSlider");
  var answerTimeLabel = document.getElementById("answerTimeLabel");
  var answerTimeSlider = document.getElementById("answerTimeSlider");
  var cardVisibilityLabel = document.getElementById("cardVisibilityLabel");
  var cardVisibilitySlider = document.getElementById("cardVisibilitySlider");
  var drawNumberLabel = document.getElementById("drawNumberLabel");
  var drawNumberSlider = document.getElementById("drawNumberSlider");
  var interferenceDurationLabel = document.getElementById("interferenceDurationLabel");
  var interferenceDurationSlider = document.getElementById("interferenceDurationSlider");
  var seriesNumberLabel = document.getElementById("seriesNumberLabel");
  var seriesNumberSlider = document.getElementById("seriesNumberSlider");
  var numberCountLabel = document.getElementById("numberCountLabel");
  var numberCountSlider = document.getElementById("numberCountSlider");
  var movementSpeedLabel = document.getElementById("movementSpeedLabel");
  var movementSpeedSlider = document.getElementById("movementSpeedSlider");
  var speedLabel = document.getElementById("speedLabel");
  var speedSlider = document.getElementById("speedSlider");
  var exerciseNumberLabel = document.getElementById("exerciseNumberLabel");
  var exerciseNumberSlider = document.getElementById("exerciseNumberSlider");
  var localizationLabel = document.getElementById("localizationLabel");
  var localizationSlider = document.getElementById("localizationSlider");
  var numberDurationLabel = document.getElementById("numberDurationLabel");
  var numberDurationSlider = document.getElementById("numberDurationSlider");
  var letterIntervalLabel = document.getElementById("letterIntervalLabel");
  var letterIntervalSlider = document.getElementById("letterIntervalSlider");
  var letterIntervalLabel2 = document.getElementById("letterIntervalLabel2");
  var letterIntervalSlider2 = document.getElementById("letterIntervalSlider2");
  var stringLengthLabel = document.getElementById("stringLengthLabel");
  var stringLengthSlider = document.getElementById("stringLengthSlider");
  var stringLengthLabel2 = document.getElementById("stringLengthLabel2");
  var stringLengthSlider2 = document.getElementById("stringLengthSlider2");
  var intervalTimeLabel = document.getElementById("intervalTimeLabel");
  var intervalTimeSlider = document.getElementById("intervalTimeSlider");
  var stimulusTimeLabel = document.getElementById("stimulusTimeLabel");
  var stimulusTimeSlider = document.getElementById("stimulusTimeSlider");
  var exerciseLengthLabel = document.getElementById("exerciseLengthLabel");
  var exerciseLengthSlider = document.getElementById("exerciseLengthSlider");
  var exerciseLengthLabel2 = document.getElementById("exerciseLengthLabel2");
  var exerciseLengthSlider2 = document.getElementById("exerciseLengthSlider2");
  var seriesLengthLabel = document.getElementById("seriesLengthLabel");
  var seriesLengthSlider = document.getElementById("seriesLengthSlider");
  var stimuliNumberLabel = document.getElementById("stimuliNumberLabel");
  var stimuliNumberSlider = document.getElementById("stimuliNumberSlider");
  var stimuliSizeLabel = document.getElementById("stimuliSizeLabel");
  var stimuliSizeSlider = document.getElementById("stimuliSizeSlider");
  var stimuliMinLabel = document.getElementById("stimuliMinLabel");
  var stimuliMinSlider = document.getElementById("stimuliMinSlider");
  var stimuliMaxLabel = document.getElementById("stimuliMaxLabel");
  var stimuliMaxSlider = document.getElementById("stimuliMaxSlider");
  var stimuliAdvanceMinLabel = document.getElementById("stimuliAdvanceMinLabel");
  var stimuliAdvanceMinSlider = document.getElementById("stimuliAdvanceMinSlider");
  var stimuliAdvanceMaxLabel = document.getElementById("stimuliAdvanceMaxLabel");
  var stimuliAdvanceMaxSlider = document.getElementById("stimuliAdvanceMaxSlider");
  var stringLeft = document.getElementById("stringLeft");
  var stringLeft2 = document.getElementById("stringLeft2");
  var stimuliRandom = document.getElementById("stimuliRandom");
  var cellMedium = document.getElementById("cellMedium");
  var layoutConstant = document.getElementById("layoutConstant");
  var advancedFish = document.getElementById("advancedFish");
  var letterSmall = document.getElementById("letterSmall");
  var serieForward = document.getElementById("serieForward");
  var interferenceMath = document.getElementById("interferenceMath");
  var interference2Math = document.getElementById("interference2Math");
  var nBack2 = document.getElementById("nBack2");
  var presentationAsText = document.getElementById("presentationAsText");
  var returnAfterButton = document.getElementById("returnAfterButton");
  var exerciseFish = document.getElementById("exerciseFish");
  var bowlMedium = document.getElementById("bowlMedium");
  var fishSmall = document.getElementById("fishSmall");
  var lineRadius = document.getElementById("lineRadius");
  var imagePhotograph = document.getElementById("imagePhotograph");
  var Size4x4 = document.getElementById("Size4x4");
  var figureGeometric = document.getElementById("figureGeometric");
  var stimulusFlashes = document.getElementById("stimulusFlashes");
  var transportingButtons = document.getElementById("transportingButtons");
  var difficultyEasy = document.getElementById("difficultyEasy");
  var difficulty5Normal = document.getElementById("difficulty5Normal");
  var positionRandom = document.getElementById("positionRandom");
  var difficultyA1 = document.getElementById("difficultyA1");
  var difficultyLevel1 = document.getElementById("difficultyLevel1");
  var exerciseUser = document.getElementById("exerciseUser");
  var board8x8 = document.getElementById("board8x8");
  var board2_8x8 = document.getElementById("board2_8x8");
  var blackKnights4 = document.getElementById("blackKnights4");
  var stimulusSeen = document.getElementById("stimulusSeen");
  var difficulty4Easy = document.getElementById("difficulty4Easy");
  var preExerciseCheckBox = document.getElementById("preExerciseCheckBox");
  var searchCheckBox = document.getElementById("searchCheckBox");
  var professionsCheckBox = document.getElementById("professionsCheckBox");
  var professionsCheckBox2 = document.getElementById("professionsCheckBox2");
  var professionsCheckBox3 = document.getElementById("professionsCheckBox3");
  var additionCheckBox = document.getElementById("additionCheckBox");
  var soundFeedBackCheckBox = document.getElementById("soundFeedBackCheckBox");
  var allowRepeatCheckBox = document.getElementById("allowRepeatCheckBox");
  var foodStuffCheckBox = document.getElementById("foodStuffCheckBox");
  var foodStuffCheckBox2 = document.getElementById("foodStuffCheckBox2");
  var useOutlinesCheckBox = document.getElementById("useOutlinesCheckBox");
  var instantFeedbackCheckBox = document.getElementById("instantFeedbackCheckBox");
  var stimuliRandomCheckBox = document.getElementById("stimuliRandomCheckBox");
  var shapeRectangle = document.getElementById("shapeRectangle");
  var shapeCircle = document.getElementById("shapeCircle");
  var shapeDiamond = document.getElementById("shapeDiamond");
  var shapeRectangleSlider = document.getElementById("shapeRectangleSlider");
  var shapeCircleSlider = document.getElementById("shapeCircleSlider");
  var shapeDiamondSlider = document.getElementById("shapeDiamondSlider");

  if (searchCheckBox !== undefined && searchCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, searchCheckBoxIndex, [], unlockAdvancedOptions);
  }

  if (lettersSelect !== undefined && lettersSelect !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, lettersIndex_json, "1", unlockAdvancedOptions);
  }

  if (letters2Select !== undefined && letters2Select !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, letters2Index_json, "1", unlockAdvancedOptions);
  }

  if (numbersSelect !== undefined && numbersSelect !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, numbersIndex_json, "1", unlockAdvancedOptions);
  }

  if (numbers2Select !== undefined && numbers2Select !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, numbers2Index_json, "1", unlockAdvancedOptions);
  }

  if (symbolsSelect !== undefined && symbolsSelect !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, symbolsIndex_json, "1", unlockAdvancedOptions);
  }

  if (symbols2Select !== undefined && symbols2Select !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, symbols2Index_json, "1", unlockAdvancedOptions);
  }

  if (gapSelect !== undefined && gapSelect !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, gapIndex_json, "0", unlockAdvancedOptions);
  }

  if (moveDirection !== undefined && moveDirection !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, moveDirectionIndex_json, "1");
  }

  if (choiceCount !== undefined && choiceCount !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, choiceCountIndex_json, "1");
  }

  if (stimulusType !== undefined && stimulusType !== null) {
    checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, stimulusTypeIndex_json, "0");
  }

  if (typeOfExerciseSelect !== undefined && typeOfExerciseSelect !== null) {
    typeOfExerciseSelect.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, typeIndex_json, "0", setAdvancedOptions);
  }

  if (typeOfExerciseSelect2 !== undefined && typeOfExerciseSelect2 !== null) {
    typeOfExerciseSelect2.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, type2Index_json, "0");
  }

  if (typeOfExerciseSelect3 !== undefined && typeOfExerciseSelect3 !== null) {
    typeOfExerciseSelect3.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, type3Index_json, "0");
  }

  if (difficultyOfExerciseSelect !== undefined && difficultyOfExerciseSelect !== null) {
    difficultyOfExerciseSelect.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, difficultyIndex_json, "0");
  }

  if (difficultyOfSearchExerciseSelect !== undefined && difficultyOfSearchExerciseSelect !== null) {
    difficultyOfSearchExerciseSelect.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, difficultySearchIndex_json, "0", setAdvanced2Options);
  }

  if (shapeTypeOfExerciseSelect !== undefined && shapeTypeOfExerciseSelect !== null) {
    shapeTypeOfExerciseSelect.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, shapeTypeIndex_json, "0");
  }

  if (soundTypeOfExerciseSelect !== undefined && soundTypeOfExerciseSelect !== null) {
    soundTypeOfExerciseSelect.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, soundTypeIndex_json, "0");
  }

  if (gridPosition !== undefined && gridPosition !== null) {
    gridPosition.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, gridPositionIndex_json, "1");
  }

  if (gridSize !== undefined && gridSize !== null) {
    gridSize.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, gridSize2Index_json, "2");
  }

  if (beginner !== undefined && beginner !== null) {
    beginner.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, beginnerIndex_json, "0");
  }

  if (boardType !== undefined && boardType !== null) {
    boardType.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, boardTypeIndex_json, "0");
  }

  if (sudokuSize !== undefined && sudokuSize !== null) {
    sudokuSize.selectedIndex = checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, sudokuSizeIndex_json, "0");
  }

  if (seriesNumberLabel2 !== undefined && seriesNumberLabel2 !== null) {
    seriesNumberLabel2.innerHTML = "4";
  }

  if (seriesNumberSlider2 !== undefined && seriesNumberSlider2 !== null) {
    seriesNumberSlider2.value = checkDefault2(selectedJsonGameParametersTable, seriesNumber2Index_json, "4", "seriesNumberLabel2");
  }

  if (stimuliInputMinLabel !== undefined && stimuliInputMinLabel !== null) {
    stimuliInputMinLabel.innerHTML = "1.00 s";
  }

  if (stimuliInputMinSlider !== undefined && stimuliInputMinSlider !== null) {
    stimuliInputMinSlider.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenInputIndex_json, "1", "stimuliInputMinLabel", "s", 2);
  }

  if (stimuliInputMaxLabel !== undefined && stimuliInputMaxLabel !== null) {
    stimuliInputMaxLabel.innerHTML = "4.00 s";
  }

  if (stimuliInputMaxSlider !== undefined && stimuliInputMaxSlider !== null) {
    stimuliInputMaxSlider.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenInput2Index_json, "4", "stimuliInputMaxLabel", "s", 2);
  }

  if (stimulusVisibilityLabel !== undefined && stimulusVisibilityLabel !== null) {
    stimulusVisibilityLabel.innerHTML = "2.0 s";
  }

  if (stimulusVisibilitySlider !== undefined && stimulusVisibilitySlider !== null) {
    stimulusVisibilitySlider.value = checkDefault2(selectedJsonGameParametersTable, stimulusVisibilityIndex_json, "2", "stimulusVisibilityLabel", "s", 1);
  }

  if (towerHeightLabel !== undefined && towerHeightLabel !== null) {
    towerHeightLabel.innerHTML = "4";
  }

  if (towerHeightSlider !== undefined && towerHeightSlider !== null) {
    towerHeightSlider.value = checkDefault2(selectedJsonGameParametersTable, towerHeightIndex_json, "4", "towerHeightLabel");
  }

  if (pearlAmountLabel !== undefined && pearlAmountLabel !== null) {
    pearlAmountLabel.innerHTML = "4";
  }

  if (pearlAmountSlider !== undefined && pearlAmountSlider !== null) {
    pearlAmountSlider.value = checkDefault2(selectedJsonGameParametersTable, pearlAmountIndex_json, "4", "pearlAmountLabel");
  }

  if (availableSlotsLabel !== undefined && availableSlotsLabel !== null) {
    availableSlotsLabel.innerHTML = "10";
  }

  if (availableSlotsSlider !== undefined && availableSlotsSlider !== null) {
    availableSlotsSlider.value = checkDefault2(selectedJsonGameParametersTable, availableSlotsIndex_json, "10", "availableSlotsLabel");
  }

  if (towerSizeLabel !== undefined && towerSizeLabel !== null) {
    towerSizeLabel.innerHTML = "4";
  }

  if (towerSizeSlider !== undefined && towerSizeSlider !== null) {
    towerSizeSlider.value = checkDefault2(selectedJsonGameParametersTable, towerSizeIndex_json, "4", "towerSizeLabel");
  }

  if (solvedLettersLabel !== undefined && solvedLettersLabel !== null) {
    solvedLettersLabel.innerHTML = "20 %";
  }

  if (solvedLettersSlider !== undefined && solvedLettersSlider !== null) {
    solvedLettersSlider.value = checkDefault2(selectedJsonGameParametersTable, solvedLettersIndex_json, "20", "solvedLettersLabel", "%");
  }

  if (hintNumberLabel !== undefined && hintNumberLabel !== null) {
    hintNumberLabel.innerHTML = "6";
  }

  if (hintNumberSlider !== undefined && hintNumberSlider !== null) {
    hintNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, hintNumberIndex_json, "6", "hintNumberLabel");
  }

  if (numbersDeducedLabel !== undefined && numbersDeducedLabel !== null) {
    numbersDeducedLabel.innerHTML = "2";
  }

  if (numbersDeducedSlider !== undefined && numbersDeducedSlider !== null) {
    numbersDeducedSlider.value = checkDefault2(selectedJsonGameParametersTable, numbersDeducedIndex_json, "2", "numbersDeducedLabel");
  }

  if (shapesVisibleLabel !== undefined && shapesVisibleLabel !== null) {
    shapesVisibleLabel.innerHTML = "8 " + t("local_parameters_pcs");
  }

  if (shapesVisibleSlider !== undefined && shapesVisibleSlider !== null) {
    shapesVisibleSlider.value = checkDefault2(selectedJsonGameParametersTable, shapesVisibleIndex_json, "8", "shapesVisibleLabel", t("local_parameters_pcs"));
  }

  if (shapesDeducedLabel !== undefined && shapesDeducedLabel !== null) {
    shapesDeducedLabel.innerHTML = "2 " + t("local_parameters_pcs");
  }

  if (shapesDeducedSlider !== undefined && shapesDeducedSlider !== null) {
    shapesDeducedSlider.value = checkDefault2(selectedJsonGameParametersTable, shapesDeducedIndex_json, "2", "shapesDeducedLabel", t("local_parameters_pcs"));
  }

  if (colorLengthLabel !== undefined && colorLengthLabel !== null) {
    colorLengthLabel.innerHTML = "4";
  }

  if (colorLengthSlider !== undefined && colorLengthSlider !== null) {
    colorLengthSlider.value = checkDefault2(selectedJsonGameParametersTable, colorLengthIndex_json, "4", "colorLengthLabel");
  }

  if (colorNumberLabel !== undefined && colorNumberLabel !== null) {
    colorNumberLabel.innerHTML = "6";
  }

  if (colorNumberSlider !== undefined && colorNumberSlider !== null) {
    colorNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, colorNumberIndex_json, "6", "colorNumberLabel");
  }

  if (wordLengthLabel !== undefined && wordLengthLabel !== null) {
    wordLengthLabel.innerHTML = "4 " + t("local_parameters_letters3");
  }

  if (wordLengthSlider !== undefined && wordLengthSlider !== null) {
    wordLengthSlider.value = checkDefault2(selectedJsonGameParametersTable, wordLengthIndex_json, "4", "wordLengthLabel", "letters");
  }

  if (optionsNumberLabel2 !== undefined && optionsNumberLabel2 !== null) {
    optionsNumberLabel2.innerHTML = "5";
  }

  if (optionsNumberSlider2 !== undefined && optionsNumberSlider2 !== null) {
    optionsNumberSlider2.value = checkDefault2(selectedJsonGameParametersTable, optionsNumber2Index_json, "5", "optionsNumberLabel2");
    optionsNumberSlider2.removeAttribute('disabled');
  }

  if (repeatNumberLabel !== undefined && repeatNumberLabel !== null) {
    repeatNumberLabel.innerHTML = "5";
  }

  if (repeatNumberSlider !== undefined && repeatNumberSlider !== null) {
    repeatNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, repeatNumberIndex_json, "5", "repeatNumberLabel");
  }

  if (mazeSizeLabel !== undefined && mazeSizeLabel !== null) {
    mazeSizeLabel.innerHTML = "5";
  }

  if (mazeSizeSlider !== undefined && mazeSizeSlider !== null) {
    mazeSizeSlider.value = checkDefault2(selectedJsonGameParametersTable, mazeSizeIndex_json, "5", "mazeSizeLabel");
  }

  if (gridSizeLabel !== undefined && gridSizeLabel !== null) {
    gridSizeLabel.innerHTML = "3x3";
  }

  if (gridSizeSlider !== undefined && gridSizeSlider !== null) {
    gridSizeSlider.value = checkDefault2(selectedJsonGameParametersTable, gridSizeIndex_json, "3", "gridSizeLabel", null, null, null, null, null, "grid");
  }

  if (stackSizeLabel !== undefined && stackSizeLabel !== null) {
    stackSizeLabel.innerHTML = "3x3x3";
  }

  if (stackSizeSlider !== undefined && stackSizeSlider !== null) {
    stackSizeSlider.value = checkDefault2(selectedJsonGameParametersTable, stackSizeIndex_json, "3", "stackSizeLabel", null, null, null, null, null, "stack");
  }

  if (choiceCountLabel !== undefined && choiceCountLabel !== null) {
    choiceCountLabel.innerHTML = "4";
  }

  if (choiceCountSlider !== undefined && choiceCountSlider !== null) {
    choiceCountSlider.value = checkDefault2(selectedJsonGameParametersTable, choiceCount2Index_json, "4", "choiceCountLabel");
  }

  if (optionsNumberLabel !== undefined && optionsNumberLabel !== null) {
    optionsNumberLabel.innerHTML = "4";
  }

  if (optionsNumberSlider !== undefined && optionsNumberSlider !== null) {
    optionsNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, optionsNumberIndex_json, "4", "optionsNumberLabel");
  }

  if (rotatingLineLabel !== undefined && rotatingLineLabel !== null) {
    rotatingLineLabel.innerHTML = "2";
  }

  if (rotatingLineSlider !== undefined && rotatingLineSlider !== null) {
    rotatingLineSlider.value = checkDefault2(selectedJsonGameParametersTable, rotatingLineIndex_json, "2", "rotatingLineLabel");
  }

  if (performanceTimeLabel !== undefined && performanceTimeLabel !== null) {
    performanceTimeLabel.innerHTML = "1 min 0 s";
  }

  if (performanceTimeSlider !== undefined && performanceTimeSlider !== null) {
    performanceTimeSlider.value = checkDefault2(selectedJsonGameParametersTable, performanceTimeIndex_json, "60", "performanceTimeLabel", null, null, null, "minutes", t("local_parameters_unlimited"));
  }

  if (performanceTimeLabel2 !== undefined && performanceTimeLabel2 !== null) {
    performanceTimeLabel2.innerHTML = "3 min 0 s";
  }

  if (performanceTimeSlider2 !== undefined && performanceTimeSlider2 !== null) {
    performanceTimeSlider2.value = checkDefault2(selectedJsonGameParametersTable, performanceTime2Index_json, "180", "performanceTimeLabel2", null, null, null, "minutes", t("local_parameters_unlimited"));
  }

  if (stimulusCategoryLabel !== undefined && stimulusCategoryLabel !== null) {
    stimulusCategoryLabel.innerHTML = "2";
  }

  if (stimulusCategorySlider !== undefined && stimulusCategorySlider !== null) {
    stimulusCategorySlider.value = checkDefault2(selectedJsonGameParametersTable, stimulusCategoryIndex_json, "2", "stimulusCategoryLabel");
  }

  if (conceptGroupsLabel !== undefined && conceptGroupsLabel !== null) {
    conceptGroupsLabel.innerHTML = "2";
  }

  if (conceptGroupsSlider !== undefined && conceptGroupsSlider !== null) {
    conceptGroupsSlider.value = checkDefault2(selectedJsonGameParametersTable, conceptGroupsIndex_json, "2", "conceptGroupsLabel");
  }

  if (newsNumberLabel !== undefined && newsNumberLabel !== null) {
    newsNumberLabel.innerHTML = "3 " + t("local_parameters_pcs");
  }

  if (newsNumberSlider !== undefined && newsNumberSlider !== null) {
    newsNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, newsNumberIndex_json, "3", "newsNumberLabel", t("local_parameters_pcs"));
  }

  if (readingTimeLabel !== undefined && readingTimeLabel !== null) {
    readingTimeLabel.innerHTML = "2 min 0 s";
  }

  if (readingTimeSlider !== undefined && readingTimeSlider !== null) {
    readingTimeSlider.value = checkDefault2(selectedJsonGameParametersTable, readingTimeIndex_json, "120", "readingTimeLabel", null, null, null, "minutes", t("local_parameters_unlimited"));
  }

  if (messageNumberLabel !== undefined && messageNumberLabel !== null) {
    messageNumberLabel.innerHTML = "5 " + t("local_parameters_pcs");
  }

  if (messageNumberSlider !== undefined && messageNumberSlider !== null) {
    messageNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, messageNumberIndex_json, "5", "messageNumberLabel", t("local_parameters_pcs"));
  }

  if (messageVisibilityLabel !== undefined && messageVisibilityLabel !== null) {
    messageVisibilityLabel.innerHTML = "30 s";
  }

  if (messageVisibilitySlider !== undefined && messageVisibilitySlider !== null) {
    messageVisibilitySlider.value = checkDefault2(selectedJsonGameParametersTable, messageVisibilityIndex_json, "30", "messageVisibilityLabel", "s");
  }

  if (additionalStimuliLabel !== undefined && additionalStimuliLabel !== null) {
    additionalStimuliLabel.innerHTML = "+ 50 %";
  }

  if (additionalStimuliSlider !== undefined && additionalStimuliSlider !== null) {
    additionalStimuliSlider.value = checkDefault2(selectedJsonGameParametersTable, additionalStimuliIndex_json, "50", "additionalStimuliLabel", "%", null, null, null, null, null, "+");
  }

  if (pictureNumberLabel !== undefined && pictureNumberLabel !== null) {
    pictureNumberLabel.innerHTML = "20";
  }

  if (pictureNumberSlider !== undefined && pictureNumberSlider !== null) {
    pictureNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, pictureNumberIndex_json, "20", "pictureNumberSlider");
  }

  if (imageTimeLabel !== undefined && imageTimeLabel !== null) {
    imageTimeLabel.innerHTML = "4.5 s";
  }

  if (imageTimeSlider !== undefined && imageTimeSlider !== null) {
    imageTimeSlider.value = checkDefault2(selectedJsonGameParametersTable, imageTimeIndex_json, "4.5", "imageTimeLabel", "s", 1);
  }

  if (wordCountLabel !== undefined && wordCountLabel !== null) {
    wordCountLabel.innerHTML = "10 " + t("local_parameters_words2");
  }

  if (wordCountSlider !== undefined && wordCountSlider !== null) {
    wordCountSlider.value = checkDefault2(selectedJsonGameParametersTable, wordCountIndex_json, "10", "wordCountLabel", t("local_parameters_words2"));
  }

  if (wordVisibilityLabel !== undefined && wordVisibilityLabel !== null) {
    wordVisibilityLabel.innerHTML = "2.0 s";
  }

  if (wordVisibilitySlider !== undefined && wordVisibilitySlider !== null) {
    wordVisibilitySlider.value = checkDefault2(selectedJsonGameParametersTable, wordVisibilityIndex_json, "2", "wordVisibilityLabel", "s", 1);
  }

  if (wordTimeLabel !== undefined && wordTimeLabel !== null) {
    wordTimeLabel.innerHTML = "0.5 s";
  }

  if (wordTimeSlider !== undefined && wordTimeSlider !== null) {
    wordTimeSlider.value = checkDefault2(selectedJsonGameParametersTable, wordTimeIndex_json, "0.5", "wordTimeLabel", "s", 1);
  }

  if (answerTimeLabel2 !== undefined && answerTimeLabel2 !== null) {
    answerTimeLabel2.innerHTML = t("local_parameters_unlimited");
  }

  if (answerTimeSlider2 !== undefined && answerTimeSlider2 !== null) {
    answerTimeSlider2.value = checkDefault2(selectedJsonGameParametersTable, answerTime2Index_json, "0", "answerTimeLabel2", null, null, null, "minutes", t("local_parameters_unlimited"));
  }

  if (answerTimeLabel !== undefined && answerTimeLabel !== null) {
    answerTimeLabel.innerHTML = "4.0 s";
  }

  if (answerTimeSlider !== undefined && answerTimeSlider !== null) {
    answerTimeSlider.value = checkDefault2(selectedJsonGameParametersTable, answerTimeIndex_json, "4", "answerTimeLabel", "s", 1);
  }

  if (cardVisibilityLabel !== undefined && cardVisibilityLabel !== null) {
    cardVisibilityLabel.innerHTML = "4.0 s";
  }

  if (cardVisibilitySlider !== undefined && cardVisibilitySlider !== null) {
    cardVisibilitySlider.value = checkDefault2(selectedJsonGameParametersTable, cardVisibilityIndex_json, "4", "cardVisibilityLabel", "s", 1);
  }

  if (drawNumberLabel !== undefined && drawNumberLabel !== null) {
    drawNumberLabel.innerHTML = "25 " + t("local_parameters_times");
  }

  if (drawNumberSlider !== undefined && drawNumberSlider !== null) {
    drawNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, drawNumberIndex_json, "25", "drawNumberLabel", t("local_parameters_times"));
  }

  if (interferenceDurationLabel !== undefined && interferenceDurationLabel !== null) {
    interferenceDurationLabel.innerHTML = "60 s";
  }

  if (interferenceDurationSlider !== undefined && interferenceDurationSlider !== null) {
    interferenceDurationSlider.value = checkDefault2(selectedJsonGameParametersTable, interferenceDurationIndex_json, "60", "interferenceDurationLabel", "s");
  }

  if (seriesNumberLabel !== undefined && seriesNumberLabel !== null) {
    seriesNumberLabel.innerHTML = "3 " + t("local_parameters_pcs");
  }

  if (seriesNumberSlider !== undefined && seriesNumberSlider !== null) {
    seriesNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, seriesNumberIndex_json, "3", "seriesNumberLabel", t("local_parameters_pcs"));
  }

  if (numberCountLabel !== undefined && numberCountLabel !== null) {
    numberCountLabel.innerHTML = "60";
  }

  if (numberCountSlider !== undefined && numberCountSlider !== null) {
    numberCountSlider.value = checkDefault2(selectedJsonGameParametersTable, numberCountIndex_json, "60", "numberCountLabel");
  }

  if (movementSpeedLabel !== undefined && movementSpeedLabel !== null) {
    movementSpeedLabel.innerHTML = "40 %";
  }

  if (movementSpeedSlider !== undefined && movementSpeedSlider !== null) {
    movementSpeedSlider.value = checkDefault2(selectedJsonGameParametersTable, movementSpeedIndex_json, "40", "movementSpeedLabel", "%");
  }

  if (speedLabel !== undefined && speedLabel !== null) {
    speedLabel.innerHTML = "50 %";
  }

  if (speedSlider !== undefined && speedSlider !== null) {
    speedSlider.value = checkDefault2(selectedJsonGameParametersTable, speedIndex_json, "50", "speedLabel", "%");
  }

  if (exerciseNumberLabel !== undefined && exerciseNumberLabel !== null) {
    exerciseNumberLabel.innerHTML = "5";
  }

  if (exerciseNumberSlider !== undefined && exerciseNumberSlider !== null) {
    exerciseNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, exerciseNumberIndex_json, "5", "exerciseNumberLabel");
  }

  if (localizationLabel !== undefined && localizationLabel !== null) {
    localizationLabel.innerHTML = "1.0";
  }

  if (localizationSlider !== undefined && localizationSlider !== null) {
    localizationSlider.value = checkDefault2(selectedJsonGameParametersTable, localizationIndex_json, "1", "localizationLabel", null, 1);
  }

  if (numberDurationLabel !== undefined && numberDurationLabel !== null) {
    numberDurationLabel.innerHTML = "2.0 s";
  }

  if (numberDurationSlider !== undefined && numberDurationSlider !== null) {
    numberDurationSlider.value = checkDefault2(selectedJsonGameParametersTable, numberDurationIndex_json, "2", "numberDurationLabel", "s", 1);
  }

  if (letterIntervalLabel !== undefined && letterIntervalLabel !== null) {
    letterIntervalLabel.innerHTML = "1.0 s";
  }

  if (letterIntervalSlider !== undefined && letterIntervalSlider !== null) {

    if (letterIntervalIndex_json !== undefined && letterIntervalIndex_json !== -1) {
      letterIntervalSlider.value = checkDefault2(selectedJsonGameParametersTable, letterIntervalIndex_json, "1", "letterIntervalLabel", "s", 1);
    }

    if (upperRowIndex_json !== undefined && upperRowIndex_json !== -1) {
      letterIntervalSlider.value = checkDefault2(selectedJsonGameParametersTable, upperRowIndex_json, "1", "letterIntervalLabel", "s", 1);
    }

    if (wordsGroupIndex_json !== undefined && wordsGroupIndex_json !== -1) {
      letterIntervalSlider.value = checkDefault2(selectedJsonGameParametersTable, wordsGroupIndex_json, "1", "letterIntervalLabel", "s", 1);
    }
  }

  if (letterIntervalLabel2 !== undefined && letterIntervalLabel2 !== null) {
    letterIntervalLabel2.innerHTML = "1.0 s";
  }

  if (letterIntervalSlider2 !== undefined && letterIntervalSlider2 !== null) {

    if (letterInterval2Index_json !== undefined && letterInterval2Index_json !== -1) {
      letterIntervalSlider2.value = checkDefault2(selectedJsonGameParametersTable, letterInterval2Index_json, "1", "letterIntervalLabel2", "s", 1);
    }

    if (lowerRowIndex_json !== undefined && lowerRowIndex_json !== -1) {
      letterIntervalSlider2.value = checkDefault2(selectedJsonGameParametersTable, lowerRowIndex_json, "1", "letterIntervalLabel2", "s", 1);
    }

    if (calculationsGroupIndex_json !== undefined && calculationsGroupIndex_json !== -1) {
      letterIntervalSlider2.value = checkDefault2(selectedJsonGameParametersTable, calculationsGroupIndex_json, "1", "letterIntervalLabel2", "s", 1);
    }
  }

  if (stringLengthLabel !== undefined && stringLengthLabel !== null) {
    stringLengthLabel.innerHTML = "2 " + t("local_parameters_letters2");
  }

  if (stringLengthSlider !== undefined && stringLengthSlider !== null) {
    stringLengthSlider.value = checkDefault2(selectedJsonGameParametersTable, stringLengthIndex_json, "2", "stringLengthLabel", t("local_parameters_letters2"));
  }

  if (stringLengthLabel2 !== undefined && stringLengthLabel2 !== null) {
    stringLengthLabel2.innerHTML = "2 " + t("local_parameters_letters2");
  }

  if (stringLengthSlider2 !== undefined && stringLengthSlider2 !== null) {
    stringLengthSlider2.value = checkDefault2(selectedJsonGameParametersTable, stringLength2Index_json, "2", "stringLengthLabel2", t("local_parameters_letters2"));
  }

  if (exerciseLengthLabel !== undefined && exerciseLengthLabel !== null) {
    exerciseLengthLabel.innerHTML = "4 min";
  }

  if (exerciseLengthSlider !== undefined && exerciseLengthSlider !== null) {
    exerciseLengthSlider.value = checkDefault2(selectedJsonGameParametersTable, exerciseLengthIndex_json, "4", "exerciseLengthLabel", "min");
  }

  if (exerciseLengthLabel2 !== undefined && exerciseLengthLabel2 !== null) {
    exerciseLengthLabel2.innerHTML = "4 min 0 s";
  }

  if (exerciseLengthSlider2 !== undefined && exerciseLengthSlider2 !== null) {
    exerciseLengthSlider2.value = checkDefault2(selectedJsonGameParametersTable, exerciseLength2Index_json, "240", "exerciseLengthLabel2", null, null, null, "minutes2");
  }

  if (seriesLengthLabel !== undefined && seriesLengthLabel !== null) {
    seriesLengthLabel.innerHTML = "2";
  }

  if (seriesLengthSlider !== undefined && seriesLengthSlider !== null) {
    seriesLengthSlider.value = checkDefault2(selectedJsonGameParametersTable, seriesLengthIndex_json, "2", "seriesLengthLabel");
  }

  if (stimuliNumberLabel !== undefined && stimuliNumberLabel !== null) {
    stimuliNumberLabel.innerHTML = "20 " + t("local_parameters_pcs");
  }

  if (stimuliNumberSlider !== undefined && stimuliNumberSlider !== null) {
    stimuliNumberSlider.value = checkDefault2(selectedJsonGameParametersTable, stimuliNumberIndex_json, "20", "stimuliNumberLabel", t("local_parameters_pcs"));
  }

  if (stimuliSizeLabel !== undefined && stimuliSizeLabel !== null) {
    stimuliSizeLabel.innerHTML = "10 %";
  }

  if (stimuliSizeSlider !== undefined && stimuliSizeSlider !== null) {
    stimuliSizeSlider.value = checkDefault2(selectedJsonGameParametersTable, stimuliSizeIndex_json, "10", "stimuliSizeLabel", "%");
  }

  if (intervalTimeLabel !== undefined && intervalTimeLabel !== null) {
    intervalTimeLabel.innerHTML = "1.0 s";
  }

  if (intervalTimeSlider !== undefined && intervalTimeSlider !== null) {
    intervalTimeSlider.value = checkDefault2(selectedJsonGameParametersTable, intervalTimeIndex_json, "1", "intervalTimeLabel", "s", 1);
  }

  if (stimulusTimeLabel !== undefined && stimulusTimeLabel !== null) {
    stimulusTimeLabel.innerHTML = "2.0 s";
  }

  if (stimulusTimeSlider !== undefined && stimulusTimeSlider !== null) {
    stimulusTimeSlider.value = checkDefault2(selectedJsonGameParametersTable, stimulusTimeIndex_json, "2", "stimulusTimeLabel", "s", 1);
  }

  if (stimuliMinLabel !== undefined && stimuliMinLabel !== null) {
    stimuliMinLabel.innerHTML = "1.0 s";
  }

  if (stimuliMinSlider !== undefined && stimuliMinSlider !== null) {
    stimuliMinSlider.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenStimuliIndex_json, "1", "stimuliMinLabel", "s", 1);
  }

  if (stimuliMaxLabel !== undefined && stimuliMaxLabel !== null) {
    stimuliMaxLabel.innerHTML = "4.0 s";
  }

  if (stimuliMaxSlider !== undefined && stimuliMaxSlider !== null) {
    stimuliMaxSlider.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenStimuli2Index_json, "4", "stimuliMaxLabel", "s", 1);
  }

  if (stimuliAdvanceMinLabel !== undefined && stimuliAdvanceMinLabel !== null) {
    stimuliAdvanceMinLabel.innerHTML = "1.0 s";
  }

  if (stimuliAdvanceMinSlider !== undefined && stimuliAdvanceMinSlider !== null) {
    stimuliAdvanceMinSlider.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenAdvanceIndex_json, "1", "stimuliAdvanceMinLabel", "s", 1);
  }

  if (stimuliAdvanceMinLabel2 !== undefined && stimuliAdvanceMinLabel2 !== null) {
    stimuliAdvanceMinLabel2.innerHTML = "1.00 s";
  }

  if (stimuliAdvanceMinSlider2 !== undefined && stimuliAdvanceMinSlider2 !== null) {
    stimuliAdvanceMinSlider2.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenAdvance3Index_json, "1", "stimuliAdvanceMinLabel2", "s", 2);
  }

  if (stimuliAdvanceMaxLabel !== undefined && stimuliAdvanceMaxLabel !== null) {
    stimuliAdvanceMaxLabel.innerHTML = "4.0 s";
  }

  if (stimuliAdvanceMaxSlider !== undefined && stimuliAdvanceMaxSlider !== null) {
    stimuliAdvanceMaxSlider.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenAdvance2Index_json, "4", "stimuliAdvanceMaxLabel", "s", 1);
  }

  if (stimuliAdvanceMaxLabel2 !== undefined && stimuliAdvanceMaxLabel2 !== null) {
    stimuliAdvanceMaxLabel2.innerHTML = "4.00 s";
  }

  if (stimuliAdvanceMaxSlider2 !== undefined && stimuliAdvanceMaxSlider2 !== null) {
    stimuliAdvanceMaxSlider2.value = checkDefault2(selectedJsonGameParametersTable, intervalBetweenAdvance4Index_json, "4", "stimuliAdvanceMaxLabel2", "s", 2);
  }

  if (stringLeft !== undefined && stringLeft !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, upperRow2Index_json, "1");
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, stringDirectionRadioButtonGroupIndex_json, "1");
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, wordsGroup2Index_json, "2");
  }

  if (stringLeft2 !== undefined && stringLeft2 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, lowerRow2Index_json, "1");
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, stringDirectionRadioButtonGroup2Index_json, "1");
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, calculationsGroup2Index_json, "2");
  }

  if (stimuliRandom !== undefined && stimuliRandom !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, stimuliRadioButtonGroupIndex_json, "false");
  }

  if (cellMedium !== undefined && cellMedium !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, cellSizeRadioButtonGroupIndex_json, "1");
  }

  if (layoutConstant !== undefined && layoutConstant !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, layoutRadioButtonGroupIndex_json, "0");
  }

  if (advancedFish !== undefined && advancedFish !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, advancedRadioButtonGroupIndex_json, "0");
  }

  if (letterSmall !== undefined && letterSmall !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, letterSizeRadioButtonGroupIndex_json, "0");
  }

  if (serieForward !== undefined && serieForward !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, serieRecoveryRadioButtonGroupIndex_json, "1");
  }

  if (interferenceMath !== undefined && interferenceMath !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, interferenceTypeRadioButtonGroupIndex_json, "1");
  }

  if (interference2Math !== undefined && interference2Math !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, interferenceTypeRadioButtonGroup2Index_json, "1");
  }

  if (nBack2 !== undefined && nBack2 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, nBackRadioButtonGroupIndex_json, "2");
  }

  if (presentationAsText !== undefined && presentationAsText !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, presentationTypeRadioButtonGroupIndex_json, "0");
  }

  if (returnAfterButton !== undefined && returnAfterButton !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, returnTypeRadioButtonGroupIndex_json, "1");
  }

  if (exerciseFish !== undefined && exerciseFish !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, exerciseTypeRadioButtonGroupIndex_json, "0");
  }

  if (bowlMedium !== undefined && bowlMedium !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, bowlScaleRadioButtonGroupIndex_json, "1", checkFishScale);
  }

  if (fishSmall !== undefined && fishSmall !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, fishScaleRadioButtonGroupIndex_json, "0");
  }

  if (lineRadius !== undefined && lineRadius !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, lineTypeRadioButtonGroupIndex_json, "0");
  }

  if (imagePhotograph !== undefined && imagePhotograph !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, imageTypeRadioButtonGroupIndex_json, "0");
  }

  if (Size4x4 !== undefined && Size4x4 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, pointSizeRadioButtonGroupIndex_json, "4");
  }

  if (figureGeometric !== undefined && figureGeometric !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, figureTypeRadioButtonGroupIndex_json, "0");
  }

  if (stimulusFlashes !== undefined && stimulusFlashes !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, stimulusVisibilityRadioButtonGroupIndex_json, "0");
  }

  if (transportingButtons !== undefined && transportingButtons !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, quadrantTransportRadioButtonGroupIndex_json, "0");
  }

  if (difficultyEasy !== undefined && difficultyEasy !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, difficultyRadioButtonGroupIndex_json, "0");
  }

  if (difficulty5Normal !== undefined && difficulty5Normal !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, difficultyRadioButtonGroup5Index_json, "1");
  }

  if (positionRandom !== undefined && positionRandom !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, targetPositionRadioButtonGroupIndex_json, "3");
  }

  if (difficultyA1 !== undefined && difficultyA1 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, difficultyRadioButtonGroup2Index_json, "0", setOptionsNumberValue);
  }

  if (difficultyLevel1 !== undefined && difficultyLevel1 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, difficultyRadioButtonGroup3Index_json, "0");
  }

  if (exerciseUser !== undefined && exerciseUser !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, exerciseStartedByRadioButtonGroupIndex_json, "0");
  }

  if (board8x8 !== undefined && board8x8 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, boardSizeRadioButtonGroupIndex_json, "8");
  }

  if (board2_8x8 !== undefined && board2_8x8 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, boardSizeRadioButtonGroup2Index_json, "8");
  }

  if (blackKnights4 !== undefined && blackKnights4 !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, blackKnightsRadioButtonGroupIndex_json, "4");
  }

  if (stimulusSeen !== undefined && stimulusSeen !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, stimulusTypeRadioButtonGroupIndex_json, "0", checkWordVisibility);
  }

  if (difficulty4Easy !== undefined && difficulty4Easy !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, calculationsGroup4Index_json, "0");
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, difficultyRadioButtonGroup4Index_json, "0");
  }

  if (professionsCheckBox !== undefined && professionsCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, categories1Index_json, ["Option1"]);
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, wordsGroup3Index_json, ["Option1"]);
  }

  if (professionsCheckBox2 !== undefined && professionsCheckBox2 !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, categories3Index_json, ["Option1"], checkMinCheckBoxChecked("categories3"));
  }

  if (professionsCheckBox3 !== undefined && professionsCheckBox3 !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, categories5Index_json, ["Option1", "Option2"], checkMinCheckBoxChecked("categories5"));
  }

  if (additionCheckBox !== undefined && additionCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, categories2Index_json, ["Option1"]);
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, calculationsGroup3Index_json, ["Option1"]);
  }

  if (soundFeedBackCheckBox !== undefined && soundFeedBackCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, soundFeedBackIndex_json, []);
  }

  if (allowRepeatCheckBox !== undefined && allowRepeatCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, allowRepeatIndex_json, []);
  }

  if (foodStuffCheckBox !== undefined && foodStuffCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, categories4Index_json, ["Option1", "Option2"]);
  }

  if (foodStuffCheckBox2 !== undefined && foodStuffCheckBox2 !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, categories6Index_json, ["Option1", "Option2"], checkMinCheckBoxChecked("categories6"));
  }

  if (useOutlinesCheckBox !== undefined && useOutlinesCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, useOutlinesIndex_json, []);
  }

  if (instantFeedbackCheckBox !== undefined && instantFeedbackCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, instantFeedbackIndex_json, ["true"]);
  }

  if (stimuliRandomCheckBox !== undefined && stimuliRandomCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, stimuliRandomOptionsIndex_json, []);
  }

  if (preExerciseCheckBox !== undefined && preExerciseCheckBox !== null) {
    checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, preExerciseCheckBoxIndex, []);
  }

  if (shapeRectangle !== undefined && shapeRectangle !== null &&
    shapeCircle !== undefined && shapeCircle !== null &&
    shapeDiamond !== undefined && shapeDiamond !== null &&
    shapeRectangleSlider !== undefined && shapeRectangleSlider !== null &&
    shapeCircleSlider !== undefined && shapeCircleSlider !== null &&
    shapeDiamondSlider !== undefined && shapeDiamondSlider !== null) {
    checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, fieldShapeRadioButtonGroup2Index_json, "0", setFieldShapeSlider);
    shapeRectangleSlider.value = checkDefault2(selectedJsonGameParametersTable, fieldShapeRadioButtonGroup3Index_json, "2");
    shapeCircleSlider.value = checkDefault2(selectedJsonGameParametersTable, fieldShapeRadioButtonGroup4Index_json, "2");
    shapeDiamondSlider.value = checkDefault2(selectedJsonGameParametersTable, fieldShapeRadioButtonGroup5Index_json, "2");
  }

  document.getElementById("overlay").style.display = "none";

}

function setMessageNumber(event, t) {
  var messageNumberLabel = document.getElementById("messageNumberLabel");

  messageNumberLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_pcs");
}

function setShapesVisibleNumber(event, t) {
  var shapesVisibleLabel = document.getElementById("shapesVisibleLabel");

  shapesVisibleLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_pcs");
}

function setShapesDeducedNumber(event, t) {
  var shapesDeducedLabel = document.getElementById("shapesDeducedLabel");

  shapesDeducedLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_pcs");
}

function setMessageVisibilityNumber(event) {
  var messageVisibilityLabel = document.getElementById("messageVisibilityLabel");

  messageVisibilityLabel.innerHTML = event.currentTarget.value + " s";
}

function setAdditionalStimuliNumber(event) {
  var additionalStimuliLabel = document.getElementById("additionalStimuliLabel");

  additionalStimuliLabel.innerHTML = "+ " + event.currentTarget.value + " %";
}

function setOptionsNumber(event) {
  var optionsNumberLabel = document.getElementById("optionsNumberLabel");

  optionsNumberLabel.innerHTML = event.currentTarget.value;
}

function setOptionsNumber2(event) {
  var optionsNumberLabel2 = document.getElementById("optionsNumberLabel2");

  optionsNumberLabel2.innerHTML = event.currentTarget.value;
}

function setPictureNumber(event) {
  var pictureNumberLabel = document.getElementById("pictureNumberLabel");

  pictureNumberLabel.innerHTML = event.currentTarget.value;
}

function setWordCountNumber(event, t) {
  var wordCountLabel = document.getElementById("wordCountLabel");

  wordCountLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_words2");
}

function setMovementSpeedNumber(event) {
  var movementSpeedLabel = document.getElementById("movementSpeedLabel");

  movementSpeedLabel.innerHTML = event.currentTarget.value + " %";
}

function setSpeedNumber(event) {
  var speedLabel = document.getElementById("speedLabel");

  speedLabel.innerHTML = event.currentTarget.value + " %";
}

function setSolvedLetters(event) {
  var solvedLettersLabel = document.getElementById("solvedLettersLabel");

  solvedLettersLabel.innerHTML = event.currentTarget.value + " %";
}

function setInterferenceDurationNumber(event) {
  var interferenceDurationLabel = document.getElementById("interferenceDurationLabel");

  interferenceDurationLabel.innerHTML = event.currentTarget.value + " s";
}

function setRotatingLineNumber(event) {
  var rotatingLineLabel = document.getElementById("rotatingLineLabel");

  rotatingLineLabel.innerHTML = event.currentTarget.value;
}

function setStringLengthNumber(event, t) {
  var stringLengthLabel = document.getElementById("stringLengthLabel");

  stringLengthLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_letters2");
}

function setStringLengthNumber2(event, t) {
  var stringLengthLabel2 = document.getElementById("stringLengthLabel2");

  stringLengthLabel2.innerHTML = event.currentTarget.value + " " + t("local_parameters_letters2");
}

function setSeriesLengthNumber(event) {
  var seriesLengthLabel = document.getElementById("seriesLengthLabel");

  seriesLengthLabel.innerHTML = event.currentTarget.value;
}

function setColorNumber(event) {
  var colorNumberLabel = document.getElementById("colorNumberLabel");

  colorNumberLabel.innerHTML = event.currentTarget.value;
}

function setColorLengthNumber(event) {
  var colorLengthLabel = document.getElementById("colorLengthLabel");

  colorLengthLabel.innerHTML = event.currentTarget.value;
}

function setExerciseNumber(event) {
  var exerciseNumberLabel = document.getElementById("exerciseNumberLabel");

  exerciseNumberLabel.innerHTML = event.currentTarget.value;
}

function setStackSizeNumber(event) {
  var stackSizeLabel = document.getElementById("stackSizeLabel");

  stackSizeLabel.innerHTML = event.currentTarget.value + "x" + event.currentTarget.value + "x" + event.currentTarget.value;
}

function setGridSizeNumber(event) {
  var gridSizeLabel = document.getElementById("gridSizeLabel");

  gridSizeLabel.innerHTML = event.currentTarget.value + "x" + event.currentTarget.value;
}

function setNumberCountNumber(event) {
  var numberCountLabel = document.getElementById("numberCountLabel");

  numberCountLabel.innerHTML = event.currentTarget.value;
}

function setAvailableSlotsNumber(event) {
  var availableSlotsLabel = document.getElementById("availableSlotsLabel");

  availableSlotsLabel.innerHTML = event.currentTarget.value;
}

function setPearlAmountNumber(event) {
  var pearlAmountLabel = document.getElementById("pearlAmountLabel");

  pearlAmountLabel.innerHTML = event.currentTarget.value;
}

function setExerciseLengthNumber(event) {
  var exerciseLengthLabel = document.getElementById("exerciseLengthLabel");

  exerciseLengthLabel.innerHTML = event.currentTarget.value + " min";
}

function setStimulusCategoryNumber(event) {
  var stimulusCategoryLabel = document.getElementById("stimulusCategoryLabel");

  stimulusCategoryLabel.innerHTML = event.currentTarget.value;
}

function setConceptGroupsNumber(event) {
  var conceptGroupsLabel = document.getElementById("conceptGroupsLabel");

  conceptGroupsLabel.innerHTML = event.currentTarget.value;
}

function setMazeSizeNumber(event) {
  var mazeSizeLabel = document.getElementById("mazeSizeLabel");

  mazeSizeLabel.innerHTML = event.currentTarget.value;
}

function setTowerSizeNumber(event) {
  var towerSizeLabel = document.getElementById("towerSizeLabel");

  towerSizeLabel.innerHTML = event.currentTarget.value;
}

function setRepeatNumber(event) {
  var repeatNumberLabel = document.getElementById("repeatNumberLabel");

  repeatNumberLabel.innerHTML = event.currentTarget.value;
}

function setHintNumber(event) {
  var hintNumberLabel = document.getElementById("hintNumberLabel");

  hintNumberLabel.innerHTML = event.currentTarget.value;
}

function setTowerHeightNumber(event) {
  var towerHeightLabel = document.getElementById("towerHeightLabel");

  towerHeightLabel.innerHTML = event.currentTarget.value;
}

function setNumbersDeducedNumber(event) {
  var numbersDeducedLabel = document.getElementById("numbersDeducedLabel");

  numbersDeducedLabel.innerHTML = event.currentTarget.value;
}

function setNewsNumber(event, t) {
  var newsNumberLabel = document.getElementById("newsNumberLabel");

  newsNumberLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_pcs");
}

function setChoiceCountNumber(event) {
  var choiceCountLabel = document.getElementById("choiceCountLabel");

  choiceCountLabel.innerHTML = event.currentTarget.value;
}

function setReadingTimeNumber(event, t) {
  var readingTimeLabel = document.getElementById("readingTimeLabel");

  var minutes = Math.floor(event.currentTarget.value / 60);
  var seconds = event.currentTarget.value - minutes * 60;

  if (event.currentTarget.value === "0") {
    readingTimeLabel.innerHTML = t("local_parameters_unlimited");
  }
  else {
    readingTimeLabel.innerHTML = minutes + " min " + seconds + " s";
  }
}

function setAnswerTimeNumber2(event, t) {
  var answerTimeLabel2 = document.getElementById("answerTimeLabel2");

  var minutes = Math.floor(event.currentTarget.value / 60);
  var seconds = event.currentTarget.value - minutes * 60;

  if (event.currentTarget.value === "0") {
    answerTimeLabel2.innerHTML = t("local_parameters_unlimited");
  }
  else {
    answerTimeLabel2.innerHTML = minutes + " min " + seconds + " s";
  }
}

function setPerformanceTimeNumber(event, t) {
  var performanceTimeLabel = document.getElementById("performanceTimeLabel");

  var minutes = Math.floor(event.currentTarget.value / 60);
  var seconds = event.currentTarget.value - minutes * 60;

  if (event.currentTarget.value === "0") {
    performanceTimeLabel.innerHTML = t("local_parameters_unlimited");
  }
  else {
    performanceTimeLabel.innerHTML = minutes + " min " + seconds + " s";
  }
}

function setPerformanceTimeNumber2(event, t) {
  var performanceTimeLabel2 = document.getElementById("performanceTimeLabel2");

  var minutes = Math.floor(event.currentTarget.value / 60);
  var seconds = event.currentTarget.value - minutes * 60;

  if (event.currentTarget.value === "0") {
    performanceTimeLabel2.innerHTML = t("local_parameters_unlimited");
  }
  else {
    performanceTimeLabel2.innerHTML = minutes + " min " + seconds + " s";
  }
}

function setExerciseLengthNumber2(event) {
  var exerciseLengthLabel2 = document.getElementById("exerciseLengthLabel2");

  if (event.currentTarget.value % 60 === 0) {
    exerciseLengthLabel2.innerHTML = event.currentTarget.value / 60 + " min 0 s";
  }
  else {
    exerciseLengthLabel2.innerHTML = Math.floor(event.currentTarget.value / 60) + " min 30 s";
  }
}

function setNumberStimuliNumber(event, t) {
  var stimuliNumberLabel = document.getElementById("stimuliNumberLabel");

  stimuliNumberLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_pcs");
}

function setWordLengthNumber(event, t) {
  var wordLengthLabel = document.getElementById("wordLengthLabel");

  wordLengthLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_letters3");
}

function setSeriesNumber(event, t) {
  var seriesNumberLabel = document.getElementById("seriesNumberLabel");

  seriesNumberLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_pcs");
}

function setSeriesNumber2(event) {
  var seriesNumberLabel2 = document.getElementById("seriesNumberLabel2");

  seriesNumberLabel2.innerHTML = event.currentTarget.value;
}

function setAnswerTimeNumber(event) {
  var answerTimeLabel = document.getElementById("answerTimeLabel");

  answerTimeLabel.innerHTML = event.currentTarget.value + ".0 s";
}

function setCardVisibilityNumber(event) {
  var cardVisibilityLabel = document.getElementById("cardVisibilityLabel");

  cardVisibilityLabel.innerHTML = event.currentTarget.value + ".0 s";
}

function setDrawNumber(event, t) {
  var drawNumberLabel = document.getElementById("drawNumberLabel");

  drawNumberLabel.innerHTML = event.currentTarget.value + " " + t("local_parameters_times");
}

function setSizeStimuliNumber(event) {
  var stimuliSizeLabel = document.getElementById("stimuliSizeLabel");

  stimuliSizeLabel.innerHTML = event.currentTarget.value + " %";
}

function setMinStimuliNumber(event) {
  var stimuliMinLabel = document.getElementById("stimuliMinLabel");
  var stimuliMaxLabel = document.getElementById("stimuliMaxLabel");
  var stimuliMaxSlider = document.getElementById("stimuliMaxSlider");

  if (stimuliMinLabel !== undefined && stimuliMinLabel !== null) {
    stimuliMinLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
  }

  if (stimuliMaxSlider !== undefined && stimuliMaxSlider !== null &&
    stimuliMaxLabel !== undefined && stimuliMaxLabel !== null) {
    if (stimuliMaxSlider.value < event.currentTarget.value) {
      stimuliMaxSlider.value = event.currentTarget.value;
      stimuliMaxLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
    }
  }
}

function setImageTimeNumber(event) {
  var imageTimeLabel = document.getElementById("imageTimeLabel");

  imageTimeLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setWordVisibilityNumber(event) {
  var wordVisibilityLabel = document.getElementById("wordVisibilityLabel");

  wordVisibilityLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setWordTimeNumber(event) {
  var wordTimeLabel = document.getElementById("wordTimeLabel");

  wordTimeLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setIntervalTimeNumber(event) {
  var intervalTimeLabel = document.getElementById("intervalTimeLabel");

  intervalTimeLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setStimulusTimeNumber(event) {
  var stimulusTimeLabel = document.getElementById("stimulusTimeLabel");

  stimulusTimeLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setNumberDurationNumber(event) {
  var numberDurationLabel = document.getElementById("numberDurationLabel");

  numberDurationLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setLetterIntervalNumber(event) {
  var letterIntervalLabel = document.getElementById("letterIntervalLabel");

  letterIntervalLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setLetterIntervalNumber2(event) {
  var letterIntervalLabel2 = document.getElementById("letterIntervalLabel2");

  letterIntervalLabel2.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setStimulusVisibilityNumber(event) {
  var stimulusVisibilityLabel = document.getElementById("stimulusVisibilityLabel");

  stimulusVisibilityLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
}

function setLocalizationNumber(event) {
  var localizationLabel = document.getElementById("localizationLabel");

  localizationLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1);
}

function setMinStimuliInputNumber(event) {
  var stimuliInputMinLabel = document.getElementById("stimuliInputMinLabel");
  var stimuliInputMaxLabel = document.getElementById("stimuliInputMaxLabel");
  var stimuliInputMaxSlider = document.getElementById("stimuliInputMaxSlider");

  if (stimuliInputMinLabel !== undefined && stimuliInputMinLabel !== null) {
    stimuliInputMinLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(2) + " s";
  }

  if (stimuliInputMaxSlider !== undefined && stimuliInputMaxSlider !== null &&
    stimuliInputMaxLabel !== undefined && stimuliInputMaxLabel !== null) {
    if (stimuliInputMaxSlider.value < event.currentTarget.value) {
      stimuliInputMaxSlider.value = event.currentTarget.value;
      stimuliInputMaxLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(2) + " s";
    }
  }
}

function setMaxStimuliInputNumber(event) {
  var stimuliInputMaxLabel = document.getElementById("stimuliInputMaxLabel");
  var stimuliInputMinLabel = document.getElementById("stimuliInputMinLabel");
  var stimuliInputMinSlider = document.getElementById("stimuliInputMinSlider");

  if (stimuliInputMaxLabel !== undefined && stimuliInputMaxLabel !== null) {
    stimuliInputMaxLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(2) + " s";
  }

  if ( (stimuliInputMinSlider !== undefined && stimuliInputMinSlider !== null) &&
    (stimuliInputMinLabel !== undefined && stimuliInputMinLabel !== null) ) {
    if (stimuliInputMinSlider.value > event.currentTarget.value) {
      stimuliInputMinSlider.value = event.currentTarget.value;
      stimuliInputMinLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(2) + " s";
    }
  }
}

function setMaxStimuliNumber(event) {
  var stimuliMaxLabel = document.getElementById("stimuliMaxLabel");
  var stimuliMinLabel = document.getElementById("stimuliMinLabel");
  var stimuliMinSlider = document.getElementById("stimuliMinSlider");

  if (stimuliMaxLabel !== undefined && stimuliMaxLabel !== null) {
    stimuliMaxLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
  }

  if ( (stimuliMinSlider !== undefined && stimuliMinSlider !== null) &&
    (stimuliMinLabel !== undefined && stimuliMinLabel !== null) ) {
    if (stimuliMinSlider.value > event.currentTarget.value) {
      stimuliMinSlider.value = event.currentTarget.value;
      stimuliMinLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
    }
  }
}

function setMaxStimuliAdvanceNumber(event) {
  var stimuliAdvanceMaxLabel = document.getElementById("stimuliAdvanceMaxLabel");
  var stimuliAdvanceMinLabel = document.getElementById("stimuliAdvanceMinLabel");
  var stimuliAdvanceMinSlider = document.getElementById("stimuliAdvanceMinSlider");

  if (stimuliAdvanceMaxLabel !== undefined && stimuliAdvanceMaxLabel !== null) {
    stimuliAdvanceMaxLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
  }

  if ( (stimuliAdvanceMinSlider !== undefined && stimuliAdvanceMinSlider !== null) &&
    (stimuliAdvanceMinLabel !== undefined && stimuliAdvanceMinLabel !== null) ) {
    if (stimuliAdvanceMinSlider.value > event.currentTarget.value) {
      stimuliAdvanceMinSlider.value = event.currentTarget.value;
      stimuliAdvanceMinLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
    }
  }
}

function setMinStimuliAdvanceNumber(event) {
  var stimuliAdvanceMinLabel = document.getElementById("stimuliAdvanceMinLabel");
  var stimuliAdvanceMaxLabel = document.getElementById("stimuliAdvanceMaxLabel");
  var stimuliAdvanceMaxSlider = document.getElementById("stimuliAdvanceMaxSlider");

  if (stimuliAdvanceMinLabel !== undefined && stimuliAdvanceMinLabel !== null) {
    stimuliAdvanceMinLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
  }

  if ( (stimuliAdvanceMaxSlider !== undefined && stimuliAdvanceMaxSlider !== null) &&
    (stimuliAdvanceMaxLabel !== undefined && stimuliAdvanceMaxLabel !== null) ) {
    if (stimuliAdvanceMaxSlider.value < event.currentTarget.value) {
      stimuliAdvanceMaxSlider.value = event.currentTarget.value;
      stimuliAdvanceMaxLabel.innerHTML = parseFloat(event.currentTarget.value).toFixed(1) + " s";
    }
  }
}

function setMaxStimuliAdvanceNumber2(event) {
  var stimuliAdvanceMaxLabel2 = document.getElementById("stimuliAdvanceMaxLabel2");
  var stimuliAdvanceMinLabel2 = document.getElementById("stimuliAdvanceMinLabel2");
  var stimuliAdvanceMinSlider2 = document.getElementById("stimuliAdvanceMinSlider2");

  if (stimuliAdvanceMaxLabel2 !== undefined && stimuliAdvanceMaxLabel2 !== null) {
    stimuliAdvanceMaxLabel2.innerHTML = parseFloat(event.currentTarget.value).toFixed(2) + " s";
  }

  if ( (stimuliAdvanceMinSlider2 !== undefined && stimuliAdvanceMinSlider2 !== null) &&
    (stimuliAdvanceMinLabel2 !== undefined && stimuliAdvanceMinLabel2 !== null) ) {
    if (stimuliAdvanceMinSlider2.value > event.currentTarget.value) {
      stimuliAdvanceMinSlider2.value = event.currentTarget.value;
      stimuliAdvanceMinLabel2.innerHTML = parseFloat(event.currentTarget.value).toFixed(2) + " s";
    }
  }
}

function setMinStimuliAdvanceNumber2(event) {
  var stimuliAdvanceMinLabel2 = document.getElementById("stimuliAdvanceMinLabel2");
  var stimuliAdvanceMaxLabel2 = document.getElementById("stimuliAdvanceMaxLabel2");
  var stimuliAdvanceMaxSlider2 = document.getElementById("stimuliAdvanceMaxSlider2");

  if (stimuliAdvanceMinLabel2 !== undefined && stimuliAdvanceMinLabel2 !== null) {
    stimuliAdvanceMinLabel2.innerHTML = parseFloat(event.currentTarget.value).toFixed(2) + " s";
  }

  if ( (stimuliAdvanceMaxSlider2 !== undefined && stimuliAdvanceMaxSlider2 !== null) &&
    (stimuliAdvanceMaxLabel2 !== undefined && stimuliAdvanceMaxLabel2 !== null) ) {
    if (stimuliAdvanceMaxSlider2.value < event.currentTarget.value) {
      stimuliAdvanceMaxSlider2.value = Math.ceil(event.currentTarget.value).toFixed(2);
      stimuliAdvanceMaxLabel2.innerHTML = parseFloat(Math.ceil(event.currentTarget.value)).toFixed(2) + " s";
    }
  }
}

function setOptionsNumberValue() {
  var difficultyA1 = document.getElementById("difficultyA1");
  var difficultyA2 = document.getElementById("difficultyA2");
  var difficultyA3 = document.getElementById("difficultyA3");
  var difficultyB1 = document.getElementById("difficultyB1");
  var difficultyB2 = document.getElementById("difficultyB2");
  var difficultyB3 = document.getElementById("difficultyB3");
  var optionsNumberLabel2 = document.getElementById("optionsNumberLabel2");
  var optionsNumberSlider2 = document.getElementById("optionsNumberSlider2");

  if (difficultyA1 !== null && difficultyA1 !== undefined &&
      difficultyA2 !== null && difficultyA2 !== undefined &&
      difficultyA3 !== null && difficultyA3 !== undefined &&
      difficultyB1 !== null && difficultyB1 !== undefined &&
      difficultyB2 !== null && difficultyB2 !== undefined &&
      difficultyB3 !== null && difficultyB3 !== undefined &&
      optionsNumberLabel2 !== null && optionsNumberLabel2 !== undefined &&
      optionsNumberSlider2 !== null && optionsNumberSlider2 !== undefined) {

    if (difficultyA1.checked || difficultyA2.checked) {
      optionsNumberSlider2.removeAttribute('disabled');
      difficultyA3.checked = false;
      difficultyB3.checked = false;
      difficultyB1.checked = false;
      difficultyB2.checked = false;
    }
    else if (difficultyA3.checked || difficultyB3.checked) {
      optionsNumberSlider2.setAttribute('disabled', '');
      optionsNumberSlider2.value = 4;
      optionsNumberLabel2.innerHTML = "4";
      difficultyA1.checked = false;
      difficultyA2.checked = false;
      difficultyB1.checked = false;
      difficultyB2.checked = false;
    }
    else if (difficultyB1.checked || difficultyB2.checked) {
      optionsNumberSlider2.setAttribute('disabled', '');
      optionsNumberSlider2.value = 5;
      optionsNumberLabel2.innerHTML = "5";
      difficultyA1.checked = false;
      difficultyA2.checked = false;
      difficultyA3.checked = false;
      difficultyB3.checked = false;
    }
  }


}

function setFieldShapeSlider() {
  var shapeRectangle = document.getElementById("shapeRectangle");
  var shapeCircle = document.getElementById("shapeCircle");
  var shapeDiamond = document.getElementById("shapeDiamond");
  var shapeRectangleSlider = document.getElementById("shapeRectangleSlider");
  var shapeCircleSlider = document.getElementById("shapeCircleSlider");
  var shapeDiamondSlider = document.getElementById("shapeDiamondSlider");

  if (shapeRectangle !== undefined && shapeRectangle !== null &&
     shapeCircle !== undefined && shapeCircle !== null &&
     shapeDiamond !== undefined && shapeDiamond !== null &&
     shapeRectangleSlider !== undefined && shapeRectangleSlider !== null &&
     shapeCircleSlider !== undefined && shapeCircleSlider !== null &&
     shapeDiamondSlider !== undefined && shapeDiamondSlider !== null) {
    if (shapeRectangle.checked) {
      shapeRectangleSlider.removeAttribute('disabled');
      shapeCircleSlider.setAttribute('disabled', '');
      shapeDiamondSlider.setAttribute('disabled', '');
    }
    else if (shapeCircle.checked) {
      shapeRectangleSlider.setAttribute('disabled', '');
      shapeCircleSlider.removeAttribute('disabled');
      shapeDiamondSlider.setAttribute('disabled', '');
    }
    else if (shapeDiamond.checked) {
      shapeRectangleSlider.setAttribute('disabled', '');
      shapeCircleSlider.setAttribute('disabled', '');
      shapeDiamondSlider.removeAttribute('disabled');
    }
  }
}

function setAdvancedOptions() {
  var typeSelect = document.getElementById("type");
  var advancedRadioButtonGroup = document.getElementById("advancedRadioButtonGroup");
  var searchOptions = document.getElementById("searchOptions");
  var lettersOptions = document.getElementById("lettersOptions");
  var numbersOptions = document.getElementById("numbersOptions");
  var symbolsOptions = document.getElementById("symbolsOptions");
  var letters2Options = document.getElementById("letters2Options");
  var numbers2Options = document.getElementById("numbers2Options");
  var symbols2Options = document.getElementById("symbols2Options");
  var difficultySearch = document.getElementById("difficultySearch");
  var gapOptions = document.getElementById("gapOptions");

  if (typeSelect !== undefined && typeSelect !== null &&
      advancedRadioButtonGroup !== undefined && advancedRadioButtonGroup !== null &&
      searchOptions !== undefined && searchOptions !== null &&
      lettersOptions !== undefined && lettersOptions !== null &&
      numbersOptions !== undefined && numbersOptions !== null &&
      symbolsOptions !== undefined && symbolsOptions !== null) {
    if (typeSelect.selectedIndex === 0) {
      advancedRadioButtonGroup.setAttribute("style", "display:block !important");
      searchOptions.setAttribute("style", "display:none !important");
      lettersOptions.setAttribute("style", "display:none !important");
      numbersOptions.setAttribute("style", "display:none !important");
      symbolsOptions.setAttribute("style", "display:none !important");
      letters2Options.setAttribute("style", "display:none !important");
      numbers2Options.setAttribute("style", "display:none !important");
      symbols2Options.setAttribute("style", "display:none !important");
      gapOptions.setAttribute("style", "display:none !important");
    }
    else if (typeSelect.selectedIndex === 1) {
      advancedRadioButtonGroup.setAttribute("style", "display:none !important");
      searchOptions.setAttribute("style", "display:block !important");
      lettersOptions.setAttribute("style", "display:none !important");
      numbersOptions.setAttribute("style", "display:block !important");
      symbolsOptions.setAttribute("style", "display:none !important");

      if (difficultySearch.selectedIndex === 0) {
        letters2Options.setAttribute("style", "display:none !important");
        numbers2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (difficultySearch.selectedIndex === 1 ) {
        numbers2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (difficultySearch.selectedIndex === 2) {
        numbers2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:block !important");
      }
    }
    else if (typeSelect.selectedIndex === 2) {
      advancedRadioButtonGroup.setAttribute("style", "display:none !important");
      searchOptions.setAttribute("style", "display:block !important");
      lettersOptions.setAttribute("style", "display:block !important");
      numbersOptions.setAttribute("style", "display:none !important");
      symbolsOptions.setAttribute("style", "display:none !important");

      if (difficultySearch.selectedIndex === 0) {
        letters2Options.setAttribute("style", "display:none !important");
        numbers2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (difficultySearch.selectedIndex === 1) {
        letters2Options.setAttribute("style", "display:block !important");
        numbers2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (difficultySearch.selectedIndex === 2) {
        letters2Options.setAttribute("style", "display:block !important");
        numbers2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:block !important");
      }
    }
    else if (typeSelect.selectedIndex === 3) {
      advancedRadioButtonGroup.setAttribute("style", "display:none !important");
      searchOptions.setAttribute("style", "display:block !important");
      lettersOptions.setAttribute("style", "display:none !important");
      numbersOptions.setAttribute("style", "display:none !important");
      symbolsOptions.setAttribute("style", "display:block !important");

      if (difficultySearch.selectedIndex === 0) {
        letters2Options.setAttribute("style", "display:none !important");
        numbers2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (difficultySearch.selectedIndex === 1) {
        symbols2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        numbers2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (difficultySearch.selectedIndex === 2) {
        symbols2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        numbers2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:block !important");
      }
    }
  }
}

function setAdvanced2Options() {
  var difficultySearch = document.getElementById("difficultySearch");
  var typeSelect = document.getElementById("type");
  var letters2Options = document.getElementById("letters2Options");
  var numbers2Options = document.getElementById("numbers2Options");
  var symbols2Options = document.getElementById("symbols2Options");
  var gapOptions = document.getElementById("gapOptions");

  if (difficultySearch !== undefined && difficultySearch !== null &&
      typeSelect !== undefined && typeSelect !== null &&
      letters2Options !== undefined && letters2Options !== null &&
      numbers2Options !== undefined && numbers2Options !== null &&
      symbols2Options !== undefined && symbols2Options !== null) {
    if (difficultySearch.selectedIndex === 0) {
      letters2Options.setAttribute("style", "display:none !important");
      numbers2Options.setAttribute("style", "display:none !important");
      symbols2Options.setAttribute("style", "display:none !important");
      gapOptions.setAttribute("style", "display:none !important");
    }
    else if (typeSelect.selectedIndex !== 0 && difficultySearch.selectedIndex === 1) {
      if (typeSelect.selectedIndex === 1) {
        numbers2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (typeSelect.selectedIndex === 2) {
        letters2Options.setAttribute("style", "display:block !important");
        numbers2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
      else if (typeSelect.selectedIndex === 3) {
        symbols2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        numbers2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:none !important");
      }
    }
    else if (typeSelect.selectedIndex !== 0 && difficultySearch.selectedIndex === 2) {
      if (typeSelect.selectedIndex === 1) {
        numbers2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:block !important");
      }
      else if (typeSelect.selectedIndex === 2) {
        letters2Options.setAttribute("style", "display:block !important");
        numbers2Options.setAttribute("style", "display:none !important");
        symbols2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:block !important");
      }
      else if (typeSelect.selectedIndex === 3) {
        symbols2Options.setAttribute("style", "display:block !important");
        letters2Options.setAttribute("style", "display:none !important");
        numbers2Options.setAttribute("style", "display:none !important");
        gapOptions.setAttribute("style", "display:block !important");
      }
    }
  }
}

function checkMinCheckBoxChecked(name) {
  var checkedCheckBoxes = [];
  var checkBoxes = document.querySelectorAll('input[name="' + name + '"]:checked');

  for (var i = 0; i < checkBoxes.length; i++) {
    checkedCheckBoxes.push(checkBoxes[i]);
  }

  if (name === "categories3") {
    if (checkedCheckBoxes.length === 0) {
      var professionsCheckBox2 = document.getElementById("professionsCheckBox2");

      if (professionsCheckBox2 !== undefined && professionsCheckBox2 !== null) {
        professionsCheckBox2.checked = true;
      }
    }
  }
  else if (name === "categories5") {
    var professionsCheckBox3 = document.getElementById("professionsCheckBox3");
    var animalsCheckBox4 = document.getElementById("animalsCheckBox4");

    if (professionsCheckBox3 !== undefined && professionsCheckBox3 !== null &&
        animalsCheckBox4 !== undefined && animalsCheckBox4 !== null) {
      if (checkedCheckBoxes.length === 1) {
        if (professionsCheckBox3.checked === false) {
          professionsCheckBox3.checked = true;
        }
        else if (animalsCheckBox4.checked === false) {
          animalsCheckBox4.checked = true;
        }

      }
      else if (checkedCheckBoxes.length === 0) {
        professionsCheckBox3.checked = true;
        animalsCheckBox4.checked = true;
      }
    }
  }
  else if (name === "categories6") {
    var foodStuffCheckBox2 = document.getElementById("foodStuffCheckBox2");
    var animalsCheckBox5 = document.getElementById("animalsCheckBox5");

    if (foodStuffCheckBox2 !== undefined && foodStuffCheckBox2 !== null &&
        animalsCheckBox5 !== undefined && animalsCheckBox5 !== null) {
      if (checkedCheckBoxes.length === 1) {
        if (foodStuffCheckBox2.checked === false) {
          foodStuffCheckBox2.checked = true;
        }
        else if (animalsCheckBox5.checked === false) {
          animalsCheckBox5.checked = true;
        }

      }
      else if (checkedCheckBoxes.length === 0) {
        foodStuffCheckBox2.checked = true;
        animalsCheckBox5.checked = true;
      }
    }
  }
}

function unlockAdvancedOptions() {
  var searchOptions = document.getElementById("searchCheckBox");
  var lettersOptions = document.getElementById("letters");
  var letters2Options = document.getElementById("letters2");
  var numbersOptions = document.getElementById("numbers");
  var numbers2Options = document.getElementById("numbers2");
  var symbolsOptions = document.getElementById("symbols");
  var symbols2Options = document.getElementById("symbols2");
  var gapOptions = document.getElementById("gap");

  if (searchOptions !== undefined && searchOptions !== null &&
      lettersOptions !== undefined && lettersOptions !== null &&
      numbersOptions !== undefined && numbersOptions !== null &&
      symbolsOptions !== undefined && symbolsOptions !== null &&
      letters2Options !== undefined && letters2Options !== null &&
      numbers2Options !== undefined && numbers2Options !== null &&
      symbols2Options !== undefined && symbols2Options !== null) {
    if (searchOptions.checked) {
      lettersOptions.removeAttribute('disabled');
      numbersOptions.removeAttribute('disabled');
      symbolsOptions.removeAttribute('disabled');
      letters2Options.removeAttribute('disabled');
      numbers2Options.removeAttribute('disabled');
      symbols2Options.removeAttribute('disabled');
      gapOptions.removeAttribute('disabled');
    }
    else {
      lettersOptions.setAttribute('disabled', '');
      numbersOptions.setAttribute('disabled', '');
      symbolsOptions.setAttribute('disabled', '');
      letters2Options.setAttribute('disabled', '');
      numbers2Options.setAttribute('disabled', '');
      symbols2Options.setAttribute('disabled', '');
      gapOptions.setAttribute('disabled', '');
    }
  }
}

function checkFishScale() {
  var bowlSmall = document.getElementById("bowlSmall");
  var fishSmall = document.getElementById("fishSmall");
  var fishLarge = document.getElementById("fishLarge");

  if (bowlSmall !== undefined && bowlSmall !== null &&
      fishSmall !== undefined && fishSmall !== null &&
      fishLarge !== undefined && fishLarge !== null) {
    if (bowlSmall.checked) {
      fishSmall.checked = true;
      fishLarge.setAttribute('disabled', '');
    }
    else {
      fishLarge.removeAttribute('disabled');
    }
  }

}

function checkWordVisibility() {
  var stimulusSeen = document.getElementById("stimulusSeen");
  var stimulusHeard = document.getElementById("stimulusHeard");
  var wordVisibilityLabel = document.getElementById("wordVisibilityLabel");
  var wordVisibilitySlider = document.getElementById("wordVisibilitySlider");

  if (stimulusSeen !== undefined && stimulusSeen !== null &&
      stimulusHeard !== undefined && stimulusHeard !== null &&
      wordVisibilityLabel !== undefined && wordVisibilityLabel !== null &&
      wordVisibilitySlider !== undefined && wordVisibilitySlider !== null) {
    if (stimulusSeen.checked) {
      wordVisibilityLabel.innerHTML = parseFloat(wordVisibilitySlider.value).toFixed(1) + " s";
      wordVisibilitySlider.removeAttribute('disabled');
    }
    else if (stimulusHeard.checked) {
      wordVisibilityLabel.innerHTML = "Off";
      wordVisibilitySlider.setAttribute('disabled', '');
    }
  }
}

function checkSelectDefault(selectedGameParameters, selectedGameParametersTable, parameterIndex, parameterDefault, optionalFunction = null) {
  var gameElement = document.getElementById(selectedGameParametersTable[parameterIndex]);

  if (gameElement !== undefined && gameElement !== null) {

    var gameElementSelect = gameElement.querySelector(".EditParameters_select");

    if (gameElementSelect !== undefined && gameElementSelect !== null) {
      var options = [...gameElementSelect.options];
      var values = options.map(option => option.value);

      var selectedGameParametersById = null;

      try {
        selectedGameParametersById = JSON.parse(localStorage.getItem("selectedGameParameters_" + selectedGameId));
      } catch (err) {

      }

      if (selectedGameParametersById !== null && selectedGameParametersById !== undefined && selectedGameParametersById.length !== 0 && selectedGameParametersById.length !== undefined) {

        var selectedGameParametersByIdTable = [];

        for (var i = 0; i < selectedGameParametersById.length; i++) {
          var selectedGameParameterById = selectedGameParametersById[i];
          selectedGameParametersByIdTable.push(selectedGameParameterById.key);
        }

        for (var j = 0; j < gameElementSelect.length; j++) {
          if (selectedGameParametersById[parameterIndex].default[0] === values[j]) {
            gameElementSelect[j].selected = true;

            if (optionalFunction !== null) {
              optionalFunction();
            }

            return;
          }
        }

      }

      for (var k = 0; k < gameElementSelect.length; k++) {
        if (selectedGameParameters[parameterIndex].default[0] === values[k]) {
          gameElementSelect[k].selected = true;

          if (optionalFunction !== null) {
            optionalFunction();
          }

          return;
        }
      }

      for (var l = 0; l < gameElementSelect.length; l++) {
        if (parameterDefault === values[l]) {
          gameElementSelect[l].selected = true;

          if (optionalFunction !== null) {
            optionalFunction();
          }

          return;
        }
      }
    }

  }
}

function checkSelectDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, parameterIndex, parameterDefault, optionalFunction = null) {
  var gameElement = document.getElementById(selectedJsonGameParametersTable[parameterIndex]);

  if (gameElement !== undefined && gameElement !== null) {

    var gameElementSelect = gameElement.querySelector(".EditParameters_select");

    if (gameElementSelect !== undefined && gameElementSelect !== null) {
      var options = [...gameElementSelect.options];
      var values = options.map(option => option.value);

      if (selectedJsonGameParameters !== null && selectedJsonGameParameters !== undefined && selectedJsonGameParameters.length !== 0 && selectedJsonGameParameters.length !== undefined) {
        for (var i = 0; i < gameElementSelect.length; i++) {
          if (selectedJsonGameParameters[parameterIndex].default[0] === values[i]) {
            gameElementSelect[i].selected = true;

            if (optionalFunction !== null) {
              optionalFunction();
            }

            return;
          }
        }

      }

      for (var k = 0; k < gameElementSelect.length; k++) {
        if (parameterDefault === values[k]) {
          gameElementSelect[k].selected = true;

          if (optionalFunction !== null) {
            optionalFunction();
          }

          return;
        }
      }
    }

  }
}

function checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, parameterIndex, parameterDefault, optionalFunction = null) {
  var gameElement = document.getElementById(selectedGameParametersTable[parameterIndex]);

  if (gameElement !== undefined && gameElement !== null) {

    var gameElementCheckBoxes = gameElement.querySelectorAll(".checkBox");

    if (gameElementCheckBoxes !== undefined && gameElementCheckBoxes !== null && gameElementCheckBoxes.length !== 0) {
      var selectedGameParametersById = null;

      try {
        selectedGameParametersById = JSON.parse(localStorage.getItem("selectedGameParameters_" + selectedGameId));
      } catch (err) {

      }

      if (selectedGameParametersById !== null && selectedGameParametersById !== undefined && selectedGameParametersById.length !== 0 && selectedGameParametersById.length !== undefined) {

        var selectedGameParametersByIdTable = [];

        for (var p = 0; p < selectedGameParametersById.length; p++) {
          var selectedGameParameterById = selectedGameParametersById[p];
          selectedGameParametersByIdTable.push(selectedGameParameterById.key);
        }

        for (var i = 0; i < gameElementCheckBoxes.length; i++) {
          for (var j = 0; j < selectedGameParametersById[parameterIndex].default.length; j++) {
            if (selectedGameParametersById[parameterIndex].default[j] === gameElementCheckBoxes[i].value) {
              gameElementCheckBoxes[i].checked = true;

              if (optionalFunction !== null) {
                optionalFunction();
              }
            }
          }
        }

        return;
      }

      if (selectedGameParameters[parameterIndex].default !== undefined && selectedGameParameters[parameterIndex].default.length !== undefined) {
        for (var k = 0; k < gameElementCheckBoxes.length; k++) {
          for (var m = 0; m < selectedGameParameters[parameterIndex].default.length; m++) {
            if (selectedGameParameters[parameterIndex].default[m] === gameElementCheckBoxes[k].value) {
              gameElementCheckBoxes[k].checked = true;

              if (optionalFunction !== null) {
                optionalFunction();
              }
            }
          }
        }

        return;
      }

      for (var l = 0; l < gameElementCheckBoxes.length; l++) {
        for (var n = 0; n < parameterDefault.length; n++) {
          if (parameterDefault[n] === gameElementCheckBoxes[l].value) {
            gameElementCheckBoxes[l].checked = true;

            if (optionalFunction !== null) {
              optionalFunction();
            }
          }
        }
      }
    }

  }
}

function checkCheckBoxesDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, parameterIndex, parameterDefault, optionalFunction = null) {
  var gameElement = document.getElementById(selectedJsonGameParametersTable[parameterIndex]);

  if (gameElement !== undefined && gameElement !== null) {

    var gameElementCheckBoxes = gameElement.querySelectorAll(".checkBox");

    if (gameElementCheckBoxes !== undefined && gameElementCheckBoxes !== null && gameElementCheckBoxes.length !== 0) {

      if (selectedJsonGameParameters !== null && selectedJsonGameParameters !== undefined && selectedJsonGameParameters.length !== 0 && selectedJsonGameParameters.length !== undefined &&
       selectedJsonGameParameters[parameterIndex].default !== undefined && selectedJsonGameParameters[parameterIndex].default.length !== undefined) {
        for (var k = 0; k < gameElementCheckBoxes.length; k++) {
          if (selectedJsonGameParameters[parameterIndex].default.length !== 0) {
            var elementChecked = false;

            for (var m = 0; m < selectedJsonGameParameters[parameterIndex].default.length; m++) {
              if (selectedJsonGameParameters[parameterIndex].default[m] === gameElementCheckBoxes[k].value) {
                gameElementCheckBoxes[k].checked = true;

                if (optionalFunction !== null) {
                  optionalFunction();
                }

                elementChecked = true;
              }
              else if (elementChecked === false) {
                gameElementCheckBoxes[k].checked = false;
              }
            }
          }
          else {
            gameElementCheckBoxes[k].checked = false;
          }
        }

        return;
      }

      if (parameterDefault.length !== 0) {
        for (var l = 0; l < gameElementCheckBoxes.length; l++) {
          var elementChecked2 = false;

          for (var n = 0; n < parameterDefault.length; n++) {
            if (parameterDefault[n] === gameElementCheckBoxes[l].value) {
              gameElementCheckBoxes[l].checked = true;

              if (optionalFunction !== null) {
                optionalFunction();
              }

              elementChecked2 = true;
            }
            else if (elementChecked2 === false) {
              gameElementCheckBoxes[l].checked = false;
            }
          }
        }

        return
      }

      for (var p = 0; p < gameElementCheckBoxes.length; p++) {
        gameElementCheckBoxes[p].checked = false;

        if (optionalFunction !== null) {
          optionalFunction();
        }
      }

    }

  }
}

function checkRadioDefault(selectedGameParameters, selectedGameParametersTable, parameterIndex, parameterDefault, optionalFunction = null) {
  var gameElement = document.getElementById(selectedGameParametersTable[parameterIndex]);

  if (gameElement !== undefined && gameElement !== null) {

    var gameElementRadios = gameElement.querySelectorAll(".radioButton");

    if (gameElementRadios !== undefined && gameElementRadios !== null && gameElementRadios.length !== 0) {
      var selectedGameParametersById = null;

      try {
        selectedGameParametersById = JSON.parse(localStorage.getItem("selectedGameParameters_" + selectedGameId));
      } catch (err) {

      }

      if (selectedGameParametersById !== null && selectedGameParametersById !== undefined && selectedGameParametersById.length !== 0 && selectedGameParametersById.length !== undefined) {

        var selectedGameParametersByIdTable = [];

        for (var i = 0; i < selectedGameParametersById.length; i++) {
          var selectedGameParameterById = selectedGameParametersById[i];
          selectedGameParametersByIdTable.push(selectedGameParameterById.key);
        }

        for (var j = 0; j < gameElementRadios.length; j++) {
          if (selectedGameParametersById[parameterIndex].default[0] === gameElementRadios[j].value) {
            gameElementRadios[j].checked = true;

            if (optionalFunction !== null) {
              optionalFunction();
            }

            return;
          }
        }

      }

      for (var k = 0; k < gameElementRadios.length; k++) {
        if (selectedGameParameters[parameterIndex].default[0] === gameElementRadios[k].value) {
          gameElementRadios[k].checked = true;

          if (optionalFunction !== null) {
            optionalFunction();
          }

          return;
        }
      }

      for (var l = 0; l < gameElementRadios.length; l++) {
        if (parameterDefault === gameElementRadios[l].value) {
          gameElementRadios[l].checked = true;

          if (optionalFunction !== null) {
            optionalFunction();
          }

          return;
        }
      }
    }

  }
}

function checkRadioDefault2(selectedJsonGameParameters, selectedJsonGameParametersTable, parameterIndex, parameterDefault, optionalFunction = null) {
  var gameElement = document.getElementById(selectedGameParametersTable[parameterIndex]);

  if (gameElement !== undefined && gameElement !== null) {

    var gameElementRadios = gameElement.querySelectorAll(".radioButton");

    if (gameElementRadios !== undefined && gameElementRadios !== null && gameElementRadios.length !== 0) {

      if (selectedJsonGameParameters !== null && selectedJsonGameParameters !== undefined && selectedJsonGameParameters.length !== 0 && selectedJsonGameParameters.length !== undefined) {
        for (var i = 0; i < gameElementRadios.length; i++) {
          if (selectedJsonGameParameters[parameterIndex].default[0] === gameElementRadios[i].value) {
            gameElementRadios[i].checked = true;

            if (optionalFunction !== null) {
              optionalFunction();
            }

            return;
          }
        }

      }

      for (var k = 0; k < gameElementRadios.length; k++) {
        if (parameterDefault === gameElementRadios[k].value) {
          gameElementRadios[k].checked = true;

          if (optionalFunction !== null) {
            optionalFunction();
          }

          return;
        }
      }
    }

  }
}

function checkDefault(selectedGameParametersTable, parameterIndex, parameterDefault, labelId,
  labelEnding = null, fixedValue = null, labelEndingNoSpace = null, labelTime = null, labelTextZero = null,
  middleCoefficient = null, labelBeginning = null) {

  var labelIdElement = document.getElementById(labelId);

  var selectedGameParametersById = null;

  try {
    selectedGameParametersById = JSON.parse(localStorage.getItem("selectedGameParameters_" + selectedGameId));
  } catch (err) {

  }

  if (selectedGameParametersById !== null && selectedGameParametersById !== undefined && selectedGameParametersById.length !== 0 && selectedGameParametersById.length !== undefined) {
    var selectedGameParametersByIdTable = [];

    for (var i = 0; i < selectedGameParametersById.length; i++) {
      var selectedGameParameterById = selectedGameParametersById[i];
      selectedGameParametersByIdTable.push(selectedGameParameterById.key);
    }

    if (selectedGameParametersByIdTable !== undefined && selectedGameParametersByIdTable !== null) {
      if (parameterIndex !== undefined && parameterIndex !== -1 && selectedGameParametersById[parameterIndex].default !== undefined) {

        if (labelIdElement !== undefined && labelIdElement !== null) {
          if (labelBeginning !== null) {
            labelIdElement.innerHTML = labelBeginning + " ";
          }

          if (labelBeginning !== null) {
            if (fixedValue !== null) {
              labelIdElement.innerHTML += parseFloat(selectedGameParametersById[parameterIndex].default).toFixed(fixedValue);
            }
            else {
              labelIdElement.innerHTML += selectedGameParametersById[parameterIndex].default;
            }
          }
          else {
            if (fixedValue !== null) {
              labelIdElement.innerHTML = parseFloat(selectedGameParametersById[parameterIndex].default).toFixed(fixedValue);
            }
            else {
              labelIdElement.innerHTML = selectedGameParametersById[parameterIndex].default;
            }
          }

          if (labelEnding !== null) {
            if (labelEndingNoSpace === true) {
              labelIdElement.innerHTML += labelEnding;
            }
            else {
              labelIdElement.innerHTML += " " + labelEnding;
            }
          }

          if (labelTime === "minutes") {
            var minutes = Math.floor(selectedGameParametersById[parameterIndex].default / 60);
            var seconds = selectedGameParametersById[parameterIndex].default - minutes * 60;

            labelIdElement.innerHTML = minutes + " m " + seconds + " s";
          }

          if (labelTime === "minutes2") {
            if (selectedGameParametersById[parameterIndex].default % 60 === 0) {
              labelIdElement.innerHTML = selectedGameParametersById[parameterIndex].default / 60 + " min 0 s";
            }
            else {
              labelIdElement.innerHTML = Math.floor(selectedGameParametersById[parameterIndex].default / 60) + " min 30 s";
            }
          }

          if (labelTextZero !== null && (selectedGameParametersById[parameterIndex].default === "0" ||
            selectedGameParametersById[parameterIndex].default === 0) ) {
            labelIdElement.innerHTML = labelTextZero;
          }

          if (middleCoefficient !== null) {
            if (middleCoefficient === "stack") {
              labelIdElement.innerHTML = selectedGameParametersById[parameterIndex].default + "x" +
                selectedGameParametersById[parameterIndex].default + "x" +
                selectedGameParametersById[parameterIndex].default
            }
            else if (middleCoefficient === "grid") {
              labelIdElement.innerHTML = selectedGameParametersById[parameterIndex].default + "x" +
                selectedGameParametersById[parameterIndex].default + "x";
            }
          }

        }

        return selectedGameParametersById[parameterIndex].default;
      }
    }

  }

  if (parameterIndex !== undefined && parameterIndex !== -1 && selectedGameParameters[parameterIndex].default !== undefined) {
    if (labelIdElement !== undefined && labelIdElement !== null) {
      if (labelBeginning !== null) {
        labelIdElement.innerHTML = labelBeginning + " ";
      }

      if (labelBeginning !== null) {
        if (fixedValue !== null) {
          labelIdElement.innerHTML += parseFloat(selectedGameParameters[parameterIndex].default).toFixed(fixedValue);
        }
        else {
          labelIdElement.innerHTML += selectedGameParameters[parameterIndex].default;
        }
      }
      else {
        if (fixedValue !== null) {
          labelIdElement.innerHTML = parseFloat(selectedGameParameters[parameterIndex].default).toFixed(fixedValue);
        }
        else {
          labelIdElement.innerHTML = selectedGameParameters[parameterIndex].default;
        }
      }

      if (labelEnding !== null) {
        if (labelEndingNoSpace === true) {
          labelIdElement.innerHTML += labelEnding;
        }
        else {
          labelIdElement.innerHTML += " " + labelEnding;
        }
      }

      if (labelTime === "minutes") {
        var minutes2 = Math.floor(selectedGameParameters[parameterIndex].default / 60);
        var seconds2 = selectedGameParameters[parameterIndex].default - minutes2 * 60;

        labelIdElement.innerHTML = minutes2 + " m " + seconds2 + " s";
      }

      if (labelTime === "minutes2") {
        if (selectedGameParameters[parameterIndex].default % 60 === 0) {
          labelIdElement.innerHTML = selectedGameParameters[parameterIndex].default / 60 + " min 0 s";
        }
        else {
          labelIdElement.innerHTML = Math.floor(selectedGameParameters[parameterIndex].default / 60) + " min 30 s";
        }
      }

      if (labelTextZero !== null && (selectedGameParameters[parameterIndex].default === "0" ||
        selectedGameParameters[parameterIndex].default === 0) ) {
        labelIdElement.innerHTML = labelTextZero;
      }

      if (middleCoefficient !== null) {
        if (middleCoefficient === "stack") {
          labelIdElement.innerHTML = selectedGameParameters[parameterIndex].default + "x" +
            selectedGameParameters[parameterIndex].default + "x" +
            selectedGameParameters[parameterIndex].default
        }
        else if (middleCoefficient === "grid") {
          labelIdElement.innerHTML = selectedGameParameters[parameterIndex].default + "x" +
            selectedGameParameters[parameterIndex].default + "x";
        }
      }

    }

    return selectedGameParameters[parameterIndex].default;
  }
  else {
    if (labelIdElement !== undefined && labelIdElement !== null) {
      if (middleCoefficient !== null) {
        if (middleCoefficient === "stack") {
          labelIdElement.innerHTML = parameterDefault + "x" +
            parameterDefault + "x" +
            parameterDefault
        }
        else if (middleCoefficient === "grid") {
          labelIdElement.innerHTML = parameterDefault + "x" +
            parameterDefault + "x";
        }
      }
    }

    return parameterDefault;
  }
}

function checkDefault2(selectedJsonGameParametersTable, parameterIndex, parameterDefault, labelId,
  labelEnding = null, fixedValue = null, labelEndingNoSpace = null, labelTime = null, labelTextZero = null,
  middleCoefficient = null, labelBeginning = null) {
  var labelIdElement = document.getElementById(labelId);

  if (parameterIndex !== undefined && parameterIndex !== -1 && selectedJsonGameParameters[parameterIndex].default !== undefined) {
    if (labelIdElement !== undefined && labelIdElement !== null) {
      if (labelBeginning !== null) {
        labelIdElement.innerHTML = labelBeginning + " ";
      }

      if (labelBeginning !== null) {
        if (fixedValue !== null) {
          labelIdElement.innerHTML += parseFloat(selectedJsonGameParameters[parameterIndex].default).toFixed(fixedValue);
        }
        else {
          labelIdElement.innerHTML += selectedJsonGameParameters[parameterIndex].default;
        }
      }
      else {
        if (fixedValue !== null) {
          labelIdElement.innerHTML = parseFloat(selectedJsonGameParameters[parameterIndex].default).toFixed(fixedValue);
        }
        else {
          labelIdElement.innerHTML = selectedJsonGameParameters[parameterIndex].default;
        }
      }

      if (labelEnding !== null) {
        if (labelEndingNoSpace === true) {
          labelIdElement.innerHTML += labelEnding;
        }
        else {
          labelIdElement.innerHTML += " " + labelEnding;
        }
      }

      if (labelTime === "minutes") {
        var minutes2 = Math.floor(selectedJsonGameParameters[parameterIndex].default / 60);
        var seconds2 = selectedJsonGameParameters[parameterIndex].default - minutes2 * 60;

        labelIdElement.innerHTML = minutes2 + " m " + seconds2 + " s";
      }

      if (labelTime === "minutes2") {
        if (selectedJsonGameParameters[parameterIndex].default % 60 === 0) {
          labelIdElement.innerHTML = selectedJsonGameParameters[parameterIndex].default / 60 + " min 0 s";
        }
        else {
          labelIdElement.innerHTML = Math.floor(selectedJsonGameParameters[parameterIndex].default / 60) + " min 30 s";
        }
      }

      if (labelTextZero !== null && (selectedJsonGameParameters[parameterIndex].default === "0" ||
        selectedJsonGameParameters[parameterIndex].default === 0) ) {
        labelIdElement.innerHTML = labelTextZero;
      }

      if (middleCoefficient !== null) {
        if (middleCoefficient === "stack") {
          labelIdElement.innerHTML = selectedJsonGameParameters[parameterIndex].default + "x" +
            selectedJsonGameParameters[parameterIndex].default + "x" +
            selectedJsonGameParameters[parameterIndex].default
        }
        else if (middleCoefficient === "grid") {
          labelIdElement.innerHTML = selectedJsonGameParameters[parameterIndex].default + "x" +
            selectedJsonGameParameters[parameterIndex].default + "x";
        }
      }

    }

    return selectedJsonGameParameters[parameterIndex].default;
  }
  else {
    if (labelIdElement !== undefined && labelIdElement !== null) {
      if (middleCoefficient !== null) {
        if (middleCoefficient === "stack") {
          labelIdElement.innerHTML = parameterDefault + "x" +
            parameterDefault + "x" +
            parameterDefault
        }
        else if (middleCoefficient === "grid") {
          labelIdElement.innerHTML = parameterDefault + "x" +
            parameterDefault + "x";
        }
      }
    }
  }

  return parameterDefault;

}

function checkMin(selectedGameParametersTable, parameterIndex, parameterDefault) {
  if (selectedGameParameters[parameterIndex].min !== undefined) {
    return selectedGameParameters[parameterIndex].min;
  }
  else {
    return parameterDefault;
  }
}

function checkMax(selectedGameParametersTable, parameterIndex, parameterDefault) {
  if (selectedGameParameters[parameterIndex].max !== undefined) {
    return selectedGameParameters[parameterIndex].max;
  }
  else {
    return parameterDefault;
  }
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const EditRecipeParameters = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (templateMenuNavigate !== "true") {
          if (showClients !== "true" && data.role !== "Pro") {
            window.location.replace(window.location.origin + "/account");
          }
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showParameters === "false") {
    window.location.replace(window.location.origin + "/account");
  }

  const navigate = useNavigate();

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(2);

  return (
    <div className="EditRecipeParameters-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        { createOrgClient === "true" && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
              {t("local_organisation")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientSelection(navigate)}>
          {t("local_clients_list")}
        </button>
        <span> – </span>
        { navigateFromClient !== null && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClient(navigate)}>
              {t("local_client")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientCreation(navigate)}>
          {t("local_create_or_edit_client")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToRecipes(navigate)}>
          {t("local_recipes")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToNewRecipe(navigate)}>
          {t("local_create_or_edit_recipe")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToParameters(navigate)}>
          {t("local_edit_parameters")}
        </button>
      </div>

      <div className="Menu-container">
        <div className="EditParameters-Menu-left">
          <input type="text" id="MenuSearchbar" /> <br />
          <strong>{t("local_clients")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditclient">{t("local_create_new")}</a>
          </span>
          <p className="EditParameters-Menu-left-footer"p>{t("local_edit_parameters_footer")}</p>
        </div>
        <div className="EditParameters-Menu-right">
          <div className="settings-container">
            <p className="selectedGameName">{t(selectedGameId)}</p>
            { imageTypeRadioButtonGroupIndex !== undefined && imageTypeRadioButtonGroupIndex !== -1 && (
              <div id="imageTypeRadioButtonGroup" className="imageTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_image_type")}</p>
                <input className="radioButton" type="radio" id="imagePhotograph" name="imageTypeRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, imageTypeRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="imagePhotograph">{t("local_parameters_photograph")}</label><br />
                <input className="radioButton" type="radio" id="imageDrawing" name="imageTypeRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="imageDrawing">{t("local_parameters_drawing")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { categories1Index !== undefined && categories1Index !== -1 && (
              <div id="categories1">
                <p className="nameHeader">{t("local_parameters_categories")}</p>
                <input className="checkBox" type="checkbox" id="professionsCheckBox" name="categories" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, categories1Index, ["Option1"])} />
                <label className="checkBoxLabel" id="professionsCheckBoxLabel" htmlFor="professionsCheckBox">{t("local_parameters_professions")}</label>
                <input className="checkBox" type="checkbox" id="instrumentsCheckBox" name="categories" value="Option2" />
                <label className="checkBoxLabel" htmlFor="instrumentsCheckBox">{t("local_parameters_instruments")}</label> <br />
                <input className="checkBox" type="checkbox" id="animalsCheckBox" name="categories" value="Option3" />
                <label className="checkBoxLabel" id="animalsCheckBoxLabel" htmlFor="animalsCheckBox">{t("local_parameters_animals")}</label>
                <input className="checkBox" type="checkbox" id="toolsCheckBox" name="categories" value="Option4" />
                <label className="checkBoxLabel" htmlFor="toolsCheckBox">{t("local_parameters_tools")}</label> <br />
                <input className="checkBox" type="checkbox" id="plantsCheckBox" name="categories" value="Option5" />
                <label className="checkBoxLabel" id="plantsCheckBoxLabel" htmlFor="plantsCheckBox">{t("local_parameters_plants")}</label>
                <input className="checkBox" type="checkbox" id="sportsCheckBox" name="categories" value="Option6" />
                <label className="checkBoxLabel" htmlFor="sportsCheckBox">{t("local_parameters_sports")}</label> <br />
                <input className="checkBox" type="checkbox" id="citiesCheckBox" name="categories" value="Option7" />
                <label className="checkBoxLabel" id="citiesCheckBoxLabel" htmlFor="citiesCheckBox">{t("local_parameters_cities")}</label>
                <input className="checkBox" type="checkbox" id="countriesCheckBox" name="categories" value="Option8" />
                <label className="checkBoxLabel" htmlFor="countriesCheckBox">{t("local_parameters_countries")}</label> <br />
                <input className="checkBox" type="checkbox" id="maleNamesCheckBox" name="categories" value="Option9" />
                <label className="checkBoxLabel" id="maleNamesCheckBoxLabel" htmlFor="maleNamesCheckBox">{t("local_parameters_male_names")}</label>
                <input className="checkBox" type="checkbox" id="femaleNamesCheckBox" name="categories" value="Option10" />
                <label className="checkBoxLabel" htmlFor="femaleNamesCheckBox">{t("local_parameters_female_names")}</label> <br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { categories2Index !== undefined && categories2Index !== -1 && (
              <div id="categories2">
                <p className="nameHeader">{t("local_parameters_categories")}</p>
                <input className="checkBox" type="checkbox" id="additionCheckBox" name="categories2" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, categories2Index, ["Option1"])} />
                <label className="checkBoxLabel" id="additionCheckBoxLabel" htmlFor="additionCheckBox">Addition</label>
                <input className="checkBox" type="checkbox" id="subtractionCheckBox" name="categories2" value="Option2" />
                <label className="checkBoxLabel" htmlFor="subtractionCheckBox">Subtraction</label> <br />
                <input className="checkBox" type="checkbox" id="multiplicationCheckBox" name="categories2" value="Option3" />
                <label className="checkBoxLabel" id="multiplicationCheckBoxLabel" htmlFor="multiplicationCheckBox">Multiplication</label>
                <input className="checkBox" type="checkbox" id="divisionCheckBox" name="categories2" value="Option4" />
                <label className="checkBoxLabel" htmlFor="divisionCheckBox">Division</label> <br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { categories3Index !== undefined && categories3Index !== -1 && (
              <div id="categories3">
                <p className="nameHeader">{t("local_parameters_categories")}</p>
                <input className="checkBox" type="checkbox" id="professionsCheckBox2" name="categories3" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, categories3Index, ["Option1"], checkMinCheckBoxChecked("categories3"))} onChange={() => checkMinCheckBoxChecked("categories3")} />
                <label className="checkBoxLabel" id="professionsCheckBoxLabel2" htmlFor="professionsCheckBox2">{t("local_parameters_professions")}</label>
                <input className="checkBox" type="checkbox" id="animalsCheckBox2" name="categories3" value="Option2" onChange={() => checkMinCheckBoxChecked("categories3")} />
                <label className="checkBoxLabel" htmlFor="animalsCheckBox2">{t("local_parameters_animals")}</label> <br />
                <input className="checkBox" type="checkbox" id="plantsCheckBox2" name="categories3" value="Option3" onChange={() => checkMinCheckBoxChecked("categories3")} />
                <label className="checkBoxLabel" id="plantsCheckBoxLabel2" htmlFor="plantsCheckBox2">{t("local_parameters_plants")}</label>
                <input className="checkBox" type="checkbox" id="maleNamesCheckBox2" name="categories3" value="Option4" onChange={() => checkMinCheckBoxChecked("categories3")} />
                <label className="checkBoxLabel" htmlFor="maleNamesCheckBox2">{t("local_parameters_male_names")}</label> <br />
                <input className="checkBox" type="checkbox" id="femaleNamesCheckBox2" name="categories3" value="Option5" onChange={() => checkMinCheckBoxChecked("categories3")} />
                <label className="checkBoxLabel" htmlFor="femaleNamesCheckBox2">{t("local_parameters_female_names")}</label> <br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { categories4Index !== undefined && categories4Index !== -1 && (
              <div id="categories4">
                <p className="nameHeader">{t("local_parameters_categories")}</p>
                <input className="checkBox" type="checkbox" id="foodStuffCheckBox" name="categories4" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, categories4Index, ["Option1", "Option2"])} />
                <label className="checkBoxLabel" id="foodStuffCheckBoxLabel" htmlFor="foodStuffCheckBox">{t("local_parameters_foodstuff")}</label>
                <input className="checkBox" type="checkbox" id="animalsCheckBox3" name="categories4" value="Option2" />
                <label className="checkBoxLabel" htmlFor="animalsCheckBox3">{t("local_parameters_animals")}</label> <br />
                <input className="checkBox" type="checkbox" id="vehiclesCheckBox" name="categories4" value="Option3" />
                <label className="checkBoxLabel" id="vehiclesCheckBoxLabel" htmlFor="vehiclesCheckBox">{t("local_parameters_vehicles")}</label>
                <input className="checkBox" type="checkbox" id="instrumentsCheckBox2" name="categories4" value="Option4" />
                <label className="checkBoxLabel" htmlFor="instrumentsCheckBox2">{t("local_parameters_instruments")}</label> <br />
                <input className="checkBox" type="checkbox" id="toolsCheckBox2" name="categories4" value="Option5" />
                <label className="checkBoxLabel" htmlFor="toolsCheckBox2">{t("local_parameters_tools")}</label> <br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { categories5Index !== undefined && categories5Index !== -1 && (
              <div id="categories5">
                <p className="nameHeader">{t("local_parameters_categories")}</p>
                <input className="checkBox" type="checkbox" id="professionsCheckBox3" name="categories5" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, categories5Index, ["Option1", "Option2"], checkMinCheckBoxChecked("categories5"))} onChange={() => checkMinCheckBoxChecked("categories5")} />
                <label className="checkBoxLabel" id="professionsCheckBoxLabel2" htmlFor="professionsCheckBox3">{t("local_parameters_professions")}</label>
                <input className="checkBox" type="checkbox" id="animalsCheckBox4" name="categories5" value="Option2" onChange={() => checkMinCheckBoxChecked("categories5")} />
                <label className="checkBoxLabel" htmlFor="animalsCheckBox4">{t("local_parameters_animals")}</label> <br />
                <input className="checkBox" type="checkbox" id="plantsCheckBox3" name="categories5" value="Option3" onChange={() => checkMinCheckBoxChecked("categories5")} />
                <label className="checkBoxLabel" id="plantsCheckBoxLabel2" htmlFor="plantsCheckBox3">{t("local_parameters_plants")}</label>
                <input className="checkBox" type="checkbox" id="maleNamesCheckBox3" name="categories5" value="Option4" onChange={() => checkMinCheckBoxChecked("categories5")} />
                <label className="checkBoxLabel" htmlFor="maleNamesCheckBox3">{t("local_parameters_male_names")}</label> <br />
                <input className="checkBox" type="checkbox" id="femaleNamesCheckBox3" name="categories5" value="Option5" onChange={() => checkMinCheckBoxChecked("categories5")} />
                <label className="checkBoxLabel" htmlFor="femaleNamesCheckBox3">{t("local_parameters_female_names")}</label> <br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { categories6Index !== undefined && categories6Index !== -1 && (
              <div id="categories6">
                <p className="nameHeader">{t("local_parameters_categories")}</p>
                <input className="checkBox" type="checkbox" id="foodStuffCheckBox2" name="categories6" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, categories4Index, ["Option1", "Option2"], checkMinCheckBoxChecked("categories6"))} onChange={() => checkMinCheckBoxChecked("categories6")} />
                <label className="checkBoxLabel" id="foodStuffCheckBoxLabel" htmlFor="foodStuffCheckBox2">{t("local_parameters_foodstuff")}</label>
                <input className="checkBox" type="checkbox" id="animalsCheckBox5" name="categories6" value="Option2" onChange={() => checkMinCheckBoxChecked("categories6")} />
                <label className="checkBoxLabel" htmlFor="animalsCheckBox5">{t("local_parameters_animals")}</label> <br />
                <input className="checkBox" type="checkbox" id="vehiclesCheckBox2" name="categories6" value="Option3" onChange={() => checkMinCheckBoxChecked("categories6")} />
                <label className="checkBoxLabel" id="vehiclesCheckBoxLabel" htmlFor="vehiclesCheckBox2">{t("local_parameters_vehicles")}</label>
                <input className="checkBox" type="checkbox" id="instrumentsCheckBox3" name="categories6" value="Option4" onChange={() => checkMinCheckBoxChecked("categories6")} />
                <label className="checkBoxLabel" htmlFor="instrumentsCheckBox3">{t("local_parameters_instruments")}</label> <br />
                <input className="checkBox" type="checkbox" id="toolsCheckBox3" name="categories6" value="Option5" onChange={() => checkMinCheckBoxChecked("categories6")} />
                <label className="checkBoxLabel" htmlFor="toolsCheckBox3">{t("local_parameters_tools")}</label> <br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { moveDirectionIndex !== undefined && moveDirectionIndex !== -1 && (
              <div id="moveDirectionOptions">
                <p className="nameHeader">{t("local_parameters_move_direction")}</p>
                <select className="EditParameters_select" name="moveDirection" id="moveDirection" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, moveDirectionIndex, "1")}>
                  <option value="1">{t("local_parameters_left_to_right")}</option>
                  <option value="2">{t("local_parameters_right_to_left")}</option>
                  <option value="3">{t("local_parameters_top_to_bottom")}</option>
                  <option value="4">{t("local_parameters_bottom_to_top")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { sudokuSizeIndex !== undefined && sudokuSizeIndex !== -1 && (
              <div id="sudokuSizeOptions">
                <p className="nameHeader">{t("local_parameters_sudoku_size")}</p>
                <select className="EditParameters_select" name="sudokuSize" id="sudokuSize" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, sudokuSizeIndex, "0")}>
                  <option value="0">4x4</option>
                  <option value="1">9x9</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { choiceCountIndex !== undefined && choiceCountIndex !== -1 && (
              <div id="choiceCountOptions">
                <p className="nameHeader">{t("local_parameters_count_of_answers")}</p>
                <select className="EditParameters_select" name="choiceCount" id="choiceCount" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, choiceCountIndex, "1")}>
                  <option value="0">9</option>
                  <option value="1">16</option>
                  <option value="2">25</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimulusTypeIndex !== undefined && stimulusTypeIndex !== -1 && (
              <div id="stimulusTypeOptions">
                <p className="nameHeader">{t("local_parameters_item_type")}</p>
                <select className="EditParameters_select" name="stimulusType" id="stimulusType" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, stimulusTypeIndex, "0")}>
                  <option value="0">{t("local_parameters_geometry")}</option>
                  <option value="1">{t("local_parameters_3D_object")}</option>
                  <option value="2">{t("local_parameters_item")}</option>
                  <option value="3">{t("local_parameters_animals")}</option>
                  <option value="4">{t("local_parameters_flags")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { typeIndex !== undefined && typeIndex !== -1 && (
              <div id="typeOptions">
                <p className="nameHeader">{t("local_parameters_choice_mode_fix")}</p>
                <select className="EditParameters_select" name="type" id="type" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, typeIndex, "0", setAdvancedOptions)} onChange={setAdvancedOptions}>
                  <option value="0">{t("local_parameters_images")}</option>
                  <option value="1">{t("local_parameters_numbers")}</option>
                  <option value="2">{t("local_parameters_letters")}</option>
                  <option value="3">{t("local_parameters_symbols")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { type2Index !== undefined && type2Index !== -1 && (
              <div id="type2Options">
                <p className="nameHeader">{t("local_parameters_choice_mode_fix")}</p>
                <select className="EditParameters_select" name="type2" id="type2" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, type2Index, "0")}>
                  <option value="0">{t("local_parameters_letters")}</option>
                  <option value="1">{t("local_parameters_numbers")}</option>
                  <option value="2">{t("local_parameters_symbols")}</option>
                  <option value="3">{t("local_parameters_shapes")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { type3Index !== undefined && type3Index !== -1 && (
              <div id="type3Options">
                <p className="nameHeader">{t("local_parameters_choice_mode_fix")}</p>
                <select className="EditParameters_select" name="type3" id="type3" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, type3Index, "0")} >
                  <option value="0">{t("local_parameters_letters")}</option>
                  <option value="1">{t("local_parameters_numbers")}</option>
                  <option value="2">{t("local_parameters_symbols")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { difficultyIndex !== undefined && difficultyIndex !== -1 && (
              <div id="difficultyOptions">
                <p className="nameHeader">{t("local_parameters_difficulty")}</p>
                <select className="EditParameters_select" name="difficulty" id="difficulty" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, difficultyIndex, "0")} >
                  <option value="0">{t("local_parameters_easy")}</option>
                  <option value="1">{t("local_parameters_normal")}</option>
                  <option value="2">{t("local_parameters_hard")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { difficultySearchIndex !== undefined && difficultySearchIndex !== -1 && (
              <div id="difficultySearchOptions">
                <p className="nameHeader">{t("local_parameters_difficulty")}</p>
                <select className="EditParameters_select" name="difficultySearch" id="difficultySearch" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, difficultySearchIndex, "0", setAdvanced2Options)} onChange={setAdvanced2Options} >
                  <option value="0">{t("local_parameters_easy_search")}</option>
                  <option value="1">{t("local_parameters_normal_search")}</option>
                  <option value="2">{t("local_parameters_hard_search")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { shapeTypeIndex !== undefined && shapeTypeIndex !== -1 && (
              <div id="shapeTypeOptions">
                <p className="nameHeader">{t("local_parameters_choice_mode_fix")}</p>
                <select className="EditParameters_select" name="shapeType" id="shapeType" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, shapeTypeIndex, "0")}>
                  <option value="0">{t("local_parameters_different_coloured_balls")}</option>
                  <option value="1">{t("local_parameters_different_coloured_and_shaped")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { soundTypeIndex !== undefined && soundTypeIndex !== -1 && (
              <div id="soundTypeOptions">
                <p className="nameHeader">{t("local_parameters_choice_mode_fix")}</p>
                <select className="EditParameters_select" name="soundType" id="soundType" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, soundTypeIndex, "0")} >
                  <option value="0">{t("local_parameters_two_sounds")}</option>
                  <option value="1">{t("local_parameters_many_sounds")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { boardTypeIndex !== undefined && boardTypeIndex !== -1 && (
              <div id="boardTypeOptions">
                <p className="nameHeader">{t("local_parameters_board_type")}</p>
                <select className="EditParameters_select" name="boardType" id="boardType" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, boardTypeIndex, "0")} >
                  <option value="0">{t("local_parameters_german")}</option>
                  <option value="1">{t("local_parameters_diamond")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { towerHeightIndex !== undefined && towerHeightIndex !== -1 && (
              <div id="towerHeightOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_tower_height")}</label>
                  <label className="nameLabel2" id="towerHeightLabel">4</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, towerHeightIndex, "3")} max={checkMax(selectedGameParametersTable, towerHeightIndex, "5")} defaultValue={checkDefault(selectedGameParametersTable, towerHeightIndex, "4", "towerHeightLabel")} className="slider" id="towerHeightSlider" onChange={setTowerHeightNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { pearlAmountIndex !== undefined && pearlAmountIndex !== -1 && (
              <div id="pearlAmountOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_pearl_count")}</label>
                  <label className="nameLabel2" id="pearlAmountLabel">4</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, pearlAmountIndex, "3")} max={checkMax(selectedGameParametersTable, pearlAmountIndex, "6")} defaultValue={checkDefault(selectedGameParametersTable, pearlAmountIndex, "4", "pearlAmountLabel")} className="slider" id="pearlAmountSlider" onChange={setPearlAmountNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { availableSlotsIndex !== undefined && availableSlotsIndex !== -1 && (
              <div id="availableSlotsOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_free_spaces")}</label>
                  <label className="nameLabel2" id="availableSlotsLabel">10</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, availableSlotsIndex, "8")} max={checkMax(selectedGameParametersTable, availableSlotsIndex, "16")} defaultValue={checkDefault(selectedGameParametersTable, availableSlotsIndex, "10", "availableSlotsLabel")} className="slider" id="availableSlotsSlider" onChange={setAvailableSlotsNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { towerSizeIndex !== undefined && towerSizeIndex !== -1 && (
              <div id="towerSizeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_tower_size")}</label>
                  <label className="nameLabel2" id="towerSizeLabel">4</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, towerSizeIndex, "3")} max={checkMax(selectedGameParametersTable, towerSizeIndex, "6")} defaultValue={checkDefault(selectedGameParametersTable, towerSizeIndex, "4", "towerSizeLabel")} className="slider" id="towerSizeSlider" onChange={setTowerSizeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { hintNumberIndex !== undefined && hintNumberIndex !== -1 && (
              <div id="hintNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_hint_count")}</label>
                  <label className="nameLabel2" id="hintNumberLabel">6</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, hintNumberIndex, "4")} max={checkMax(selectedGameParametersTable, hintNumberIndex, "8")} defaultValue={checkDefault(selectedGameParametersTable, hintNumberIndex, "6", "hintNumberLabel")} className="slider" id="hintNumberSlider" onChange={setHintNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { numbersDeducedIndex !== undefined && numbersDeducedIndex !== -1 && (
              <div id="numbersDeducedOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_number_count_deduce")}</label>
                  <label className="nameLabel2" id="numbersDeducedLabel">2</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, numbersDeducedIndex, "1")} max={checkMax(selectedGameParametersTable, numbersDeducedIndex, "3")} defaultValue={checkDefault(selectedGameParametersTable, numbersDeducedIndex, "2", "numbersDeducedLabel")} className="slider" id="numbersDeducedSlider" onChange={setNumbersDeducedNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { shapesVisibleIndex !== undefined && shapesVisibleIndex !== -1 && (
              <div id="shapesVisibleOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_shape_count_visible")}</label>
                  <label className="nameLabel2" id="shapesVisibleLabel">8 {t("local_parameters_pcs")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, shapesVisibleIndex, "8")} max={checkMax(selectedGameParametersTable, shapesVisibleIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, shapesVisibleIndex, "8", "shapesVisibleLabel", t("local_parameters_pcs"))} className="slider" id="shapesVisibleSlider" onChange={(event) => setShapesVisibleNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { shapesDeducedIndex !== undefined && shapesDeducedIndex !== -1 && (
              <div id="shapesDeducedOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_shape_count_deduce")}</label>
                  <label className="nameLabel2" id="shapesDeducedLabel">2 {t("local_parameters_pcs")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, shapesDeducedIndex, "1")} max={checkMax(selectedGameParametersTable, shapesDeducedIndex, "3")} defaultValue={checkDefault(selectedGameParametersTable, shapesDeducedIndex, "2", "shapesDeducedLabel", t("local_parameters_pcs"))} className="slider" id="shapesDeducedSlider" onChange={(event) => setShapesDeducedNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { colorLengthIndex !== undefined && colorLengthIndex !== -1 && (
              <div id="colorLengthOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_color_code_length")}</label>
                  <label className="nameLabel2" id="colorLengthLabel">4</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, colorLengthIndex, "3")} max={checkMax(selectedGameParametersTable, colorLengthIndex, "6")} defaultValue={checkDefault(selectedGameParametersTable, colorLengthIndex, "4", "colorLengthLabel")} className="slider" id="colorLengthSlider" onChange={setColorLengthNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { colorNumberIndex !== undefined && colorNumberIndex !== -1 && (
              <div id="colorNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_color_count")}</label>
                  <label className="nameLabel2" id="colorNumberLabel">6</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, colorNumberIndex, "6")} max={checkMax(selectedGameParametersTable, colorNumberIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, colorNumberIndex, "6", "colorNumberLabel")} className="slider" id="colorNumberSlider" onChange={setColorNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { wordLengthIndex !== undefined && wordLengthIndex !== -1 && (
              <div id="wordLengthOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_word_lenght")}</label>
                  <label className="nameLabel2" id="wordLengthLabel">4 {t("local_parameters_letters3")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, wordLengthIndex, "3")} max={checkMax(selectedGameParametersTable, wordLengthIndex, "6")} defaultValue={checkDefault(selectedGameParametersTable, wordLengthIndex, "4", "wordLengthLabel", t("local_parameters_letters3"))} className="slider" id="wordLengthSlider" onChange={(event) => setWordLengthNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { repeatNumberIndex !== undefined && repeatNumberIndex !== -1 && (
              <div id="repeatNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_amount_of_repeats")}</label>
                  <label className="nameLabel2" id="repeatNumberLabel">5</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, repeatNumberIndex, "3")} max={checkMax(selectedGameParametersTable, repeatNumberIndex, "15")} defaultValue={checkDefault(selectedGameParametersTable, repeatNumberIndex, "5", "repeatNumberLabel")} className="slider" id="repeatNumberSlider" onChange={setRepeatNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { solvedLettersIndex !== undefined && solvedLettersIndex !== -1 && (
              <div id="solvedLettersOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_cryptogram_portion_of_solved_letters")}</label>
                  <label className="nameLabel2" id="solvedLettersLabel">20 %</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, solvedLettersIndex, "0")} max={checkMax(selectedGameParametersTable, solvedLettersIndex, "50")} step="10" defaultValue={checkDefault(selectedGameParametersTable, solvedLettersIndex, "20", "solvedLettersLabel", "%")} className="slider" id="solvedLettersSlider" onChange={setSolvedLetters} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { instantFeedbackIndex !== undefined && instantFeedbackIndex !== -1 && (
              <div id="instantFeedbackOptions" className="instantFeedbackOptions">
                <input className="checkBox" type="checkbox" id="instantFeedbackCheckBox" name="instantFeedback" value="true" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, instantFeedbackIndex, ["true"])} />
                <label className="checkBoxLabel" htmlFor="instantFeedbackCheckBox">{t("local_parameters_instant_feedback_after_selection")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { mazeSizeIndex !== undefined && mazeSizeIndex !== -1 && (
              <div id="mazeSizeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_maze_size")}</label>
                  <label className="nameLabel2" id="mazeSizeLabel">5</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, mazeSizeIndex, "3")} max={checkMax(selectedGameParametersTable, mazeSizeIndex, "15")} defaultValue={checkDefault(selectedGameParametersTable, mazeSizeIndex, "5", "mazeSizeLabel")} className="slider" id="mazeSizeSlider" onChange={setMazeSizeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { optionsNumberIndex !== undefined && optionsNumberIndex !== -1 && (
              <div id="optionsNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_options_count")}</label>
                  <label className="nameLabel2" id="optionsNumberLabel">4</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, optionsNumberIndex, "3")} max={checkMax(selectedGameParametersTable, optionsNumberIndex, "5")} defaultValue={checkDefault(selectedGameParametersTable, optionsNumberIndex, "4", "optionsNumberLabel")} className="slider" id="optionsNumberSlider" onChange={setOptionsNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { optionsNumber2Index !== undefined && optionsNumber2Index !== -1 && (
              <div id="optionsNumber2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_options_count")}</label>
                  <label className="nameLabel2" id="optionsNumberLabel2">5</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, optionsNumber2Index, "3")} max={checkMax(selectedGameParametersTable, optionsNumber2Index, "6")} defaultValue={checkDefault(selectedGameParametersTable, optionsNumber2Index, "5", "optionsNumberLabel2")} className="slider" id="optionsNumberSlider2" onChange={setOptionsNumber2} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { difficultyRadioButtonGroup2Index !== undefined && difficultyRadioButtonGroup2Index !== -1 && (
              <div id="difficultyRadioButtonGroup2" className="difficultyRadioButtonGroup2">
                <p className="radioButtonGroupHeader">{t("local_parameters_difficulty")}</p>
                <label>{t("local_parameters_easy2")}</label><br />
                <input className="radioButton" type="radio" id="difficultyA1" name="difficultyRadio2" value="0" onChange={setOptionsNumberValue} onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, difficultyRadioButtonGroup2Index, "0", setOptionsNumberValue)} />
                <label className="radioButtonLabel" id="difficultyA1Label" htmlFor="difficultyA1">A1</label>
                <input className="radioButton" type="radio" id="difficultyA2" name="difficultyRadio2" value="2" onChange={setOptionsNumberValue} />
                <label className="radioButtonLabel" id="difficultyA2Label" htmlFor="difficultyA2">A2</label>
                <input className="radioButton" type="radio" id="difficultyA3" name="difficultyRadio2" value="4" onChange={setOptionsNumberValue} />
                <label className="radioButtonLabel" htmlFor="difficultyA3">A3</label><br />
                <label>{t("local_parameters_hard2")}</label><br />
                <input className="radioButton" type="radio" id="difficultyB1" name="difficultyRadio2" value="1" onChange={setOptionsNumberValue} />
                <label className="radioButtonLabel" id="difficultyB1Label" htmlFor="difficultyB1">B1</label>
                <input className="radioButton" type="radio" id="difficultyB2" name="difficultyRadio2" value="3" onChange={setOptionsNumberValue} />
                <label className="radioButtonLabel" id="difficultyB2Label" htmlFor="difficultyB2">B2</label>
                <input className="radioButton" type="radio" id="difficultyB3" name="difficultyRadio2" value="5" onChange={setOptionsNumberValue} />
                <label className="radioButtonLabel" htmlFor="difficultyB3">B3</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { rotatingLineIndex !== undefined && rotatingLineIndex !== -1 && (
              <div id="rotatingLineOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_count_of_line")}</label>
                  <label className="nameLabel2" id="rotatingLineLabel">2</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, rotatingLineIndex, "1")} max={checkMax(selectedGameParametersTable, rotatingLineIndex, "5")} defaultValue={checkDefault(selectedGameParametersTable, rotatingLineIndex, "2", "rotatingLineLabel")} className="slider" id="rotatingLineSlider" onChange={setRotatingLineNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { performanceTimeIndex !== undefined && performanceTimeIndex !== -1 && (
              <div id="performanceTimeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_performance_time")}</label>
                  <label className="nameLabel2" id="performanceTimeLabel">1 min 0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, performanceTimeIndex, "30")}  max={checkMax(selectedGameParametersTable, performanceTimeIndex, "300")} defaultValue={checkDefault(selectedGameParametersTable, performanceTimeIndex, "60", "performanceTimeLabel", null, null, null, "minutes", t("local_parameters_unlimited"))} className="slider" id="performanceTimeSlider" onChange={(event) => setPerformanceTimeNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { performanceTime2Index !== undefined && performanceTime2Index !== -1 && (
              <div id="performanceTime2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_performance_time")}</label>
                  <label className="nameLabel2" id="performanceTimeLabel2">3 min 0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, performanceTime2Index, "30")} max={checkMax(selectedGameParametersTable, performanceTime2Index, "300")} defaultValue={checkDefault(selectedGameParametersTable, performanceTime2Index, "180", "performanceTimeLabel2", null, null, null, "minutes", t("local_parameters_unlimited"))} className="slider" id="performanceTimeSlider2" onChange={(event) => setPerformanceTimeNumber2(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { speedIndex !== undefined && speedIndex !== -1 && (
              <div id="speedOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_fps_speed")}</label>
                  <label className="nameLabel2" id="speedLabel">50 %</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, speedIndex, "20")} max={checkMax(selectedGameParametersTable, speedIndex, "100")} defaultValue={checkDefault(selectedGameParametersTable, speedIndex, "50", "speedLabel", "%")} className="slider" id="speedSlider" onChange={setSpeedNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimulusCategoryIndex !== undefined && stimulusCategoryIndex !== -1 && (
              <div id="stimulusCategoryOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_item_amount_per_category")}</label>
                  <label className="nameLabel2" id="stimulusCategoryLabel">2</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stimulusCategoryIndex, "1")} max={checkMax(selectedGameParametersTable, stimulusCategoryIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, stimulusCategoryIndex, "2", "stimulusCategoryLabel")} className="slider" id="stimulusCategorySlider" onChange={setStimulusCategoryNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { conceptGroupsIndex !== undefined && conceptGroupsIndex !== -1 && (
              <div id="conceptGroupsOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_amount_consept_of_group")}</label>
                  <label className="nameLabel2" id="conceptGroupsLabel">2</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, conceptGroupsIndex, "1")} max={checkMax(selectedGameParametersTable, conceptGroupsIndex, "7")} defaultValue={checkDefault(selectedGameParametersTable, conceptGroupsIndex, "2", "conceptGroupsLabel")} className="slider" id="conceptGroupsSlider" onChange={setConceptGroupsNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { newsNumberIndex !== undefined && newsNumberIndex !== -1 && (
              <div id="newsNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_news_count")}</label>
                  <label className="nameLabel2" id="newsNumberLabel">3 {t("local_parameters_pcs")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, newsNumberIndex, "1")} max={checkMax(selectedGameParametersTable, newsNumberIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, newsNumberIndex, "3", "newsNumberLabel", t("local_parameters_pcs"))} className="slider" id="newsNumberSlider" onChange={(event) => setNewsNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { readingTimeIndex !== undefined && readingTimeIndex !== -1 && (
              <div id="readingTimeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_reading_time")}</label>
                  <label className="nameLabel2" id="readingTimeLabel">2 min 0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, readingTimeIndex, "0")} max={checkMax(selectedGameParametersTable, readingTimeIndex, "240")} defaultValue={checkDefault(selectedGameParametersTable, readingTimeIndex, "120", "readingTimeLabel", null, null, null, "minutes", t("local_parameters_unlimited"))} step="10" className="slider" id="readingTimeSlider" onChange={(event) => setReadingTimeNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { answerTime2Index !== undefined && answerTime2Index !== -1 && (
              <div id="answerTime2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_answer_time")}</label>
                  <label className="nameLabel2" id="answerTimeLabel2">{t("local_parameters_unlimited")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, answerTime2Index, "0")} max={checkMax(selectedGameParametersTable, answerTime2Index, "240")} defaultValue={checkDefault(selectedGameParametersTable, answerTime2Index, "0", "answerTimeLabel2", null, null, null, "minutes", t("local_parameters_unlimited"))} step="10" className="slider" id="answerTimeSlider2" onChange={(event) => setAnswerTimeNumber2(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { messageNumberIndex !== undefined && messageNumberIndex !== -1 && (
              <div id="messageNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_sms_amount")}</label>
                  <label className="nameLabel2" id="messageNumberLabel">5 {t("local_parameters_pcs")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, messageNumberIndex, "1")} max={checkMax(selectedGameParametersTable, messageNumberIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, messageNumberIndex, "5", "messageNumberLabel", t("local_parameters_pcs"))} className="slider" id="messageNumberSlider" onChange={(event) => setMessageNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { messageVisibilityIndex !== undefined && messageVisibilityIndex !== -1 && (
              <div id="messageVisibilityOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_sms_time")}</label>
                  <label className="nameLabel2" id="messageVisibilityLabel">30 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, messageVisibilityIndex, "4")} max={checkMax(selectedGameParametersTable, messageVisibilityIndex, "40")} defaultValue={checkDefault(selectedGameParametersTable, messageVisibilityIndex, "30", "messageVisibilityLabel", "s")} className="slider" id="messageVisibilitySlider" onChange={setMessageVisibilityNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { pictureNumberIndex !== undefined && pictureNumberIndex !== -1 && (
              <div id="pictureNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_picture_number")}</label>
                  <label className="nameLabel2" id="pictureNumberLabel">20</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, pictureNumberIndex, "4")} max={checkMax(selectedGameParametersTable, pictureNumberIndex, "24")} defaultValue={checkDefault(selectedGameParametersTable, pictureNumberIndex, "20", "pictureNumberLabel")} className="slider" id="pictureNumberSlider" onChange={setPictureNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { imageTimeIndex !== undefined && imageTimeIndex !== -1 && (
              <div id="imageTimeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_image_time")}</label>
                  <label className="nameLabel2" id="imageTimeLabel">4.5 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, imageTimeIndex, "2")} max={checkMax(selectedGameParametersTable, imageTimeIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, imageTimeIndex, "4.5", "imageTimeLabel", "s", 1)} step="0.1" className="slider" id="imageTimeSlider" onChange={setImageTimeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { wordCountIndex !== undefined && wordCountIndex !== -1 && (
              <div id="wordCountOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_word_count")}</label>
                  <label className="nameLabel2" id="wordCountLabel">10 {t("local_parameters_words2")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, wordCountIndex, "3")} max={checkMax(selectedGameParametersTable, wordCountIndex, "30")} defaultValue={checkDefault(selectedGameParametersTable, wordCountIndex, "10", "wordCountLabel", t("local_parameters_words2"))} className="slider" id="wordCountSlider" onChange={(event) => setWordCountNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { wordVisibilityIndex !== undefined && wordVisibilityIndex !== -1 && (
              <div id="wordVisibilityOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_word_visibility_time")}</label>
                  <label className="nameLabel2" id="wordVisibilityLabel">2.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, wordVisibilityIndex, "0.5")} max={checkMax(selectedGameParametersTable, wordVisibilityIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, wordVisibilityIndex, "2", "wordVisibilityLabel", "s", 1)} step="0.1" className="slider" id="wordVisibilitySlider" onChange={setWordVisibilityNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { wordTimeIndex !== undefined && wordTimeIndex !== -1 && (
              <div id="wordTimeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_word_interval")}</label>
                  <label className="nameLabel2" id="wordTimeLabel">0.5 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, wordTimeIndex, "0")} max={checkMax(selectedGameParametersTable, wordTimeIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, wordTimeIndex, "0.5", "wordTimeLabel", "s", 1)} step="0.1" className="slider" id="wordTimeSlider" onChange={setWordTimeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { answerTimeIndex !== undefined && answerTimeIndex !== -1 && (
              <div id="answerTimeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_answer_time")}</label>
                  <label className="nameLabel2" id="answerTimeLabel">4.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, answerTimeIndex, "1")} max={checkMax(selectedGameParametersTable, answerTimeIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, answerTimeIndex, "4", "answerTimeLabel", "s", 1)} className="slider" id="answerTimeSlider" onChange={setAnswerTimeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { allowRepeatIndex !== undefined && allowRepeatIndex !== -1 && (
              <div id="allowRepeatOptions" className="allowRepeatOptions">
                <input className="checkBox" type="checkbox" id="allowRepeatCheckBox" name="allowRepeat" value="true" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, allowRepeatIndex, [])} />
                <label className="checkBoxLabel" htmlFor="allowRepeatCheckBox">{t("local_parameters_allow_repeat")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { cardVisibilityIndex !== undefined && cardVisibilityIndex !== -1 && (
              <div id="cardVisibilityOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_card_visibility_time")}</label>
                  <label className="nameLabel2" id="cardVisibilityLabel">4.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, cardVisibilityIndex, "1")} max={checkMax(selectedGameParametersTable, cardVisibilityIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, cardVisibilityIndex, "4", "cardVisibilityLabel", "s", 1)} className="slider" id="cardVisibilitySlider" onChange={setCardVisibilityNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { drawNumberIndex !== undefined && drawNumberIndex !== -1 && (
              <div id="drawNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_draw_count")}</label>
                  <label className="nameLabel2" id="drawNumberLabel">25 {t("local_parameters_times")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, drawNumberIndex, "10")} max={checkMax(selectedGameParametersTable, drawNumberIndex, "50")} defaultValue={checkDefault(selectedGameParametersTable, drawNumberIndex, "25", "drawNumberLabel", t("local_parameters_times"))} className="slider" id="drawNumberSlider" onChange={(event) => setDrawNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { interferenceDurationIndex !== undefined && interferenceDurationIndex !== -1 && (
              <div id="interferenceDurationOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_interference_duration")}</label>
                  <label className="nameLabel2" id="interferenceDurationLabel">60 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, interferenceDurationIndex, "30")} max={checkMax(selectedGameParametersTable, interferenceDurationIndex, "180")} defaultValue={checkDefault(selectedGameParametersTable, interferenceDurationIndex, "60", "interferenceDurationLabel", "s")} className="slider" id="interferenceDurationSlider" onChange={setInterferenceDurationNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { exerciseTypeRadioButtonGroupIndex !== undefined && exerciseTypeRadioButtonGroupIndex !== -1 && (
              <div id="exerciseTypeRadioButtonGroup" className="exerciseTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_choice_mode_fix")}</p>
                <input className="radioButton" type="radio" id="exerciseFish" name="exerciseTypeRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, exerciseTypeRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="exerciseFish">{t("local_parameters_fish2")}</label><br />
                <input className="radioButton" type="radio" id="exerciseBall" name="exerciseTypeRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="exerciseBall">{t("local_parameters_ball")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { movementSpeedIndex !== undefined && movementSpeedIndex !== -1 && (
              <div id="movementSpeedOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_item_movement_speed")}</label>
                  <label className="nameLabel2" id="movementSpeedLabel">40 %</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, movementSpeedIndex, "10")} max={checkMax(selectedGameParametersTable, movementSpeedIndex, "100")} defaultValue={checkDefault(selectedGameParametersTable, movementSpeedIndex, "40", "movementSpeedLabel", "%")} className="slider" id="movementSpeedSlider" onChange={setMovementSpeedNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { numberDurationIndex !== undefined && numberDurationIndex !== -1 && (
              <div id="numberDurationOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_numbers_visibility_time")}</label>
                  <label className="nameLabel2" id="numberDurationLabel">2.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, numberDurationIndex, "0.1")} max={checkMax(selectedGameParametersTable, numberDurationIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, numberDurationIndex, "2", "numberDurationLabel", "s", 1)} step="0.1" className="slider" id="numberDurationSlider" onChange={setNumberDurationNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { localizationIndex !== undefined && localizationIndex !== -1 && (
              <div id="localizationOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_numbers_interval")}</label>
                  <label className="nameLabel2" id="localizationLabel">1.0</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, localizationIndex, "0.1")} max={checkMax(selectedGameParametersTable, localizationIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, localizationIndex, "1", "localizationLabel", null, 1)} step="0.1" className="slider" id="localizationSlider" onChange={setLocalizationNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { figureTypeRadioButtonGroupIndex !== undefined && figureTypeRadioButtonGroupIndex !== -1 && (
              <div id="figureTypeRadioButtonGroup" className="figureTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_gestalt_figure_type")}</p>
                <input className="radioButton" type="radio" id="figureGeometric" name="figureTypeRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, figureTypeRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" id="figureGeometricLabel" htmlFor="figureGeometric">{t("local_parameters_geometric")}</label>
                <input className="radioButton" type="radio" id="figureCharacter" name="figureTypeRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="figureCharacter">{t("local_parameters_character")}</label><br />
                <input className="radioButton" type="radio" id="figureNumber" name="figureTypeRadio" value="2" />
                <label className="radioButtonLabel" id="figureNumberLabel" htmlFor="figureNumber">{t("local_parameters_number")}</label>
                <input className="radioButton" type="radio" id="figureSymbol" name="figureTypeRadio" value="3" />
                <label className="radioButtonLabel" htmlFor="figureSymbol">{t("local_parameters_symbol")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stackSizeIndex !== undefined && stackSizeIndex !== -1 && (
              <div id="stackSizeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_cubestack_size")}</label>
                  <label className="nameLabel2" id="stackSizeLabel">3x3x3</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stackSizeIndex, "2")} max={checkMax(selectedGameParametersTable, stackSizeIndex, "5")} defaultValue={checkDefault(selectedGameParametersTable, stackSizeIndex, "3", "stackSizeLabel", null, null, null, null, null, "stack")} className="slider" id="stackSizeSlider" onChange={setStackSizeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { gridSizeIndex !== undefined && gridSizeIndex !== -1 && (
              <div id="gridSizeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_grid_size")}</label>
                  <label className="nameLabel2" id="gridSizeLabel">3x3</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, gridSizeIndex, "2")} max={checkMax(selectedGameParametersTable, gridSizeIndex, "6")} defaultValue={checkDefault(selectedGameParametersTable, gridSizeIndex, "3", "gridSizeLabel", null, null, null, null, null, "grid")} className="slider" id="gridSizeSlider" onChange={setGridSizeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { exerciseNumberIndex !== undefined && exerciseNumberIndex !== -1 && (
              <div id="exerciseNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_game_amount")}</label>
                  <label className="nameLabel2" id="exerciseNumberLabel">5</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, exerciseNumberIndex, "5")} max={checkMax(selectedGameParametersTable, exerciseNumberIndex, "100")} defaultValue={checkDefault(selectedGameParametersTable, exerciseNumberIndex, "5", "exerciseNumberLabel")} className="slider" id="exerciseNumberSlider" onChange={setExerciseNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { beginnerIndex !== undefined && beginnerIndex !== -1 && (
              <div id="beginnerOptions">
                <p className="nameHeader">{t("local_parameters_starter")}</p>
                <select className="EditParameters_select" name="beginner" id="beginner" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, beginnerIndex, "0")} >
                  <option value="0">{t("local_parameters_user")}</option>
                  <option value="1">{t("local_parameters_computer")}</option>
                  <option value="2">{t("local_parameters_random2")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { useOutlinesIndex !== undefined && useOutlinesIndex !== -1 && (
              <div id="useOutlinesOptions" className="useOutlinesOptions">
                <input className="checkBox" type="checkbox" id="useOutlinesCheckBox" name="useOutlines" value="true" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, useOutlinesIndex, [])} />
                <label className="checkBoxLabel" htmlFor="useOutlinesCheckBox">{t("local_parameters_use_outlines")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { gridPositionIndex !== undefined && gridPositionIndex !== -1 && (
              <div id="gridPositionOptions">
                <p className="nameHeader">{t("local_parameters_grid_pos")}</p>
                <select className="EditParameters_select" name="gridPosition" id="gridPosition" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, gridPositionIndex, "1")}>
                  <option value="0">{t("local_parameters_on_left")}</option>
                  <option value="1">{t("local_parameters_in_the_middle")}</option>
                  <option value="2">{t("local_parameters_on_right")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { gridSize2Index !== undefined && gridSize2Index !== -1 && (
              <div id="gridSize2Options">
                <p className="nameHeader">{t("local_parameters_grid_size")}</p>
                <select className="EditParameters_select" name="gridSize" id="gridSize" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, gridSize2Index, "2")}>
                  <option value="0">4x4</option>
                  <option value="1">6x6</option>
                  <option value="2">8x8</option>
                  <option value="3">10x10</option>
                  <option value="4">12x12</option>
                  <option value="5">14x14</option>
                  <option value="6">16x16</option>
                  <option value="7">18x18</option>
                  <option value="8">20x20</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { choiceCount2Index !== undefined && choiceCount2Index !== -1 && (
              <div id="choiceCount2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_choice_count2")}</label>
                  <label className="nameLabel2" id="choiceCountLabel">4</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, choiceCount2Index, "2")} max={checkMax(selectedGameParametersTable, choiceCount2Index, "16")} defaultValue={checkDefault(selectedGameParametersTable, choiceCount2Index, "4", "choiceCountLabel")} className="slider" id="choiceCountSlider" onChange={setChoiceCountNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { numberCountIndex !== undefined && numberCountIndex !== -1 && (
              <div id="numberCountOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_numbers_count")}</label>
                  <label className="nameLabel2" id="numberCountLabel">60</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, numberCountIndex, "5")} max={checkMax(selectedGameParametersTable, numberCountIndex, "100")} defaultValue={checkDefault(selectedGameParametersTable, numberCountIndex, "60", "numberCountLabel")} className="slider" id="numberCountSlider" onChange={setNumberCountNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { exerciseLengthIndex !== undefined && exerciseLengthIndex !== -1 && (
              <div id="exerciseLengthOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_lenght_of_exercise")}</label>
                  <label className="nameLabel2" id="exerciseLengthLabel">4 min</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, exerciseLengthIndex, "1")} max={checkMax(selectedGameParametersTable, exerciseLengthIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, exerciseLengthIndex, "4", "exerciseLengthLabel", "min")} className="slider" id="exerciseLengthSlider" onChange={setExerciseLengthNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { upperRowIndex !== undefined && upperRowIndex !== -1 && (
              <div id="upperRowOptions">
                <p className="nameHeader">{t("local_parameters_upper_row")}</p>
                <div id="letterIntervalOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_string_scroll_interval")}</label>
                    <label className="nameLabel2" id="letterIntervalLabel">1.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, upperRowIndex, "0.1")} max={checkMax(selectedGameParametersTable, upperRowIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, upperRowIndex, "1", "letterIntervalLabel", "s", 1)} step="0.1" className="slider" id="letterIntervalSlider" onChange={setLetterIntervalNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { upperRow2Index !== undefined && upperRow2Index !== -1 && (
              <div id="upperRow2Options">
                <div id="stringDirectionRadioButtonGroup" className="stringDirectionRadioButtonGroup">
                  <p className="radioButtonGroupHeader">{t("local_parameters_string_direction")}</p>
                  <input className="radioButton" type="radio" id="stringLeft" name="stringRadio" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, upperRow2Index, "1")} />
                  <label className="radioButtonLabel" htmlFor="stringLeft">{t("local_parameters_to_left")}</label><br />
                  <input className="radioButton" type="radio" id="stringRight" name="stringRadio" value="2" />
                  <label className="radioButtonLabel" htmlFor="stringRight">{t("local_parameters_to_right")}</label><br />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { stringLengthIndex !== undefined && stringLengthIndex !== -1 && (
              <div id="stringLengthOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_scroller_target_lenght")}</label>
                  <label className="nameLabel2" id="stringLengthLabel">2 {t("local_parameters_letters2")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stringLengthIndex, "1")} max={checkMax(selectedGameParametersTable, stringLengthIndex, "7")} defaultValue={checkDefault(selectedGameParametersTable, stringLengthIndex, "2", "stringLengthLabel", t("local_parameters_letters2"))} className="slider" id="stringLengthSlider" onChange={(event) => setStringLengthNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { letterIntervalIndex !== undefined && letterIntervalIndex !== -1 && (
              <div id="letterIntervalOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_string_scroll_interval")}</label>
                  <label className="nameLabel2" id="letterIntervalLabel">1.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, letterIntervalIndex, "0.1")} max={checkMax(selectedGameParametersTable, letterIntervalIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, letterIntervalIndex, "1", "letterIntervalLabel", "s", 1)} step="0.1" className="slider" id="letterIntervalSlider" onChange={setLetterIntervalNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stringDirectionRadioButtonGroupIndex !== undefined && stringDirectionRadioButtonGroupIndex !== -1 && (
              <div id="stringDirectionRadioButtonGroup" className="stringDirectionRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_string_direction")}</p>
                <input className="radioButton" type="radio" id="stringLeft" name="stringRadio" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, stringDirectionRadioButtonGroupIndex, "1")} />
                <label className="radioButtonLabel" htmlFor="stringLeft">{t("local_parameters_to_left")}</label><br />
                <input className="radioButton" type="radio" id="stringRight" name="stringRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="stringRight">{t("local_parameters_to_right")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { lowerRowIndex !== undefined && lowerRowIndex !== -1 && (
              <div id="lowerRowOptions">
                <p className="nameHeader">{t("local_parameters_lower_row")}</p>
                <div id="letterInterval2Options">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_string_scroll_interval")}</label>
                    <label className="nameLabel2" id="letterIntervalLabel2">1.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, lowerRowIndex, "0.1")} max={checkMax(selectedGameParametersTable, lowerRowIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, lowerRowIndex, "1", "letterIntervalLabel2", "s", 1)} step="0.1" className="slider" id="letterIntervalSlider2" onChange={setLetterIntervalNumber2} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { lowerRow2Index !== undefined && lowerRow2Index !== -1 && (
              <div id="lowerRow2Options">
                <div id="stringDirectionRadioButtonGroup2" className="stringDirectionRadioButtonGroup2">
                  <p className="radioButtonGroupHeader">{t("local_parameters_string_direction")}</p>
                  <input className="radioButton" type="radio" id="stringLeft2" name="stringRadio2" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, lowerRow2Index, "1")} />
                  <label className="radioButtonLabel" htmlFor="stringLeft2">{t("local_parameters_to_left")}</label><br />
                  <input className="radioButton" type="radio" id="stringRight2" name="stringRadio2" value="2" />
                  <label className="radioButtonLabel" htmlFor="stringRight2">{t("local_parameters_to_right")}</label><br />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { letterInterval2Index !== undefined && letterInterval2Index !== -1 && (
              <div id="letterInterval2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_string_scroll_interval")}</label>
                  <label className="nameLabel2" id="letterIntervalLabel2">1.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, letterInterval2Index, "0.1")} max={checkMax(selectedGameParametersTable, letterInterval2Index, "4")} step="0.1" defaultValue={checkDefault(selectedGameParametersTable, letterInterval2Index, "1", "letterIntervalLabel2", "s", 1)} className="slider" id="letterIntervalSlider2" onChange={setLetterIntervalNumber2} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stringDirectionRadioButtonGroup2Index !== undefined && stringDirectionRadioButtonGroup2Index !== -1 && (
              <div id="stringDirectionRadioButtonGroup2" className="stringDirectionRadioButtonGroup2">
                <p className="radioButtonGroupHeader">{t("local_parameters_string_direction")}</p>
                <input className="radioButton" type="radio" id="stringLeft2" name="stringRadio2" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, stringDirectionRadioButtonGroup2Index, "1")} />
                <label className="radioButtonLabel" htmlFor="stringLeft2">{t("local_parameters_to_left")}</label><br />
                <input className="radioButton" type="radio" id="stringRight2" name="stringRadio2" value="2" />
                <label className="radioButtonLabel" htmlFor="stringRight2">{t("local_parameters_to_right")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stringLength2Index !== undefined && stringLength2Index !== -1 && (
              <div id="stringLength2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_scroller_target_lenght")}</label>
                  <label className="nameLabel2" id="stringLengthLabel2">2 {t("local_parameters_letters2")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stringLength2Index, "1")} max={checkMax(selectedGameParametersTable, stringLength2Index, "7")} defaultValue={checkDefault(selectedGameParametersTable, stringLength2Index, "2", "stringLengthLabel2", t("local_parameters_letters2"))} className="slider" id="stringLengthSlider2" onChange={(event) => setStringLengthNumber2(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { seriesLengthIndex !== undefined && seriesLengthIndex !== -1 && (
              <div id="seriesLengthOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_row_lenght")}</label>
                  <label className="nameLabel2" id="seriesLengthLabel">2</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, seriesLengthIndex, "2")} max={checkMax(selectedGameParametersTable, seriesLengthIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, seriesLengthIndex, "2", "seriesLengthLabel")} className="slider" id="seriesLengthSlider" onChange={setSeriesLengthNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { wordsGroupIndex !== undefined && wordsGroupIndex !== -1 && (
              <div id="wordsGroupOptions">
                <p className="nameHeader2">{t("local_parameters_words")}</p>
                <div id="letterIntervalOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_string_scroll_interval")}</label>
                    <label className="nameLabel2" id="letterIntervalLabel">1.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, wordsGroupIndex, "0.1")} max={checkMax(selectedGameParametersTable, wordsGroupIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, wordsGroupIndex, "1", "letterIntervalLabel", "s", 1)} step="0.1" className="slider" id="letterIntervalSlider" onChange={setLetterIntervalNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { wordsGroup2Index !== undefined && wordsGroup2Index !== -1 && (
              <div id="wordsGroup2Options">
                <div id="stringDirectionRadioButtonGroup" className="stringDirectionRadioButtonGroup">
                  <p className="radioButtonGroupHeader">{t("local_parameters_string_direction")}</p>
                  <input className="radioButton" type="radio" id="stringLeft" name="stringRadio" value="1" />
                  <label className="radioButtonLabel" htmlFor="stringLeft">{t("local_parameters_to_left")}</label><br />
                  <input className="radioButton" type="radio" id="stringRight" name="stringRadio" value="2" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, wordsGroup2Index, "2")} />
                  <label className="radioButtonLabel" htmlFor="stringRight">{t("local_parameters_to_right")}</label><br />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { wordsGroup3Index !== undefined && wordsGroup3Index !== -1 && (
              <div id="wordsGroup3Options">
                <div id="categories1">
                  <p className="nameHeader">{t("local_parameters_categories")}</p>
                  <input className="checkBox" type="checkbox" id="professionsCheckBox" name="categories" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, wordsGroup3Index, ["Option1"])} />
                  <label className="checkBoxLabel" id="professionsCheckBoxLabel" htmlFor="professionsCheckBox">{t("local_parameters_professions")}</label>
                  <input className="checkBox" type="checkbox" id="instrumentsCheckBox" name="categories" value="Option2" />
                  <label className="checkBoxLabel" htmlFor="instrumentsCheckBox">{t("local_parameters_instruments")}</label> <br />
                  <input className="checkBox" type="checkbox" id="animalsCheckBox" name="categories" value="Option3" />
                  <label className="checkBoxLabel" id="animalsCheckBoxLabel" htmlFor="animalsCheckBox">{t("local_parameters_animals")}</label>
                  <input className="checkBox" type="checkbox" id="toolsCheckBox" name="categories" value="Option4" />
                  <label className="checkBoxLabel" htmlFor="toolsCheckBox">{t("local_parameters_tools")}</label> <br />
                  <input className="checkBox" type="checkbox" id="plantsCheckBox" name="categories" value="Option5" />
                  <label className="checkBoxLabel" id="plantsCheckBoxLabel" htmlFor="plantsCheckBox">{t("local_parameters_plants")}</label>
                  <input className="checkBox" type="checkbox" id="sportsCheckBox" name="categories" value="Option6" />
                  <label className="checkBoxLabel" htmlFor="sportsCheckBox">{t("local_parameters_sports")}</label> <br />
                  <input className="checkBox" type="checkbox" id="citiesCheckBox" name="categories" value="Option7" />
                  <label className="checkBoxLabel" id="citiesCheckBoxLabel" htmlFor="citiesCheckBox">{t("local_parameters_cities")}</label>
                  <input className="checkBox" type="checkbox" id="countriesCheckBox" name="categories" value="Option8" />
                  <label className="checkBoxLabel" htmlFor="countriesCheckBox">{t("local_parameters_countries")}</label> <br />
                  <input className="checkBox" type="checkbox" id="maleNamesCheckBox" name="categories" value="Option9" />
                  <label className="checkBoxLabel" id="maleNamesCheckBoxLabel" htmlFor="maleNamesCheckBox">{t("local_parameters_male_names")}</label>
                  <input className="checkBox" type="checkbox" id="femaleNamesCheckBox" name="categories" value="Option10" />
                  <label className="checkBoxLabel" htmlFor="femaleNamesCheckBox">{t("local_parameters_female_names")}</label> <br />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { calculationsGroupIndex !== undefined && calculationsGroupIndex !== -1 && (
              <div id="calculationsGroupOptions">
                <p className="nameHeader2">{t("local_parameters_calculations")}</p>
                <div id="letterInterval2Options">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_string_scroll_interval")}</label>
                    <label className="nameLabel2" id="letterIntervalLabel2">1.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, calculationsGroupIndex, "0.1")} max={checkMax(selectedGameParametersTable, calculationsGroupIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, calculationsGroupIndex, "1", "letterIntervalLabel2", "s", 1)} step="0.1" className="slider" id="letterIntervalSlider2" onChange={setLetterIntervalNumber2} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { calculationsGroup2Index !== undefined && calculationsGroup2Index !== -1 && (
              <div id="calculationsGroup2Options">
                <div id="stringDirectionRadioButtonGroup2" className="stringDirectionRadioButtonGroup2">
                  <p className="radioButtonGroupHeader">{t("local_parameters_string_direction")}</p>
                  <input className="radioButton" type="radio" id="stringLeft2" name="stringRadio2" value="1" />
                  <label className="radioButtonLabel" htmlFor="stringLeft2">{t("local_parameters_to_left")}</label><br />
                  <input className="radioButton" type="radio" id="stringRight2" name="stringRadio2" value="2" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, calculationsGroup2Index, "2")} />
                  <label className="radioButtonLabel" htmlFor="stringRight2">{t("local_parameters_to_right")}</label><br />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { calculationsGroup3Index !== undefined && calculationsGroup3Index !== -1 && (
              <div id="calculationsGroup3Options">
                <div id="categories2">
                  <p className="nameHeader">{t("local_parameters_categories")}</p>
                  <input className="checkBox" type="checkbox" id="additionCheckBox" name="categories2" value="Option1" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, calculationsGroup3Index, ["Option1"])} />
                  <label className="checkBoxLabel" id="additionCheckBoxLabel" htmlFor="additionCheckBox">{t("local_parameters_addition")}</label>
                  <input className="checkBox" type="checkbox" id="subtractionCheckBox" name="categories2" value="Option2" />
                  <label className="checkBoxLabel" htmlFor="subtractionCheckBox">{t("local_parameters_subtraction")}</label> <br />
                  <input className="checkBox" type="checkbox" id="multiplicationCheckBox" name="categories2" value="Option3" />
                  <label className="checkBoxLabel" id="multiplicationCheckBoxLabel" htmlFor="multiplicationCheckBox">{t("local_parameters_multiplication")}</label>
                  <input className="checkBox" type="checkbox" id="divisionCheckBox" name="categories2" value="Option4" />
                  <label className="checkBoxLabel" htmlFor="divisionCheckBox">{t("local_parameters_division")}</label> <br />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { calculationsGroup4Index !== undefined && calculationsGroup4Index !== -1 && (
              <div id="calculationsGroup4Options">
                <div id="difficultyRadioButtonGroup4" className="difficultyRadioButtonGroup4">
                  <p className="radioButtonGroupHeader">{t("local_parameters_difficulty")}</p>
                  <input className="radioButton" type="radio" id="difficulty4Easy" name="difficultyRadio4" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, calculationsGroup4Index, "0")} />
                  <label className="radioButtonLabel" htmlFor="difficulty4Easy">{t("local_parameters_easy")}</label><br />
                  <input className="radioButton" type="radio" id="difficulty4Hard" name="difficultyRadio4" value="1" />
                  <label className="radioButtonLabel" htmlFor="difficulty4Hard">{t("local_parameters_hard")}</label><br />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { stimulusVisibilityIndex !== undefined && stimulusVisibilityIndex !== -1 && (
              <div id="stimulusVisibilityOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_stimuli_visibility_duration")}</label>
                  <label className="nameLabel2" id="stimulusVisibilityLabel">2.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stimulusVisibilityIndex, "1")} max={checkMax(selectedGameParametersTable, stimulusVisibilityIndex, "5")} defaultValue={checkDefault(selectedGameParametersTable, stimulusVisibilityIndex, "2", "stimulusVisibilityLabel", "s", 1)} step="0.1" className="slider" id="stimulusVisibilitySlider" onChange={setStimulusVisibilityNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { exerciseLength2Index !== undefined && exerciseLength2Index !== -1 && (
              <div id="exerciseLength2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_lenght_of_exercise")}</label>
                  <label className="nameLabel2" id="exerciseLengthLabel2">4 min 0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, exerciseLength2Index, "60")} max={checkMax(selectedGameParametersTable, exerciseLength2Index, "600")} defaultValue={checkDefault(selectedGameParametersTable, exerciseLength2Index, "240", "exerciseLengthLabel2", null, null, null, "minutes2")} step="30" className="slider" id="exerciseLengthSlider2" onChange={setExerciseLengthNumber2} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { soundFeedBackIndex !== undefined && soundFeedBackIndex !== -1 && (
              <div id="soundFeedBackOptions" className="soundFeedBackOptions">
                <input className="checkBox" type="checkbox" id="soundFeedBackCheckBox" name="soundFeedBack" value="true" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, soundFeedBackIndex, [])} />
                <label className="checkBoxLabel" htmlFor="soundFeedBackCheckBox">{t("local_parameters_sound_feedback")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimulusTimeIndex !== undefined && stimulusTimeIndex !== -1 && (
              <div id="stimulusTimeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_item_visibility_time")}</label>
                  <label className="nameLabel2" id="stimulusTimeLabel">2.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stimulusTimeIndex, "0.1")} max={checkMax(selectedGameParametersTable, stimulusTimeIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, stimulusTimeIndex, "2", "stimulusTimeLabel", "s", 1)} step="0.1" className="slider" id="stimulusTimeSlider" onChange={setStimulusTimeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { additionalStimuliIndex !== undefined && additionalStimuliIndex !== -1 && (
              <div id="additionalStimuliOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_additional_stimuli")}</label>
                  <label className="nameLabel2" id="additionalStimuliLabel">+ 50 %</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, additionalStimuliIndex, "50")} max={checkMax(selectedGameParametersTable, additionalStimuliIndex, "100")} defaultValue={checkDefault(selectedGameParametersTable, additionalStimuliIndex, "50", "additionalStimuliLabel", "%", null, null, null, null, null, "+")} step="50" className="slider" id="additionalStimuliSlider" onChange={setAdditionalStimuliNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { intervalTimeIndex !== undefined && intervalTimeIndex !== -1 && (
              <div id="intervalTimeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_scroller_move_time")}</label>
                  <label className="nameLabel2" id="intervalTimeLabel">1.0 s</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, intervalTimeIndex, "0.1")} max={checkMax(selectedGameParametersTable, intervalTimeIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalTimeIndex, "1", "intervalTimeLabel", "s", 1)} step="0.1" className="slider" id="intervalTimeSlider" onChange={setIntervalTimeNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { seriesNumberIndex !== undefined && seriesNumberIndex !== -1 && (
              <div id="seriesNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_row_amount")}</label>
                  <label className="nameLabel2" id="seriesNumberLabel">3 {t("local_parameters_pcs")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, seriesNumberIndex, "1")} max={checkMax(selectedGameParametersTable, seriesNumberIndex, "10")} defaultValue={checkDefault(selectedGameParametersTable, seriesNumberIndex, "3", "seriesNumberLabel", t("local_parameters_pcs"))} className="slider" id="seriesNumberSlider" onChange={(event) => setSeriesNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { seriesNumber2Index !== undefined && seriesNumber2Index !== -1 && (
              <div id="seriesNumber2Options">
                <span>
                  <label className="nameLabel1">{t("local_parameters_row_amount")}</label>
                  <label className="nameLabel2" id="seriesNumberLabel2">4</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, seriesNumber2Index, "1")} max={checkMax(selectedGameParametersTable, seriesNumber2Index, "10")} defaultValue={checkDefault(selectedGameParametersTable, seriesNumber2Index, "4", "seriesNumberLabel2")} className="slider" id="seriesNumberSlider2" onChange={setSeriesNumber2} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimuliNumberIndex !== undefined && stimuliNumberIndex !== -1 && (
              <div id="stimuliNumberOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_item_amount")}</label>
                  <label className="nameLabel2" id="stimuliNumberLabel">20 {t("local_parameters_pcs")}</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stimuliNumberIndex, "5")} max={checkMax(selectedGameParametersTable, stimuliNumberIndex, "50")} defaultValue={checkDefault(selectedGameParametersTable, stimuliNumberIndex, "20", "stimuliNumberLabel", t("local_parameters_pcs"))} className="slider" id="stimuliNumberSlider" onChange={(event) => setNumberStimuliNumber(event, t)} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimuliSizeIndex !== undefined && stimuliSizeIndex !== -1 && (
              <div id="stimuliSizeOptions">
                <span>
                  <label className="nameLabel1">{t("local_parameters_stimulus_size_percent")}</label>
                  <label className="nameLabel2" id="stimuliSizeLabel">10 %</label>
                </span>
                <br />
                <input type="range" min={checkMin(selectedGameParametersTable, stimuliSizeIndex, "5")} max={checkMax(selectedGameParametersTable, stimuliSizeIndex, "50")} defaultValue={checkDefault(selectedGameParametersTable, stimuliSizeIndex, "10", "stimuliSizeLabel", "%")} className="slider" id="stimuliSizeSlider" onChange={setSizeStimuliNumber} />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { intervalBetweenStimuliIndex !== undefined && intervalBetweenStimuliIndex !== -1 && (
              <div id="intervalBetweenStimuliOptions">
                <p className="nameHeader">{t("local_parameters_item_visibility_interval")}</p>
                <div id="stimuliMinOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_minimum")}</label>
                    <label className="nameLabel2" id="stimuliMinLabel">1.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenStimuliIndex, "0.1")} max={checkMax(selectedGameParametersTable, intervalBetweenStimuliIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenStimuliIndex, "1", "stimuliMinLabel", "s", 1)} step="0.1" className="slider" id="stimuliMinSlider" onChange={setMinStimuliNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { intervalBetweenStimuli2Index !== undefined && intervalBetweenStimuli2Index !== -1 && (
              <div id="intervalBetweenStimuli2Options">
                <div id="stimuliMaxOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_maximum")}</label>
                    <label className="nameLabel2" id="stimuliMaxLabel">4.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenStimuli2Index, "1")} max={checkMax(selectedGameParametersTable, intervalBetweenStimuli2Index, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenStimuli2Index, "4", "stimuliMaxLabel", "s", 1)} step="0.1" className="slider" id="stimuliMaxSlider" onChange={setMaxStimuliNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { intervalBetweenInputIndex !== undefined && intervalBetweenInputIndex !== -1 && (
              <div id="intervalBetweenInputOptions">
                <p className="nameHeader">{t("local_parameters_interval_between_input_and_advance")}</p>
                <div id="stimuliInputMinOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_minimum")}</label>
                    <label className="nameLabel2" id="stimuliInputMinLabel">1.00 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenInputIndex, "0.1")} max={checkMax(selectedGameParametersTable, intervalBetweenInputIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenInputIndex, "1", "stimuliInputMinLabel", "s", 2)} step="0.01" className="slider" id="stimuliInputMinSlider" onChange={setMinStimuliInputNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { intervalBetweenInput2Index !== undefined && intervalBetweenInput2Index !== -1 && (
              <div id="intervalBetweenInput2Options">
                <div id="stimuliInputMaxOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_maximum")}</label>
                    <label className="nameLabel2" id="stimuliInputMaxLabel">4.00 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenInput2Index, "1")} max={checkMax(selectedGameParametersTable, intervalBetweenInput2Index, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenInput2Index, "4", "stimuliInputMaxLabel", "s", 2)} step="0.01" className="slider" id="stimuliInputMaxSlider" onChange={setMaxStimuliInputNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { intervalBetweenAdvanceIndex !== undefined && intervalBetweenAdvanceIndex !== -1 && (
              <div id="intervalBetweenAdvanceOptions">
                <p className="nameHeader">{t("local_parameters_interval_between_advance_and_target_stimuli")}</p>
                <div id="stimuliAdvanceMinOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_minimum")}</label>
                    <label className="nameLabel2" id="stimuliAdvanceMinLabel">1.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenAdvanceIndex, "0.1")} max={checkMax(selectedGameParametersTable, intervalBetweenAdvanceIndex, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenAdvanceIndex, "1", "stimuliAdvanceMinLabel", "s", 1)} step="0.1" className="slider" id="stimuliAdvanceMinSlider" onChange={setMinStimuliAdvanceNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { intervalBetweenAdvance2Index !== undefined && intervalBetweenAdvance2Index !== -1 && (
              <div id="intervalBetweenAdvance2Options">
                <div id="stimuliAdvanceMaxOptions">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_maximum")}</label>
                    <label className="nameLabel2" id="stimuliAdvanceMaxLabel">4.0 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenAdvance2Index, "1")} max={checkMax(selectedGameParametersTable, intervalBetweenAdvance2Index, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenAdvance2Index, "4", "stimuliAdvanceMaxLabel", "s", 1)} step="0.1" className="slider" id="stimuliAdvanceMaxSlider" onChange={setMaxStimuliAdvanceNumber} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { intervalBetweenAdvance3Index !== undefined && intervalBetweenAdvance3Index !== -1 && (
              <div id="intervalBetweenAdvance3Options">
                <p className="nameHeader">{t("local_parameters_interval_between_advance_and_target_stimuli")}</p>
                <div id="stimuliAdvanceMin2Options">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_minimum")}</label>
                    <label className="nameLabel2" id="stimuliAdvanceMinLabel2">1.00 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenAdvance3Index, "0.1")} max={checkMax(selectedGameParametersTable, intervalBetweenAdvance3Index, "4")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenAdvance3Index, "1", "stimuliAdvanceMinLabel2", "s", 2)} step="0.01" className="slider" id="stimuliAdvanceMinSlider2" onChange={setMinStimuliAdvanceNumber2} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { intervalBetweenAdvance4Index !== undefined && intervalBetweenAdvance4Index !== -1 && (
              <div id="intervalBetweenAdvance4Options">
                <div id="stimuliAdvanceMax2Options">
                  <span>
                    <label className="nameLabel1">{t("local_parameters_maximum")}</label>
                    <label className="nameLabel2" id="stimuliAdvanceMaxLabel2">4.00 s</label>
                  </span>
                  <br />
                  <input type="range" min={checkMin(selectedGameParametersTable, intervalBetweenAdvance4Index, "1")} max={checkMax(selectedGameParametersTable, intervalBetweenAdvance4Index, "10")} defaultValue={checkDefault(selectedGameParametersTable, intervalBetweenAdvance4Index, "4", "stimuliAdvanceMaxLabel2", "s", 2)} className="slider" id="stimuliAdvanceMaxSlider2" onChange={setMaxStimuliAdvanceNumber2} />
                  <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                </div>
              </div>
            )}
            { (fieldShapeRadioButtonGroupIndex !== undefined && fieldShapeRadioButtonGroupIndex !== -1) && (
              <div className="fieldShapeDiv">
                { fieldShapeRadioButtonGroupIndex !== undefined && fieldShapeRadioButtonGroupIndex !== -1 && (
                  <div id="fieldShapeRadioButtonGroup" className="fieldShapeRadioButtonGroup">
                    <p className="radioButtonGroupHeader">{t("local_parameters_field_shape_and_size")}</p>
                  </div>
                )}
                { fieldShapeRadioButtonGroup2Index !== undefined && fieldShapeRadioButtonGroup2Index !== -1 && (
                  <div id="fieldShapeRadioButton2Group" className="fieldShapeRadioButton2Group">
                    <div className="fieldShapeRow1">
                      <input className="radioButton" type="radio" id="shapeRectangle" name="fieldShapeRadio" value="0" onChange={setFieldShapeSlider} onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, fieldShapeRadioButtonGroup2Index, "0", setFieldShapeSlider)} />
                      <img src={require("../images/rectangleNarrow.png")} className="rectangleNarrow" alt="rectangleNarrow" border="0"/>
                    </div>
                    <div className="fieldShapeRow2">
                      <input className="radioButton" type="radio" id="shapeCircle" name="fieldShapeRadio" value="1" onChange={setFieldShapeSlider} />
                      <img src={require("../images/circleNarrow.png")} className="circleNarrow" alt="circleNarrow" border="0"/>
                    </div>
                    <div className="fieldShapeRow3">
                      <input className="radioButton" type="radio" id="shapeDiamond" name="fieldShapeRadio" value="2" onChange={setFieldShapeSlider} />
                      <img src={require("../images/diamondNarrow.png")} className="diamondNarrow" alt="diamondNarrow" border="0"/>
                    </div>
                    <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
                  </div>
                )}
                { fieldShapeRadioButtonGroup3Index !== undefined && fieldShapeRadioButtonGroup3Index !== -1 && (
                  <div id="fieldShapeRadioButton3Group" className="fieldShapeRadioButton3Group">
                    <div>
                      <input type="range" min={checkMin(selectedGameParametersTable, fieldShapeRadioButtonGroup3Index, "1")} max={checkMax(selectedGameParametersTable, fieldShapeRadioButtonGroup3Index, "2")} defaultValue={checkDefault(selectedGameParametersTable, fieldShapeRadioButtonGroup3Index, "2")} step="0.001" className="slider" id="shapeRectangleSlider" />
                      <img src={require("../images/rectangleWide.png")} className="rectangleWide" alt="rectangleWide" border="0"/>
                    </div>
                  </div>
                )}
                { fieldShapeRadioButtonGroup4Index !== undefined && fieldShapeRadioButtonGroup4Index !== -1 && (
                  <div id="fieldShapeRadioButton4Group" className="fieldShapeRadioButton4Group">
                    <div>
                      <input type="range" min={checkMin(selectedGameParametersTable, fieldShapeRadioButtonGroup4Index, "1")} max={checkMax(selectedGameParametersTable, fieldShapeRadioButtonGroup4Index, "2")} defaultValue={checkDefault(selectedGameParametersTable, fieldShapeRadioButtonGroup4Index, "2")} step="0.001" className="slider" id="shapeCircleSlider" />
                      <img src={require("../images/circleWide.png")} className="circleWide" alt="circleWide" border="0"/>
                    </div>
                  </div>
                )}
                { fieldShapeRadioButtonGroup5Index !== undefined && fieldShapeRadioButtonGroup5Index !== -1 && (
                  <div id="fieldShapeRadioButton5Group" className="fieldShapeRadioButton5Group">
                    <div>
                      <input type="range" min={checkMin(selectedGameParametersTable, fieldShapeRadioButtonGroup5Index, "1")} max={checkMax(selectedGameParametersTable, fieldShapeRadioButtonGroup5Index, "2")} defaultValue={checkDefault(selectedGameParametersTable, fieldShapeRadioButtonGroup5Index, "2")} step="0.001" className="slider" id="shapeDiamondSlider" />
                      <img src={require("../images/diamondWide.png")} className="diamondWide" alt="diamondWide" border="0"/>
                    </div>
                  </div>
                )}
              </div>
            )}
            { stimuliRadioButtonGroupIndex !== undefined && stimuliRadioButtonGroupIndex !== -1 && (
              <div id="stimuliRadioButtonGroup" className="stimuliRadioButtonGroup">
                <input className="radioButton" type="radio" id="stimuliCenter" name="stimuliRadio" value="true" />
                <label className="radioButtonLabel" htmlFor="stimuliCenter">{t("local_parameters_stimuli_at_center")}</label><br />
                <input className="radioButton" type="radio" id="stimuliRandom" name="stimuliRadio" value="false" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, stimuliRadioButtonGroupIndex, "false")} />
                <label className="radioButtonLabel" htmlFor="stimuliRandom">{t("local_parameters_stimuli_appear_randomly")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimuliRandomOptionsIndex !== undefined && stimuliRandomOptionsIndex !== -1 && (
              <div id="stimuliRandomOptions" className="stimuliRandomOptions">
                <input className="checkBox" type="checkbox" id="stimuliRandomCheckBox" name="stimuliRandom2" value="true" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, stimuliRandomOptionsIndex, [])} />
                <label className="checkBoxLabel" htmlFor="stimuliRandomCheckBox">{t("local_parameters_stimuli_appear_randomly")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimulusVisibilityRadioButtonGroupIndex !== undefined && stimulusVisibilityRadioButtonGroupIndex !== -1 && (
              <div id="stimulusVisibilityRadioButtonGroup" className="stimulusVisibilityRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_item_stays_visible")}</p>
                <input className="radioButton" type="radio" id="stimulusFlashes" name="stimulusVisibilityRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, stimulusVisibilityRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="stimulusFlashes">{t("local_parameters_flashes_on_screen")}</label><br />
                <input className="radioButton" type="radio" id="stimulusStays" name="stimulusVisibilityRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="stimulusStays">{t("local_parameters_stays_visible")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { difficultyRadioButtonGroup3Index !== undefined && difficultyRadioButtonGroup3Index !== -1 && (
              <div id="difficultyRadioButtonGroup3" className="difficultyRadioButtonGroup3">
                <p className="radioButtonGroupHeader">{t("local_parameters_difficulty")}</p>
                <div className="difficultyRow1Options">
                  <div className="difficultyLevel1Options">
                    <input className="radioButton" type="radio" id="difficultyLevel1" name="difficultyRadio3" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, difficultyRadioButtonGroup3Index, "0")} />
                    <label className="radioButtonLabel" id="difficultyLevel1Label" htmlFor="difficultyLevel1">Level 1</label>
                    <br />
                    <label>{t("local_parameters_addition_and_subtraction")}</label>
                  </div>
                  <div className="difficultyLevel3Options">
                    <input className="radioButton" type="radio" id="difficultyLevel3" name="difficultyRadio3" value="2" />
                    <label className="radioButtonLabel" htmlFor="difficultyLevel3">Level 3</label>
                    <br />
                    <label>{t("local_parameters_powers")}</label>
                  </div> <br />
                </div>
                <div className="difficultyRow2Options">
                  <div className="difficultyLevel2Options">
                    <input className="radioButton" type="radio" id="difficultyLevel2" name="difficultyRadio3" value="1" />
                    <label className="radioButtonLabel" id="difficultyLevel2Label" htmlFor="difficultyLevel2">Level 2</label>
                    <br />
                    <label>{t("local_parameters_multiplication_and_division")}</label>
                  </div>
                  <div className="difficultyLevel4Options">
                    <input className="radioButton" type="radio" id="difficultyLevel4" name="difficultyRadio3" value="3" />
                    <label className="radioButtonLabel" htmlFor="difficultyLevel4">Level 4</label>
                    <br />
                    <label>{t("local_parameters_all_operations")}</label>
                  </div> <br />
                </div>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { difficultyRadioButtonGroup4Index !== undefined && difficultyRadioButtonGroup4Index !== -1 && (
              <div id="difficultyRadioButtonGroup4" className="difficultyRadioButtonGroup4">
                <p className="radioButtonGroupHeader">{t("local_parameters_difficulty")}</p>
                <input className="radioButton" type="radio" id="difficulty4Easy" name="difficultyRadio4" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, difficultyRadioButtonGroup4Index, "0")} />
                <label className="radioButtonLabel" htmlFor="difficulty4Easy">{t("local_parameters_easy")}</label><br />
                <input className="radioButton" type="radio" id="difficulty4Hard" name="difficultyRadio4" value="1" />
                <label className="radioButtonLabel" htmlFor="difficulty4Hard">{t("local_parameters_hard")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { difficultyRadioButtonGroupIndex !== undefined && difficultyRadioButtonGroupIndex !== -1 && (
              <div id="difficultyRadioButtonGroup" className="difficultyRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_difficulty")}</p>
                <input className="radioButton" type="radio" id="difficultyEasy" name="difficultyRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, difficultyRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="difficultyEasy">{t("local_parameters_easy")}</label><br />
                <input className="radioButton" type="radio" id="difficultyNormal" name="difficultyRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="difficultyNormal">{t("local_parameters_normal")}</label><br />
                <input className="radioButton" type="radio" id="difficultyHard" name="difficultyRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="difficultyHard">{t("local_parameters_hard")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { difficultyRadioButtonGroup5Index !== undefined && difficultyRadioButtonGroup5Index !== -1 && (
              <div id="difficultyRadioButtonGroup5" className="difficultyRadioButtonGroup5">
                <p className="radioButtonGroupHeader">{t("local_parameters_difficulty")}</p>
                <input className="radioButton" type="radio" id="difficulty5Easy" name="difficultyRadio5" value="0" />
                <label className="radioButtonLabel" htmlFor="difficulty5Easy">{t("local_parameters_easy")}</label><br />
                <input className="radioButton" type="radio" id="difficulty5Normal" name="difficultyRadio5" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, difficultyRadioButtonGroup5Index, "1")} />
                <label className="radioButtonLabel" htmlFor="difficulty5Normal">{t("local_parameters_normal")}</label><br />
                <input className="radioButton" type="radio" id="difficulty5Hard" name="difficultyRadio5" value="2" />
                <label className="radioButtonLabel" htmlFor="difficulty5Hard">{t("local_parameters_hard")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { boardSizeRadioButtonGroupIndex !== undefined && boardSizeRadioButtonGroupIndex !== -1 && (
              <div id="boardSizeRadioButtonGroup" className="boardSizeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_lines_board_size")}</p>
                <input className="radioButton" type="radio" id="board8x8" name="boardSizeRadio" value="8" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, boardSizeRadioButtonGroupIndex, "8")} />
                <label className="radioButtonLabel" htmlFor="board8x8">8x8</label><br />
                <input className="radioButton" type="radio" id="board12x12" name="boardSizeRadio" value="12" />
                <label className="radioButtonLabel" htmlFor="board12x12">12x12</label><br />
                <input className="radioButton" type="radio" id="board16x16" name="boardSizeRadio" value="16" />
                <label className="radioButtonLabel" htmlFor="board16x16">16x16</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { boardSizeRadioButtonGroup2Index !== undefined && boardSizeRadioButtonGroup2Index !== -1 && (
              <div id="boardSizeRadioButtonGroup2" className="boardSizeRadioButtonGroup2">
                <p className="radioButtonGroupHeader">{t("local_parameters_lines_board_size")}</p>
                <input className="radioButton" type="radio" id="board2_6x6" name="boardSizeRadio2" value="6" />
                <label className="radioButtonLabel" htmlFor="board2_6x6">6x6</label><br />
                <input className="radioButton" type="radio" id="board2_8x8" name="boardSizeRadio2" value="8" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, boardSizeRadioButtonGroup2Index, "8")} />
                <label className="radioButtonLabel" htmlFor="board2_8x8">8x8</label><br />
                <input className="radioButton" type="radio" id="board2_10x10" name="boardSizeRadio2" value="10" />
                <label className="radioButtonLabel" htmlFor="board2_10x10">10x10</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { blackKnightsRadioButtonGroupIndex !== undefined && blackKnightsRadioButtonGroupIndex !== -1 && (
              <div id="blackKnightsRadioButtonGroup" className="blackKnightsRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_black_knights")}</p>
                <input className="radioButton" type="radio" id="blackKnights2" name="blackKnightsRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="blackKnights2">8</label><br />
                <input className="radioButton" type="radio" id="blackKnights4" name="blackKnightsRadio" value="4" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, blackKnightsRadioButtonGroupIndex, "4")} />
                <label className="radioButtonLabel" htmlFor="blackKnights4">10</label><br />
                <input className="radioButton" type="radio" id="blackKnights6" name="blackKnightsRadio" value="6" />
                <label className="radioButtonLabel" htmlFor="blackKnights6">12</label><br />
                <input className="radioButton" type="radio" id="blackKnights8" name="blackKnightsRadio" value="8" />
                <label className="radioButtonLabel" htmlFor="blackKnights8">14</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { stimulusTypeRadioButtonGroupIndex !== undefined && stimulusTypeRadioButtonGroupIndex !== -1 && (
              <div id="stimulusTypeRadioButtonGroup" className="stimulusTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_item_type")}</p>
                <input className="radioButton" type="radio" id="stimulusSeen" name="stimulusTypeRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, stimulusTypeRadioButtonGroupIndex, "0", checkWordVisibility)} onChange={checkWordVisibility} />
                <label className="radioButtonLabel" htmlFor="stimulusSeen">{t("local_parameters_as_seen")}</label><br />
                <input className="radioButton" type="radio" id="stimulusHeard" name="stimulusTypeRadio" value="1" onChange={checkWordVisibility} />
                <label className="radioButtonLabel" htmlFor="stimulusHeard">{t("local_parameters_as_heard")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { exerciseStartedByRadioButtonGroupIndex !== undefined && exerciseStartedByRadioButtonGroupIndex !== -1 && (
              <div id="exerciseStartedByRadioButtonGroup" className="exerciseStartedByRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_starting_player")}</p>
                <input className="radioButton" type="radio" id="exerciseUser" name="exerciseStartedRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, exerciseStartedByRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="exerciseUser">{t("local_parameters_user2")}</label><br />
                <input className="radioButton" type="radio" id="exerciseComputer" name="exerciseStartedRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="exerciseComputer">{t("local_parameters_computer2")}</label><br />
                <input className="radioButton" type="radio" id="exerciseRandom" name="exerciseStartedRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="exerciseRandom">{t("local_parameters_random_starter")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { targetPositionRadioButtonGroupIndex !== undefined && targetPositionRadioButtonGroupIndex !== -1 && (
              <div id="targetPositionRadioButtonGroup" className="targetPositionRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_target_position")}</p>
                <input className="radioButton" type="radio" id="positionLeft" name="targetPositionRadio" value="0" />
                <label className="radioButtonLabel" id="positionLeftLabel" htmlFor="positionLeft">{t("local_parameters_on_left")}</label>
                <input className="radioButton" type="radio" id="positionRight" name="targetPositionRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="positionRight">{t("local_parameters_right_side")}</label><br />
                <input className="radioButton" type="radio" id="positionMiddle" name="targetPositionRadio" value="2" />
                <label className="radioButtonLabel" id="positionMiddleLabel" htmlFor="positionMiddle">{t("local_parameters_in_the_middle2")}</label>
                <input className="radioButton" type="radio" id="positionRandom" name="targetPositionRadio" value="3" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, targetPositionRadioButtonGroupIndex, "3")} />
                <label className="radioButtonLabel" htmlFor="positionRandom">{t("local_parameters_random2")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { quadrantTransportRadioButtonGroupIndex !== undefined && quadrantTransportRadioButtonGroupIndex !== -1 && (
              <div id="quadrantTransportRadioButtonGroup" className="quadrantTransportRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_game_style_maze")}</p>
                <input className="radioButton" type="radio" id="transportingButtons" name="quadrantTransportRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, quadrantTransportRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="transportingButtons">{t("local_parameters_buttons")}</label><br />
                <input className="radioButton" type="radio" id="transportingDrawing" name="quadrantTransportRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="transportingDrawing">{t("local_parameters_drawing2")}</label><br />
                <input className="radioButton" type="radio" id="transportingDragging" name="quadrantTransportRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="transportingDragging">{t("local_parameters_dragging")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { pointSizeRadioButtonGroupIndex !== undefined && pointSizeRadioButtonGroupIndex !== -1 && (
              <div id="pointSizeRadioButtonGroup" className="pointSizeRadioButtonGroup">
                <p className="radioButtonGroupHeader">Point size</p>
                <input className="radioButton" type="radio" id="Size4x4" name="pointSizeRadio" value="4" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, pointSizeRadioButtonGroupIndex, "4")} />
                <label className="radioButtonLabel" htmlFor="Size4x4">4x4 {t("local_parameters_pixels")}</label><br />
                <input className="radioButton" type="radio" id="Size6x6" name="pointSizeRadio" value="6" />
                <label className="radioButtonLabel" htmlFor="Size6x6">6x6 {t("local_parameters_pixels")}</label><br />
                <input className="radioButton" type="radio" id="Size8x8" name="pointSizeRadio" value="8" />
                <label className="radioButtonLabel" htmlFor="Size8x8">8x8 {t("local_parameters_pixels")}</label><br />
                <input className="radioButton" type="radio" id="Size10x10" name="pointSizeRadio" value="10" />
                <label className="radioButtonLabel" htmlFor="Size10x10">10x10 {t("local_parameters_pixels")}</label><br />
                <input className="radioButton" type="radio" id="Size12x12" name="pointSizeRadio" value="12" />
                <label className="radioButtonLabel" htmlFor="Size12x12">12x12 {t("local_parameters_pixels")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { lineTypeRadioButtonGroupIndex !== undefined && lineTypeRadioButtonGroupIndex !== -1 && (
              <div id="lineTypeRadioButtonGroup" className="lineTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_line_type")}</p>
                <input className="radioButton" type="radio" id="lineRadius" name="lineTypeRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, lineTypeRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="lineRadius">{t("local_parameters_radius")}</label><br />
                <input className="radioButton" type="radio" id="lineDiameter" name="lineTypeRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="lineDiameter">{t("local_parameters_diameter")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { bowlScaleRadioButtonGroupIndex !== undefined && bowlScaleRadioButtonGroupIndex !== -1 && (
              <div id="bowlScaleRadioButtonGroup" className="bowlScaleRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_bowl_scale")}</p>
                <input className="radioButton" type="radio" id="bowlSmall" name="bowlScaleRadio" value="0" onChange={checkFishScale} />
                <label className="radioButtonLabel" htmlFor="bowlSmall">{t("local_parameters_small")}</label><br />
                <input className="radioButton" type="radio" id="bowlMedium" name="bowlScaleRadio" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, bowlScaleRadioButtonGroupIndex, "1", checkFishScale)} onChange={checkFishScale} />
                <label className="radioButtonLabel" htmlFor="bowlMedium">{t("local_parameters_medium")}</label><br />
                <input className="radioButton" type="radio" id="bowlLarge" name="bowlScaleRadio" value="2" onChange={checkFishScale} />
                <label className="radioButtonLabel" htmlFor="bowlLarge">{t("local_parameters_large")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { fishScaleRadioButtonGroupIndex !== undefined && fishScaleRadioButtonGroupIndex !== -1 && (
              <div id="fishScaleRadioButtonGroup" className="fishScaleRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_fish_scale")}</p>
                <input className="radioButton" type="radio" id="fishSmall" name="fishScaleRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, fishScaleRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="fishSmall">{t("local_parameters_small")}</label><br />
                <input className="radioButton" type="radio" id="fishLarge" name="fishScaleRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="fishLarge">{t("local_parameters_large")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { returnTypeRadioButtonGroupIndex !== undefined && returnTypeRadioButtonGroupIndex !== -1 && (
              <div id="returnTypeRadioButtonGroup" className="returnTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_return")}</p>
                <input className="radioButton" type="radio" id="returnAfterSorting" name="returnTypeRadio" value="0" />
                <label className="radioButtonLabel" htmlFor="returnAfterSorting">{t("local_parameters_after_sorting")}</label><br />
                <input className="radioButton" type="radio" id="returnAfterButton" name="returnTypeRadio" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, returnTypeRadioButtonGroupIndex, "1")} />
                <label className="radioButtonLabel" htmlFor="returnAfterButton">{t("local_parameters_pressing_button")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { presentationTypeRadioButtonGroupIndex !== undefined && presentationTypeRadioButtonGroupIndex !== -1 && (
              <div id="presentationTypeRadioButtonGroup" className="presentationTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_presentation_type")}</p>
                <input className="radioButton" type="radio" id="presentationAsText" name="presentationTypeRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, presentationTypeRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="presentationAsText">{t("local_parameters_as_text")}</label><br />
                <input className="radioButton" type="radio" id="presentationAsRecord" name="presentationTypeRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="presentationAsRecord">{t("local_parameters_as_record")}</label><br />
                <input className="radioButton" type="radio" id="presentationAsTextAndRecord" name="presentationTypeRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="presentationAsTextAndRecord">{t("local_parameters_as_record_and_text")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { nBackRadioButtonGroupIndex !== undefined && nBackRadioButtonGroupIndex !== -1 && (
              <div id="nBackRadioButtonGroup" className="nBackRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_nback")}</p>
                <input className="radioButton" type="radio" id="nBack1" name="nBackRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="nBack1">1</label><br />
                <input className="radioButton" type="radio" id="nBack2" name="nBackRadio" value="2" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, nBackRadioButtonGroupIndex, "2")} />
                <label className="radioButtonLabel" htmlFor="nBack2">2</label><br />
                <input className="radioButton" type="radio" id="nBack3" name="nBackRadio" value="3" />
                <label className="radioButtonLabel" htmlFor="nBack3">3</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { interferenceTypeRadioButtonGroupIndex !== undefined && interferenceTypeRadioButtonGroupIndex !== -1 && (
              <div id="interferenceTypeRadioButtonGroup" className="interferenceTypeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_interference_type")}</p>
                <input className="radioButton" type="radio" id="interferenceMath" name="interferenceRadio" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, interferenceTypeRadioButtonGroupIndex, "1")} />
                <label className="radioButtonLabel" id="interferenceMathLabel" htmlFor="interferenceMath">{t("local_parameters_math")}</label>
                <input className="radioButton" type="radio" id="interferenceIdentification" name="interferenceRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="interferenceIdentification">{t("local_parameters_identification")}</label><br />
                <input className="radioButton" type="radio" id="interferenceClassification" name="interferenceRadio" value="3" />
                <label className="radioButtonLabel" htmlFor="interferenceClassification">{t("local_parameters_word_classification")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { interferenceTypeRadioButtonGroup2Index !== undefined && interferenceTypeRadioButtonGroup2Index !== -1 && (
              <div id="interferenceTypeRadioButtonGroup2" className="interferenceTypeRadioButtonGroup2">
                <p className="radioButtonGroupHeader">{t("local_parameters_interference_type")}</p>
                <input className="radioButton" type="radio" id="interference2Math" name="interferenceRadio2" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, interferenceTypeRadioButtonGroup2Index, "1")} />
                <label className="radioButtonLabel" htmlFor="interference2Math">{t("local_parameters_math")}</label> <br />
                <input className="radioButton" type="radio" id="interference2Classification" name="interferenceRadio2" value="3" />
                <label className="radioButtonLabel" htmlFor="interference2Classification">{t("local_parameters_word_classification")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { serieRecoveryRadioButtonGroupIndex !== undefined && serieRecoveryRadioButtonGroupIndex !== -1 && (
              <div id="serieRecoveryRadioButtonGroup" className="serieRecoveryRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_serie_recovery")}</p>
                <input className="radioButton" type="radio" id="serieForward" name="serieRadio" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, serieRecoveryRadioButtonGroupIndex, "1")} />
                <label className="radioButtonLabel" htmlFor="serieForward">{t("local_parameters_forward")}</label><br />
                <input className="radioButton" type="radio" id="serieBackwards" name="serieRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="serieBackwards">{t("local_parameters_backwards")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { cellSizeRadioButtonGroupIndex !== undefined && cellSizeRadioButtonGroupIndex !== -1 && (
              <div id="cellSizeRadioButtonGroup" className="cellSizeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_finding_task_cell_size")}</p>
                <input className="radioButton" type="radio" id="cellSmall" name="cellRadio" value="0" />
                <label className="radioButtonLabel" htmlFor="cellSmall">{t("local_parameters_small")}</label><br />
                <input className="radioButton" type="radio" id="cellMedium" name="cellRadio" value="1" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, cellSizeRadioButtonGroupIndex, "1")} />
                <label className="radioButtonLabel" htmlFor="cellMedium">{t("local_parameters_medium")}</label><br />
                <input className="radioButton" type="radio" id="cellBig" name="cellRadio" value="2" />
                <label className="radioButtonLabel" htmlFor="cellBig">{t("local_parameters_big")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { layoutRadioButtonGroupIndex !== undefined && layoutRadioButtonGroupIndex !== -1 && (
              <div id="layoutRadioButtonGroup" className="layoutRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_finding_task_layout")}</p>
                <input className="radioButton" type="radio" id="layoutConstant" name="layoutRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, layoutRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="layoutConstant">{t("local_parameters_constant")}</label><br />
                <input className="radioButton" type="radio" id="layoutRandom" name="layoutRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="layoutRandom">{t("local_parameters_random")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { letterSizeRadioButtonGroupIndex !== undefined && letterSizeRadioButtonGroupIndex !== -1 && (
              <div id="letterSizeRadioButtonGroup" className="letterSizeRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_finding_task_letter_size")}</p>
                <input className="radioButton" type="radio" id="letterSmall" name="letterRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, letterSizeRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" htmlFor="letterSmall">{t("local_parameters_small")}</label><br />
                <input className="radioButton" type="radio" id="letterBig" name="letterRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="letterBig">{t("local_parameters_big")}</label><br />
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { advancedRadioButtonGroupIndex !== undefined && advancedRadioButtonGroupIndex !== -1 && (
              <div id="advancedRadioButtonGroup" className="advancedRadioButtonGroup">
                <p className="radioButtonGroupHeader">{t("local_parameters_categories")}</p>
                <input className="radioButton" type="radio" id="advancedFish" name="advancedRadio" value="0" onLoad={checkRadioDefault(selectedGameParameters, selectedGameParametersTable, advancedRadioButtonGroupIndex, "0")} />
                <label className="radioButtonLabel" id="advancedFishLabel" htmlFor="advancedFish">{t("local_parameters_fish")}</label>
                <input className="radioButton" type="radio" id="advancedAnimals" name="advancedRadio" value="1" />
                <label className="radioButtonLabel" htmlFor="advancedAnimals">{t("local_parameters_animals")}</label><br />
                <input className="radioButton" type="radio" id="advancedFlags" name="advancedRadio" value="2" />
                <label className="radioButtonLabel" id="advancedFlagsLabel" htmlFor="advancedFlags">{t("local_parameters_flags")}</label>
                <input className="radioButton" type="radio" id="advancedSports" name="advancedRadio" value="3" />
                <label className="radioButtonLabel" htmlFor="advancedSports">{t("local_parameters_sports")}</label><br />
                <input className="radioButton" type="radio" id="advancedTools" name="advancedRadio" value="4" />
                <label className="radioButtonLabel" htmlFor="advancedTools">{t("local_parameters_tools")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { searchCheckBoxIndex !== undefined && searchCheckBoxIndex !== -1 && (
              <div id="searchOptions" className="searchOptions">
                <p className="nameHeader2">{t("local_parameters_advanced_settings")}</p>
                <input className="checkBox" type="checkbox" id="searchCheckBox" name="search" value="true" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, searchCheckBoxIndex, [], unlockAdvancedOptions)} onChange={unlockAdvancedOptions} />
                <label className="checkBoxLabel" htmlFor="searchCheckBox">{t("local_parameters_finding_task_select_characters")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { lettersIndex !== undefined && lettersIndex !== -1 && (
              <div id="lettersOptions">
                <p className="nameHeader">{t("local_parameters_search_options_character")}</p>
                <select className="EditParameters_select" name="letters" id="letters" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, lettersIndex, "1", unlockAdvancedOptions)}>
                  <option value="1">A</option>
                  <option value="2">B</option>
                  <option value="3">C</option>
                  <option value="4">D</option>
                  <option value="5">E</option>
                  <option value="6">F</option>
                  <option value="7">G</option>
                  <option value="8">H</option>
                  <option value="9">I</option>
                  <option value="10">J</option>
                  <option value="11">K</option>
                  <option value="12">L</option>
                  <option value="13">M</option>
                  <option value="14">N</option>
                  <option value="15">O</option>
                  <option value="16">P</option>
                  <option value="17">Q</option>
                  <option value="18">R</option>
                  <option value="19">S</option>
                  <option value="20">T</option>
                  <option value="21">U</option>
                  <option value="22">V</option>
                  <option value="23">W</option>
                  <option value="24">X</option>
                  <option value="25">Y</option>
                  <option value="26">Z</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { letters2Index !== undefined && letters2Index !== -1 && (
              <div id="letters2Options">
                <p className="nameHeader">{t("local_parameters_search_options_character2")}</p>
                <select className="EditParameters_select" name="letters2" id="letters2" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, letters2Index, "2", unlockAdvancedOptions)}>
                  <option value="1">A</option>
                  <option value="2">B</option>
                  <option value="3">C</option>
                  <option value="4">D</option>
                  <option value="5">E</option>
                  <option value="6">F</option>
                  <option value="7">G</option>
                  <option value="8">H</option>
                  <option value="9">I</option>
                  <option value="10">J</option>
                  <option value="11">K</option>
                  <option value="12">L</option>
                  <option value="13">M</option>
                  <option value="14">N</option>
                  <option value="15">O</option>
                  <option value="16">P</option>
                  <option value="17">Q</option>
                  <option value="18">R</option>
                  <option value="19">S</option>
                  <option value="20">T</option>
                  <option value="21">U</option>
                  <option value="22">V</option>
                  <option value="23">W</option>
                  <option value="24">X</option>
                  <option value="25">Y</option>
                  <option value="26">Z</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { numbersIndex !== undefined && numbersIndex !== -1 && (
              <div id="numbersOptions">
                <p className="nameHeader">{t("local_parameters_search_options_character")}</p>
                <select className="EditParameters_select" name="numbers" id="numbers" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, numbersIndex, "1", unlockAdvancedOptions)}>
                  <option value="1">0</option>
                  <option value="2">1</option>
                  <option value="3">2</option>
                  <option value="4">3</option>
                  <option value="5">4</option>
                  <option value="6">5</option>
                  <option value="7">6</option>
                  <option value="8">7</option>
                  <option value="9">8</option>
                  <option value="10">9</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { numbers2Index !== undefined && numbers2Index !== -1 && (
              <div id="numbers2Options">
                <p className="nameHeader">{t("local_parameters_search_options_character2")}</p>
                <select className="EditParameters_select" name="numbers2" id="numbers2" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, numbers2Index, "2", unlockAdvancedOptions)}>
                  <option value="1">0</option>
                  <option value="2">1</option>
                  <option value="3">2</option>
                  <option value="4">3</option>
                  <option value="5">4</option>
                  <option value="6">5</option>
                  <option value="7">6</option>
                  <option value="8">7</option>
                  <option value="9">8</option>
                  <option value="10">9</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { symbolsIndex !== undefined && symbolsIndex !== -1 && (
              <div id="symbolsOptions">
                <p className="nameHeader">{t("local_parameters_search_options_character")}</p>
                <select className="EditParameters_select" name="symbols" id="symbols" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, symbolsIndex, "1", unlockAdvancedOptions)}>
                  <option value="1">!</option>
                  <option value="2">@</option>
                  <option value="3">#</option>
                  <option value="4">£</option>
                  <option value="5">¤</option>
                  <option value="6">$</option>
                  <option value="7">%</option>
                  <option value="8">&</option>
                  <option value="9">+</option>
                  <option value="10">§</option>
                  <option value="11">?</option>
                  <option value="12">¿</option>
                  <option value="13">µ</option>
                  <option value="14">©</option>
                  <option value="15">¥</option>
                  <option value="16">Ʒ</option>
                  <option value="17">Ю</option>
                  <option value="18">Æ</option>
                  <option value="19">ᴪ</option>
                  <option value="20">Ө</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { symbols2Index !== undefined && symbols2Index !== -1 && (
              <div id="symbols2Options">
                <p className="nameHeader">{t("local_parameters_search_options_character2")}</p>
                <select className="EditParameters_select" name="symbols2" id="symbols2" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, symbols2Index, "2", unlockAdvancedOptions)}>
                  <option value="1">Ф</option>
                  <option value="2">д</option>
                  <option value="3">Ω</option>
                  <option value="4">Δ</option>
                  <option value="5">Σ</option>
                  <option value="6">λ</option>
                  <option value="7">Ш</option>
                  <option value="8">™</option>
                  <option value="9">►</option>
                  <option value="10">♂</option>
                  <option value="11">♀</option>
                  <option value="12">♦</option>
                  <option value="13">♥</option>
                  <option value="14">♣</option>
                  <option value="15">♠</option>
                  <option value="16">♪</option>
                  <option value="17">♫</option>
                  <option value="18">א</option>
                  <option value="19">צ</option>
                  <option value="20">ל</option>
                  <option value="21">ה</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { gapIndex !== undefined && gapIndex !== -1 && (
              <div id="gapOptions">
                <p className="nameHeader">{t("local_parameters_search_options_character3")}</p>
                <select className="EditParameters_select" name="gap" id="gap" onLoad={checkSelectDefault(selectedGameParameters, selectedGameParametersTable, gapIndex, "0", unlockAdvancedOptions)}>
                  <option value="0">{t("local_parameters_search_options_gap1")}</option>
                  <option value="1">{t("local_parameters_search_options_gap2")}</option>
                  <option value="2">{t("local_parameters_search_options_gap3")}</option>
                </select>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { preExerciseCheckBoxIndex !== undefined && preExerciseCheckBoxIndex !== -1 && (
              <div id="preExerciseOptions" className="preExerciseOptions">
                <input className="checkBox" type="checkbox" id="preExerciseCheckBox" name="preExercise" value="true" onLoad={checkCheckBoxesDefault(selectedGameParameters, selectedGameParametersTable, preExerciseCheckBoxIndex, [])} />
                <label className="checkBoxLabel" htmlFor="preExerciseCheckBox">{t("local_parameters_vilkin_identification_toggle_pre_exercise")}</label>
                <img src={require("../images/info-icon.png")} className="EditParameters-info-icon" alt = "Info" border = "0"/>
              </div>
            )}
            { selectedGameParametersTable.length === 0 && (
              <p>Settings not found.</p>
            )}
            { findTableIndex(selectedGameParametersTable, "NOT FOUND") !== -1 && (
              <p>Settings not found ID: {selectedGameParameters.split(" ")[2]}</p>
            )}
            { selectedGameParametersTable.length !== 0 && findTableIndex(selectedGameParametersTable, "NOT FOUND") !== -1 && (
              <p>Settings not found.</p>
            )}
          </div>
          <div className="EditParameters-Menu-right2">
            <button id="resetSettingsButton" type="button" className="btn btn-primary btn-reset-settings" onClick={() => resetSettings(t)} >
              {t("local_parameters_reset_default_settings")}
            </button>
            <div className="EditParameters-Menu-right-bottom">
              <button id="saveButton" type="button" className="btn btn-primary btn-save" onClick={() => saveParameters(navigate, selectedGameParameters, selectedGameParametersTable)} >
                {t("local_save")}
              </button>
              <button id="cancelButton" type="button" className="btn btn-primary btn-cancel" onClick={() => showNewRecipe(navigate)} >
                {t("local_cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default EditRecipeParameters;
