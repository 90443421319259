import React, { useState, useEffect, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var org = localStorage.getItem("org");

var token = localStorage.getItem("token");
var selectedProUserId = localStorage.getItem("selectedProUserId");
var selectedProUserOrgs = localStorage.getItem("selectedProUserOrgs");
var selectedProUserEmail = localStorage.getItem("selectedProUserEmail");
var selectedProUserRole = localStorage.getItem("selectedProUserRole");
var selectedProUserLocale = localStorage.getItem("selectedProUserLocale");
var selectedProUserName = localStorage.getItem("selectedProUserName");
var selectedProUserExpires = localStorage.getItem("selectedProUserExpires");
var selectedProUserTwoStepVerified = localStorage.getItem("selectedProUserTwoStepVerified");
var editProUser = localStorage.getItem("editProUser");
var deleteProUser = localStorage.getItem("deleteProUser");
var createOrgUser = localStorage.getItem("createOrgUser");
var selectedOrgName = localStorage.getItem("selectedOrgName");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function navigateToProUsers2(navigate) {
  navigate("/prousers");
}

function navigateToCreateProUsers(navigate) {
  navigate("/createoreditprouser");
}

var today = new Date();

var currentYear = today.getFullYear();
var currentMonth = today.getMonth() + 1;
var currentDay = today.getDate();

if (currentDay >= 1 && currentDay <= 9) {
  currentDay = "0" + currentDay;
}

if (currentMonth >= 1 && currentMonth <= 9) {
  currentMonth = "0" + currentMonth;
}

var currentDate = currentYear + "-" + currentMonth + "-" + currentDay;

function checkExpireDate(date) {
  if (date === null || date === undefined) {
    return currentDate;
  }
  else if (date.split("T") === undefined) {
    return date;
  }
  else {
    return date.split("T")[0];
  }
}

function setExpireDate(event) {
  var expireDate = document.getElementById("expireDate");

  if (expireDate !== undefined) {
    if (event.currentTarget.checked) {
      expireDate.setAttribute('disabled', '');
    }
    else {
      expireDate.removeAttribute('disabled');
    }
  }

}

function cancelClientCreation() {
  window.location.replace(window.location.origin + "/prousers");
}

function navigateToProUsers() {
  window.location.replace(window.location.origin + "/prousers");
}

function handleKeyPress(event) {
  var keyChar = String.fromCharCode(event.which || event.keyCode);
  var regex = new RegExp(/[a-zA-Z0-9@,.!#$%&'*+-/=?^_`{|}~']/);

  if (regex.test(String(keyChar).toLowerCase()) === false) {
    event.preventDefault();
  }
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const CreateOrEditProUser = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const newUserCreatedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_new_user_created")}</h1>
        <button className="confirmButton" onClick={() => {navigateToProUsers(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const newUserCreationFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_new_user_creation_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const userDeletedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_deleted")}</h1>
        <button className="confirmButton" onClick={() => {navigateToProUsers(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const userDeleteFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_delete_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const userEditedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_edited")}</h1>
        <button className="confirmButton" onClick={() => {navigateToProUsers(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const userEditFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_edit_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  setTimeout( function() {
    var expiresCheckBox = document.getElementById("expiresCheckBox");
    var expireDate = document.getElementById("expireDate");

    if (expiresCheckBox !== undefined) {
      if (selectedProUserExpires === null) {
        expiresCheckBox.checked = true;
        if (expireDate !== undefined) {
          expireDate.setAttribute('disabled', '');
        }
      }
      else {
        expiresCheckBox.checked = false;
      }
    }
  }, 10);

  const [userOrgs, setUserOrgs] = useState([]);

  const fetchUserOrgData = async () => {

    if (createOrgUser === null) {

      try {
        var orgData = null;

        if (selectedProUserOrgs != null) {
          var orgList = [];
          var orgIdList = [];

          if (selectedProUserOrgs.includes(",")) {
            orgIdList = selectedProUserOrgs.split(",");

            var expiredUser = false;

            for (var i = 0; i < orgList.length; i++) {
              const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + orgIdList[i],
              {
                method: "GET",
                headers: { "Authorization": "Bearer " + token },
              });

              console.log(response);

              const data = await response.json();

              if (data.Message !== "AuthError_LicenseExpired") {
                orgData = {"name": "Expired organisation"};
                setUserOrgs(orgData);
                expiredUser = true;
              }
              else {
                orgList.push(data.organisations[0]);
              }
            }

            if (expiredUser === false) {
              setUserOrgs(orgList);
            }
          }
          else {
            const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + selectedProUserOrgs,
            {
              method: "GET",
              headers: { "Authorization": "Bearer " + token },
            });

            console.log(response);

            const data2 = await response.json();

            if (data2.Message === "AuthError_LicenseExpired") {
              orgData = {"name": "Expired organisation"};
              setUserOrgs(orgData);
            }
            else {
              setUserOrgs(data2.organisations[0]);
            }
          }
        }
        else {
          const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + org,
          {
            method: "GET",
            headers: { "Authorization": "Bearer " + token },
          });

          console.log(response);

          const data3 = await response.json();

          if (data3.Message === "AuthError_LicenseExpired") {
            orgData = {"name": "Expired organisation"};
            setUserOrgs(orgData);
          }
          else {
            setUserOrgs(data3.organisations[0]);
          }
        }

      } catch (err) {

        const organisationFetchFailedOptions = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_failed_to_fetch_org_data")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(organisationFetchFailedOptions);
      }
    }
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  //const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (data.role === "Pro") {
          window.location.replace(window.location.origin + "/menu");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        //setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    await fetchUserOrgData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function confirmUserDelete() {
    const confirmUserDeleteOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_confirm_user_delete")}</h1>
          <button className="confirmButton" onClick={() => { confirmUserEdit(); onClose();}}>{t("local_delete")}</button>
          <button className="closeButton" onClick={onClose}>{t("local_cancel")}</button>
        </div>,
      willUnmount: () => {}
    }

    confirmAlert(confirmUserDeleteOptions);
  }

  async function confirmUserEdit() {
    var email = document.getElementById("email");
    //var newUserName = document.getElementById("username");
    var fullname = document.getElementById("fullname");
    var roleSelect = document.getElementById("role");
    var languageSelect = document.getElementById("language");

    document.getElementById("overlay").style.display = "block";

    var emailText = email.value;
    //var newUserNameText = newUserName.value;
    var fullnameText = fullname.value;

    var emailRegex = /^[A-Za-z0-9_!$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;

    if ( emailText !== "" && emailRegex.test(String(emailText).toLowerCase() ) === false) {
      document.getElementById("overlay").style.display = "none";

      const emailInvalidOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_email_invalid")}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(emailInvalidOptions);
    }
    else if (emailText !== "" && fullnameText !== "") {
      var roleSelected = null;

      if (roleSelect !== null && roleSelect !== undefined) {
        roleSelected = roleSelect.options[roleSelect.selectedIndex].value;
      }

      var languageSelected = languageSelect.options[languageSelect.selectedIndex].value;

      // "username": "' + newUserNameText + '",

      var expiresCheckBox = document.getElementById("expiresCheckBox");
      var expireDateInput = document.getElementById("expireDate");
      var expireDate = expireDateInput.value;

      expireDate += "T22:00:00Z";

      if (createOrgUser === "true") {
        org = localStorage.getItem("selectedOrgId");
      }

      var selectedOrgs = '"' + org + '"';

      if (selectedProUserOrgs !== null) {
        selectedOrgs = "";

        var orgList = selectedProUserOrgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var twoStepVerify = (selectedProUserTwoStepVerified === "true");

      var jsonBody = "";

      if (expiresCheckBox.checked === true && (editProUser === "true" || deleteProUser === "true")) {
        jsonBody = '{ "user_id": "' + selectedProUserId + '", "twostep": ' + twoStepVerify + ', "email": "' + emailText + '", "full_name": "' + fullnameText + '", "expires": null, "locale": "' + languageSelected + '", "role": "' + selectedProUserRole + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[]}", "orgs": [' + selectedOrgs + '] }';
      }
      else if (editProUser === "true" || deleteProUser === "true") {
        jsonBody = '{ "user_id": "' + selectedProUserId + '", "twostep": ' + twoStepVerify + ', "email": "' + emailText + '", "full_name": "' + fullnameText + '", "expires": "' + expireDate + '", "locale": "' + languageSelected + '", "role": "' + selectedProUserRole + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[]}", "orgs": [' + selectedOrgs + '] }';
      }
      else if (expiresCheckBox.checked === true) {
        jsonBody = '{ "email": "' + emailText + '", "name": "' + fullnameText + '", "expires": null, "locale": "' + languageSelected + '", "role": "' + roleSelected + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[]}", "organisation": "' + org + '" }';
      }
      else {
        jsonBody = '{ "email": "' + emailText + '", "name": "' + fullnameText + '", "expires": "' + expireDate + '", "locale": "' + languageSelected + '", "role": "' + roleSelected + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[]}", "organisation": "' + org + '" }';
      }

      var fetchUrl = baseUrl + "/api/v1/account/";

      if (editProUser === "true" || deleteProUser === "true") {
        fetchUrl += selectedProUserId;
      }
      else {
        fetchUrl += "new";
      }

      var fetchMethod = "POST";

      if (deleteProUser === "true") {
        fetchMethod = "DELETE";
      }

      try {
        const response = await fetch(fetchUrl, {
          method: fetchMethod,
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
          if (editProUser === "true") {
            confirmAlert(userEditedOptions);
          }
          else if (deleteProUser === "true") {
            confirmAlert(userDeletedOptions);
          }
          else {
            confirmAlert(newUserCreatedOptions);
          }
        }
        else if (response.status === 500) {
          if (editProUser === "true") {
            confirmAlert(userEditFailedOptions);
          }
          else if (deleteProUser === "true") {
            confirmAlert(userDeleteFailedOptions);
          }
          else {
            confirmAlert(newUserCreationFailedOptions);
          }
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }
    else if (fullnameText === "") {
      document.getElementById("overlay").style.display = "none";

      const nameNotGivenOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_user_name_required")}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(nameNotGivenOptions);
    }
    else if (emailText === "") {
      document.getElementById("overlay").style.display = "none";

      const emailNotGivenOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_user_email_required")}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(emailNotGivenOptions);
    }
  }

  const navigate = useNavigate();

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1);

  return (
    <div className="CreateOrEditProUser-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        { createOrgUser === "true" && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
              {t("local_organisation")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToProUsers2(navigate)}>
          {t("local_pro_users")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToCreateProUsers(navigate)}>
          {t("local_create_or_edit_user")}
        </button>
      </div>
      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" /> <br />
          <strong>{t("local_pro_users")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditprouser">{t("local_create_new")}</a>
          </span>
          <p className="ProUsers-Menu-left-footer">{t("local_create_or_edit_prousers_footer")}</p>
        </div>
        <div className="Menu-right">
          <div className="ProUser-Menu-right-bottom">
            <table className="Menu-right-bottom-table-lower">
              <tr className="coepud1-row1">
                { createOrgUser === "true" ? (
                  <th className="coepud1-row1-item1">{t("local_organisations2")}: {selectedOrgName}</th>
                ) : (
                  <th className="coepud1-row1-item1">{t("local_organisations2")}: {userOrgs.name}</th>
                )}
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coepud1-row1-item2">{t("local_name")}</th>
              </tr>
              {/*<tr>
                <td className="coepud1-row1-item2">Username</td>
                <input type="text" id="username" name="username" disabled />
              </tr>*/}
              <tr>
                <td className="coepud1-row1-item3">{t("local_full_name")}</td>
                <input type="text" id="fullname" name="fullname" defaultValue={selectedProUserName} disabled={deleteProUser} />
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coepud2-row1-item4">{t("local_adress")}</th>
              </tr>
              <tr>
                <td className="coepud2-row1-item5">{t("local_email")}</td>
                <input type="text" onKeyPress={handleKeyPress} id="email" name="email" defaultValue={selectedProUserEmail} disabled={editProUser || deleteProUser} />
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coecd3-row1-item6">{t("local_settings_button_language")}</th>
                <select className="CreateOrEditProUser-language-selection" name="language" id="language" defaultValue={selectedProUserLocale} disabled={deleteProUser} >
                  <option value="EN">{t("local_english")}</option>
                  <option value="FI">{t("local_finnish")}</option>
                  <option value="SE">{t("local_swedish")}</option>
                </select>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coecd2-row1-item7">{t("local_expires")}</th>
                <input className="expiresCheckBox" type="checkbox" id="expiresCheckBox" name="expires" value="permanent" onChange={setExpireDate} />
                <label className="expiresCheckBoxLabel" htmlFor="expiresCheckBox">{t("local_permanently_active")}</label>
                <input type="date" id="expireDate" name="expireDate" min={currentDate} defaultValue={checkExpireDate(selectedProUserExpires)} />
              </tr>
              {/*<tr>
                <td className="coepud2-row1-item6">Add1</td>
                <input type="text" id="add1" name="add1" disabled/>
              </tr>
              <tr>
                <td className="coepud2-row1-item7">Add2</td>
                <input type="text" id="add2" name="add2" disabled/>
              </tr>
              <tr>
                <td className="coepud2-row1-item8">Town</td>
                <input type="text" id="town" name="town" disabled/>
              </tr>
              <tr>
                <td className="coepud2-row1-item9">Postcode</td>
                <input type="text" id="postcode" name="postcode" disabled/>
              </tr>
              <tr>
                <td className="coepud2-row1-item10">County</td>
                <input type="text" id="county" name="county" disabled/>
              </tr>
              <tr>
                <td className="coepud2-row1-item11">Country</td>
                <input type="text" id="country" name="country" disabled/>
              </tr>*/}
              <tr>
                <br />
              </tr>
              {/*<tr>
                <th className="coepud3-row1-item12">DOB</th>
                <input type="text" id="dob" name="dob" disabled/>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="coepud4-row1-item13">Gender</th>
                <input type="text" id="gender" name="gender" disabled/>
              </tr>
              <tr>
                <br />
              </tr>*/}
              { (editProUser === null && deleteProUser === null) && (
                <tr>
                  <th className="coepud5-row1-item8">{t("local_role")}</th>
                  <select className="CreateOrEditProUser-role-selection" name="role" id="role">
                    <option value="Pro">Pro</option>
                    <option value="Admin">Admin</option>
                    <option value="Retailer">{t("local_retailer")}</option>
                  </select>
                </tr>
              )}
            </table>
            <table className="CreateOrEditProUser-links-table">
              { editProUser === "true" ? (
                <td><button className="btn btn-primary btn-save" onClick={confirmUserEdit}>
                  {t("local_save")}
                </button></td>
              ) : deleteProUser === "true" ? (
                <td><button className="btn btn-primary btn-save" onClick={confirmUserDelete}>
                  {t("local_delete")}
                </button></td>
              ) : (
                <td><button className="btn btn-primary btn-save" onClick={confirmUserEdit}>
                  OK
                </button></td>
              )}
              <td><button className="btn btn-primary btn-cancel" onClick={cancelClientCreation}>
                {t("local_cancel")}
              </button></td>
            </table>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default CreateOrEditProUser;
