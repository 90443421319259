import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext, GameContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var token = localStorage.getItem("token");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

var showClients = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

function proUsersNavigate() {
  localStorage.removeItem("createOrgUser");
  window.location.replace(window.location.origin + "/prousers");
}

function clientSelectionNavigate() {
  localStorage.removeItem("createOrgClient");
  window.location.replace(window.location.origin + "/clientselection");
}

function templatesNavigate(setTempGameLinks) {
  localStorage.setItem("templateMenuNavigate", true);
  localStorage.removeItem("createOrgClient");
  localStorage.removeItem("selectedClientUserId");
  setTempGameLinks(null);
  window.location.replace(window.location.origin + "/templates");
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const MainMenu = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setTempGameLinks } = useContext(GameContext);

  const { setNavigateContext } = useContext(NavigateContext);

  setNavigateContext(false);

  localStorage.removeItem("selectedRecipeId");
  localStorage.removeItem("templateRecipeId");
  localStorage.removeItem("selectedRecipeType");

  for (var i = 0; i < localStorage.length; i++){
    if (localStorage.key(i).startsWith("timesPerDaySelect_")) {
      localStorage.removeItem(localStorage.key(i));
    }
  }

  for (var j = 0; j < localStorage.length; j++){
    if (localStorage.key(j).startsWith("selectedGameParameters_")) {
      localStorage.removeItem(localStorage.key(j));
    }
  }

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1); //3

  return (
    <div className="MainMenu-container">
      {<Navbar />}

      <div className="AdminMenu-container">
        <div className="MainMenu-left">
          <strong className="MainMenu-title underline">{t("local_button_ingame_menu")}</strong>
          <div className="AdminMenu-items-container">
            <p>
              <a className="AdminMenu-item" href="./modules">{t("local_exercise_modules")}</a>
            </p>
            <p>
              <button className="AdminMenu-item btn btn-link btn-link-nopad" onClick={() => templatesNavigate(setTempGameLinks)}>
                {t("local_templates")}
              </button>
            </p>
            { (role === "Pro" || showClients === "true") && (
              <p>
                <button className="AdminMenu-item btn btn-link btn-link-nopad" onClick={clientSelectionNavigate}>
                  {t("local_clients")}
                </button>
              </p>
            )}
            { (role === "Super" || role === "Admin" || role === "Retailer")  && (
              <React.Fragment>
                <p>
                  <button className="AdminMenu-item btn btn-link btn-link-nopad" onClick={proUsersNavigate}>
                    {t("local_pro_users")}
                  </button>
                </p>
                <p>
                  <a className="AdminMenu-item" href="./organisation">{t("local_organisation")}</a>
                </p>
                <p>
                  <a className="License-manager-link AdminMenu-item" href="./">{t("local_license_manager")}</a>
                </p>
              </React.Fragment>
            )}
          </div>
          <p className="Menu-left-footer"p>{t("local_menu_footer")}</p>
        </div>
        <div className="Menu-right">
          <span className="MainMenu-line"></span>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default MainMenu;
