import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { NavbarContext, GameContext, NavigateContext } from "../App";
import categories from "./Categories.json";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var token = localStorage.getItem("token");
var createOrgClient = localStorage.getItem("createOrgClient");
var navigateFromClient = localStorage.getItem("navigateFromClient");
var userId = localStorage.getItem("userId");

var showClients = null;
var showParameters = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
  showParameters = localStorage.getItem("showParameters_" + userId);
}

var selectedClientUserId = localStorage.getItem("selectedClientUserId");
var selectedRecipeId = localStorage.getItem("selectedRecipeId");
var selectedRecipeType = localStorage.getItem("selectedRecipeType");
var selectedRecipeName = localStorage.getItem("selectedRecipeName");
var selectedRecipeStartDate = localStorage.getItem("selectedRecipeStartDate");
var selectedRecipeEndDate = localStorage.getItem("selectedRecipeEndDate");
var userLanguage = localStorage.getItem("userLanguage");
var notifyByEmailStorage = localStorage.getItem("notifyByEmail");
var templateRecipeId = localStorage.getItem("templateRecipeId");
var templateMenuNavigate = localStorage.getItem("templateMenuNavigate");

var recipeChecked = false;

var timesPerDay = [];

for (var i = 1; i <= 10; i++) {
  timesPerDay.push(i);
}

var today = new Date();

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

var endDateDefault = today.addDays(7);

var currentYear = today.getFullYear();
var currentMonth = today.getMonth() + 1;
var currentDay = today.getDate();

var endYearDefault = endDateDefault.getFullYear();
var endMonthDefault = endDateDefault.getMonth() + 1;
var endDayDefault = endDateDefault.getDate();

if (endDayDefault >= 1 && endDayDefault <= 9) {
  endDayDefault = "0" + endDayDefault;
}

if (currentDay >= 1 && currentDay <= 9) {
  currentDay = "0" + currentDay;
}

if (endMonthDefault >= 1 && endMonthDefault <= 9) {
  endMonthDefault = "0" + endMonthDefault;
}

if (currentMonth >= 1 && currentMonth <= 9) {
  currentMonth = "0" + currentMonth;
}

var currentDate = currentYear + "-" + currentMonth + "-" + currentDay;
var endDate = endYearDefault + "-" + endMonthDefault + "-" + endDayDefault;

var years = [];
var months = [];

for (var j = currentYear; j < 2100; j++) {
  years.push(j);
}

for (var k = currentMonth; k <= 12; k++) {
  months.push(k);
}

function showRecipes(navigate) {
  navigate("/createoreditrecipe");
  window.location.reload();
}

function navigateToClientSelection(navigate) {
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function navigateToClientCreation(navigate) {
  navigate("/createoreditclient");
}

function navigateToRecipes(navigate) {
  navigate("/createoreditrecipe");
}

function navigateToTemplates(navigate) {
  navigate("/templates");
}

function navigateToNewRecipe(navigate) {
  navigate("/newrecipe");
}

function navigateToClient(navigate) {
  navigate("/client");
}

function changeCheckBoxState(event) {
  if (event.currentTarget.checked === true) {
    localStorage.setItem("notifyByEmail", true);
  }
  else if (event.currentTarget.checked === false) {
    localStorage.removeItem("notifyByEmail");
  }
}

function checkStartDate(date) {
  if (selectedRecipeType === "template" && selectedRecipeEndDate === null) {
    return currentDate;
  }
  else if (selectedRecipeStartDate !== null) {
    return selectedRecipeStartDate;
  }
  else if (selectedRecipeId === null) {
    return currentDate;
  }
  else if (date === undefined) {
    return null;
  }
  else if (date.split("T") === undefined) {
    return date;
  }
  else {
    return date.split("T")[0];
  }
}

function checkEndDate(date) {
  if (selectedRecipeType === "template" && selectedRecipeEndDate === null) {
    return endDate;
  }
  else if (selectedRecipeEndDate !== null) {
    return selectedRecipeEndDate;
  }
  else if (selectedRecipeId === null) {
    return endDate;
  }
  else if (date === undefined) {
    return null;
  }
  else if (date.split("T") === undefined) {
    return date;
  }
  else {
    return date.split("T")[0];
  }
}

function checkRecipeName(recipeName) {
  if (selectedRecipeName !== null) {
    return selectedRecipeName;
  }
  else if (recipeName === undefined) {
    return "";
  }
  else {
    return recipeName;
  }
}

async function getRecipeGames(t) {
  var recipeUserId = selectedClientUserId;
  var recipeId = selectedRecipeId;

  if (selectedRecipeType === "template") {
    recipeUserId = userId;
    recipeId = templateRecipeId;
  }

  if (recipeId !== null) {

    var overlay = document.getElementById("overlay");

    if (overlay === undefined || overlay === null) {
      return;
    }

    try {
      overlay.style.display = "block";

      const response = await fetch(baseUrl + "/api/v1/recipe/" + recipeUserId + "/" + recipeId,
      {
        headers: { "Authorization": "Bearer " + token,
        "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status !== 204) {
        const recipeData = await response.json();

        var recipeDataParsed = JSON.parse(recipeData.recipes[0].data);

        return recipeDataParsed.games;

      }
    } catch (err) {
      overlay.style.display = "none";

      const recipeFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_recipe_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(recipeFetchFailedOptions);
    }
  }

  return null;
}

async function getJsonGameParameters(game, t, findRecipe = false) {
  var gameId = game.split("/")[1];

  var selectedGameParametersById = null;

  try {
    selectedGameParametersById = JSON.parse(localStorage.getItem("selectedGameParameters_" + game.split("/")[0]));
  } catch (err) {

  }

  if (selectedGameParametersById !== undefined && selectedGameParametersById !== null) {
    return selectedGameParametersById;
  }

  if (findRecipe === true) {
    var recipeDataGames = await getRecipeGames(t);

    if (recipeDataGames !== undefined && recipeDataGames !== null) {
      for (var j = 0; j < recipeDataGames.length; j++) {
        if (recipeDataGames[j].id === gameId) {
          if (recipeDataGames[j].parameters !== undefined && recipeDataGames[j].parameters !== null) {
            return recipeDataGames[j].parameters;
          }
        }
      }
    }
  }

  var category = Number(game.split("/")[2]);
  var sub_category = null;
  var sub_sub_category = null;

  if (game.split("/")[3] !== undefined) {
    sub_category = Number(game.split("/")[3]);
  }

  if (game.split("/")[4] !== undefined) {
    sub_sub_category = Number(game.split("/")[4]);
  }

  var games = null;

  if (sub_category !== null) {
    if (sub_sub_category !== null) {
      games = categories.category[category].sub[sub_category].sub[sub_sub_category].sub;
    }
    else {
      games = categories.category[category].sub[sub_category].sub;
    }
  }
  else {
    games = categories.category[category].sub;
  }

  if (games !== null) {
    for (var i = 0; i < games.length; i++) {
      if (games[i].gameId === gameId) {
        return games[i].parameters;
      }
    }
  }

  return "NOT FOUND " + gameId.toString();
}

async function getJsonGameParameters2(game, t) {
  var gameId = game.split("/")[1];
  var category = Number(game.split("/")[2]);
  var sub_category = null;
  var sub_sub_category = null;

  if (game.split("/")[3] !== undefined) {
    sub_category = Number(game.split("/")[3]);
  }

  if (game.split("/")[4] !== undefined) {
    sub_sub_category = Number(game.split("/")[4]);
  }

  var games = null;

  if (sub_category !== null) {
    if (sub_sub_category !== null) {
      games = categories.category[category].sub[sub_category].sub[sub_sub_category].sub;
    }
    else {
      games = categories.category[category].sub[sub_category].sub;
    }
  }
  else {
    games = categories.category[category].sub;
  }

  if (games !== null) {
    for (var i = 0; i < games.length; i++) {
      if (games[i].gameId === gameId) {
        return games[i].parameters;
      }
    }
  }

  return "NOT FOUND " + gameId.toString();
}

function getJsonGameId(game, t) {
  var gameId = game.split("/")[1];
  var category = Number(game.split("/")[2]);
  var sub_category = null;
  var sub_sub_category = null;

  if (game.split("/")[3] !== undefined) {
    sub_category = Number(game.split("/")[3]);
  }

  if (game.split("/")[4] !== undefined) {
    sub_sub_category = Number(game.split("/")[4]);
  }

  if (gameId !== "-1") {
    var games = null;

    if (sub_category !== null) {
      if (sub_sub_category !== null) {
        games = categories.category[category].sub[sub_category].sub[sub_sub_category].sub;
      }
      else {
        games = categories.category[category].sub[sub_category].sub;
      }
    }
    else {
      games = categories.category[category].sub;
    }

    if (games !== null) {
      for (var i = 0; i < games.length; i++) {
        if (games[i].gameId === gameId) {
          return games[i].id;
        }
      }
    }

    return "NOT FOUND " + gameId.toString();
  }
  else {
    return "NOT FOUND -1";
  }
}

function swapGameUp(gameLinks, setGameLinks, index) {
  var temp = gameLinks[index];

  if (index > 0) {
    gameLinks[index] = gameLinks[index - 1];
    gameLinks[index - 1] = temp;
  }
  else {
    gameLinks = gameLinks.slice(1);
    gameLinks.push(temp);
  }

  setGameLinks([...gameLinks]);
}

function swapGameDown(gameLinks, setGameLinks, index) {
  var temp = gameLinks[index];

  if (index < gameLinks.length - 1) {
    gameLinks[index] = gameLinks[index + 1];
    gameLinks[index + 1] = temp;
  }
  else {
    gameLinks.pop();
    gameLinks.splice(0, 0, temp);
  }

  setGameLinks([...gameLinks]);
}

function getJsonGameName(game, t) {
  var gameId = game.split("/")[1];
  var category = Number(game.split("/")[2]);
  var sub_category = null;
  var sub_sub_category = null;

  if (game.split("/")[3] !== undefined) {
    sub_category = Number(game.split("/")[3]);
  }

  if (game.split("/")[4] !== undefined) {
    sub_sub_category = Number(game.split("/")[4]);
  }

  if (gameId !== "-1") {
    var games = null;

    if (sub_category !== null) {
      if (sub_sub_category !== null) {
        games = categories.category[category].sub[sub_category].sub[sub_sub_category].sub;
      }
      else {
        games = categories.category[category].sub[sub_category].sub;
      }
    }
    else {
      games = categories.category[category].sub;
    }

    if (games !== null) {
      for (var i = 0; i < games.length; i++) {
        if (games[i].gameId === gameId) {
          return t(games[i].id);
        }
      }
    }

    return "NOT FOUND " + gameId.toString();
  }
  else {
    if (sub_category !== null) {
      if (sub_sub_category !== null) {
        return t("local_random_game_id") + " " + t(categories.category[category].sub[sub_category].sub[sub_sub_category].id);
      }
      return t("local_random_game_id") + " " + t(categories.category[category].sub[sub_category].id);
    }
    else {
      return t("local_random_game_id") + " " + t(categories.category[category].id);
    }
  }


}

function getJsonId(gameId, category, sub_category = null, sub_sub_category = null) {

  var games = null;

  if (sub_category !== null) {
    if (sub_sub_category !== null) {
      games = categories.category[category].sub[sub_category].sub[sub_sub_category].sub;
    }
    else {
      games = categories.category[category].sub[sub_category].sub;
    }
  }
  else {
    games = categories.category[category].sub;
  }

  if (games !== null) {
    for (var i = 0; i < games.length; i++) {
      if (games[i].gameId === gameId) {
        return games[i].id;
      }
    }
  }

  return "NOT FOUND " + gameId.toString();
}

function getJsonIdRandom(gameId, category, sub_category = null, sub_sub_category = null) {
  if (sub_category !== null) {
    if (sub_sub_category !== null) {
      return "GAME_RANDOM_" + categories.category[category].sub[sub_category].sub[sub_sub_category].id.toUpperCase();
    }
    return "GAME_RANDOM_" + categories.category[category].sub[sub_category].id.toUpperCase();
  }
  else {
    return "GAME_RANDOM_" + categories.category[category].id.toUpperCase();
  }
}

function saveNameToStorage(event) {
  localStorage.setItem("selectedRecipeName", event.currentTarget.value);
}

function saveStartDateStorage(event) {
  localStorage.setItem("selectedRecipeStartDate", event.currentTarget.value);
}

function saveEndDateToStorage(event) {
  localStorage.setItem("selectedRecipeEndDate", event.currentTarget.value);
}

function getGameSpanId(gameId) {
  return "gameSpan" + gameId;
}

function getSelectId(gameId) {
  return "timesPerDaySelect_" + gameId;
}

function getSelectDivId(gameId) {
  return "timesPerDayDiv" + gameId;
}

function getGameDivId(gameId) {
  return "gameDiv" + gameId;
}

function saveTimesPerDayToStorage(gameId, event) {
  localStorage.setItem("timesPerDaySelect_" + gameId, event.currentTarget.value);
}

function showGames(navigate, setNavigateContext) {
  setNavigateContext(true);
  navigate("/addgame");
  window.location.reload();
}

function getTemplates(navigate, setNavigateContext, gameLinks, setTempGameLinks) {
  if (selectedClientUserId !== null) {
    setTempGameLinks(gameLinks);
  }
  setNavigateContext(true);
  navigate("/templates");
  window.location.reload();
}

async function editRecipeParameters(navigate, setNavigateContext, game, t, jsonGameId) {
  var jsonParameters = await getJsonGameParameters2(game, t);

  localStorage.setItem("selectedJsonGameParameters", JSON.stringify(jsonParameters));

  var parameters = await getJsonGameParameters(game, t, true);

  localStorage.setItem("selectedGameParameters", JSON.stringify(parameters));
  localStorage.setItem("selectedGameId", jsonGameId);
  setNavigateContext(true);
  navigate("/editrecipeparameters");
  window.location.reload();
}

function addOldGames(tempGameLinks, jsonIdList, setGameLinks) {
  var allGameLinks = tempGameLinks.concat(jsonIdList);

  setGameLinks([...new Set(allGameLinks)]);
}

var recipeGames = [];

function returnToLogin() {
  window.location.replace(window.location.origin);
}

var saveGames = true;

const NewRecipe = () => {
  const { t, i18n } = useTranslation();

  const customUICategoryError = (recipeGames, i) => ({ title, message, onClose }) => (
    <div className='custom-ui'>
      <h1>{t("local_wrong_category_id")} {recipeGames[i].id} "/" {recipeGames[i].category}</h1>
      <button className="confirmButton" onClick={onClose}>Ok</button>
    </div>
  );

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const noGamesSelectedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_game_not_selected")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const noRecipeNameOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_recipe_name_required")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (templateMenuNavigate !== "true") {
          if (showClients !== "true" && data.role !== "Pro") {
            window.location.replace(window.location.origin + "/account");
          }
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const [recipe, setRecipe] = useState({"title": "", "start_date": undefined, "end_date": undefined, "data": "{\"games\": []}" });
  const { gameLinks, setGameLinks, tempGameLinks, setTempGameLinks } = useContext(GameContext);

  async function getRecipe() {
    var recipeUserId = selectedClientUserId;
    var recipeId = selectedRecipeId;

    if (selectedRecipeType === "template") {
      recipeUserId = userId;
      recipeId = templateRecipeId;
    }

    var overlay = document.getElementById("overlay");

    if (recipeId !== null) {

      if (overlay === undefined || overlay === null) {
        return;
      }

      try {
        overlay.style.display = "block";

        const response = await fetch(baseUrl + "/api/v1/recipe/" + recipeUserId + "/" + recipeId,
        {
          headers: { "Authorization": "Bearer " + token,
          "Auth-ForceAdminClientAccess": "1" },
        });

        console.log(response);

        if (response.status !== 204) {
          const recipeData = await response.json();

          setRecipe(recipeData.recipes[0]);

          if (selectedRecipeType === "template") {
            localStorage.setItem("selectedRecipeName", recipeData.recipes[0].title);
          }

          var sendNotificationCheckBox = document.getElementById("sendNotificationCheckBox");

          if (sendNotificationCheckBox !== undefined && sendNotificationCheckBox !== null) {
            if (recipe.notify_by_email === true) {
              sendNotificationCheckBox.checked = true;
              localStorage.setItem("notifyByEmail", true);
            }
            else {
              sendNotificationCheckBox.checked = false;
              localStorage.removeItem("notifyByEmail");
            }
          }

          if (recipe.data !== "") {
            var recipeDataParsed = JSON.parse(recipe.data);

            recipeGames = recipeDataParsed.games;

            for (var j = 0; j < recipeGames.length; j++) {
              recipeGames[j].category -= 1;
              if (recipeGames[j].sub_category !== null) {
                recipeGames[j].sub_category -= 1;
              }
              if (recipeGames[j].sub_sub_category !== null) {
                recipeGames[j].sub_sub_category -= 1;
              }
            }

            var jsonIdList = [];
            var timesPerDaySelect = null;

            if (recipeGames.length > 0) {
              for (var i = 0; i < recipeGames.length; i++) {
                if (recipeGames[i].category !== null && !isNaN(recipeGames[i].category)) {
                  if (recipeGames[i].sub_category !== null && !isNaN(recipeGames[i].sub_category)) {
                    if (recipeGames[i].sub_sub_category !== null && !isNaN(recipeGames[i].sub_sub_category)) {
                      if (recipeGames[i].id === "-1") {
                        jsonIdList.push(getJsonIdRandom(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category, recipeGames[i].sub_sub_category)
                        + "/" + recipeGames[i].id + "/" + recipeGames[i].category + "/" + recipeGames[i].sub_category + "/" + recipeGames[i].sub_sub_category);

                        timesPerDaySelect = document.getElementById("timesPerDaySelect_" + getJsonIdRandom(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category, recipeGames[i].sub_sub_category));

                        if (timesPerDaySelect !== null && timesPerDaySelect !== undefined) {
                          timesPerDaySelect.selectedIndex = recipeGames[i].perday;
                        }

                        localStorage.setItem("timesPerDaySelect_" + getJsonIdRandom(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category, recipeGames[i].sub_sub_category), recipeGames[i].perday);
                      }
                      else {
                        jsonIdList.push(getJsonId(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category, recipeGames[i].sub_sub_category)
                        + "/" + recipeGames[i].id + "/" + recipeGames[i].category + "/" + recipeGames[i].sub_category + "/" + recipeGames[i].sub_sub_category);

                        timesPerDaySelect = document.getElementById("timesPerDaySelect_" + getJsonId(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category, recipeGames[i].sub_sub_category));

                        if (timesPerDaySelect !== null && timesPerDaySelect !== undefined) {
                          timesPerDaySelect.selectedIndex = recipeGames[i].perday;
                        }

                        localStorage.setItem("timesPerDaySelect_" + getJsonId(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category, recipeGames[i].sub_sub_category), recipeGames[i].perday);
                      }
                    }
                    else if (recipeGames[i].id === "-1") {
                      jsonIdList.push(getJsonIdRandom(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category)
                      + "/" + recipeGames[i].id + "/" + recipeGames[i].category + "/" + recipeGames[i].sub_category);

                      timesPerDaySelect = document.getElementById("timesPerDaySelect_" + getJsonIdRandom(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category));

                      if (timesPerDaySelect !== null && timesPerDaySelect !== undefined) {
                        timesPerDaySelect.selectedIndex = recipeGames[i].perday;
                      }

                      localStorage.setItem("timesPerDaySelect_" + getJsonIdRandom(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category), recipeGames[i].perday);
                    }
                    else {
                      jsonIdList.push(getJsonId(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category)
                      + "/" + recipeGames[i].id + "/" + recipeGames[i].category + "/" + recipeGames[i].sub_category);

                      timesPerDaySelect = document.getElementById("timesPerDaySelect_" + getJsonId(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category));

                      if (timesPerDaySelect !== null && timesPerDaySelect !== undefined) {
                        timesPerDaySelect.selectedIndex = recipeGames[i].perday;
                      }

                      localStorage.setItem("timesPerDaySelect_" + getJsonId(recipeGames[i].id, recipeGames[i].category, recipeGames[i].sub_category), recipeGames[i].perday);
                    }
                  }
                  else if (recipeGames[i].id === "-1") {
                    jsonIdList.push(getJsonIdRandom(recipeGames[i].id, recipeGames[i].category)
                    + "/" + recipeGames[i].id + "/" + recipeGames[i].category);

                    timesPerDaySelect = document.getElementById("timesPerDaySelect_" + getJsonIdRandom(recipeGames[i].id, recipeGames[i].category));

                    if (timesPerDaySelect !== null && timesPerDaySelect !== undefined) {
                      timesPerDaySelect.selectedIndex = recipeGames[i].perday;
                    }

                    localStorage.setItem("timesPerDaySelect_" + getJsonIdRandom(recipeGames[i].id, recipeGames[i].category), recipeGames[i].perday);
                  }
                  else {
                    jsonIdList.push(getJsonId(recipeGames[i].id, recipeGames[i].category)
                    + "/" + recipeGames[i].id + "/" + recipeGames[i].category);

                    timesPerDaySelect = document.getElementById("timesPerDaySelect_" + getJsonId(recipeGames[i].id, recipeGames[i].category));

                    if (timesPerDaySelect !== null && timesPerDaySelect !== undefined) {
                      timesPerDaySelect.selectedIndex = recipeGames[i].perday;
                    }

                    localStorage.setItem("timesPerDaySelect_" + getJsonId(recipeGames[i].id, recipeGames[i].category), recipeGames[i].perday);
                  }
                }
                else {
                  overlay.style.display = "none";

                  const wrongCategoryIdOptions = {
                    title: 'Title',
                    message: 'Message',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => alert('Click Yes')
                      },
                      {
                        label: 'No',
                        onClick: () => alert('Click No')
                      }
                    ],
                    childrenElement: () => <div />,
                    customUI: customUICategoryError(recipeGames, i),
                    willUnmount: () => {}
                  }

                  confirmAlert(wrongCategoryIdOptions);
                  saveGames = false;
                }
              }

              if (saveGames === true) {
                setGameLinks(jsonIdList);

                overlay.style.display = "none";

                if (selectedClientUserId !== null && selectedRecipeType === "template" && tempGameLinks !== null && tempGameLinks.length > 0) {
                  const confirmGameAddOptions = {
                    title: 'Title',
                    message: 'Message',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => alert('Click Yes')
                      },
                      {
                        label: 'No',
                        onClick: () => alert('Click No')
                      }
                    ],
                    childrenElement: () => <div />,
                    customUI: ({ title, message, onClose }) =>
                      <div className='custom-ui'>
                        <h1>{t("local_add_old_games")}</h1>
                        <button className="confirmButton" onClick={() => { addOldGames(tempGameLinks, jsonIdList, setGameLinks); onClose();}}>{t("local_add")}</button>
                        <button className="closeButton" onClick={onClose}>{t("local_cancel")}</button>
                      </div>,
                    closeOnClickOutside: false,
                    willUnmount: () => {}
                  }

                  confirmAlert(confirmGameAddOptions);
                }
              }

            }
          }
        }
      } catch (err) {
        overlay.style.display = "none";

        const recipeFetchFailedOptions = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_failed_to_fetch_recipe_data")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(recipeFetchFailedOptions);
      }
    }

    overlay.style.display = "none";

    recipeChecked = true;
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    if (recipeChecked === true) {
      document.getElementById("overlay").style.display = "none";
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.onpopstate=function() {
    window.location.reload();
  }

  var recipeNameInput = document.getElementById("recipeName");
  var startDateInput = document.getElementById("startDate");
  var endDateInput = document.getElementById("endDate");

  if (selectedRecipeId !== null) {
    if (recipeNameInput !== null && recipeNameInput.value !== "") {
      localStorage.setItem("selectedRecipeName", recipeNameInput.value);
    }
    if (startDateInput !== null && startDateInput.value !== "") {
      localStorage.setItem("selectedRecipeStartDate", startDateInput.value);
    }
    if (endDateInput !== null && endDateInput.value !== "") {
      localStorage.setItem("selectedRecipeEndDate", endDateInput.value);
    }
  }

  const navigate = useNavigate();

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(2);

  if (gameLinks.length === 0 && saveGames !== false) {
    getRecipe();
  }
  else {
    recipeChecked = true;
  }

  if (selectedRecipeId === null && gameLinks.length === 0) {
    var keys = [];
    for (var l = 0; l < localStorage.length; l++){
      if (localStorage.key(l).substring(0,17) === "timesPerDaySelect") {
        keys.push(localStorage.key(l));
      }
    }

    for (var m = 0; m < keys.length; m++) {
      localStorage.removeItem(keys[m]);
    }
  }

  function removeGame(game) {
    for (var j = 0; j < gameLinks.length; j++) {
      if (game === gameLinks[j]) {
        gameLinks.splice(gameLinks.indexOf(gameLinks[j]), 1);
        j--;
      }
    }

    var gameDiv = document.getElementById(getGameDivId(game.split("/")[0]));

    if (gameDiv !== undefined) {
      gameDiv.parentNode.removeChild(gameDiv);
    }

    localStorage.setItem("gameLinks" + selectedClientUserId, gameLinks);
  }

  function setConfirm(game) {
    confirmAlert({
      message: 'Are you sure you want to remove game ' + getJsonGameName(game, t) + '?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => removeGame(game)
        },
        {
          label: 'No'
        }
      ],
      closeOnClickOutside: false
    });
  }

  async function saveRecipe(navigate, draft = false, updateDraft = false, template = false) {
    var recipeNameInput = document.getElementById("recipeName");
    var recipeName = recipeNameInput.value;

    if (recipeName !== "") {
      var startDateInput = document.getElementById("startDate");
      var startDate = null;

      if (startDateInput !== undefined && startDateInput !== null) {
        startDate = startDateInput.value;
      }

      var endDateInput = document.getElementById("endDate");
      var endDate = null;

      if (endDateInput !== undefined && endDateInput !== null) {
        endDate = endDateInput.value;
      }

      if (gameLinks.length > 0) {
        document.getElementById("overlay").style.display = "block";

        var gameLists = {"games": []};

        for (var i = 0; i < gameLinks.length; i++) {
          var gameList = {};

          gameList.id = gameLinks[i].split("/")[1];
          gameList.index = i;
          gameList.category = Number(gameLinks[i].split("/")[2]) + 1;

          if (gameLinks[i].split("/")[3] !== undefined) {
            gameList.sub_category = Number(gameLinks[i].split("/")[3]) + 1;
          }

          if (gameLinks[i].split("/")[4] !== undefined) {
            gameList.sub_sub_category = Number(gameLinks[i].split("/")[4]) + 1;
          }

          var timesPerDaySelect = document.getElementById("timesPerDaySelect_" + gameLinks[i].split("/")[0]);

          if (timesPerDaySelect !== undefined) {
            var timesPerDaySelected = timesPerDaySelect.options[timesPerDaySelect.selectedIndex].value;
            gameList.perday = timesPerDaySelected;
          }
          else {
            gameList.perday = 0;
          }

          gameList.difficulty = 0;

          var selectedGameParametersById = null;

          try {
            selectedGameParametersById = JSON.parse(localStorage.getItem("selectedGameParameters_" + gameLinks[i].split("/")[0]));
          } catch (err) {

          }

          var parameters = await getJsonGameParameters(gameLinks[i], t);
          var parametersByRecipe = await getJsonGameParameters(gameLinks[i], t, true);

          if (Array.isArray(parameters)) {
            if (selectedGameParametersById !== undefined && selectedGameParametersById !== null) {
              gameList.parameters = selectedGameParametersById;
            }
            else {
              gameList.parameters = parametersByRecipe;
            }
          }

          gameLists.games[i] = gameList;
        }

        startDate += "T22:00:00Z";
        endDate += "T21:59:59Z";

        var sendNotificationCheckBox = document.getElementById("sendNotificationCheckBox");

        var notifyByEmail = false;

        if (sendNotificationCheckBox !== undefined && sendNotificationCheckBox !== null) {
          if (sendNotificationCheckBox.checked === true) {
            notifyByEmail = true;
          }
        }

        var recipeType = "recipe";

        if (draft === true) {
          startDate = "2000-01-01";//(currentYear + 1000) + "-01-01";

          endDate = "2000-01-01";//(endYearDefault + 1000) + "-01-01";

          startDate += "T22:00:00Z";

          endDate += "T21:59:59Z";

          recipeType = "draft";

          if (updateDraft !== true) {
            selectedRecipeId = null;
          }
        }

        var recipeUserId = selectedClientUserId;

        if (template === true) {
          startDate = (currentYear + 1000) + "-01-01";

          endDate = (endYearDefault + 1000) + "-01-01";

          startDate += "T22:00:00Z";

          endDate += "T21:59:59Z";

          recipeUserId = userId;

          selectedRecipeId = null;

          recipeType = "template";
        }

        var recipeJson = {
          "id": selectedRecipeId,
          "title": recipeName,
          "user_id": recipeUserId,
          "sender_id": userId,
          "notify_by_email": notifyByEmail,
          "start_date": startDate,
          "end_date": endDate,
          "data": {},
          "type": recipeType
        }

        recipeJson.data = JSON.stringify(gameLists);

        var fetchUrl = baseUrl + "/api/v1/recipe/" + recipeUserId + "/";

        if (selectedRecipeId !== null) {
          fetchUrl += selectedRecipeId;
        }
        else {
          fetchUrl += "new";
        }

        try {
          const response = await fetch(fetchUrl,
          {
            method: "POST",
            headers: { "Authorization": "Bearer " + token,
            "Auth-ForceAdminClientAccess": "1" },
            body: JSON.stringify(recipeJson),
          });

          console.log(response);

          document.getElementById("overlay").style.display = "none";

          if (response.status === 200) {
            if (template === true) {
              if (templateMenuNavigate === "true") {
                const templateSavedOptions = {
                  title: 'Title',
                  message: 'Message',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => alert('Click Yes')
                    },
                    {
                      label: 'No',
                      onClick: () => alert('Click No')
                    }
                  ],
                  childrenElement: () => <div />,
                  customUI: ({ title, message, onClose }) =>
                    <div className='custom-ui'>
                      <h1>{t("local_template_saved")}</h1>
                      <button className="confirmButton" onClick={() => { navigateToTemplates(navigate); onClose();}}>Ok</button>
                    </div>,
                  closeOnClickOutside: false,
                  willUnmount: () => {}
                }

                confirmAlert(templateSavedOptions);
              }
              else {
                const templateSavedOptions2 = {
                  title: 'Title',
                  message: 'Message',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => alert('Click Yes')
                    },
                    {
                      label: 'No',
                      onClick: () => alert('Click No')
                    }
                  ],
                  childrenElement: () => <div />,
                  customUI: ({ title, message, onClose }) =>
                    <div className='custom-ui'>
                      <h1>{t("local_template_saved")}</h1>
                      <button className="confirmButton" onClick={onClose}>Ok</button>
                    </div>,
                  willUnmount: () => {}
                }

                confirmAlert(templateSavedOptions2);
              }
            }
            else {
              localStorage.removeItem("gameLinks" + selectedClientUserId);
              navigate("/createoreditrecipe");
              window.location.reload();
            }
          }
          else {
            var errorText = t("local_failed_to_save_recipe");

            if (selectedRecipeType === "draft") {
              errorText = t("local_failed_to_save_draft");
            }

            const recipeSaveFailedOptions = {
              title: 'Title',
              message: 'Message',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => alert('Click Yes')
                },
                {
                  label: 'No',
                  onClick: () => alert('Click No')
                }
              ],
              childrenElement: () => <div />,
              customUI: ({ title, message, onClose }) =>
                <div className='custom-ui'>
                  <h1>{errorText}</h1>
                  <button className="confirmButton" onClick={onClose}>Ok</button>
                </div>,
              willUnmount: () => {}
            }

            confirmAlert(recipeSaveFailedOptions);
          }

        } catch (err) {
          document.getElementById("overlay").style.display = "none";

          var errorText2 = t("local_failed_to_save_recipe");

          if (selectedRecipeType === "draft") {
            errorText2 = t("local_failed_to_save_draft");
          }

          const recipeSaveFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{errorText2} {err}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            willUnmount: () => {}
          }

          confirmAlert(recipeSaveFailedOptions2);
        }

      }
      else {
        confirmAlert(noGamesSelectedOptions);
      }
    }
    else {
      confirmAlert(noRecipeNameOptions);
    }
  }

  async function confirmRecipeCancel(navigate) {
    var cancelText = t("local_confirm_recipe_cancel");

    if (selectedRecipeType === "draft") {
      cancelText = t("local_confirm_draft_remove");
    }

    const confirmRecipeCancelOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{cancelText}</h1>
          <button className="confirmButton" onClick={() => { cancelRecipe(navigate); onClose();}}>{t("local_cancel_recipe")}</button>
          <button className="closeButton" onClick={onClose}>{t("local_cancel")}</button>
        </div>,
      willUnmount: () => {}
    }

    confirmAlert(confirmRecipeCancelOptions);
  }

  async function cancelRecipe(navigate) {
    getRecipe();

    if (gameLinks.length > 0) {
      document.getElementById("overlay").style.display = "block";

      var gameLists = {"games": []};

      for (var i = 0; i < gameLinks.length; i++) {
        var gameList = {};

        gameList.id = gameLinks[i].split("/")[1];
        gameList.index = i;
        gameList.category = Number(gameLinks[i].split("/")[2]) + 1;

        if (gameLinks[i].split("/")[3] !== undefined) {
          gameList.sub_category = Number(gameLinks[i].split("/")[3]) + 1;
        }

        if (gameLinks[i].split("/")[4] !== undefined) {
          gameList.sub_sub_category = Number(gameLinks[i].split("/")[4]) + 1;
        }

        var timesPerDaySelect = document.getElementById("timesPerDaySelect_" + gameLinks[i].split("/")[0]);

        if (timesPerDaySelect !== undefined) {
          var timesPerDaySelected = timesPerDaySelect.options[timesPerDaySelect.selectedIndex].value;
          gameList.perday = timesPerDaySelected;
        }
        else {
          gameList.perday = 0;
        }

        gameList.difficulty = 0;

        gameLists.games[i] = gameList;
      }

      var deleteDate = new Date();

      var deleteYear = deleteDate.getUTCFullYear();
      var deleteMonth = deleteDate.getUTCMonth() + 1;
      var deleteDay = deleteDate.getUTCDate();
      var deleteHours = deleteDate.getUTCHours();
      var deleteMinutes = deleteDate.getUTCMinutes();
      var deleteSeconds = deleteDate.getUTCSeconds();

      if (deleteMonth >= 1 && deleteMonth <= 9) {
        deleteMonth = "0" + deleteMonth;
      }

      if (deleteDay >= 1 && deleteDay <= 9) {
        deleteDay = "0" + deleteDay;
      }

      if (deleteHours >= 0 && deleteHours <= 9) {
        deleteHours = "0" + deleteHours;
      }

      if (deleteMinutes >= 0 && deleteMinutes <= 9) {
        deleteMinutes = "0" + deleteMinutes;
      }

      if (deleteSeconds >= 0 && deleteSeconds <= 9) {
        deleteSeconds = "0" + deleteSeconds;
      }

      endDate = deleteYear + "-" + deleteMonth + "-" + deleteDay + "T" + deleteHours + ":" + deleteMinutes + ":" + deleteSeconds + "Z";

      var sendNotificationCheckBox = document.getElementById("sendNotificationCheckBox");

      var notifyByEmail = false;

      if (sendNotificationCheckBox !== undefined && sendNotificationCheckBox !== null) {
        if (sendNotificationCheckBox.checked === true) {
          notifyByEmail = true;
        }
      }

      var recipeJson = {
        "id": selectedRecipeId,
        "title": recipe.title,
        "user_id": selectedClientUserId,
        "sender_id": userId,
        "notify_by_email": notifyByEmail,
        "start_date": recipe.start_date,
        "end_date": endDate,
        "data": {},
        "type": "deleted"
      }

      recipeJson.data = JSON.stringify(gameLists);

      var fetchUrl = baseUrl + "/api/v1/recipe/" + selectedClientUserId + "/" + selectedRecipeId;

      try {
        const response = await fetch(fetchUrl,
        {
          method: "POST",
          headers: { "Authorization": "Bearer " + token,
          "Auth-ForceAdminClientAccess": "1" },
          body: JSON.stringify(recipeJson),
        });

        console.log(response);

        document.getElementById("overlay").style.display = "none";

        if (response.status === 200) {
          localStorage.removeItem("gameLinks" + selectedClientUserId);

          navigate("/createoreditrecipe");
          window.location.reload();
        }
        else {
          var errorText = t("local_failed_to_cancel_recipe");

          if (selectedRecipeType === "draft") {
            errorText = t("local_failed_to_remove_draft");
          }

          const recipeSaveFailedOptions = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{errorText}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            willUnmount: () => {}
          }

          confirmAlert(recipeSaveFailedOptions);
        }

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        var errorText2 = t("local_failed_to_cancel_recipe");

        if (selectedRecipeType === "draft") {
          errorText2 = t("local_failed_to_remove_draft");
        }

        const recipeCancelFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{errorText2} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(recipeCancelFailedOptions2);
      }
    }
    else {
      confirmAlert(noGamesSelectedOptions);
    }
  }

  var sendNotificationCheckBox = document.getElementById("sendNotificationCheckBox");

  if (sendNotificationCheckBox !== undefined && sendNotificationCheckBox !== null) {
    if (notifyByEmailStorage === "true") {
      sendNotificationCheckBox.checked = true;
    }
  }

  setTimeout( function() {

    for (var i = 0; i < gameLinks.length; i++) {
      var timesPerDaySelect = document.getElementById("timesPerDaySelect_" + gameLinks[i].split("/")[0]);
      var timesPerDaySelectStorageValue = localStorage.getItem("timesPerDaySelect_" + gameLinks[i].split("/")[0]);

      if (timesPerDaySelect !== null && timesPerDaySelect !== undefined && timesPerDaySelectStorageValue !== null) {
        timesPerDaySelect.selectedIndex = timesPerDaySelectStorageValue;
      }
    }

  }, 500);

  return (
    <div className="NewRecipe-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        { templateMenuNavigate === "true" ? (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
              {t("local_button_ingame_menu")}
            </button>
            <span> – </span>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToTemplates(navigate)}>
              {t("local_templates")}
            </button>
            <span> – </span>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToNewRecipe(navigate)}>
              {t("local_create_or_edit_recipe")}
            </button>
          </React.Fragment>
        ): (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
              {t("local_button_ingame_menu")}
            </button>
            <span> – </span>
            { createOrgClient === "true" && (
              <React.Fragment>
                <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
                  {t("local_organisation")}
                </button>
                <span> – </span>
              </React.Fragment>
            )}
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientSelection(navigate)}>
              {t("local_clients_list")}
            </button>
            <span> – </span>
            { navigateFromClient !== null && (
              <React.Fragment>
                <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClient(navigate)}>
                  {t("local_client")}
                </button>
                <span> – </span>
              </React.Fragment>
            )}
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientCreation(navigate)}>
              {t("local_create_or_edit_client")}
            </button>
            <span> – </span>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToRecipes(navigate)}>
              {t("local_recipes")}
            </button>
            <span> – </span>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToNewRecipe(navigate)}>
              {t("local_create_or_edit_recipe")}
            </button>
          </React.Fragment>
        )}
      </div>

      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" /> <br />
          <strong>{t("local_clients")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditclient">{t("local_create_new")}</a>
          </span>
          <p className="NewRecipe-Menu-left-footer"p>{t("local_new_recipe_footer")}</p>
        </div>
        <div className="Menu-right-newrecipe" id="Menu-right">
          <div className="CreateRecipes-Menu-right-bottom">
            <table className="CreateOrEditRecipeMenu-left">
              <tr>
                <td className="CreateRecipeName" colSpan="2">
                  <label className="CreateRecipeNameLabel">{t("local_recipe_name")}:</label>
                  <input type="text" className="CreateRecipeNameText" id="recipeName" name="recipeName" defaultValue={checkRecipeName(recipe.title)} onChange={(event) => saveNameToStorage(event)} />
                </td>
              </tr>
              <tr>
                <br />
              </tr>
              { selectedClientUserId !== null && (
                <React.Fragment>
                  <tr>
                    <td><label className="CreateRecipeStartDateLabel">{t("local_starting_from")}</label></td>
                    <td><label className="CreateRecipeEndDateLabel">{t("local_ending_to")}</label>  </td>
                  </tr>
                  <tr>
                    <td><input type="date" id="startDate" name="startDate" className="recipeStartDate" defaultValue={checkStartDate(recipe.start_date)} onChange={saveStartDateStorage} /></td>
                    <td><input type="date" id="endDate" name="endDate" defaultValue={checkEndDate(recipe.end_date)} onChange={saveEndDateToStorage} /></td>
                  </tr>
                </React.Fragment>
              )}
              {/*<tr>
                <td className="notificationCheckBoxRow">
                  <input className="checkBox" type="checkbox" id="sendNotificationCheckBox" name="sendNotification" value="sendNotification" onChange={changeCheckBoxState} />
                  <label className="checkBoxLabel" id="sendNotificationCheckBoxLabel" htmlFor="sendNotificationCheckBox">{t("local_send_recipe_notification")}</label>
                </td>
              </tr>*/}
              <tr>
                <br />
              </tr>
              <tr>
                <td className="reasonForVisitRow" colSpan="2">
                  <label className="reasonForVisitLabel">{t("local_reason_for_visit")}:</label>
                  <input type="text" id="reasonForVisitText" className="reasonForVisitText" />
                </td>
              </tr>
            </table>
            <table className="CreateOrEditRecipeMenu-right">
              <tr className="EditGamesButtons">
                <button id="addGameButton" type="button" className="btn btn-primary btn-add-game" onClick={() => showGames(navigate, setNavigateContext)} >
                  {t("local_add_exercise")}
                </button>
                <button id="getTemplatesButton" type="button" className="btn btn-primary btn-get-templates" onClick={() => getTemplates(navigate, setNavigateContext, gameLinks, setTempGameLinks)} >
                  {t("local_get_template")}
                </button>
              </tr>
            </table>
          </div>
          <div className="CreateRecipes-Menu-right-bottom2">
            <label className="recipeExercisesLabel"l>{t("local_recipe_exercises")}</label>
            <br />
            { gameLinks.length > 0 && (
              gameLinks.map((game, index, gameLinks) => (
                <React.Fragment key={game.split("/")[0]}>
                  <div className="recipesListDiv" id={getGameDivId(game.split("/")[0])}>
                    <span id={getGameSpanId(game.split("/")[0])} className="modules-link modules-exercise-link2">
                      {getJsonGameName(game, t)}
                    </span>
                    <div id={getSelectDivId(game.split("/")[0])} className="timesPerDayDiv">
                      <select id={getSelectId(game.split("/")[0])} name="timesPerDaySelect" className="timesPerDaySelect" onChange={(event) => saveTimesPerDayToStorage(game.split("/")[0], event)}>
                        <option value="0">{t("local_not_set")}</option>
                        { timesPerDay.map(count => (
                          <option value={count}>{count}</option>
                        ))}
                      </select>
                      <label className="timesPerDayLabel">{t("local_times_a_day")}</label>
                    </div>
                    { (showParameters === null || showParameters === "true") && game.split("/")[1] !== "-1" && (
                      localStorage.getItem("selectedGameParameters_" + game.split("/")[0]) !== null ? (
                        <button className="ParametersEdited-Link recipeParameterEditLink btn btn-link btn-link-nopad" onClick={() => editRecipeParameters(navigate, setNavigateContext, game, t, getJsonGameId(game, t))}>
                          {t("local_edit_parameters")}
                        </button>
                      ) : (
                        <button className="recipeParameterEditLink btn btn-link btn-link-nopad" onClick={() => editRecipeParameters(navigate, setNavigateContext, game, t, getJsonGameId(game, t))}>
                          {t("local_edit_parameters")}
                        </button>
                      )
                    )}
                    <div className="recycleBinDiv">
                      <img src={require("../images/recycle_bin.png")} className="Dropdown-imagebutton" alt = "Menu" border = "0" onClick={() => setConfirm(game)}/>
                    </div>
                    <div className="arrowDiv">
                      <img src={require("../images/upArrow.png")} className="Dropdown-imagebutton" alt = "Menu" border = "0" onClick={() => swapGameUp(gameLinks, setGameLinks, index)}/>
                    </div>
                    <div className="arrowDiv">
                      <img src={require("../images/downArrow.png")} className="Dropdown-imagebutton" alt = "Menu" border = "0" onClick={() => swapGameDown(gameLinks, setGameLinks, index)}/>
                    </div>
                    <br />
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
          <div className="CreateRecipes-Menu-right-bottom2">
            { selectedRecipeId !== null ? (
              <React.Fragment>
                { selectedRecipeType === "draft" ? (
                  <React.Fragment>
                    { selectedClientUserId !== null && (
                      <React.Fragment>
                        <button id="saveButton" type="button" className="btn btn-primary btn-save" onClick={() => saveRecipe(navigate)} >
                          {t("local_send")}
                        </button>
                        <button id="saveButton" type="button" className="btn btn-primary btn-save2" onClick={() => saveRecipe(navigate, true, true)} >
                          {t("local_save_unfinished")}
                        </button>
                        <button id="saveButton" type="button" className="btn btn-primary btn-save3" onClick={() => saveRecipe(navigate, true)} >
                          {t("local_save_copy_of_draft")}
                        </button>
                      </React.Fragment>
                    )}
                    <button id="saveButton" type="button" className="btn btn-primary btn-save4" onClick={() => saveRecipe(navigate, false, false, true)} >
                      {t("local_save_as_template")}
                    </button>
                    { selectedClientUserId !== null && (
                      <button id="cancelRecipeButton" type="button" className="btn btn-primary btn-cancelRecipe" onClick={() => confirmRecipeCancel(navigate)} >
                        {t("local_remove_draft")}
                      </button>
                    )}
                  </React.Fragment>
                ): (
                  <React.Fragment>
                    { selectedClientUserId !== null && (
                      <React.Fragment>
                        <button id="saveButton" type="button" className="btn btn-primary btn-save" onClick={() => saveRecipe(navigate)} >
                          {t("local_update")}
                        </button>
                        <button id="saveButton" type="button" className="btn btn-primary btn-save3" onClick={() => saveRecipe(navigate, true)} >
                          {t("local_save_unfinished")}
                        </button>
                      </React.Fragment>
                    )}
                    <button id="saveButton" type="button" className="btn btn-primary btn-save4" onClick={() => saveRecipe(navigate, false, false, true)} >
                      {t("local_save_as_template")}
                    </button>
                    { selectedClientUserId !== null && (
                      <button id="cancelRecipeButton" type="button" className="btn btn-primary btn-cancelRecipe" onClick={() => confirmRecipeCancel(navigate)} >
                        {t("local_cancel_recipe")}
                      </button>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                { selectedClientUserId !== null && (
                  <React.Fragment>
                    <button id="saveButton" type="button" className="btn btn-primary btn-save" onClick={() => saveRecipe(navigate)} >
                      {t("local_send")}
                    </button>
                    <button id="saveButton" type="button" className="btn btn-primary btn-save3" onClick={() => saveRecipe(navigate, true)} >
                      {t("local_save_unfinished")}
                    </button>
                  </React.Fragment>
                )}
                <button id="saveButton" type="button" className="btn btn-primary btn-save4" onClick={() => saveRecipe(navigate, false, false, true)} >
                  {t("local_save_as_template")}
                </button>
              </React.Fragment>
            )}
            { selectedClientUserId !== null && (
              <button id="cancelButton" type="button" className="btn btn-primary btn-cancel" onClick={() => showRecipes(navigate)} >
                {t("local_cancel")}
              </button>
            )}
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default NewRecipe;
