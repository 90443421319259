import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import categories from "./Categories.json";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var org = localStorage.getItem("org");

var createOrgClient = localStorage.getItem("createOrgClient");
var selectedOrgName = localStorage.getItem("selectedOrgName");
var userId = localStorage.getItem("userId");
var clientUserId = localStorage.getItem("clientUserId");

var showClients = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

var token = localStorage.getItem("token");
var navigateFromClient = localStorage.getItem("navigateFromClient");
var selectedClientName = localStorage.getItem("selectedClientName");
var selectedClientOrgs = localStorage.getItem("selectedClientOrgs");
var userLanguage = localStorage.getItem("userLanguage");

function filterUsers(event) {
  if (event.role === "Pro" || event.role === "Admin" || event.role === "Retailer" || event.role === "Super") {
    return true;
  }

  return false;
}

function cancelSearchResults() {
  if (navigateFromClient === "true") {
    window.location.replace(window.location.origin + "/client");
  }
  else {
    window.location.replace(window.location.origin + "/clientselection");
  }
}

function fillHiddenSearchBar1(event) {
  var searchBar1 = document.getElementById("MenuSearchbar");
  var searchBar2 = document.getElementById("MenuSearchbar2");

  if (searchBar1 !== null && searchBar1 !== undefined &&
    searchBar2 !== null && searchBar2 !== undefined) {
    searchBar1.value = searchBar2.value;
  }
}

function fillHiddenSearchBar2(event) {
  var searchBar1 = document.getElementById("MenuSearchbar");
  var searchBar2 = document.getElementById("MenuSearchbar2");

  if (searchBar1 !== null && searchBar1 !== undefined &&
    searchBar2 !== null && searchBar2 !== undefined) {
    searchBar2.value = searchBar1.value;
  }
}

function findName(item, gameId) {
  if (!item.type) { // Categories
      var nameId = "";
      for (let subitem of item) {
          var nameId = findName(subitem, gameId)
          if (nameId != '')
              return nameId;
      }
      return nameId;
  }
  else if (item.type === "folder" || item.type === "header") { // folders and headers
      var nameId = "";
      for (let subitem of item.sub) {
          nameId = findName(subitem, gameId)
          if (nameId != "")
              return nameId;
      }
      return nameId;

  }
  else if (item.type === "game" || item.type === "recipe") { // games and recipes
      if (item.gameId === gameId)
          return item.id;
      else
          return '';

  }
}

function showGame(t, id, navigate, setNavigateContext) {
  var gameName = t(findName(categories.category, id.toString()));

  localStorage.setItem("selectedGameId", id);
  localStorage.setItem("selectedGameName", gameName);


  /*localStorage.setItem("selectedRecipeId", id);
  localStorage.setItem("selectedRecipeType", type);
  localStorage.setItem("selectedRecipeName", name);
  localStorage.setItem("selectedRecipeEndDate", checkDate(recipeEndDate));
  localStorage.setItem("selectedRecipeStartDate", checkDate(recipeStartDate));*/
  setNavigateContext(true);
  navigate("/resultByGameShow");
}

function checkEndDate(endDate) {
  var dateNowUtc = new Date().toUTCString();
  var dateNow = new Date(dateNowUtc).valueOf();
  var endDateNow = new Date(endDate).valueOf();

  if (dateNow > endDateNow) {
    return false;
  }

  return true;
}

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function checkDate(date) {
  if (date.split("T") === undefined) {
    return date;
  }
  else {
    if (date.split("T")[1].startsWith("22")) {
      var newDateRaw = new Date(date.split("T")[0]);

      newDateRaw = newDateRaw.addDays(1);

      var newDateYear = newDateRaw.getFullYear();
      var newDateMonth = newDateRaw.getMonth() + 1;
      var newDateDay = newDateRaw.getDate();

      if (newDateDay >= 1 && newDateDay <= 9) {
        newDateDay = "0" + newDateDay;
      }

      if (newDateMonth >= 1 && newDateMonth <= 9) {
        newDateMonth = "0" + newDateMonth;
      }

      var newDate = newDateYear + "-" + newDateMonth + "-" + newDateDay;

      return newDate;
    }

    return date.split("T")[0];
  }
}

function checkIfEarlierDate(dateNow, dateCompare) {
  var dateNow = new Date(dateNow).valueOf();
  var dateCompare = new Date(dateCompare).valueOf();

  if (dateNow > dateCompare) {
    return true;
  }

  return false;
}

function checkIfDeletedRecipe(type) {
  if (type === "deleted") {
    return false;
  }

  return true;
}

function checkRecipeType(t, type) {
  if (type !== null) {
    return t("local_" + type);
  }
  else {
    return "Unknown";
  }
}

function navigateToClientSelection() {
  if (navigateFromClient === "true") {
    window.location.replace(window.location.origin + "/client");
  }
  else {
    window.location.replace(window.location.origin + "/clientselection");
  }
}

function returnToMenu() {
  window.location.replace(window.location.origin + "/menu");
}

function navigateToClientSelection2(navigate) {
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function navigateToResultByGame(navigate) {
  navigate("/resultByGame");
}

function navigateToClient(navigate) {
  navigate("/client");
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const ResultByGame = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const cannotCreateRecipeOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_client_not_selected1")}</h1>
        <button className="confirmButton" onClick={() => { returnToMenu(); onClose();}}>{t("local_return_to_menu")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  if (clientUserId === null || clientUserId === "") {
    confirmAlert(cannotCreateRecipeOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  function searchGames(event) {
    var clientElements = document.getElementsByClassName("RecipeItemRow");
    var setFirstClient = false;

    for (let i = 0; i < clientElements.length; i++) {

      if (event.target.value.trim().length < 2 && clientElements[i].getAttribute("data-hidden") === "true") {
        clientElements[i].setAttribute("style", "display:block !important");
        clientElements[i].setAttribute("data-hidden", "false !important");
      }

      if (event.target.value.trim() !== "" && ( clientElements[i].getAttribute("data-recipe-name").toLowerCase().includes(event.target.value.trim().toLowerCase()) )) {
        clientElements[i].setAttribute("style", "color:blue !important");
        clientElements[i].setAttribute("data-hidden", "false !important");
        if (setFirstClient === false) {
          setFirstClient = true;
        }
      }
      else if (event.target.value.trim().length >= 2) {
        clientElements[i].setAttribute("style", "display:none !important");
        clientElements[i].setAttribute("data-hidden", "true !important");
      }
      else {
        clientElements[i].setAttribute("style", "color:black !important");
      }
    }
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  //const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (showClients !== "true" && data.role !== "Pro") {
          window.location.replace(window.location.origin + "/account");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        //setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const [userOrgs, setUserOrgs] = useState([]);

  const fetchUserOrgData = async () => {

    if (createOrgClient === null) {
      try {
        var orgData = null;

        if (selectedClientOrgs != null) {
          var orgList = [];
          var orgIdList = [];

          if (selectedClientOrgs.includes(",")) {
            orgIdList = selectedClientOrgs.split(",");

            var expiredUser = false;

            for (var i = 0; i < orgList.length; i++) {
              const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + orgIdList[i],
              {
                method: "GET",
                headers: { "Authorization": "Bearer " + token },
              });

              console.log(response);

              const data = await response.json();

              if (data.Message !== "AuthError_LicenseExpired") {
                orgData = {"name": "Expired organisation"};
                setUserOrgs(orgData);
                expiredUser = true;
              }
              else {
                orgList.push(data.organisations[0]);
              }
            }

            if (expiredUser === false) {
              setUserOrgs(orgList);
            }
          }
          else {
            const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + selectedClientOrgs,
            {
              method: "GET",
              headers: { "Authorization": "Bearer " + token },
            });

            console.log(response);

            const data2 = await response.json();

            if (data2.Message === "AuthError_LicenseExpired") {
              orgData = {"name": "Expired organisation"};
              setUserOrgs(orgData);
            }
            else {
              setUserOrgs(data2.organisations[0]);
            }
          }
        }
        else {
          if (createOrgClient === "true") {
            org = localStorage.getItem("selectedOrgId");
          }

          const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + org,
          {
            method: "GET",
            headers: { "Authorization": "Bearer " + token },
          });

          console.log(response);

          const data3 = await response.json();

          if (data3.Message === "AuthError_LicenseExpired") {
            orgData = {"name": "Expired organisation"};
            setUserOrgs(orgData);
          }
          else {
            setUserOrgs(data3.organisations[0]);
          }
        }

      } catch (err) {

        const organisationFetchFailedOptions = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_failed_to_fetch_org_data")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(organisationFetchFailedOptions);
      }
    }
  }

  const [results, setResults] = useState([]);
  const [uniqueResults, setUniqueResults] = useState([]);
  const [unauthorized, setUnauthorized] = useState(true);

  const fetchResults = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/result/" + clientUserId,
      {
        headers: { "Authorization": "Bearer " + token },
      });

      console.log(response);

      if (response.status === 403) {
        setUnauthorized(true);
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        setUnauthorized(false);

        var resultsTable = data.results;

        setResults(resultsTable);

        var uniqueResultsTable = [];
        var duplicateIdTable = [];

        for (var i = 0; i < resultsTable.length; i++) {

          for (var j = 0; j < resultsTable.length; j++) {

            if (!duplicateIdTable.includes(resultsTable[i].game_id) &&
                resultsTable[i].id !== resultsTable[j].id && resultsTable[i].game_id === resultsTable[j].game_id) {

              const response2 = await fetch(baseUrl + "/api/v1/result/" + clientUserId + "/game/" + resultsTable[i].game_id,
              {
                headers: { "Authorization": "Bearer " + token },
              });

              console.log(response2);

              if (response2.status === 403) {
                confirmAlert(tokenExpiredOptions);
              }
              else {
                const data2 = await response2.json();

                var resultsByGame = data2.results;

                for (var k = 0; k < resultsByGame.length; k++) {
                  var counter = 0;

                  for (var l = 0; l < resultsByGame.length; l++) {
                    if (checkIfEarlierDate(resultsByGame[k].executed, resultsByGame[l].executed)) {
                      ++counter;

                      if (counter === resultsByGame.length - 1) {
                        uniqueResultsTable.push(resultsByGame[k]);
                      }
                    }
                  }
                }

                duplicateIdTable.push(resultsTable[i].game_id);
              }

              break;
            }
          }

          if (!duplicateIdTable.includes(resultsTable[i].game_id)) {
            uniqueResultsTable.push(resultsTable[i]);
          }
        }

        uniqueResultsTable.sort(function(date1, date2) {
          return new Date(date2.executed) - new Date(date1.executed);
        });

        setUniqueResults(uniqueResultsTable);
      }
      else {
        setUnauthorized(true);
      }

    } catch (err) {
      const resultFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_results")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(resultFetchFailedOptions);
    }
  }


  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    if (clientUserId !== null && clientUserId !== "") {
      await fetchResults();
    }
    await fetchUserOrgData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkNumberOfResults(gameId) {
    var numberOfResults = 0;

    for (var i = 0; i < results.length; i++) {
      if (results[i].game_id === gameId) {
        ++numberOfResults;
      }
    }

    return numberOfResults.toString();
  }

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  if (navigateContext !== true) {
    localStorage.removeItem("gameLinks" + clientUserId);
  }

  const navigate = useNavigate();

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1);

  return (
    <div className="CreateOrEditClient-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
            {t("local_button_ingame_menu")}
          </button>
          <span> – </span>
          { createOrgClient === "true" && (
            <React.Fragment>
              <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
                {t("local_organisation")}
              </button>
              <span> – </span>
            </React.Fragment>
          )}
          <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientSelection2(navigate)}>
            {t("local_clients_list")}
          </button>
          <span> – </span>
          { navigateFromClient !== null && (
            <React.Fragment>
              <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClient(navigate)}>
                {t("local_client")}
              </button>
              <span> – </span>
            </React.Fragment>
          )}
          <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToResultByGame(navigate)}>
            {t("local_result_by_game")}
          </button>
      </div>
      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" onChange={(event) => { searchGames(event); fillHiddenSearchBar2(event);}} /><br />
          <strong>{t("local_clients")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditclient">{t("local_create_new")}</a>
          </span>
          <p className="ResultByGame-Menu-left-footer"p>{t("local_search_game_results_footer")}</p>
        </div>
        <div className="SearchResult-Menu-right">
          <div className="Menu-right-top">
            <table className="Menu-right-top-table">
              <tr>
                <td className="Menu-right-top-header-upper cnmrth-client">{t("local_client")}: {selectedClientName}</td>
              </tr>
              <tr>
                { createOrgClient === "true" ? (
                  <th className="Menu-right-top-header-upper cnmrth-organisation">{t("local_organisations2")}: {selectedOrgName}</th>
                ) : (
                  <th className="Menu-right-top-header-upper cnmrth-organisation">{t("local_organisations2")}: {userOrgs.name}</th>
                )}
              </tr>
            </table>
          </div>
          <span className="Menu-line"></span>
          <div className="Recipes-Menu-right-bottom">
            { (unauthorized === false && uniqueResults.length > 0) && (
              <table className="Menu-right-bottom-table-lower">
                <tr>
                  <td className="rd-row1-header1">{t("local_name")}</td>
                  <td className="rd-row3-header3">{t("local_number_of_results")}</td>
                  <td className="rd-row2-header2">{t("local_date")}</td>
                </tr>
                { uniqueResults.map(result => (
                  <React.Fragment key={result.game_id}>
                    <tr className="RecipeItemRow" data-recipe-name={t(findName(categories.category, result.game_id.toString()))}>
                      <td className="rd-row1-item1">
                        {t(findName(categories.category, result.game_id.toString()))}
                      </td>
                      <td className="rd-row2-item1">
                        {checkNumberOfResults(result.game_id)}
                      </td>
                      <td className="rd-row3-item1">
                        {checkDate(result.executed)}
                      </td>
                      { checkNumberOfResults(result.game_id) !== "0" && (
                        <td>
                          <button className="recipeShowLink btn btn-link btn-link-nopad" onClick={() => showGame(t, result.game_id, navigate, setNavigateContext)}>
                            {t("local_show")}
                          </button>
                        </td>
                      )}

                    </tr>
                  </React.Fragment>
                ))}
              </table>
            )}

            <input type="text" id="MenuSearchbar2" onChange={(event) => { searchGames(event); fillHiddenSearchBar1(event);}} /><br />

            <button className="btn btn-primary btn-cancelSearchResults" onClick={cancelSearchResults}>
              {t("local_cancel")}
            </button>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default ResultByGame;
