import React, { useState, useContext, useEffect } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var token = localStorage.getItem("token");
var createOrgClient = localStorage.getItem("createOrgClient");
var navigateFromClient = localStorage.getItem("navigateFromClient");
var selectedClientUserId = localStorage.getItem("selectedClientUserId");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

var showClients = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

function checkEndDate(endDate, recipe) {
  var date = new Date();
  var endDateNow = new Date(endDate).getTime();

  var yearNow = date.getUTCFullYear();
  var monthNow = date.getUTCMonth() + 1;
  var dayNow = date.getUTCDate();
  var hoursNow = date.getUTCHours();
  var minutesNow = date.getUTCMinutes();
  var secondsNow = date.getUTCSeconds();

  if (monthNow >= 1 && monthNow <= 9) {
    monthNow = "0" + monthNow;
  }

  if (dayNow >= 1 && dayNow <= 9) {
    dayNow = "0" + dayNow;
  }

  if (hoursNow >= 0 && hoursNow <= 9) {
    hoursNow = "0" + hoursNow;
  }

  if (minutesNow >= 0 && minutesNow <= 9) {
    minutesNow = "0" + minutesNow;
  }

  if (secondsNow >= 0 && secondsNow <= 9) {
    secondsNow = "0" + secondsNow;
  }

  var dateNowUtc = yearNow + "-" + monthNow + "-" + dayNow + "T" + hoursNow + ":" + minutesNow + ":" + secondsNow + "Z";

  var dateNow = new Date(dateNowUtc).getTime();

  if (dateNow > endDateNow) {
    return false;
  }

  return true;
}

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function checkDate(date) {
  if (date.split("T") === undefined) {
    return date;
  }
  else {
    if (date.split("T")[1].startsWith("22")) {
      var newDateRaw = new Date(date.split("T")[0]);

      newDateRaw = newDateRaw.addDays(1);

      var newDateYear = newDateRaw.getFullYear();
      var newDateMonth = newDateRaw.getMonth() + 1;
      var newDateDay = newDateRaw.getDate();

      if (newDateDay >= 1 && newDateDay <= 9) {
        newDateDay = "0" + newDateDay;
      }

      if (newDateMonth >= 1 && newDateMonth <= 9) {
        newDateMonth = "0" + newDateMonth;
      }

      var newDate = newDateYear + "-" + newDateMonth + "-" + newDateDay;

      return newDate;
    }

    return date.split("T")[0];
  }
}

function checkIfDeletedRecipe(type) {
  if (type === "deleted") {
    return false;
  }

  return true;
}

function checkRecipeType(t, type) {
  if (type !== null) {
    return t("local_" + type);
  }
  else {
    return "Unknown";
  }
}

function fillHiddenSearchBar1(event) {
  var searchBar1 = document.getElementById("MenuSearchbar");
  var searchBar2 = document.getElementById("MenuSearchbar2");

  if (searchBar1 !== null && searchBar1 !== undefined &&
    searchBar2 !== null && searchBar2 !== undefined) {
    searchBar1.value = searchBar2.value;
  }
}

function fillHiddenSearchBar2(event) {
  var searchBar1 = document.getElementById("MenuSearchbar");
  var searchBar2 = document.getElementById("MenuSearchbar2");

  if (searchBar1 !== null && searchBar1 !== undefined &&
    searchBar2 !== null && searchBar2 !== undefined) {
    searchBar2.value = searchBar1.value;
  }
}

function editRecipe(id, type, navigate, setNavigateContext) {
  localStorage.setItem("selectedRecipeId", id);
  localStorage.setItem("selectedRecipeType", type);
  setNavigateContext(true);
  navigate("/newrecipe");
}

function returnToMenu() {
  window.location.replace(window.location.origin + "/menu");
}

function navigateToClientSelection(navigate) {
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function navigateToClientCreation(navigate) {
  navigate("/createoreditclient");
}

function navigateToRecipes(navigate) {
  navigate("/createoreditrecipe");
}

function navigateToClient(navigate) {
  navigate("/client");
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const CreateOrEditRecipe = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const cannotCreateRecipeOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_client_not_selected1")}</h1>
        <button className="confirmButton" onClick={() => { returnToMenu(); onClose();}}>{t("local_return_to_menu")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  if (selectedClientUserId === null || selectedClientUserId === "") {
    confirmAlert(cannotCreateRecipeOptions);
  }

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  window.onpopstate=function() {
    setNavigateContext(false);
    window.location.reload();
  }

  if (navigateContext !== true) {
    localStorage.removeItem("gameLinks" + selectedClientUserId);
  }

  localStorage.removeItem("selectedRecipeId");
  localStorage.removeItem("selectedRecipeType");
  localStorage.removeItem("selectedRecipeName");
  localStorage.removeItem("selectedRecipeStartDate");
  localStorage.removeItem("selectedRecipeEndDate");
  localStorage.removeItem("templateRecipeId");

  for (var i = 0; i < localStorage.length; i++){
    if (localStorage.key(i).startsWith("timesPerDaySelect_")) {
      localStorage.removeItem(localStorage.key(i));
    }
  }

  for (var j = 0; j < localStorage.length; j++){
    if (localStorage.key(j).startsWith("selectedGameParameters_")) {
      localStorage.removeItem(localStorage.key(j));
    }
  }

  function searchRecipes(event) {
    var clientElements = document.getElementsByClassName("RecipeItemRow");
    var setFirstClient = false;

    for (let i = 0; i < clientElements.length; i++) {

      if (event.target.value.trim().length < 2 && clientElements[i].getAttribute("data-hidden") === "true") {
        clientElements[i].setAttribute("style", "display:block !important");
        clientElements[i].setAttribute("data-hidden", "false !important");
      }

      if (event.target.value.trim() !== "" && ( clientElements[i].getAttribute("data-recipe-name").toLowerCase().includes(event.target.value.trim().toLowerCase()) )) {
        clientElements[i].setAttribute("style", "color:blue !important");
        clientElements[i].setAttribute("data-hidden", "false !important");
        if (setFirstClient === false) {
          setFirstClient = true;
        }
      }
      else if (event.target.value.trim().length >= 2) {
        clientElements[i].setAttribute("style", "display:none !important");
        clientElements[i].setAttribute("data-hidden", "true !important");
      }
      else {
        clientElements[i].setAttribute("style", "color:black !important");
      }
    }
  }

  const [recipes, setRecipes] = useState([]);

  const fetchUserData = async () => {

    try {
      const response = await fetch(baseUrl + "/api/v1/recipe/" + selectedClientUserId,
      {
        headers: { "Authorization": "Bearer " + token,
        "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 200) {
        const data = await response.json();

        setRecipes(data.recipes);
      }
      else if (response.status === 401) {
        const recipeFetchFailedOptions = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_no_access_to_recipes")}</h1>
              <button className="confirmButton" onClick={returnToMenu}>{t("local_return_to_menu")}</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(recipeFetchFailedOptions);
      }
    } catch (err) {

      const recipeFetchFailedOptions2 = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_recipe_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(recipeFetchFailedOptions2);
    }

  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (showClients !== "true" && data.role !== "Pro") {
          window.location.replace(window.location.origin + "/account");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    await fetchUserData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(2);

  return (
    <div className="CreateOrEditRecipe-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        { createOrgClient === "true" && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
              {t("local_organisation")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientSelection(navigate)}>
          {t("local_clients_list")}
        </button>
        <span> – </span>
        { navigateFromClient !== null && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClient(navigate)}>
              {t("local_client")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientCreation(navigate)}>
          {t("local_create_or_edit_client")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToRecipes(navigate)}>
          {t("local_recipes")}
        </button>
      </div>

      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" onChange={(event) => { searchRecipes(event); fillHiddenSearchBar2(event);}} /> <br />
          <strong>{t("local_recipes")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditrecipe">{t("local_create_new")}</a>
          </span>
          <p className="Recipes-Menu-left-footer"p>{t("local_create_or_edit_recipe_footer")}</p>
        </div>
        <div className="Recipes-Menu-right">
          <div className="CreateRecipes-menu-right-top">
            <table className="Menu-right-top-table3">
              <tr>
                <td className="Menu-right-top-header cnemrth-general"><a className="Menu-right-top-header-link" href="./createoreditclient">{t("local_general")}</a></td>
                <td className="Menu-right-top-header cnemrth-recipes"><a className="Menu-right-top-header-link menu-header-link-selected" href="./createoreditrecipe">{t("local_recipes")}</a></td>
                {/*<td className="Menu-right-top-header cnemrth-progress"><a className="Menu-right-top-header-link" href="./createnewprogress">{t("local_progress")}</a></td>*/}
              </tr>
            </table>
          </div>
          <span className="Menu-line"></span>
          <div className="CreateRecipes-menu-right-top2">
            <table className="Menu-right-top-table-row2">
              <tr>
                <td className="Menu-right-top-header cnemrth-history"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad">
                  {t("local_history")}
                </button></td>
                <td className="Menu-right-top-header cnemrth-condition"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad">
                  {t("local_condition")}
                </button></td>
                <td className="Menu-right-top-header cnemrth-type"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad">
                  {t("local_recipe_type")}
                </button></td>
                <td className="Menu-right-top-header cnemrth-status"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad">
                  {t("local_status")}
                </button></td>
               {/*<td className="Menu-right-top-header cnemrth-ctscore"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad">
                  {t("local_ct_score")}
                </button></td>*/}
              </tr>
              <tr>
                <td colspan="5">
                  <span className="Menu-line2"></span>
                </td>
              </tr>
              { recipes.length > 0 && (
                  recipes.map(recipe => (
                    <React.Fragment key={recipe.id}>
                      { checkIfDeletedRecipe(recipe.type) === true && (
                        <tr className="RecipeItemRow" data-recipe-name={recipe.title}>
                          { checkEndDate(recipe.end_date, recipe) === true ? (
                            <React.Fragment>
                              <td className="coerd-row1-item1 underline">
                                {checkDate(recipe.start_date)}
                                /
                                {checkDate(recipe.end_date)}
                              </td>
                              <td className="coerd-row2-item1" >Unknown</td>
                              <td className="coerd-row3-item1">
                                {recipe.title}
                              </td>
                              <td className="coerd-row4-item1">
                                {checkRecipeType(t, recipe.type)}
                              </td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <td className="Expired-Recipe coerd-row1-item1 underline">
                                {checkDate(recipe.start_date)}
                                /
                                {checkDate(recipe.end_date)}
                              </td>
                              <td className="Expired-Recipe coerd-row2-item1" >Unknown</td>
                              <td className="Expired-Recipe coerd-row3-item1">
                                {recipe.title}
                              </td>
                              <td className="Expired-Recipe coerd-row4-item1">
                                {checkRecipeType(t, recipe.type)}
                              </td>
                            </React.Fragment>
                          )}
                          {/*<th className="coerd-row5-item1">Unknown</th>*/}
                          <td className="coerd-row6-item1">
                            <button className="recipeEditLink btn btn-link btn-link-nopad" onClick={() => editRecipe(recipe.id, recipe.type, navigate, setNavigateContext)}>
                              {t("local_edit")}
                            </button>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
              )}
            </table>
          </div>

          <div className="Recipes-Menu-right-bottom">
            <table className="coerd-newrecipe-table">
              <tr>
                <td className="ed-row1-item1 reciperow"><a className="Menu-right-top-createoreditrecipe" href="/newrecipe">{t("local_new_recipe")}</a></td>
              </tr>
            </table>

            <input type="text" id="MenuSearchbar2" onChange={(event) => { searchRecipes(event); fillHiddenSearchBar1(event);}} /><br />
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default CreateOrEditRecipe;
