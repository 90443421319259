import React, { useContext } from "react";
import _ from "lodash";
import { GameContext, ExerciseContext } from "../App";
import categories from "./Categories.json";
import { useTranslation } from 'react-i18next';
import { Checkmark } from 'react-checkmark';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

var selectedClientId = localStorage.getItem("selectedClientUserId");

const selectExercise = (gameId, gameIntId, setGameLinks, gameLinks, dataId) => (event) => {
  var gameLink = gameId + "/" + gameIntId + "/" + dataId.replaceAll(",", "/");

  setGameLinks(gameLinks.indexOf(gameLink) >= 0 ? _.reject(gameLinks, (item) => item === (gameLink)) : [...gameLinks, gameLink]);
}

const addExercise = (gameId, gameIntId, setGameLinks, gameLinks, dataId) => (event) => {
  var gameLink = gameId + "/" + gameIntId + "/" + dataId.replaceAll(",", "/");

  setGameLinks(gameLinks.indexOf(gameLink) < 0 && [...gameLinks, gameLink]);
}

const removeExercise = (gameId, gameIntId, setGameLinks, gameLinks, dataId) => (event) => {
  var gameLink = gameId + "/" + gameIntId + "/" + dataId.replaceAll(",", "/");

  setGameLinks(gameLinks.indexOf(gameLink) >= 0 && _.reject(gameLinks, (item) => item === (gameLink)));
}

function showInfo(t, videoName) {
  var textName = videoName.replace(".mov", "");

  const showInfoOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='info-custom-ui'>
        <h1>{t("local_" + textName)}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  confirmAlert(showInfoOptions);
}

function showInfo2(t, gameId) {
  const showInfoOptions2 = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='info-custom-ui'>
        <h1>{t(gameId + "_INFO")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  confirmAlert(showInfoOptions2);
}

function showExercise(videoName) {
  localStorage.setItem("videoName", videoName);
  window.location.replace(window.location.origin + "/video");
}

function showRecipeExercise(videoName) {
  localStorage.setItem("recipeSelected", true);
  localStorage.setItem("videoName", videoName);
  window.location.replace(window.location.origin + "/video");
}

function findFromGameLinks(gameLinks, gameId) {
  for (var i = 0; i < gameLinks.length; i++) {
    if (gameLinks[i].startsWith(gameId + "/")) {
      return true;
    }
  }

  return false;
}

function renderGameRow(game, gameLinks, setGameLinks, t) {
  return (
    <tr className={"gameFlexRow mfad1-row1" + (findFromGameLinks(gameLinks, game.id) === true ? " selected-modules-link" : "")}>
      <td className="mfad1-row1-item1">
        <button
          className="modules-link modules-exercise-link btn btn-link btn-link-nopad"
          data-id={game.id}
          onClick={selectExercise(game.id, game.gameId, setGameLinks, gameLinks, game.dataId)}
          >{t(game.id)}
        </button>
      </td>
      <td>
        <img src={require("../images/info-icon.png")} className="ExerciseList-info-icon" alt = "Info" border = "0" onClick={() => showInfo(t, game.video)}/>
      </td>
      { findFromGameLinks(gameLinks, game.id) === true ? (
        <React.Fragment>
          <td className="mfad1-row1-item2">
            <button
              className="modules-link modules-settings-link btn btn-link btn-link-nopad"
              data-id={1}
              onClick={removeExercise(game.id, game.gameId, setGameLinks, gameLinks, game.dataId)}
              ><span className="addGameSpan"><div className="addGameCheckMark"><Checkmark size='small' /></div> {t("local_remove")}</span>
            </button>
          </td>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <td className="mfad1-row1-item2">
            <button
              className="modules-link modules-settings-link btn btn-link btn-link-nopad"
              data-id={1}
              onClick={addExercise(game.id, game.gameId, setGameLinks, gameLinks, game.dataId)}
              ><span className="addGameSpan2">{t("local_add")}</span>
            </button>
          </td>
        </React.Fragment>
      )}
      <td className="mfad1-row1-item3">
        <button
          className="modules-link modules-settings-link btn btn-link btn-link-nopad"
          data-id={1}
          onClick={() => showRecipeExercise(game.video)}
          >{t("local_view")}
        </button>
      </td>
      {/*<td className="mfad1-row1-item4">
        <button
          className="modules-link modules-settings-link btn btn-link btn-link-nopad"
          data-id={1}
          >{t("local_standard")}
        </button>
      </td>*/}
    </tr>
  )
}

function renderGameRowLibrary(game, gameLinks, setGameLinks, t) {
  return (
    <tr className="gameFlexRow mfad1-row1">
      <td className="mfad1-row1-item1">
        <button
          className="modules-link modules-exercise-link btn btn-link btn-link-nopad"
          data-id={game.id}
          onClick={() => showExercise(game.video)}
          >{t(game.id)}
        </button>
      </td>
      {/*<td className="mfad1-row1-item4">
        <button
          className="modules-link modules-settings-link btn btn-link btn-link-nopad"
          data-id={1}
          >{t("local_standard")}
        </button>
      </td>*/}
    </tr>
  )
}

function renderTableRowsLibrary(gameListJson, gameLinks, setGameLinks, t) {
  return gameListJson.filter(({type}) => type === 'game' || type === "header").map(game =>
    <React.Fragment key={game.id}>
      { Array.isArray(game.sub) ? (
        <React.Fragment>
          <tr className={"mfad1-row1" + (findFromGameLinks(gameLinks, game.id) === true ? " selected-modules-link" : "")}>
            <td className="mfad1-row1-item1">
              <label className="modules-label"><strong>{t(game.id)}</strong></label>
            </td>
          </tr>
          {renderTableRowsLibrary(game.sub, gameLinks, setGameLinks, t)}
        </React.Fragment>
      ) : (
        renderGameRowLibrary(game, gameLinks, setGameLinks, t)
      )}
    </React.Fragment>
  )
}

function renderTableRowsGames(gameListJson, gameLinks, setGameLinks, t) {
  return gameListJson.map(game =>
    <React.Fragment key={game.id}>
      { Array.isArray(game.sub) ? (
        <React.Fragment>
          <tr>
            <td className="mfad1-row1-item1">
              <label className="modules-label"><strong>{t(game.id)}</strong> &nbsp; &nbsp; {t("local_number_of_games")}: {game.sub.length + 1}</label>
            </td>
          </tr>
          <tr className={"gameFlexRow mfad1-row1" + (findFromGameLinks(gameLinks, getRandomId(game.id)) === true ? " selected-modules-link" : "")}>
            <td className="mfad1-row1-item1">
              <button
                className="modules-link modules-exercise-link btn btn-link btn-link-nopad"
                data-id={getRandomId(game.id)}
                onClick={selectExercise(getRandomId(game.id), -1, setGameLinks, gameLinks, game.dataId)}
                >{t("local_random_game_id")}: {t(game.id)}
              </button>
            </td>
            <td>
              <img src={require("../images/info-icon.png")} className="ExerciseList-info-icon" alt = "Info" border = "0" onClick={() => showInfo(t, game.video)}/>
            </td>
            { findFromGameLinks(gameLinks, getRandomId(game.id)) === true ? (
              <React.Fragment>
                <td className="mfad1-row1-item2">
                  <button
                    className="modules-link modules-settings-link btn btn-link btn-link-nopad"
                    data-id={getRandomId(game.id)}
                    onClick={removeExercise(getRandomId(game.id), -1, setGameLinks, gameLinks, game.id)}
                    ><span className="addGameSpan"><div className="addGameCheckMark"><Checkmark size='small' /></div> {t("local_remove")}</span>
                  </button>
                </td>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <td className="mfad1-row1-item2">
                  <button
                    className="modules-link modules-settings-link btn btn-link btn-link-nopad"
                    data-id={1}
                    onClick={addExercise(getRandomId(game.id), -1, setGameLinks, gameLinks, game.id)}
                    ><span className="addGameSpan2">{t("local_add")}</span>
                  </button>
                </td>
              </React.Fragment>
            )}
          </tr>
          {renderTableRowsGames(game.sub, gameLinks, setGameLinks, t)}
        </React.Fragment>
      ) : (
        renderGameRow(game, gameLinks, setGameLinks, t)
      )}
    </React.Fragment>
  )
}

function getRandomId(selCategoryName) {
  return "GAME_RANDOM_" + selCategoryName.toUpperCase();
}

const ExerciseList = () => {
  const { gameLinks, setGameLinks } = useContext(GameContext);
  const { selectedExerciseCategory, exerciseStatus } = useContext(ExerciseContext);

  var selectedCategory = selectedExerciseCategory.split(",")[0];
  var selectedSubCategory = selectedExerciseCategory.split(",")[1];

  localStorage.setItem("gameLinks" + selectedClientId, gameLinks);

  var gameListJson = categories.category[selectedCategory].sub[selectedSubCategory].sub;

  const { t } = useTranslation();

  return (
    <div className="Modules-Menu-right-bottom">
      <table className="Menu-right-bottom-table-lower">
        { exerciseStatus === 3 && (
          renderTableRowsLibrary(gameListJson, gameLinks, setGameLinks, t)
        )}
        { exerciseStatus === 4 && (
          <React.Fragment>
            { _.filter(categories.category, ["dataId", selectedCategory]).map(selCategory => (
              <React.Fragment>
                <tr>
                  <td className="mfad1-row1-item1">
                    <label className="modules-label"><strong>{t(selCategory.id)}</strong></label>
                  </td>
                </tr>
                <tr className={"gameFlexRow mfad1-row1" + (findFromGameLinks(gameLinks, getRandomId(selCategory.id)) === true ? " selected-modules-link" : "")}>
                  <td className="mfad1-row1-item1">
                    <button
                      className="modules-link modules-exercise-link btn btn-link btn-link-nopad"
                      data-id={getRandomId(selCategory.id)}
                      onClick={selectExercise(getRandomId(selCategory.id), -1, setGameLinks, gameLinks, selCategory.dataId)}
                      >{t("local_random_game_id")}: {t(selCategory.id)}
                    </button>
                  </td>
                  <td>
                    <img src={require("../images/info-icon.png")} className="ExerciseList-info-icon" alt = "Info" border = "0" onClick={() => showInfo2(t, selCategory.id)}/>
                  </td>
                  { findFromGameLinks(gameLinks, getRandomId(selCategory.id)) === true ? (
                    <React.Fragment>
                      <td className="mfad1-row1-item2">
                        <button
                          className="modules-link modules-settings-link btn btn-link btn-link-nopad"
                          data-id={getRandomId(selCategory.id)}
                          onClick={removeExercise(getRandomId(selCategory.id), -1, setGameLinks, gameLinks, selCategory.dataId)}
                          ><span className="addGameSpan"><div className="addGameCheckMark"><Checkmark size='small' /></div> {t("local_remove")}</span>
                        </button>
                      </td>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <td className="mfad1-row1-item2">
                        <button
                          className="modules-link modules-settings-link btn btn-link btn-link-nopad"
                          data-id={1}
                          onClick={addExercise(getRandomId(selCategory.id), -1, setGameLinks, gameLinks, selCategory.dataId)}
                          ><span className="addGameSpan2">{t("local_add")}</span>
                        </button>
                      </td>
                    </React.Fragment>
                  )}
                </tr>
                { _.filter(selCategory.sub, ["dataId", selectedCategory + "," + selectedSubCategory]).map(selSubCategory => (
                  <React.Fragment>
                    <tr>
                      <td className="mfad1-row1-item1">
                        { selSubCategory.sub[0].type === "game" ? (
                          <label className="modules-label"><strong>{t(selSubCategory.id)}</strong> &nbsp; &nbsp; {t("local_number_of_games")}: {selSubCategory.sub.length + 1}</label>
                        ): (
                          <label className="modules-label"><strong>{t(selSubCategory.id)}</strong></label>
                        )}

                      </td>
                    </tr>
                    <tr className={"gameFlexRow mfad1-row1" + (findFromGameLinks(gameLinks, getRandomId(selSubCategory.id)) === true ? " selected-modules-link" : "")}>
                      <td className="mfad1-row1-item1">
                        <button
                          className="modules-link modules-exercise-link btn btn-link btn-link-nopad"
                          data-id={getRandomId(selSubCategory.id)}
                          onClick={selectExercise(getRandomId(selSubCategory.id), -1, setGameLinks, gameLinks, selSubCategory.dataId)}
                          >{t("local_random_game_id")}: {t(selSubCategory.id)}
                        </button>
                      </td>
                      <td>
                        <img src={require("../images/info-icon.png")} className="ExerciseList-info-icon" alt = "Info" border = "0" onClick={() => showInfo2(t, selSubCategory.id)}/>
                      </td>
                      { findFromGameLinks(gameLinks, getRandomId(selSubCategory.id)) === true ? (
                        <React.Fragment>
                          <td className="mfad1-row1-item2">
                            <button
                              className="modules-link modules-settings-link btn btn-link btn-link-nopad"
                              data-id={getRandomId(selSubCategory.id)}
                              onClick={removeExercise(getRandomId(selSubCategory.id), -1, setGameLinks, gameLinks, selSubCategory.dataId)}
                              ><span className="addGameSpan"><div className="addGameCheckMark"><Checkmark size='small' /></div> {t("local_remove")}</span>
                            </button>
                          </td>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <td className="mfad1-row1-item2">
                            <button
                              className="modules-link modules-settings-link btn btn-link btn-link-nopad"
                              data-id={1}
                              onClick={addExercise(getRandomId(selSubCategory.id), -1, setGameLinks, gameLinks, selSubCategory.dataId)}
                              ><span className="addGameSpan2">{t("local_add")}</span>
                            </button>
                          </td>
                        </React.Fragment>
                      )}
                    </tr>
                  </React.Fragment>
                ))}
              </React.Fragment>

            ))}

            {renderTableRowsGames(gameListJson, gameLinks, setGameLinks, t)}
          </React.Fragment>
        )}
      </table>
      {  selectedCategory === "0" ? (
        <p>{t("local_attention")}</p>
      ) : selectedCategory === "1" ? (
        <p>{t("local_memory")}</p>
      ) : selectedCategory === "2" ? (
        <p>{t("local_visual")}</p>
      ) : selectedCategory === "3" ? (
        <p>{t("local_executive")}</p>
      ) : (
        <p>{t("local_attention")}</p>
      )}
    </div>
  )
}

export default ExerciseList;
