import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import categories from "./Categories.json";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var org = localStorage.getItem("org");

var createOrgClient = localStorage.getItem("createOrgClient");
var selectedOrgName = localStorage.getItem("selectedOrgName");
var userId = localStorage.getItem("userId");
var clientUserId = localStorage.getItem("clientUserId");

var showClients = null;

var chartDataTemp = [];

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

var token = localStorage.getItem("token");
var navigateFromClient = localStorage.getItem("navigateFromClient");
var selectedClientName = localStorage.getItem("selectedClientName");
var selectedClientOrgs = localStorage.getItem("selectedClientOrgs");
var userLanguage = localStorage.getItem("userLanguage");
var selectedGameId = localStorage.getItem("selectedGameId");
var selectedGameName = localStorage.getItem("selectedGameName");

function filterUsers(event) {
  if (event.role === "Pro" || event.role === "Admin" || event.role === "Retailer" || event.role === "Super") {
    return true;
  }

  return false;
}

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function checkDate(date) {
  if (date.split("T") === undefined) {
    return date;
  }
  else {
    if (date.split("T")[1].startsWith("22")) {
      var newDateRaw = new Date(date.split("T")[0]);

      newDateRaw = newDateRaw.addDays(1);

      var newDateYear = newDateRaw.getFullYear();
      var newDateMonth = newDateRaw.getMonth() + 1;
      var newDateDay = newDateRaw.getDate();

      if (newDateDay >= 1 && newDateDay <= 9) {
        newDateDay = "0" + newDateDay;
      }

      if (newDateMonth >= 1 && newDateMonth <= 9) {
        newDateMonth = "0" + newDateMonth;
      }

      var newDate = newDateYear + "-" + newDateMonth + "-" + newDateDay;

      return newDate;
    }

    return date.split("T")[0];
  }
}

function checkDefaultSettings(t, value) {
  if (value === true) {
    return t("local_yes");
  }

  return t("local_no")
}

function findName(item, gameId) {
  if (!item.type) { // Categories
      var nameId = "";
      for (let subitem of item) {
          var nameId = findName(subitem, gameId)
          if (nameId != '')
              return nameId;
      }
      return nameId;
  }
  else if (item.type === "folder" || item.type === "header") { // folders and headers
      var nameId = "";
      for (let subitem of item.sub) {
          nameId = findName(subitem, gameId)
          if (nameId != "")
              return nameId;
      }
      return nameId;

  }
  else if (item.type === "game" || item.type === "recipe") { // games and recipes
      if (item.gameId === gameId)
          return item.id;
      else
          return '';

  }
}

function checkIfEarlierDate(dateNow, dateCompare) {
  var dateNow = new Date(dateNow).valueOf();
  var dateCompare = new Date(dateCompare).valueOf();

  if (dateNow > dateCompare) {
    return true;
  }

  return false;
}

function checkResultValue(t, value, key, gameId) {
  if (key === "amount_consept_of_group") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "item_amount") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "return") {
    if (value === "0") {
      return t("local_parameters_after_sorting");
    }
    else if (value === "1") {
      return t("local_parameters_pressing_button");
    }
  }
  else if (key === "right_answers_array") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "wrong_answers_array") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "stimulus_interval_min") {
    return value + " s";
  }
  else if (key === "stimulus_interval_max") {
    return value + " s";
  }
  else if (key === "reactions") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "average_time") {
    return value + " s";
  }
  else if (key === "standard_deviation") {
    return value + " s";
  }
  else if (key === "play_time") {
    return value + " s";
  }
  else if (key === "string_direction_upper_row") {
    if (value === "1") {
      return t("local_parameters_to_left");
    }
    else if (value === "2") {
      return t("local_parameters_to_right");
    }
  }
  else if (key === "string_direction_lower_row") {
    if (value === "1") {
      return t("local_parameters_to_left");
    }
    else if (value === "2") {
      return t("local_parameters_to_right");
    }
  }
  else if (key === "string_scroll_interval_upper_row") {
    return value + " s";
  }
  else if (key === "string_scroll_interval_lower_row") {
    return value + " s";
  }
  else if (key === "target_amount") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "string_scroll_interval") {
    return value + " s";
  }
  else if (key === "exercise_duration_minutes") {
    return value + " min";
  }
  else if (key === "string_direction") {
    if (value === "1") {
      return t("local_parameters_to_left");
    }
    else if (value === "2") {
      return t("local_parameters_to_right");
    }
  }
  else if (key === "answer_time" && gameId === "39") {
    if (value === "0") {
      return t("local_parameters_unlimited");
    }

    return value + " s";
  }
  else if (key === "answer_time") {
    return value + " s";
  }
  else if (key === "card_visibility_time") {
    return value + " s";
  }
  else if (key === "draw_count") {
    return value + " " + t("local_parameters_times");
  }
  else if (key === "numbers_visibility_time") {
    return value + " s";
  }
  else if (key === "row_amount" && gameId === "1") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "row_amount" && gameId === "4") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "row_amount" && gameId === "33") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "row_amount") {
    return value + " " + t("local_parameters_times");
  }
  else if (key === "stimuli_visibility_duration") {
    return value + " s";
  }
  else if (key === "interference_duration") {
    return value + " s";
  }
  else if (key === "interference_type") {
    if (value === "1") {
      return t("local_parameters_math");
    }
    else if (value === "2") {
      return t("local_parameters_identification");
    }
    else if (value === "3") {
      return t("local_parameters_word_classification");
    }
  }
  else if (key === "interference_correct_percentage") {
    return value + " %";
  }
  else if (key === "item_visibility_time") {
    return value + " s";
  }
  else if (key === "item_visibility_interval") {
    return value + " s";
  }
  else if (key === "point_size") {
    if (value === "0") {
      return value;
    }

    return value + "x" + value + " " + t("local_parameters_pixels");
  }
  else if (key === "image_type") {
    if (value === "0") {
      return t("local_parameters_photograph");
    }
    else if (value === "1") {
      return t("local_parameters_drawing");
    }
  }
  else if (key === "right_answers_percent") {
    return value + " %";
  }
  else if (key === "right_answers_average_time") {
    return value + " s";
  }
  else if (key === "right_answers_standard_deviation") {
    return value + " s";
  }
  else if (key === "wrong_answers_percent") {
    return value + " %";
  }
  else if (key === "wrong_answers_average_time") {
    return value + " s";
  }
  else if (key === "wrong_answers_standard_deviation") {
    return value + " s";
  }
  else if (key === "stimulus_size_percent") {
    return value + " %";
  }
  else if (key === "stimulus_input_time_min") {
    return value + " s";
  }
  else if (key === "stimulus_input_time_max") {
    return value + " s";
  }
  else if (key === "prestimulus_interval_min") {
    return value + " s";
  }
  else if (key === "prestimulus_interval_max") {
    return value + " s";
  }
  else if (key === "stimulus_position") {
    if (value === "0") {
      return t("local_parameters_random2");
    }
    else if (value === "1") {
      return t("local_parameters_in_the_middle2");
    }
  }
  else if (key === "average_time_left") {
    return value + " s";
  }
  else if (key === "standard_deviation_left") {
    return value + " s";
  }
  else if (key === "average_time_right") {
    return value + " s";
  }
  else if (key === "standard_deviation_right") {
    return value + " s";
  }
  else if (key === "word_count") {
    return value + " " + t("local_parameters_words2");
  }
  else if (key === "word_visibility_time") {
    return value + " s";
  }
  else if (key === "word_interval") {
    return value + " s";
  }
  else if (key === "item_type") {
    if (value === "1") {
      return t("local_parameters_as_seen");
    }
    else if (value === "2") {
      return t("local_parameters_as_heard");
    }
  }
  else if (key === "string_scroll_interval_words_row") {
    return value + " s";
  }
  else if (key === "string_scroll_interval_calculations_row") {
    return value + " s";
  }
  else if (key === "string_direction_words_row") {
    if (value === "1") {
      return t("local_parameters_to_left");
    }
    else if (value === "2") {
      return t("local_parameters_to_right");
    }
  }
  else if (key === "string_direction_calculations_row") {
    if (value === "1") {
      return t("local_parameters_to_left");
    }
    else if (value === "2") {
      return t("local_parameters_to_right");
    }
  }
  else if (key === "math_difficulty") {
    if (value === "0") {
      return t("local_parameters_easy");
    }
    else if (value === "1") {
      return t("local_parameters_hard");
    }
  }
  else if (key === "string_length_upper_row") {
    return value + " " + t("local_parameters_letters2");
  }
  else if (key === "string_length_lower_row") {
    return value + " " + t("local_parameters_letters2");
  }
  else if (key === "lenght_of_exercise") {
    return value + " s";
  }
  else if (key === "move_direction") {
    if (value === "1") {
      return t("local_parameters_left_to_right");
    }
    else if (value === "2") {
      return t("local_parameters_right_to_left");
    }
    else if (value === "3") {
      return t("local_parameters_top_to_bottom");
    }
    else if (value === "4") {
      return t("local_parameters_bottom_to_top");
    }
  }
  else if (key === "fps_speed") {
    return value + " %";
  }
  else if (key === "count_of_answers") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "type_of_stimulus") {
    if (value === "0") {
      return t("local_parameters_geometry");
    }
    else if (value === "1") {
      return t("local_parameters_3D_object");
    }
    else if (value === "2") {
      return t("local_parameters_item");
    }
    else if (value === "3") {
      return t("local_parameters_animals");
    }
    else if (value === "4") {
      return t("local_parameters_flags");
    }
  }
  else if (key === "scroller_move_time") {
    return value + " s";
  }
  else if (key === "scroller_target_lenght") {
    return value + " " + t("local_parameters_letters2");
  }
  else if (key === "scroller_move_direction") {
    if (value === "1") {
      return t("local_parameters_to_left");
    }
    else if (value === "2") {
      return t("local_parameters_to_right");
    }
  }
  else if (key === "choice_mode_fix") {
    if (value === "0") {
      return t("local_parameters_different_coloured_balls");
    }
    else if (value === "1") {
      return t("local_parameters_different_coloured_and_shaped");
    }
  }
  else if (key === "visual_choice_interference_color") {
    if (value === "0") {
      return t("local_parameters_random2");
    }
    if (value === "1") {
      return t("local_parameters_blue");
    }
  }
  else if (key === "game_difficulty_odd") {
    if (value === "0") {
      return "A1";
    }
    else if (value === "1") {
      return "B1";
    }
    else if (value === "2") {
      return "A2";
    }
    else if (value === "3") {
      return "B2";
    }
    else if (value === "4") {
      return "A3";
    }
    else if (value === "5") {
      return "B3";
    }
  }
  else if (key === "performance_time") {
    return value + " s";
  }
  else if (key === "game_difficulty_3way") {
    if (value === "0") {
      return t("local_parameters_easy");
    }
    else if (value === "1") {
      return t("local_parameters_normal");
    }
    else if (value === "2") {
      return t("local_parameters_hard");
    }
  }
  else if (key === "hint_count") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "finding_task_mode") {
    if (value === "0") {
      return t("local_parameters_images");
    }
    else if (value === "1") {
      return t("local_parameters_numbers");
    }
    else if (value === "2") {
      return t("local_parameters_letters");
    }
    else if (value === "3") {
      return t("local_parameters_symbols");
    }
  }
  else if (key === "finding_task_cell_size") {
    if (value === "0") {
      return t("local_parameters_small");
    }
    else if (value === "1") {
      return t("local_parameters_medium");
    }
    else if (value === "2") {
      return t("local_parameters_big");
    }
  }
  else if (key === "finding_task_layout") {
    if (value === "0") {
      return t("local_parameters_constant");
    }
    else if (value === "1") {
      return t("local_parameters_random");
    }
  }
  else if (key === "finding_task_categories") {
    if (value === "-1") {
      return t("local_parameters_none");
    }
    if (value === "0") {
      return t("local_parameters_fish");
    }
    else if (value === "1") {
      return t("local_parameters_animals");
    }
    else if (value === "2") {
      return t("local_parameters_flags");
    }
    else if (value === "3") {
      return t("local_parameters_sports");
    }
    else if (value === "4") {
      return t("local_parameters_tools");
    }
  }
  else if (key === "finding_task_select_characters") {
    if (value === "0") {
      return t("local_no");
    }
    if (value === "1") {
      return t("local_yes");
    }
  }
  else if (key === "finding_task_first_character") {
    if (value === "none") {
      return t("local_parameters_none");
    }
  }
  else if (key === "finding_task_second_character") {
    if (value === "none") {
      return t("local_parameters_none");
    }
  }
  else if (key === "finding_task_rules") {
    if (value === "none" || value === "-1") {
      return t("local_parameters_none");
    }
  }
  else if (key === "maze_size") {
    return value + " / " + t("local_parameters_side");
  }
  else if (key === "game_style_maze") {
    if (value === "0") {
      return t("local_parameters_buttons");
    }
    if (value === "1") {
      return t("local_parameters_drawing2");
    }
    if (value === "2") {
      return t("local_parameters_dragging");
    }
  }
  else if (key === "maze_rep_moves") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "direction_answer") {
    if (value === "1") {
      return t("local_parameters_forward");
    }
    if (value === "2") {
      return t("local_parameters_backwards");
    }
  }
  else if (key === "play_time_average") {
    return value + " s";
  }
  else if (key === "starter") {
    if (value === "0") {
      return t("local_parameters_user");
    }
    if (value === "1") {
      return t("local_parameters_computer");
    }
    if (value === "2") {
      return t("local_parameters_random2");
    }
  }
  else if (key === "remaining_items") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "fishbowl_gamemode") {
    if (value === "0") {
      return t("local_parameters_fish2");
    }
    if (value === "1") {
      return t("local_parameters_ball");
    }
  }
  else if (key === "bowl_scale") {
    if (value === "0") {
      return t("local_parameters_small");
    }
    if (value === "1") {
      return t("local_parameters_medium");
    }
    if (value === "2") {
      return t("local_parameters_large");
    }
  }
  else if (key === "fish_scale") {
    if (value === "0") {
      return t("local_parameters_small");
    }
    if (value === "1") {
      return t("local_parameters_large");
    }
  }
  else if (key === "fish_in_bowl") {
    return value + " %";
  }
  else if (key === "word_lenght") {
    return value + " " + t("local_parameters_letters2");
  }
  else if (key === "number_count_deduce") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "grid_size") {
    if (value === "0") {
      return value;
    }

    return value + "x" + value;
  }
  else if (key === "cryptogram_portion_of_solved_letters") {
    return value + " %";
  }
  else if (key === "right_answers_proportion") {
    return value + " %";
  }
  else if (key === "instant_feedback_after_selection") {
    if (value === "0") {
      return t("local_no");
    }
    if (value === "1") {
      return t("local_yes");
    }
  }
  else if (key === "target_position") {
    if (value === "0") {
      return t("local_parameters_on_left");
    }
    if (value === "1") {
      return t("local_parameters_right_side");
    }
    if (value === "2") {
      return t("local_parameters_in_the_middle2");
    }
    if (value === "3") {
      return t("local_parameters_random2");
    }
  }
  else if (key === "gestalt_figure_type") {
    if (value === "0") {
      return t("local_parameters_geometric");
    }
    if (value === "1") {
      return t("local_parameters_character");
    }
    if (value === "2") {
      return t("local_parameters_number");
    }
    if (value === "3") {
      return t("local_parameters_symbol");
    }
  }
  else if (key === "finding_pairs_mode") {
    if (value === "0") {
      return t("local_parameters_letters");
    }
    if (value === "1") {
      return t("local_parameters_numbers");
    }
    if (value === "2") {
      return t("local_parameters_symbols");
    }
  }
  else if (key === "finding_task_letter_size") {
    if (value === "0") {
      return t("local_parameters_small");
    }
    if (value === "1") {
      return t("local_parameters_big");
    }
  }
  else if (key === "shape_count_visible") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "shape_count_deduce") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "grid_pos") {
    if (value === "0") {
      return t("local_parameters_on_left");
    }
    if (value === "1") {
      return t("local_parameters_in_the_middle");
    }
    if (value === "2") {
      return t("local_parameters_on_right");
    }
  }
  else if (key === "item_stays_visible") {
    if (value === "0") {
      return t("local_parameters_flashes_on_screen");
    }
    if (value === "1") {
      return t("local_parameters_stays_visible");
    }
  }
  else if (key === "right_answers_percent_top_left") {
    return value + " %";
  }
  else if (key === "right_answers_percent_top_right") {
    return value + " %";
  }
  else if (key === "right_answers_percent_bottom_left") {
    return value + " %";
  }
  else if (key === "right_answers_percent_bottom_right") {
    return value + " %";
  }
  else if (key === "right_answers_percent_all") {
    return value + " %";
  }
  else if (key === "average_time_top_left") {
    return value + " s";
  }
  else if (key === "average_time_top_right") {
    return value + " s";
  }
  else if (key === "average_time_bottom_left") {
    return value + " s";
  }
  else if (key === "average_time_bottom_right") {
    return value + " s";
  }
  else if (key === "average_time_all") {
    return value + " s";
  }
  else if (key === "standard_deviation_top_left") {
    return value + " s";
  }
  else if (key === "standard_deviation_top_right") {
    return value + " s";
  }
  else if (key === "standard_deviation_bottom_left") {
    return value + " s";
  }
  else if (key === "standard_deviation_bottom_right") {
    return value + " s";
  }
  else if (key === "standard_deviation_all") {
    return value + " s";
  }
  else if (key === "vilkin_identification_toggle_pre_exercise") {
    if (value === "0") {
      return t("local_no");
    }
    if (value === "1") {
      return t("local_yes");
    }
  }
  else if (key === "starting_player") {
    if (value === "1") {
      return t("local_parameters_user2");
    }
    if (value === "2") {
      return t("local_parameters_computer2");
    }
    if (value === "3") {
      return t("local_parameters_random_starter");
    }
  }
  else if (key === "euro_game_type") {
    if (value === "1") {
      return "Euro";
    }
  }
  else if (key === "sms_amount") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "sms_time") {
    return value + " s";
  }
  else if (key === "finding_sets_mode") {
    if (value === "0") {
      return t("local_parameters_letters");
    }
    if (value === "1") {
      return t("local_parameters_numbers");
    }
    if (value === "2") {
      return t("local_parameters_symbols");
    }
    if (value === "3") {
      return t("local_parameters_shapes");
    }
  }
  else if (key === "item_movement_speed") {
    return value + " %";
  }
  else if (key === "sound_feedback") {
    if (value === "0") {
      return t("local_off");
    }
    if (value === "1") {
      return t("local_on");
    }
  }
  else if (key === "use_outlines") {
    if (value === "0") {
      return t("local_no");
    }
    if (value === "1") {
      return t("local_yes");
    }
  }
  else if (key === "angle_diff_average") {
    return value + " °";
  }
  else if (key === "news_count") {
    return value + " " + t("local_parameters_pcs");
  }
  else if (key === "reading_time") {
    if (value === "0") {
      return t("local_parameters_unlimited");
    }

    return value + " s";
  }
  else if (key === "allow_repeat") {
    if (value === "0") {
      return t("local_no");
    }
    if (value === "1") {
      return t("local_yes");
    }
  }
  else if (key === "presentation_type") {
    if (value === "0") {
      return t("local_parameters_as_text");
    }
    if (value === "1") {
      return t("local_parameters_as_record");
    }
    if (value === "2") {
      return t("local_parameters_as_record_and_text");
    }
  }

  return value;
}

function cancelSearchResults() {
  window.location.replace(window.location.origin + "/resultByGame");
}

function navigateToClientSelection() {
  if (navigateFromClient === "true") {
    window.location.replace(window.location.origin + "/client");
  }
  else {
    window.location.replace(window.location.origin + "/clientselection");
  }
}

function returnToMenu() {
  window.location.replace(window.location.origin + "/menu");
}

function navigateToClientSelection2(navigate) {
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function navigateToResultByGame(navigate) {
  navigate("/resultByGame");
}

function navigateToResultByGameShow(navigate) {
  navigate("/resultByGameShow");
}

function navigateToClient(navigate) {
  navigate("/client");
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const ResultByGameShow = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const cannotEditDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_client_not_selected3")}</h1>
        <button className="confirmButton" onClick={() => { returnToMenu(); onClose();}}>{t("local_return_to_menu")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  if (clientUserId === null || clientUserId === "") {
    confirmAlert(cannotEditDataOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  //const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (showClients !== "true" && data.role !== "Pro") {
          window.location.replace(window.location.origin + "/account");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        //setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const [userOrgs, setUserOrgs] = useState([]);

  const fetchUserOrgData = async () => {

    if (createOrgClient === null) {
      try {
        var orgData = null;

        if (selectedClientOrgs != null) {
          var orgList = [];
          var orgIdList = [];

          if (selectedClientOrgs.includes(",")) {
            orgIdList = selectedClientOrgs.split(",");

            var expiredUser = false;

            for (var i = 0; i < orgList.length; i++) {
              const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + orgIdList[i],
              {
                method: "GET",
                headers: { "Authorization": "Bearer " + token },
              });

              console.log(response);

              const data = await response.json();

              if (data.Message !== "AuthError_LicenseExpired") {
                orgData = {"name": "Expired organisation"};
                setUserOrgs(orgData);
                expiredUser = true;
              }
              else {
                orgList.push(data.organisations[0]);
              }
            }

            if (expiredUser === false) {
              setUserOrgs(orgList);
            }
          }
          else {
            const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + selectedClientOrgs,
            {
              method: "GET",
              headers: { "Authorization": "Bearer " + token },
            });

            console.log(response);

            const data2 = await response.json();

            if (data2.Message === "AuthError_LicenseExpired") {
              orgData = {"name": "Expired organisation"};
              setUserOrgs(orgData);
            }
            else {
              setUserOrgs(data2.organisations[0]);
            }
          }
        }
        else {
          if (createOrgClient === "true") {
            org = localStorage.getItem("selectedOrgId");
          }

          const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + org,
          {
            method: "GET",
            headers: { "Authorization": "Bearer " + token },
          });

          console.log(response);

          const data3 = await response.json();

          if (data3.Message === "AuthError_LicenseExpired") {
            orgData = {"name": "Expired organisation"};
            setUserOrgs(orgData);
          }
          else {
            setUserOrgs(data3.organisations[0]);
          }
        }

      } catch (err) {

        const organisationFetchFailedOptions = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_failed_to_fetch_org_data")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(organisationFetchFailedOptions);
      }
    }
  }

  const [results, setResults] = useState([]);
  const [uniqueResults, setUniqueResults] = useState([]);

  const fetchResults = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/result/" + clientUserId + "/game/" + selectedGameId,
      {
        headers: { "Authorization": "Bearer " + token },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else {
        const data = await response.json();

        var resultsTable = data.results;

        setResults(resultsTable);

        var uniqueResultsTable = [];
        var duplicateIdTable = [];

        for (var i = 0; i < resultsTable.length; i++) {

          for (var j = 0; j < resultsTable.length; j++) {

            if (!duplicateIdTable.includes(resultsTable[i].game_id) &&
                resultsTable[i].id !== resultsTable[j].id && resultsTable[i].game_id === resultsTable[j].game_id) {

              const response2 = await fetch(baseUrl + "/api/v1/result/" + clientUserId + "/game/" + resultsTable[i].game_id,
              {
                headers: { "Authorization": "Bearer " + token },
              });

              console.log(response2);

              if (response2.status === 403) {
                confirmAlert(tokenExpiredOptions);
              }
              else {
                const data2 = await response2.json();

                var resultsByGame = data2.results;

                for (var k = 0; k < resultsByGame.length; k++) {
                  var counter = 0;

                  for (var l = 0; l < resultsByGame.length; l++) {
                    if (checkIfEarlierDate(resultsByGame[k].executed, resultsByGame[l].executed)) {
                      ++counter;

                      if (counter === resultsByGame.length - 1) {
                        uniqueResultsTable.push(resultsByGame[k]);
                      }
                    }
                  }
                }

                duplicateIdTable.push(resultsTable[i].game_id);
              }

              break;
            }
          }

          if (!duplicateIdTable.includes(resultsTable[i].game_id)) {
            uniqueResultsTable.push(resultsTable[i]);
          }
        }

        setUniqueResults(uniqueResultsTable);
      }

    } catch (err) {
      const resultFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_results")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(resultFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    await fetchResults();
    await fetchUserOrgData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkNumberOfResultsByGame(gameId) {
    var numberOfResults = 0;

    for (var i = 0; i < results.length; i++) {
      if (results[i].game_id === gameId) {
        ++numberOfResults;
      }
    }

    return numberOfResults.toString();
  }

  const DataFormater = (number, name) => {
    if (name === "play_time") {
      return number.toString() + " s";
    }
  }

  const [chartData, setChartData] = useState(null);

  async function showResult() {
    var resultsTable = document.getElementById("resultsTable");
    var resultsChart = document.getElementById("resultsChart");

    chartDataTemp = [];

    try {
      const response = await fetch(baseUrl + "/api/v1/result/" + clientUserId + "/game/" + selectedGameId,
      {
        headers: { "Authorization": "Bearer " + token },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else {
        const data1 = await response.json();

        var resultsDataTableRaw = data1.results;
        var resultsDataTable = [];

        for (var p = 0; p < resultsDataTableRaw.length; p++) {
          if (resultsDataTableRaw[p].game_id.toString() === selectedGameId) {
            resultsDataTable.push(resultsDataTableRaw[p]);
          }
        }

        resultsDataTable.sort(function(date1, date2) {
          return new Date(date2.executed) - new Date(date1.executed);
        });

        var keysTable = [];

        for (var i = 0; i < resultsDataTable.length; i++) {
          var resultsData = JSON.parse(resultsDataTable[i].data).data;

          var keys = Object.keys(resultsData);

          for(var j = 0; j < keys.length; j++) {
            if (!keysTable.includes(keys[j])) {
              keysTable.push(keys[j]);
            }
          }
        }

        resultsTable.innerHTML = "";

        var tr1 = document.createElement("tr");
        var th1 = document.createElement("th");

        th1.innerText = t("local_time");
        th1.className = "resultsRow";

        tr1.appendChild(th1);

        for (var n = 0; n < resultsDataTable.length; n++) {
          var td1 = document.createElement("td");
          td1.innerText = checkDate(resultsDataTable[n].executed);
          td1.className = "resultsDateRow";
          tr1.appendChild(td1);
        }

        resultsTable.appendChild(tr1);

        for (var k = 0; k < keysTable.length; k++) {
          var tr = document.createElement("tr");
          var th = document.createElement("th");

          th.innerText = t("local_parameters_" + keysTable[k]);
          th.className = "resultsRow";

          tr.appendChild(th);

          for (var l = 0; l < resultsDataTable.length; l++) {
            var resultsData = JSON.parse(resultsDataTable[l].data).data;

            var keys = Object.keys(resultsData);
            var values = Object.values(resultsData);
            var keyValues = [];

            for (var r = 0; r < keys.length; r++) {
              if (keys[r] === keysTable[k]) {
                keyValues.push(values[r])
              }
            }

            var foundKey = false;

            for (var m = 0; m < keys.length; m++) {
              if (keys[m] === keysTable[k]) {
                var td = document.createElement("td");
                td.innerText = checkResultValue(t, values[m], keys[m], selectedGameId);
                if (keysTable[k] === "play_time") {
                  chartDataTemp.push({
                      "yAxis" : Math.max.apply(Math, keyValues),
                      "play_time"  : values[m],
                      "executed" : checkDate(resultsDataTable[l].executed)
                  });
                }
                td.className = "resultsRow";
                tr.appendChild(td);
                foundKey = true;
              }
            }

            if (foundKey === false) {
              var td = document.createElement("td");
              td.innerText = "NOT FOUND";
              td.className = "resultsRow";
              tr.appendChild(td);
            }
          }

          if (keysTable[k] === "play_time") {
            th.onclick = function() {
              setChartData(chartDataTemp);
              resultsChart.style.display = "block";
            };
            th.className = "resultsRowClick";
          }

          resultsTable.appendChild(tr);


        }

        var trLast = document.createElement("tr");
        var thLast = document.createElement("th");

        thLast.innerText = t("local_default_settings");
        thLast.className = "resultsRow";

        trLast.appendChild(thLast);

        for (var n = 0; n < resultsDataTable.length; n++) {
          var tdLast = document.createElement("td");
          tdLast.innerText = checkDefaultSettings(t, resultsDataTable[n].default_settings);
          tdLast.className = "resultsRow";
          trLast.appendChild(tdLast);
        }

        resultsTable.appendChild(trLast);
      }

    } catch (err) {
      const resultFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_results")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(resultFetchFailedOptions);
    }
  }

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  const navigate = useNavigate();

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1);

  var resultsTable = document.getElementById("resultsTable");
  var resultsChart = document.getElementById("resultsChart");

  if (resultsTable !== undefined && resultsTable !== null &&
      resultsChart !== undefined && resultsChart !== null) {
    showResult();
  }

  return (
    <div className="CreateOrEditClient-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
            {t("local_button_ingame_menu")}
          </button>
          <span> – </span>
          { createOrgClient === "true" && (
            <React.Fragment>
              <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
                {t("local_organisation")}
              </button>
              <span> – </span>
            </React.Fragment>
          )}
          <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientSelection2(navigate)}>
            {t("local_clients_list")}
          </button>
          <span> – </span>
          { navigateFromClient !== null && (
            <React.Fragment>
              <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClient(navigate)}>
                {t("local_client")}
              </button>
              <span> – </span>
            </React.Fragment>
          )}
          <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToResultByGame(navigate)}>
            {t("local_result_by_game")}
          </button>
          <span> – </span>
          <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToResultByGameShow(navigate)}>
            {t("local_result_by_game_show")}
          </button>
      </div>
      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" /> <br />
          <strong>{t("local_clients")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditclient">{t("local_create_new")}</a>
          </span>
          <p className="ResultByGame-Menu-left-footer"p>{t("local_search_game_results_footer")}</p>
        </div>
        <div className="SearchResult-Menu-right">
          <div className="Menu-right-top">
            <table className="Menu-right-top-table">
              <tr>
                <td className="Menu-right-top-header-upper cnmrth-client">{t("local_client")}: {selectedClientName}</td>
              </tr>
              <tr>
                { createOrgClient === "true" ? (
                  <th className="Menu-right-top-header-upper cnmrth-organisation">{t("local_organisations2")}: {selectedOrgName}</th>
                ) : (
                  <th className="Menu-right-top-header-upper cnmrth-organisation">{t("local_organisations2")}: {userOrgs.name}</th>
                )}
              </tr>
            </table>
          </div>
          <span className="Menu-line"></span>
          <div className="Recipes-Menu-right-bottom">
            <strong className="recipeNameTag">{selectedGameName}</strong>
            <br /> <br />
            <table id="resultsTable" className="resultsTable">
            </table>
            <div id="resultsChart" className="resultsChart">
              <LineChart width={500} height={300} data={chartData}>
                <XAxis dataKey="executed" reversed/>
                <YAxis tickFormatter={(number) => DataFormater(number, "play_time")} dataKey="yAxis"/>
                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <Line type="monotone" dataKey="play_time" stroke="#8884d8" />
                <Tooltip />
              </LineChart>
            </div>
            <button className="btn btn-primary btn-cancelSearchResults" onClick={cancelSearchResults}>
              {t("local_cancel")}
            </button>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default ResultByGameShow;
