import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
      en: {
        translations: require("../translations/en-GB.json")
      },
      fi: {
        translations: require("../translations/fi-FI.json")
      },
      se: {
        translations: require("../translations/sv-SE.json")
      }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'fi', 'se'];

export default i18n;