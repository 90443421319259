import React, { useContext, useState, useEffect } from "react";
import Clock from 'react-digital-clock';
import { NavbarContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var clientName = localStorage.getItem("clientName");
var token = localStorage.getItem("token");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

function navigateToClientPage(clientName) {
  if (clientName !== null && clientName !== "") {
    window.location.replace(window.location.origin + "/client");
  }
}

function navigateToClientPageLink(clientName, navigate) {
  if (clientName !== null && clientName !== "") {
    navigate("/client");
  }
}

function navigateToAccountPage() {
  window.location.replace(window.location.origin + "/account");
}

function navigateToAccountPageLink(navigate) {
  navigate("/account");
}

const Navbar = () => {
  const navbarContext = useContext(NavbarContext);

  var navbarLogoClassName = "Client-Navbar-cognituner-logo";
  var navbarLogoImageClassName = "Client-Navbar-cognituner-logo-image";

  if (navbarContext.navbarStyle === 1 || navbarContext.navbarStyle === 2) {
    navbarLogoClassName = "Client-Navbar-cognituner-logo";
    navbarLogoImageClassName = "Client-Navbar-cognituner-logo-image";
  }
  else if (navbarContext.navbarStyle === 3) {
    navbarLogoClassName = "Menu-Navbar-cognituner-logo";
    navbarLogoImageClassName = "Menu-Navbar-cognituner-logo-image";
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={logOutUser}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  /*const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={logOutUser}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }*/

  async function logOutUser() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        //confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        //confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      /*const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);*/
    }
  }

  const fetchData = async () => {
    //document.getElementById("overlay").style.display = "block";
    await checkUserData();
    //document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="Navbar">
      <ul className="Navbar-links">
        <li className="Navbar-menu">
          <div className="Dropdown-menu">
            <img src={require("../images/navbar-menu.png")} className="Dropdown-imagebutton" alt = "Menu" border = "0"/>
            <div className="Dropdown-content">
              <a href="/menu" >{t("local_home")}</a>
              <a href="/account" >{t("local_settings_button_account")}</a>
              <a href="/eula" >{t("local_settings_button_eula")}</a>
              <a href="/language" >{t("local_settings_button_language")}</a>
              { navbarContext.navbarStyle === 1 && (
                <React.Fragment>
                  <button className="Username-Menu btn btn-link btn-link-nopad" onClick={navigateToAccountPage}>
                    {t("local_username")}: {username}
                  </button>
                </React.Fragment>
              )}
              { navbarContext.navbarStyle === 2 && (
                <React.Fragment>
                  <button className="Username-Menu btn btn-link btn-link-nopad" onClick={navigateToAccountPage}>
                    {t("local_username")}: {username}
                  </button>
                  { (clientName !== null && clientName !== "") && (
                    <button className="ClientName-Menu btn btn-link btn-link-nopad" onClick={() => navigateToClientPage(clientName)}>
                      {t("local_client")}: {clientName}
                    </button>
                  )}
                </React.Fragment>
              )}
              { navbarContext.navbarStyle === 3 && (
                <React.Fragment>
                  <button className="Username-Menu btn btn-link btn-link-nopad" onClick={navigateToAccountPage}>
                    {t("local_username")}: {username}
                  </button>
                  <input type="text" className="NavbarMenuSearchbar" id="NavbarMenuSearchbar" />
                </React.Fragment>
              )}
              <button className="Logout-menu btn btn-link btn-link-nopad" onClick={logOutUser} >
                {t("local_settings_button_logout")}
              </button>
            </div>
          </div>
        </li>
        <li className="Username underline"><a href="#" onClick={() => navigateToAccountPageLink(navigate)}>{username}</a></li>
        { navbarContext.navbarStyle === 3 && (
          <React.Fragment>
            <li>
              <input type="text" className="Navbar-searchbar" id="Navbar-searchbar" />
            </li>
          </React.Fragment>
        )}
        <li className={navbarLogoClassName}><a href = "./menu" target = "_self">
          { role === "Client" && (
            <div className="Navbar-logo-div">
              <img className={navbarLogoImageClassName} src={require("../images/Cognituner Logo pro.png")} alt = "Cognituner" border = "0"/>
            </div>
          )}
          { role === "Pro" && (
            <div className="Navbar-logo-div">
              <img className={navbarLogoImageClassName} src={require("../images/Cognituner Logo pro.png")} alt = "Cognituner" border = "0"/>
            </div>
          )}
          { (role === "Super" || role === "Admin" || role === "Retailer") && (
            <div className="Navbar-logo-div">
              <img className={navbarLogoImageClassName} src={require("../images/Cognituner Logo professional.png")} alt = "Cognituner" border = "0"/>
            </div>
          )}
        </a></li>
        { navbarContext.navbarStyle === 2 && (
          <React.Fragment>
            <li className="ClientName underline"><a href="#" onClick={() => navigateToClientPageLink(clientName, navigate)}>{clientName}</a></li>
            <img src={require("../images/navbar-gauge.png")} alt = "Gauge" className="Gauge" border = "0"/>
            <img src={require("../images/navbar-hourglass.png")} alt = "Hourglass" className="Hourglass" border = "0"/>
            <div className="Clock">
              <Clock format={'hh-mm'} />
            </div>
          </React.Fragment>
        )}
      </ul>
    </div>
  )
}

export default Navbar;
