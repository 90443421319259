import React, { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var forgotEmail = localStorage.getItem("forgotEmail");
var userLanguage = localStorage.getItem("userLanguage");

function returnToLoginPage() {
  window.location.replace(window.location.origin);
}

const ForgotEmail = () => {
  const [email,setEmail]=useState(forgotEmail);

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      sendEmail();
    }
  }

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const resetEmailSentOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_email_sent_if_exists")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const resetEmailFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_failed_to_send_reset_email")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  async function sendEmail() {

    try {
      document.getElementById("overlay").style.display = "block";

      const response = await fetch(baseUrl + "/api/v1/login/reset?email=" + encodeURIComponent(email));
      console.log(response);

      document.getElementById("overlay").style.display = "none";

      if (response.status === 200) {
        confirmAlert(resetEmailSentOptions);
      }
      else {
        confirmAlert(resetEmailFailedOptions);
      }
    } catch (err) {
      document.getElementById("overlay").style.display = "none";

      const resetEmailFailedOptions2 = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_send_reset_email")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(resetEmailFailedOptions2);
    }

  }

  return (
    <div className="ForgotEmail-container">
      <div className="ForgetEmail-reset-container">
        <input
          type="email"
          className="ForgotEmail-input"
          placeholder={t("local_enter_email")}
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button type="button" className="btn btn-primary" onClick={sendEmail} >
          {t("local_send_reset_email")}
        </button>
        <button className="btn btn-primary btn-return-to-login2" onClick={returnToLoginPage}>
          {t("local_return_to_login")}
        </button>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default ForgotEmail;
