import React, { useContext } from "react";
import { ExerciseContext, GameContext } from "../App";
import categories from "./Categories.json";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//var recipeSelected = localStorage.getItem("recipeSelected");

function showModule(categoryId) {
  var modulesList = document.getElementById(categoryId);

  if (modulesList.style.display === "none")
  {
    modulesList.style.display = "block";
  }
  else
  {
    modulesList.style.display = "none";
  }
}

const setExercise = (setSelectedExerciseCategory, dataId, navigate, exerciseStatus) => () => {
  setSelectedExerciseCategory(dataId);

  var previousLink = document.querySelector(`.Modules-list-link.blue-highlight`);

  if (previousLink !== null) {
    previousLink.className = "Modules-list-link";
  }

  localStorage.setItem("selectedCategory", dataId);

  if (exerciseStatus === 1) {
    navigate("/modulesexerciselist");
  }
  else if (exerciseStatus === 2 || exerciseStatus === 4) {
    navigate("/addgamelist");
  }
  else if (exerciseStatus === 3) {
    navigate("/modulesexerciselist");
  }
}

function setDisplay(dataId, exerciseStatus) {
  var selectedCategory = localStorage.getItem("selectedCategory");

  if (exerciseStatus === 1 || exerciseStatus === 2) {
    return "none";
  }

  if (selectedCategory.split(",")[0] === dataId) {
    return "block";
  }
  else {
    return "none";
  }
}

const SearchExercises = () => {
  const { selectedExerciseCategory, setSelectedExerciseCategory, exerciseStatus } = useContext(ExerciseContext);
  const { gameLinks } = useContext(GameContext);
  const navigate = useNavigate();

  function checkIfExerciseSelected() {
    if (gameLinks.length !== 0) {
      window.location.replace(window.location.origin + "/moduleseditexercises");
    }
  }

  var category = selectedExerciseCategory.split(",")[0];
  var subCategory = selectedExerciseCategory.split(",")[1];

  if (exerciseStatus === 3 || exerciseStatus === 4) {
    var subCategoryLink = document.getElementById(categories.category[category].sub[subCategory].id);
    subCategoryLink.className = "Modules-list-link blue-highlight";
  }

  const { t } = useTranslation();

  return(
    <div className="Modules-Menu-left">
      <input type="text" id="MenuSearchbar" /> <br />
      <table className="Modules-Menu-left-table">
        <tr>
          <th className="Modules-header1">{t("local_select")}</th>
          <th className="Modules-header2">{t("local_exercises")}</th>
          { exerciseStatus !== 2 && exerciseStatus !== 4 && (
            <React.Fragment>
              <td className="Modules-header3">
                <button className="Modules-edit-link btn btn-link btn-link-nopad" onClick={checkIfExerciseSelected} >
                  {t("local_edit")}
                </button>
              </td>
            </React.Fragment>
          )}
        </tr>
      </table>
      <span className="Modules-line"></span>
      <div className="AdminMenu-items-container">
        <section>
          { categories.category.map(category => (
            <div className="category-container">
              <span className={category.style} id={category.style}></span>
              <div className="category-content">
                <span style={{cursor: "pointer"}} className="category-module" onClick={() => showModule(category.id)}><strong>{t(category.id)}</strong></span>
                <ul style={{display: setDisplay(category.dataId, exerciseStatus)}} className="category-modules-list" id={category.id}>
                  { category.sub.map(subCategory => (
                    <li>
                      <button title={t(subCategory.id)} className="Modules-list-link btn btn-link btn-link-nopad" id={subCategory.id} data-id={subCategory.dataId} onClick={setExercise(setSelectedExerciseCategory, subCategory.dataId, navigate, exerciseStatus)} >
                        {t(subCategory.id)}
                      </button>
                      {/*<a className="Modules-list-link" id={subCategory.id} data-id={subCategory.dataId} onClick={setExercise(setSelectedExerciseCategory, subCategory.dataId, navigate, exerciseStatus)} href="#">{t(subCategory.id)}</a>*/}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </section>
      </div>
      <p className="AddGame-Menu-left-footer">{t("local_exercise_library_footer")}</p>
      {/* recipeSelected === "true" ? (
        <p className="AddGame-Menu-left-footer">{t("local_exercise_library_footer")}</p>
      ) : exerciseStatus !== 2 && exerciseStatus !== 4 ? (
        <p className="AddGame-Menu-left-footer">{t("local_exercise_library_footer")}</p>
      ) : (
        <p className="AddGame-Menu-left-footer">{t("local_exercise_library_footer")}</p>
      )*/}

    </div>

  )
}

export default SearchExercises;
