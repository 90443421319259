import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var token = localStorage.getItem("token");
var selectedOrgId = localStorage.getItem("selectedOrgId");
var selectedOrgName = localStorage.getItem("selectedOrgName");
var selectedOrgUserCount = localStorage.getItem("selectedOrgUserCount");
var selectedOrgExpires = localStorage.getItem("selectedOrgExpires");
var selectedOrgType = localStorage.getItem("selectedOrgType");
var editOrg = localStorage.getItem("editOrg");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation2(navigate) {
  navigate("/organisation");
}

function navigateToCreateOrganisation(navigate) {
  navigate("/createoreditorganisation");
}

var today = new Date();

var currentYear = today.getFullYear();
var currentMonth = today.getMonth() + 1;
var currentDay = today.getDate();

if (currentDay >= 1 && currentDay <= 9) {
  currentDay = "0" + currentDay;
}

if (currentMonth >= 1 && currentMonth <= 9) {
  currentMonth = "0" + currentMonth;
}

var currentDate = currentYear + "-" + currentMonth + "-" + currentDay;

function checkMaxValue(event) {
  if (event.currentTarget.value > 100) {
    event.currentTarget.value = 100;
  }
}

function showOrganisation() {
  window.location.replace(window.location.origin + "/organisation");
}

function navigateToOrganisation() {
  window.location.replace(window.location.origin + "/organisation");
}

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function checkDate(date) {
  if (date.split("T") === undefined) {
    return date;
  }
  else {
    if (date.split("T")[1].startsWith("22")) {
      var newDateRaw = new Date(date.split("T")[0]);

      newDateRaw = newDateRaw.addDays(1);

      var newDateYear = newDateRaw.getFullYear();
      var newDateMonth = newDateRaw.getMonth() + 1;
      var newDateDay = newDateRaw.getDate();

      if (newDateDay >= 1 && newDateDay <= 9) {
        newDateDay = "0" + newDateDay;
      }

      if (newDateMonth >= 1 && newDateMonth <= 9) {
        newDateMonth = "0" + newDateMonth;
      }

      var newDate = newDateYear + "-" + newDateMonth + "-" + newDateDay;

      return newDate;
    }

    return date.split("T")[0];
  }
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const CreateOrEditOrganisation = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const organisationEditedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_organisation_edited")}</h1>
        <button className="confirmButton" onClick={() => {navigateToOrganisation(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const organisationEditFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_organisation_edit_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const organisationCanceledOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_organisation_canceled")}</h1>
        <button className="confirmButton" onClick={() => {navigateToOrganisation(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const organisationCanceledFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_organisation_cancel_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const newOrganisationCreatedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_organisation_created")}</h1>
        <button className="confirmButton" onClick={() => {navigateToOrganisation(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const newOrganisationCreationFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_organisation_creation_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const organisationCreationFailedOptions5 = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_organisation_name_required")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  async function confirmOrganisationCancel() {
    const confirmOrganisationDeleteOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_confirm_organisation_cancel")}</h1>
          <button className="confirmButton" onClick={() => { cancelOrganisation(); onClose();}}>{t("local_end_license")}</button>
          <button className="closeButton" onClick={onClose}>{t("local_cancel")}</button>
        </div>,
      willUnmount: () => {}
    }

    confirmAlert(confirmOrganisationDeleteOptions);
  }

  async function cancelOrganisation() {

    document.getElementById("overlay").style.display = "block";

    var deleteDate = new Date();

    var deleteYear = deleteDate.getUTCFullYear();
    var deleteMonth = deleteDate.getUTCMonth() + 1;
    var deleteDay = deleteDate.getUTCDate();
    var deleteHours = deleteDate.getUTCHours();
    var deleteMinutes = deleteDate.getUTCMinutes();
    var deleteSeconds = deleteDate.getUTCSeconds();

    if (deleteMonth >= 1 && deleteMonth <= 9) {
      deleteMonth = "0" + deleteMonth;
    }

    if (deleteDay >= 1 && deleteDay <= 9) {
      deleteDay = "0" + deleteDay;
    }

    if (deleteHours >= 0 && deleteHours <= 9) {
      deleteHours = "0" + deleteHours;
    }

    if (deleteMinutes >= 0 && deleteMinutes <= 9) {
      deleteMinutes = "0" + deleteMinutes;
    }

    if (deleteSeconds >= 0 && deleteSeconds <= 9) {
      deleteSeconds = "0" + deleteSeconds;
    }

    var endDate = deleteYear + "-" + deleteMonth + "-" + deleteDay + "T" + deleteHours + ":" + deleteMinutes + ":" + deleteSeconds + "Z";

    var jsonBody = '{ "id": "' + selectedOrgId + '", "type": "' + selectedOrgType + '", "name": "' + selectedOrgName + '", "max_number_of_users": ' + selectedOrgUserCount + ', "expires": "' + endDate + '", "billing_date": null }';

    try {
      const response = await fetch(baseUrl + "/api/v1/organisation/" + selectedOrgId, {
        method: 'POST',
        headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
        body: jsonBody,
      });

      console.log(response);

      if (response.status === 200) {
        confirmAlert(organisationCanceledOptions);
      }
      else if (response.status === 500) {
        confirmAlert(organisationCanceledFailedOptions);
      }
      else {
        const data = await response.json();

        if (data.Message !== null && data.Message !== undefined) {
          var errorMessage = data.Message;

          if (data.Message === "Error_EmailExists") {
            errorMessage = t("local_api_result_error_email_exists");
          }
          else if (data.Message === "Error_UserLimitReached") {
            errorMessage = t("local_api_result_error_user_limit_reached");
          }
          else if (data.Message === "Error_UserNameExists") {
            errorMessage = t("local_api_result_error_user_name_exists");
          }
          else if (data.Message === "Error_Internal") {
            errorMessage = t("local_api_result_error_internal");
          }
          else if (data.Message === "Error_ResourceNotFound") {
            errorMessage = t("local_api_result_error_resource_not_found");
          }
          else if (data.Message === "Error_UnauthorizedAccess") {
            errorMessage = t("local_api_result_error_unauthorized_access");
          }
          else if (data.Message === "Error_InvalidBody") {
            errorMessage = t("local_api_result_error_invalid_body");
          }
          else if (data.Message === "Error_ValidationFailed") {
            errorMessage = t("local_api_result_error_validation_failed");
          }
          else if (data.Message === "Error_InvalidRequest") {
            errorMessage = t("local_api_result_error_invalid_request");
          }
          else if (data.Message === "Error_InsufficientPermissions") {
            errorMessage = t("local_api_result_error_insufficient_permissions");
          }
          else if (data.Message === "Error_InvalidEmail") {
            errorMessage = t("local_api_result_error_invalid_email");
          }
          else if (data.Message === "Error_AlreadyExists") {
            errorMessage = t("local_api_result_error_already_exists");
          }
          else if (data.Message === "Unknown") {
            errorMessage = t("local_api_result_unknown");
          }

          if (data.Message === "AuthError_InvalidAccessToken") {
            confirmAlert(tokenExpiredOptions);
          }
          else {
            const organisationCreationFailedOptions = {
              title: 'Title',
              message: 'Message',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => alert('Click Yes')
                },
                {
                  label: 'No',
                  onClick: () => alert('Click No')
                }
              ],
              childrenElement: () => <div />,
              customUI: ({ title, message, onClose }) =>
                <div className='custom-ui'>
                  <h1>{errorMessage}</h1>
                  <button className="confirmButton" onClick={onClose}>Ok</button>
                </div>,
              willUnmount: () => {}
            }

            confirmAlert(organisationCreationFailedOptions);
          }

        }
      }

      document.getElementById("overlay").style.display = "none";

    } catch (err) {
      document.getElementById("overlay").style.display = "none";

      const organisationCreationFailedOptions2 = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_organisation_cancel_failed")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(organisationCreationFailedOptions2);
    }


  }

  async function confirmOrganisation() {
    var organisationNameInput = document.getElementById("organisationName");
    var organisationName = organisationNameInput.value;

    if (organisationName !== "") {
      var organisationType = document.getElementById("organisationType");
      var organisationUserCountInput = document.getElementById("organisationUserCount");
      var endDateInput = document.getElementById("endDate");

      document.getElementById("overlay").style.display = "block";

      var typeSelected = organisationType.options[organisationType.selectedIndex].value;
      var organisationUserCount = organisationUserCountInput.value;
      var endDate = endDateInput.value;

      endDate += "T22:00:00Z";

      var jsonBody = "";

      if (editOrg === "true") {
        jsonBody = '{ "id": "' + selectedOrgId + '", "type": "' + typeSelected + '", "name": "' + organisationName + '", "max_number_of_users": ' + organisationUserCount + ', "expires": "' + endDate + '", "billing_date": null }';
      }
      else {
        jsonBody = '{ "id": null, "type": "' + typeSelected + '", "name": "' + organisationName + '", "max_number_of_users": ' + organisationUserCount + ', "expires": "' + endDate + '", "billing_date": null }';
      }

      var fetchUrl = baseUrl + "/api/v1/organisation/";

      if (editOrg === "true") {
        fetchUrl += selectedOrgId;
      }
      else {
        fetchUrl += "new";
      }

      try {
        const response = await fetch(fetchUrl, {
          method: 'POST',
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
          if (editOrg === "true") {
            confirmAlert(organisationEditedOptions);
          }
          else {
            confirmAlert(newOrganisationCreatedOptions);
          }
        }
        else if (response.status === 500) {
          if (editOrg === "true") {
            confirmAlert(organisationEditFailedOptions);
          }
          else {
            confirmAlert(newOrganisationCreationFailedOptions);
          }
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const organisationCreationFailedOptions3 = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(organisationCreationFailedOptions3);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const organisationCreationFailedOptions4 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_organisation_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(organisationCreationFailedOptions4);
      }

    }
    else {
      confirmAlert(organisationCreationFailedOptions5);
    }
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (data.role !== "Super") {
          window.location.replace(window.location.origin + "/menu");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1);

  return (
    <div className="CreateOrEditOrganisation-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation2(navigate)}>
          {t("local_organisation")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToCreateOrganisation(navigate)}>
          {t("local_create_or_edit_organisation")}
        </button>
      </div>
      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" /> <br />
          <strong>{t("local_organisations")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditorganisation">{t("local_create_new")}</a>
          </span>
          <p className="CreateOrganisation-Menu-left-footer"p>{t("local_create_or_edit_organisation_footer")}</p>
        </div>
        <div className="Menu-right">
          <div className="Organisation-Menu-right-bottom">
            <label>{t("local_organisation_name")}:</label>
            {  editOrg === "true" ? (
              <input type="text" className="CreateOrganisationNameText" id="organisationName" name="organisationName" defaultValue={selectedOrgName} />
            ) : (
              <input type="text" className="CreateOrganisationNameText" id="organisationName" name="organisationName" />
            )}
            <br />
            <label className="OrganisationTypeLabel">{t("local_type")}:</label>
            {  editOrg === "true" ? (
              <select className="Organisation-type-selection" name="organisationType" id="organisationType" defaultValue={selectedOrgType}>
                <option value="SingleUser">{t("local_single_license")}</option>
                <option value="Organization">{t("local_organisation_license")}</option>
              </select>
            ) : (
              <select className="Organisation-type-selection" name="organisationType" id="organisationType" >
                <option value="SingleUser">{t("local_single_license")}</option>
                <option value="Organization">{t("local_organisation_license")}</option>
              </select>
            )}
            <br />
            <label className="OrganisationUserCountLabel">{t("local_user_count")}:</label>
            {  editOrg === "true" ? (
              <input type="number" className="CreateOrganisationUserCountText" id="organisationUserCount" name="organisationUserCount" defaultValue={selectedOrgUserCount} min="1" max="100" onChange={checkMaxValue} />
            ) : (
              <input type="number" className="CreateOrganisationUserCountText" id="organisationUserCount" name="organisationUserCount" defaultValue="1" min="1" max="100" onChange={checkMaxValue} />
            )}
            <br />
            <label className="OrganisationExpireDateLabel">{t("local_expires")}:</label>
            {  editOrg === "true" ? (
              <input type="date" className="OrganisationEndDateCalendar" id="endDate" name="endDate" min={currentDate} defaultValue={checkDate(selectedOrgExpires)} />
            ) : (
              <input type="date" className="OrganisationEndDateCalendar" id="endDate" name="endDate" min={currentDate} defaultValue={currentDate} />
            )}
            <br />
          </div>
          <div className="Organisation-Menu-right-bottom2">
            {  editOrg === "true" ? (
              <React.Fragment>
                <button id="editButton" type="button" className="btn btn-primary btn-save" onClick={confirmOrganisation} >
                  {t("local_save")}
                </button>
                <button id="cancelLicenseButton" type="button" className="btn btn-primary btn-cancelRecipe" onClick={confirmOrganisationCancel} >
                  {t("local_end_license")}
                </button>
              </React.Fragment>
            ) : (
              <button id="saveButton" type="button" className="btn btn-primary btn-save" onClick={confirmOrganisation} >
                {t("local_save")}
              </button>
            )}

            <button id="cancelOrgButton" type="button" className="btn btn-primary btn-cancelRecipe" onClick={showOrganisation} >
              {t("local_cancel")}
            </button>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default CreateOrEditOrganisation;
