import React, {useState} from "react";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import _ from "lodash";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Auth from "./components/Auth"
import Client from "./components/Client"
import Recipes from "./components/Recipes"
import Progress from "./components/Progress"
import Clock2 from "./components/Clock"
import MainMenu from "./components/MainMenu"
import Organisation from "./components/Organisation"
import OrganisationSubscription from "./components/OrganisationSubscription"
import OrganisationLicences from "./components/OrganisationLicences"
import ClientSelection from "./components/ClientSelection"
import CreateOrEditClient from "./components/CreateOrEditClient"
import CreateOrEditRecipe from "./components/CreateOrEditRecipe"
import CreateNewProgress from "./components/CreateNewProgress"
import CreateOrEditProUser from "./components/CreateOrEditProUser"
import CreateOrEditOrganisation from "./components/CreateOrEditOrganisation"
import NewRecipe from "./components/NewRecipe"
import AddGame from "./components/AddGame"
import AddGameList from "./components/AddGameList"
import ProUsers from "./components/ProUsers"
import ExerciseHistory from "./components/ExerciseHistory"
import Modules from "./components/Modules"
import ModulesExerciseList from "./components/ModulesExerciseList"
import ModulesEditExercises from "./components/ModulesEditExercises"
import Video from "./components/Video"
import ForgotEmail from "./components/ForgotEmail"
import Eula from "./components/Eula"
import Language from "./components/Language"
import Account from "./components/Account"
import EditOrDeleteAccount from "./components/EditOrDeleteAccount"
import TwoStepAuth from "./components/TwoStepAuth"
import EditRecipeParameters from "./components/EditRecipeParameters"
import AssignPros from "./components/AssignPros"
import Templates from "./components/Templates"
import ResultByRecipe from "./components/ResultByRecipe"
import ResultByRecipeShow from "./components/ResultByRecipeShow"
import ResultByGame from "./components/ResultByGame"
import ResultByGameShow from "./components/ResultByGameShow"

export const NavbarContext = React.createContext();
export const ExerciseContext = React.createContext();
export const GameContext = React.createContext();
export const LanguageContext = React.createContext();
export const NavigateContext = React.createContext();

var selectedClientUserId = localStorage.getItem("selectedClientUserId");

const saveContext = (setFn, name) => (value) => {
    setFn(value);
    localStorage.setItem(name + selectedClientUserId, value);
};

function App() {
  const [navbarStyle, setNavbarStyle] = useState(1);
  const [exerciseStatus, setExerciseStatus] = useState(1);
  const [selectedExerciseCategory, setSelectedExerciseCategory] = useState(localStorage.getItem("selectedCategory") || "0,0");
  const [gameLinks, setGameLinks] = useState(_.filter(_.defaultTo(localStorage.getItem("gameLinks" + selectedClientUserId), "").split(","), _.identity));
  const [oldGameLinks, setOldGameLinks] = useState(_.filter(_.defaultTo(localStorage.getItem("gameLinks" + selectedClientUserId), "").split(","), _.identity));
  const [tempGameLinks, setTempGameLinks] = useState(_.filter(_.defaultTo(localStorage.getItem("tempGameLinks" + selectedClientUserId), "").split(","), _.identity));
  const [languageContext, setLanguageContext] = useState(localStorage.getItem("userLanguage") || "en");
  const [navigateContext, setNavigateContext] = useState(localStorage.getItem("pageNavigated") || "false");

  return (
    <GameContext.Provider value={{gameLinks, setGameLinks: saveContext(setGameLinks, "gameLinks"), oldGameLinks, setOldGameLinks: saveContext(setOldGameLinks, "gameLinks"), tempGameLinks, setTempGameLinks: saveContext(setTempGameLinks, "tempGameLinks")}}>
      <ExerciseContext.Provider value={{exerciseStatus, setExerciseStatus, selectedExerciseCategory, setSelectedExerciseCategory}}>
        <NavbarContext.Provider value={{navbarStyle, setNavbarStyle}}>
          <LanguageContext.Provider value={{languageContext, setLanguageContext: saveContext(setLanguageContext, "userLanguage")}}>
            <NavigateContext.Provider value={{navigateContext, setNavigateContext: saveContext(setNavigateContext, "pageNavigated")}}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Auth />} />
                  <Route path="/resultByRecipe" element={<ResultByRecipe />} />
                  <Route path="/resultByRecipeShow" element={<ResultByRecipeShow />} />
                  <Route path="/resultByGame" element={<ResultByGame />} />
                  <Route path="/resultByGameShow" element={<ResultByGameShow />} />
                  <Route path="/menu" element={<MainMenu />} />
                  <Route path="/clientselection" element={<ClientSelection />} />
                  <Route path="/client" element={<Client />} />
                  <Route path="/recipes" element={<Recipes />} />
                  <Route path="/progress" element={<Progress />} />
                  <Route path="/createoreditclient" element={<CreateOrEditClient />} />
                  <Route path="/createoreditrecipe" element={<CreateOrEditRecipe />} />
                  <Route path="/createnewprogress" element={<CreateNewProgress />} />
                  <Route path="/createoreditprouser" element={<CreateOrEditProUser />} />
                  <Route path="/createoreditorganisation" element={<CreateOrEditOrganisation />} />
                  <Route path="/newrecipe" element={<NewRecipe />} />
                  <Route path="/addgame" element={<AddGame />} />
                  <Route path="/addgamelist" element={<AddGameList />} />
                  <Route path="/organisation" element={<Organisation />} />
                  <Route path="/organisationsubscription" element={<OrganisationSubscription />} />
                  <Route path="/organisationlicences" element={<OrganisationLicences />} />
                  <Route path="/prousers" element={<ProUsers />} />
                  <Route path="/exercisehistory" element={<ExerciseHistory />} />
                  <Route path="/modules" element={<Modules />} />
                  <Route path="/modulesexerciselist" element={<ModulesExerciseList />} />
                  <Route path="/moduleseditexercises" element={<ModulesEditExercises />} />
                  <Route path="/video" element={<Video />} />
                  <Route path="/forgotemail" element={<ForgotEmail />} />
                  <Route path="/twostepauth" element={<TwoStepAuth />} />
                  <Route path="/eula" element={<Eula />} />
                  <Route path="/language" element={<Language />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/editordeleteaccount" element={<EditOrDeleteAccount />} />
                  <Route path="/editrecipeparameters" element={<EditRecipeParameters />} />
                  <Route path="/assignpros" element={<AssignPros />} />
                  <Route path="/templates" element={<Templates />} />
                  <Route path="/clock" element={<Clock2 />} />
                </Routes>
              </BrowserRouter>
            </NavigateContext.Provider>
          </LanguageContext.Provider>
        </NavbarContext.Provider>
      </ExerciseContext.Provider>
    </GameContext.Provider>
  )
}

export default App;
