import React, { useState, useEffect, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var org = localStorage.getItem("org");
var token = localStorage.getItem("token");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

var showClients = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

var selectedOrg = false;

function searchUsers(navigate, setNavigateContext) {
  localStorage.setItem("createOrgUser", true);
  setNavigateContext(true);
  navigate("/prousers");
}

function searchClients(navigate, setNavigateContext) {
  localStorage.setItem("createOrgClient", true);
  setNavigateContext(true);
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function checkDate(date) {
  if (date === null || date === undefined) {
    return date;
  }

  if (date.split("T") === undefined) {
    return date;
  }
  else {
    if (date.split("T")[1].startsWith("22")) {
      var newDateRaw = new Date(date.split("T")[0]);

      newDateRaw = newDateRaw.addDays(1);

      var newDateYear = newDateRaw.getFullYear();
      var newDateMonth = newDateRaw.getMonth() + 1;
      var newDateDay = newDateRaw.getDate();

      if (newDateDay >= 1 && newDateDay <= 9) {
        newDateDay = "0" + newDateDay;
      }

      if (newDateMonth >= 1 && newDateMonth <= 9) {
        newDateMonth = "0" + newDateMonth;
      }

      var newDate = newDateYear + "-" + newDateMonth + "-" + newDateDay;

      return newDate;
    }

    return date.split("T")[0];
  }
}

function editOrg(navigate, setNavigateContext) {
  localStorage.setItem("editOrg", true);
  setNavigateContext(true);
  navigate("/createoreditorganisation");
}

function checkOrgData(selectedOrgData, userOrgData) {
  if (selectedOrgData === null) {
    return userOrgData;
  }

  return selectedOrgData;
}

function checkOrgType(selectedOrgType, userOrgType, t) {
  if (selectedOrgType === null) {
    if (userOrgType === "Organization") {
      return t("local_organisation_license");
    }
    else { // SingleUser
      return t("local_single_license");
    }
  }

  if (selectedOrgType === "Organization") {
    return t("local_organisation_license");
  }
  else { // SingleUser
    return t("local_single_license");
  }
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const Organisation = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const navigate = useNavigate();

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  window.onpopstate=function() {
    setNavigateContext(false);

    if (selectedOrg === false) {
      window.location.reload();
    }
  }

  if (navigateContext !== true) {
    localStorage.removeItem("selectedClientName");
    localStorage.removeItem("selectedClientEmail");
    localStorage.removeItem("editOrg");
  }

  localStorage.removeItem("selectedRecipeId");
  localStorage.removeItem("selectedRecipeType");
  localStorage.removeItem("selectedRecipeEndDate");
  localStorage.removeItem("selectedRecipeStartDate");
  localStorage.removeItem("selectedRecipeName");
  localStorage.removeItem("templateRecipeId");

  const [userOrg, setUserOrg] = useState([]);

  const fetchUserOrgData = async () => {

    try {
      const response = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + org,
      {
        headers: { "Authorization": "Bearer " + token },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else {
        const data = await response.json();

        setUserOrg(data.organisations[0]);

        localStorage.setItem("selectedOrgId", data.organisations[0].id);
        localStorage.setItem("selectedOrgName", data.organisations[0].name);
        localStorage.setItem("selectedOrgUserCount", data.organisations[0].max_number_of_users);
        localStorage.setItem("selectedOrgExpires", data.organisations[0].expires);
        localStorage.setItem("selectedOrgBilling", data.organisations[0].billing_date);
        localStorage.setItem("selectedOrgType", data.organisations[0].type);
      }

    } catch (err) {
      const userOrganisationFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_user_org_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(userOrganisationFetchFailedOptions);

    }

  }

  const [orgsList, setOrgsList] = useState([]);

  const fetchOrgsData = async () => {

    try {
      const response = await fetch(baseUrl + "/api/v1/organisation/list-all",
      {
        headers: { "Authorization": "Bearer " + token },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else {
        const data = await response.json();

        var orgList = [];

        for (var i = 0; i < data.organisations.length; i++) {
          const response2 = await fetch(baseUrl + "/api/v1/organisation/get?ids=" + data.organisations[i],
          {
            headers: { "Authorization": "Bearer " + token },
          });

          console.log(response2);

          const data2 = await response2.json();

          if (response2.status === 200) {
            orgList.push(data2.organisations[0]);
          }
        }

        setOrgsList(orgList);
      }

    } catch (err) {

      const organisationFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_org_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(organisationFetchFailedOptions);

    }
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  //const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (data.role === "Pro") {
          window.location.replace(window.location.origin + "/menu");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        //setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    await fetchUserOrgData();
    await fetchOrgsData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [orgName, setOrgName] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [orgUserCount, setOrgUserCount] = useState(null);
  const [orgExpires, setOrgExpires] = useState(null);
  const [orgBilling, setOrgBilling] = useState(null);

  function saveOrg(event) {
    setOrgName(event.currentTarget.innerHTML);
    setOrgUserCount(event.currentTarget.getAttribute("data-org-usercount"));
    setOrgExpires(event.currentTarget.getAttribute("data-org-expires"));
    setOrgBilling(event.currentTarget.getAttribute("data-org-billing"));
    setOrgType(event.currentTarget.getAttribute("data-org-type"));

    localStorage.setItem("selectedOrgId", event.currentTarget.getAttribute("data-org-id"));
    localStorage.setItem("selectedOrgName", event.currentTarget.innerHTML);
    localStorage.setItem("selectedOrgUserCount", event.currentTarget.getAttribute("data-org-usercount"));
    localStorage.setItem("selectedOrgExpires", event.currentTarget.getAttribute("data-org-expires"));
    localStorage.setItem("selectedOrgBilling", event.currentTarget.getAttribute("data-org-billing"));
    localStorage.setItem("selectedOrgType", event.currentTarget.getAttribute("data-org-type"));

    var orgElements = document.getElementsByClassName("Organisation-OrgLink");
    for (let i = 0; i < orgElements.length; i++) {
      orgElements[i].style.color = null;
    }

    selectedOrg = true;

    event.currentTarget.setAttribute("style", "color:blue !important");

  }

  function setOrgData(element) {
    setOrgName(element.innerHTML);
    setOrgUserCount(element.getAttribute("data-org-usercount"));
    setOrgExpires(element.getAttribute("data-org-expires"));
    setOrgBilling(element.getAttribute("data-org-billing"));
    setOrgType(element.getAttribute("data-org-type"));

    localStorage.setItem("selectedOrgId", element.getAttribute("data-org-id"));
    localStorage.setItem("selectedOrgName", element.innerHTML);
    localStorage.setItem("selectedOrgUserCount", element.getAttribute("data-org-usercount"));
    localStorage.setItem("selectedOrgExpires", element.getAttribute("data-org-expires"));
    localStorage.setItem("selectedOrgBilling", element.getAttribute("data-org-billing"));
    localStorage.setItem("selectedOrgType", element.getAttribute("data-org-type"));
  }

  function searchOrgs(event) {
    var orgElements = document.getElementsByClassName("Organisation-OrgLink");
    var setFirstOrg = false;

    for (let i = 0; i < orgElements.length; i++) {

      if (event.target.value.trim().length < 2 && orgElements[i].getAttribute("data-hidden") === "true") {
        orgElements[i].setAttribute("style", "display:block !important");
        orgElements[i].setAttribute("data-hidden", false);
      }

      if (event.target.value.trim() !== "" && ( orgElements[i].innerHTML.toLowerCase().includes(event.target.value.trim().toLowerCase()))) {
        orgElements[i].setAttribute("style", "color:blue !important");
        //orgElements[i].setAttribute("style", "display:block !important");
        orgElements[i].setAttribute("data-hidden", false);
        if (setFirstOrg === false) {
          setOrgData(orgElements[i]);
          setFirstOrg = true;
        }
      }
      else if (event.target.value.trim() === "" && selectedOrg === true && orgElements[i].innerHTML.toString() === localStorage.getItem("selectedOrgName")) {
        orgElements[i].setAttribute("style", "color:blue !important");
        setOrgData(orgElements[i]);
      }
      else if (event.target.value.trim().length >= 2) {
        orgElements[i].setAttribute("style", "display:none !important");
        orgElements[i].setAttribute("data-hidden", true);
      }
      else {
        orgElements[i].setAttribute("style", "color:black !important");
      }
    }
  }

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(1);

  return (
    <div className="Organisation-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
          {t("local_organisation")}
        </button>
      </div>
      <div className="Organisation-Menu-container">
        <div className="Organisation-Menu-left">
          <input type="text" id="MenuSearchbar" onChange={searchOrgs} /> <br />
          <strong>{t("local_organisations")}</strong>
          { role === "Super" && (
            <span>
              <a className="CreateClient" href="./createoreditorganisation">{t("local_create_new")}</a>
            </span>
          )}
          <div className="Organisation-items-container">
            { orgsList.length > 0 && (
              <ul className="Organisation-orglist">
                 { orgsList.map(org => (
                  <li>
                    <span title={org.name}>
                      <button className="Organisation-OrgLink btn btn-link btn-link-nopad" data-org-id={org.id} data-org-type={org.type} data-org-usercount={org.max_number_of_users} data-org-expires={org.expires} data-org-billing={org.billing_date} onClick={saveOrg}>
                        {org.name}
                      </button>
                    </span>
                    <br />
                  </li>
                ))}
              </ul>
            )}
          </div>
          <p className="Organisation-Menu-left-footer"p>{t("local_organisation_footer")}</p>
        </div>
        <div className="Menu-right">
          <div className="Menu-right-top">
            <table>
              <tr>
                <td className="Menu-right-top-header omrth-organisation">{t("local_organisation")}: <strong>{checkOrgData(orgName, userOrg.name)}</strong></td>
              </tr>
            </table>
            <table className="Menu-right-top-table2">
              <tr>
                <td className="Menu-right-top-header omrth-general"><a className="Menu-right-top-header-link menu-header-link-selected" href="./organisation">{t("local_general")}</a></td>
                {/*<td className="Menu-right-top-header omrth-subscription"><a className="Menu-right-top-header-link" href="./organisationsubscription">Subscription</a></td>
                <td className="Menu-right-top-header omrth-licences"><a className="Menu-right-top-header-link" href="./organisationlicences">Licences</a></td>
                <td className="Menu-right-top-header omrth-partners"><a className="Menu-right-top-header-link" href="./">Partners</a></td>
                <td className="Menu-right-top-header omrth-customers"><a className="Menu-right-top-header-link" href="./">Customers</a></td>*/}
              </tr>
            </table>
          </div>
          <span className="Menu-line"></span>
          <div className="Organisation-Menu-right-bottom">
            <table className="Menu-right-bottom-table-lower">
              <tr>
                <th className="od1-row1-item1">{t("local_organisation_type_and_user_count")}</th>
              </tr>
              <tr>
                <td className="od1-row2-item1">{checkOrgType(orgType, userOrg.type, t)}</td>
              </tr>
              <tr>
                <td className="od1-row3-item1">{checkOrgData(orgUserCount, userOrg.max_number_of_users)}</td>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <th className="od1-row4-item1">{t("local_organisation_expires_and_billing")}</th>
              </tr>
              { (checkOrgData(orgExpires, userOrg.expires) !== null || checkOrgData(orgExpires, userOrg.expires) !== undefined) && (
                <tr>
                  <td className="od1-row5-item1">{checkDate(checkOrgData(orgExpires, userOrg.expires))}</td>
                </tr>
              )}
              { (checkOrgData(orgBilling, userOrg.billing_date) !== null || checkOrgData(orgBilling, userOrg.billing_date) !== undefined) && (
                <React.Fragment>
                  <tr>
                    <td className="od1-row6-item1">{checkDate(checkOrgData(orgBilling, userOrg.billing_date))}</td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <br />
              </tr>
              { role === "Super" && (
                <React.Fragment>
                  <tr>
                    <td><button className="editOrganisationLink btn btn-link btn-link-nopad" onClick={() => editOrg(navigate, setNavigateContext)}>
                      {t("local_edit_organisation")}
                    </button></td>
                  </tr>
                  <tr>
                    <br />
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <td><button className="searchForProUsersLink btn btn-link btn-link-nopad" onClick={() => searchUsers(navigate, setNavigateContext)}>
                  {t("local_search_for_users")}
                </button></td>
              </tr>
              <tr>
                <br />
              </tr>
              { showClients === "true" && (
                <tr>
                  <td><button className="searchForClientsLink btn btn-link btn-link-nopad" onClick={() => searchClients(navigate, setNavigateContext)}>
                    {t("local_search_for_clients")}
                  </button></td>
                </tr>
              )}
              {/*<tr>
                <th className="od1-row1-item1">Organisation Name and Address</th>
                <td className="od1-row1-item2"><a className="Edit-link" href="./">Edit</a></td>
              </tr>
              <tr>
                <td className="od1-row2-item1">Camden Neuroclinic</td>
              </tr>
              <tr>
                <td className="od1-row3-item1">Address</td>
              </tr>
              <tr>
                <td className="od1-row4-item1">New Street 123</td>
              </tr>
              <tr>
                <td className="od1-row5-item1">Hamden</td>
              </tr>
              <tr>
                <td className="od1-row6-item1">Cheshire</td>
              </tr>
              <tr>
                <td className="od1-row7-item1">NZ12 BR34</td>
              </tr>
              <tr>
                <td className="od1-row8-item1">UK</td>
              </tr>
              <tr>
                <br />
                <br />
                <br />
                <br />
                <br />
              </tr>
              <tr>
                <th className="od2-row1-item1">Contact Person</th>
                <td className="od2-row1-item2"><a className="Edit-link" href="./">Edit</a></td>
              </tr>
              <tr>
                <td className="od2-row2-item1">Robert Bloom</td>
              </tr>
              <tr>
                <td className="od2-row3-item1">IT Director</td>
              </tr>
              <tr>
                <td className="od2-row4-item1">089-4567 543</td>
              </tr>
              <tr>
                <td className="od2-row5-item1"><a href="./">robert.bloom@nhs.co.uk</a></td>
              </tr>*/}
            </table>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default Organisation;
