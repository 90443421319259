import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import { NavbarContext, NavigateContext } from "../App";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

var baseUrl = "https://cognitunerdev.azurewebsites.net";

var createOrgClient = localStorage.getItem("createOrgClient");
var clientName = localStorage.getItem("clientName");
var token = localStorage.getItem("token");
var selectedClientUserId = localStorage.getItem("selectedClientUserId");
var selectedClientDeviceLock = localStorage.getItem("selectedClientDeviceLock");
var clientEmail = localStorage.getItem("clientEmail");
var clientUsername = localStorage.getItem("clientUsername");
var clientEulaAccepted = localStorage.getItem("clientEulaAccepted");
var clientExpires = localStorage.getItem("clientExpires");
var userLanguage = localStorage.getItem("userLanguage");
var userId = localStorage.getItem("userId");

var showClients = null;

if (userId !== null) {
  showClients = localStorage.getItem("showClients_" + userId);
}

function editClient(navigate, setNavigateContext) {
  localStorage.setItem("editClient", true);
  setNavigateContext(true);
  navigate("/createoreditclient");
}

function deleteClient(navigate, setNavigateContext) {
  localStorage.setItem("deleteClient", true);
  setNavigateContext(true);
  navigate("/createoreditclient");
}

function navigateToRecipes(navigate) {
  navigate("/recipes");
  window.location.reload();
}

function navigateToClientSelection(navigate) {
  navigate("/clientselection");
}

function navigateToMenu(navigate) {
  navigate("/menu");
}

function navigateToClient(navigate) {
  navigate("/client");
}

function navigateToOrganisation(navigate) {
  navigate("/organisation");
}

function assignPros(navigate, setNavigateContext) {
  setNavigateContext(true);
  navigate("/assignpros");
}

function searchResultRecipe(navigate, setNavigateContext) {
  setNavigateContext(true);
  navigate("/resultByRecipe");
}

function searchResultGame(navigate, setNavigateContext) {
  setNavigateContext(true);
  navigate("/resultByGame");
}

function reloadPage() {
  window.location.reload();
}

function returnToMenu() {
  window.location.replace(window.location.origin + "/menu");
}

function returnToLogin() {
  window.location.replace(window.location.origin);
}

const Client = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userLanguage !== null) {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  const userEditedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_edited")}</h1>
        <button className="confirmButton" onClick={() => {reloadPage(); onClose();}}>Ok</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const userEditFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_user_edit_failed")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const resetEmailSentOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_reset_email_sent")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const resetEmailFailedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_failed_to_send_reset_email")}</h1>
        <button className="confirmButton" onClick={onClose}>Ok</button>
      </div>,
    willUnmount: () => {}
  }

  const clientNotSelectedOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_client_not_selected3")}</h1>
        <button className="confirmButton" onClick={() => { returnToMenu(); onClose();}}>{t("local_return_to_menu")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const tokenExpiredOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_token_expired")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  const wrongUserDataOptions = {
    title: 'Title',
    message: 'Message',
    buttons: [
      {
        label: 'Yes',
        onClick: () => alert('Click Yes')
      },
      {
        label: 'No',
        onClick: () => alert('Click No')
      }
    ],
    childrenElement: () => <div />,
    customUI: ({ title, message, onClose }) =>
      <div className='custom-ui'>
        <h1>{t("local_wrong_user_data")}</h1>
        <button className="confirmButton" onClick={removeTokenAndReturnToLogin}>{t("local_return_to_login")}</button>
      </div>,
    closeOnClickOutside: false,
    willUnmount: () => {}
  }

  if (token === null || token === undefined || !token) {
    const tokenOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_token_not_found")}</h1>
          <button className="confirmButton" onClick={returnToLogin}>{t("local_return_to_login")}</button>
        </div>,
      closeOnClickOutside: false,
      willUnmount: () => {}
    }

    confirmAlert(tokenOptions);
  }

  async function setTwoStepFalse() {
    if (userId !== undefined && userId !== null && token !== undefined && token !== null) {
      document.getElementById("overlay").style.display = "block";

      var selectedOrgs = '"' + org + '"';

      if (orgs !== null) {
        selectedOrgs = "";

        var orgList = orgs.split(",");

        for (var i = 0; i < orgList.length; i++) {
          selectedOrgs += '"' + orgList[i] + '",';
        }

        selectedOrgs = selectedOrgs.substring(0, selectedOrgs.length - 1);
      }

      var pros = '\\"' + userId + '\\"';

      if (userSettingsIds !== null) {
        pros = "";

        var prosList = userSettingsIds.split(",");

        for (var j = 0; j < prosList.length; j++) {
          if (userId !== prosList[j]) {
            pros += '\\"' + prosList[j] + '\\",';
          }
        }

        pros += '\\"' + userId + '\\"';
      }

      var jsonBody = "";

      if (userExpires === "null" || userExpires === null) {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": null, "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + '", "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }
      else {
        jsonBody = '{ "user_id": "' + userId + '", "twostep": false, "email": "' + userEmail + '", "full_name": "' + username + '", "expires": "' + userExpires + '", "locale": "' + userLanguage.toUpperCase() + '", "role": "' + role + ', "active": true, "settings": "{\\"RestrictToRecipes\\":false,\\"Pros\\":[' + pros + ']}", "orgs": [' + selectedOrgs + '] }';
      }

      try {
        const response = await fetch(baseUrl + "/api/v1/account/" + userId, {
          method: "POST",
          headers: { "Authorization": "Bearer " + token, "Content-Type": 'application/json' },
          body: jsonBody,
        });

        console.log(response);

        if (response.status === 200) {
        }
        else if (response.status === 500) {
          const twoStepVerifyFailedOptions2 = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'Yes',
                onClick: () => alert('Click Yes')
              },
              {
                label: 'No',
                onClick: () => alert('Click No')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ title, message, onClose }) =>
              <div className='custom-ui'>
                <h1>{t("local_two_step_verify_failed")}</h1>
                <button className="confirmButton" onClick={onClose}>Ok</button>
              </div>,
            closeOnClickOutside: false,
            willUnmount: () => {}
          }

          confirmAlert(twoStepVerifyFailedOptions2);
        }
        else {
          const data = await response.json();

          if (data.Message !== null && data.Message !== undefined) {
            var errorMessage = data.Message;

            if (data.Message === "Error_EmailExists") {
              errorMessage = t("local_api_result_error_email_exists");
            }
            else if (data.Message === "Error_UserLimitReached") {
              errorMessage = t("local_api_result_error_user_limit_reached");
            }
            else if (data.Message === "Error_UserNameExists") {
              errorMessage = t("local_api_result_error_user_name_exists");
            }
            else if (data.Message === "Error_Internal") {
              errorMessage = t("local_api_result_error_internal");
            }
            else if (data.Message === "Error_ResourceNotFound") {
              errorMessage = t("local_api_result_error_resource_not_found");
            }
            else if (data.Message === "Error_UnauthorizedAccess") {
              errorMessage = t("local_api_result_error_unauthorized_access");
            }
            else if (data.Message === "Error_InvalidBody") {
              errorMessage = t("local_api_result_error_invalid_body");
            }
            else if (data.Message === "Error_ValidationFailed") {
              errorMessage = t("local_api_result_error_validation_failed");
            }
            else if (data.Message === "Error_InvalidRequest") {
              errorMessage = t("local_api_result_error_invalid_request");
            }
            else if (data.Message === "Error_InsufficientPermissions") {
              errorMessage = t("local_api_result_error_insufficient_permissions");
            }
            else if (data.Message === "Error_InvalidEmail") {
              errorMessage = t("local_api_result_error_invalid_email");
            }
            else if (data.Message === "Error_AlreadyExists") {
              errorMessage = t("local_api_result_error_already_exists");
            }
            else if (data.Message === "Unknown") {
              errorMessage = t("local_api_result_unknown");
            }

            if (data.Message === "AuthError_InvalidAccessToken") {
              confirmAlert(tokenExpiredOptions);
            }
            else {
              const userCreationFailedOptions = {
                title: 'Title',
                message: 'Message',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => alert('Click Yes')
                  },
                  {
                    label: 'No',
                    onClick: () => alert('Click No')
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ title, message, onClose }) =>
                  <div className='custom-ui'>
                    <h1>{errorMessage}</h1>
                    <button className="confirmButton" onClick={onClose}>Ok</button>
                  </div>,
                willUnmount: () => {}
              }

              confirmAlert(userCreationFailedOptions);
            }

          }
        }

        document.getElementById("overlay").style.display = "none";

      } catch (err) {
        document.getElementById("overlay").style.display = "none";

        const userCreationFailedOptions2 = {
          title: 'Title',
          message: 'Message',
          buttons: [
            {
              label: 'Yes',
              onClick: () => alert('Click Yes')
            },
            {
              label: 'No',
              onClick: () => alert('Click No')
            }
          ],
          childrenElement: () => <div />,
          customUI: ({ title, message, onClose }) =>
            <div className='custom-ui'>
              <h1>{t("local_user_edit_failed2")} {err}</h1>
              <button className="confirmButton" onClick={onClose}>Ok</button>
            </div>,
          willUnmount: () => {}
        }

        confirmAlert(userCreationFailedOptions2);
      }
    }

  }

  async function removeTokenAndReturnToLogin() {
    await setTwoStepFalse();
    localStorage.clear();
    localStorage.setItem("userLanguage", userLanguage);
    sessionStorage.clear();
    window.location.replace(window.location.origin);
  }

  const [role, setRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [userExpires, setUserExpires] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userSettingsIds, setUserSettingsIds] = useState(null);

  const checkUserData = async () => {
    try {
      const response = await fetch(baseUrl + "/api/v1/account/" + userId,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" },
      });

      console.log(response);

      if (response.status === 403) {
        confirmAlert(tokenExpiredOptions);
      }
      else if (response.status === 200) {
        const data = await response.json();

        if (data.twostep !== true && ( data.secret !== null && data.secret !== "none" )) {
          window.location.replace(window.location.origin);
        }

        if (showClients !== "true" && data.role !== "Pro") {
          window.location.replace(window.location.origin + "/account");
        }

        setRole(data.role);
        setUserEmail(data.email);
        setUsername(data.full_name);
        setUserExpires(data.expires);
        setOrg(data.orgs[0]);
        setOrgs(data.orgs.toString());

        var settings = data.settings;

        if (settings !== null && settings.substring(settings.indexOf("[") + 1, settings.indexOf("[") + 2) === '"') {
          setUserSettingsIds(settings.substring(settings.indexOf("[") + 1, settings.indexOf("]")).replaceAll('"', ""));
        }
      }
      else {
        confirmAlert(wrongUserDataOptions);
      }

    } catch (err) {

      const accountFetchFailedOptions = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_fetch_account_data")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(accountFetchFailedOptions);
    }
  }

  const fetchData = async () => {
    document.getElementById("overlay").style.display = "block";
    await checkUserData();
    document.getElementById("overlay").style.display = "none";
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (selectedClientUserId === null || selectedClientUserId === "") {
    confirmAlert(clientNotSelectedOptions);
  }

  async function confirmDeviceLockRelease() {
    const confirmDeviceLockReleaseOptions = {
      title: 'Title',
      message: 'Message',
      buttons: [
        {
          label: 'Yes',
          onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ],
      childrenElement: () => <div />,
      customUI: ({ title, message, onClose }) =>
        <div className='custom-ui'>
          <h1>{t("local_confirm_release_device_lock")}</h1>
          <button className="confirmButton" onClick={() => { releaseDeviceLock(); onClose();}}>{t("local_confirm")}</button>
          <button className="closeButton" onClick={onClose}>{t("local_cancel")}</button>
        </div>,
      willUnmount: () => {}
    }

    confirmAlert(confirmDeviceLockReleaseOptions);
  }

  const [clientDeviceLock, setClientDeviceLock] = useState(selectedClientDeviceLock);

  async function releaseDeviceLock() {
    document.getElementById("overlay").style.display = "block";

    var fetchUrl = baseUrl + "/api/v1/account/" + selectedClientUserId + "/release-device-lock";

    try {
      const response = await fetch(fetchUrl,
      {
        headers: { "Authorization": "Bearer " + token,
         "Auth-ForceAdminClientAccess": "1" }
      });

      console.log(response);

      if (response.status === 200) {
        setClientDeviceLock("false");
        localStorage.setItem("selectedClientDeviceLock", false);
        confirmAlert(userEditedOptions);
      }
      else if (response.status === 500) {
        confirmAlert(userEditFailedOptions);
      }
      else {
        const data = await response.json();

        if (data.Message !== null && data.Message !== undefined) {
          var errorMessage = data.Message;

          if (data.Message === "Error_EmailExists") {
            errorMessage = t("local_api_result_error_email_exists");
          }
          else if (data.Message === "Error_UserLimitReached") {
            errorMessage = t("local_api_result_error_user_limit_reached");
          }
          else if (data.Message === "Error_UserNameExists") {
            errorMessage = t("local_api_result_error_user_name_exists");
          }
          else if (data.Message === "Error_Internal") {
            errorMessage = t("local_api_result_error_internal");
          }
          else if (data.Message === "Error_ResourceNotFound") {
            errorMessage = t("local_api_result_error_resource_not_found");
          }
          else if (data.Message === "Error_UnauthorizedAccess") {
            errorMessage = t("local_api_result_error_unauthorized_access");
          }
          else if (data.Message === "Error_InvalidBody") {
            errorMessage = t("local_api_result_error_invalid_body");
          }
          else if (data.Message === "Error_ValidationFailed") {
            errorMessage = t("local_api_result_error_validation_failed");
          }
          else if (data.Message === "Error_InvalidRequest") {
            errorMessage = t("local_api_result_error_invalid_request");
          }
          else if (data.Message === "Error_InsufficientPermissions") {
            errorMessage = t("local_api_result_error_insufficient_permissions");
          }
          else if (data.Message === "Error_InvalidEmail") {
            errorMessage = t("local_api_result_error_invalid_email");
          }
          else if (data.Message === "Error_AlreadyExists") {
            errorMessage = t("local_api_result_error_already_exists");
          }
          else if (data.Message === "Unknown") {
            errorMessage = t("local_api_result_unknown");
          }

          if (data.Message === "AuthError_InvalidAccessToken") {
            confirmAlert(tokenExpiredOptions);
          }
          else {
            const userCreationFailedOptions = {
              title: 'Title',
              message: 'Message',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => alert('Click Yes')
                },
                {
                  label: 'No',
                  onClick: () => alert('Click No')
                }
              ],
              childrenElement: () => <div />,
              customUI: ({ title, message, onClose }) =>
                <div className='custom-ui'>
                  <h1>{errorMessage}</h1>
                  <button className="confirmButton" onClick={onClose}>Ok</button>
                </div>,
              willUnmount: () => {}
            }

            confirmAlert(userCreationFailedOptions);
          }

        }
      }

      document.getElementById("overlay").style.display = "none";

    } catch (err) {
      document.getElementById("overlay").style.display = "none";

      const userCreationFailedOptions2 = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_user_edit_failed2")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(userCreationFailedOptions2);
    }
  }

  async function sendPwdReset() {

    document.getElementById("overlay").style.display = "block";

    try {
      const response = await fetch(baseUrl + "/api/v1/login/reset?email=" + encodeURIComponent(clientEmail));
      console.log(response);

      if (response.status === 200) {
        confirmAlert(resetEmailSentOptions);
      }
      else {
        confirmAlert(resetEmailFailedOptions);
      }

      document.getElementById("overlay").style.display = "none";

    } catch (err) {
      document.getElementById("overlay").style.display = "none";

      const resetEmailFailedOptions2 = {
        title: 'Title',
        message: 'Message',
        buttons: [
          {
            label: 'Yes',
            onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
            onClick: () => alert('Click No')
          }
        ],
        childrenElement: () => <div />,
        customUI: ({ title, message, onClose }) =>
          <div className='custom-ui'>
            <h1>{t("local_failed_to_send_reset_email")} {err}</h1>
            <button className="confirmButton" onClick={onClose}>Ok</button>
          </div>,
        willUnmount: () => {}
      }

      confirmAlert(resetEmailFailedOptions2);
    }

  }

  const { navigateContext, setNavigateContext } = useContext(NavigateContext);

  window.onpopstate=function() {
    setNavigateContext(false);
    window.location.reload();
  }

  if (navigateContext !== true) {
    localStorage.setItem("navigateFromClient", true);
    localStorage.removeItem("editClient");
    localStorage.removeItem("deleteClient");
  }

  if (navigateContext === true) {
    window.location.reload();
    setNavigateContext(false);
  }

  /*var clientFirstName = "None";
  var clientLastName = "None";

  var whiteSpaceLocation = clientName.search(/\s/);

  if (whiteSpaceLocation !== -1) {
    var lastSpaceIndex = clientName.lastIndexOf(" ");

    clientFirstName = clientName.split(" ")[0];
    clientLastName = clientName.substr(lastSpaceIndex);
  }
  else {
    clientFirstName = clientName;
  }*/

  const navigate = useNavigate();

  const navbarContext = useContext(NavbarContext);
  navbarContext.setNavbarStyle(2);

  return (
    <div className="Client-container">
      {<Navbar />}
      <div className="CrumbPathDiv">
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToMenu(navigate)}>
          {t("local_button_ingame_menu")}
        </button>
        <span> – </span>
        { createOrgClient === "true" && (
          <React.Fragment>
            <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToOrganisation(navigate)}>
              {t("local_organisation")}
            </button>
            <span> – </span>
          </React.Fragment>
        )}
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClientSelection(navigate)}>
          {t("local_clients_list")}
        </button>
        <span> – </span>
        <button className="CrumbPath-link btn btn-link btn-link-nopad" onClick={() => navigateToClient(navigate)}>
          {t("local_client")}
        </button>
      </div>

      <div className="Menu-container">
        <div className="Menu-left">
          <input type="text" id="MenuSearchbar" /><br />
          <strong>{t("local_clients")}</strong>
          <span>
            <a className="CreateClient" href="./createoreditclient">{t("local_create_new")}</a>
          </span>
          <p className="Client-Menu-left-footer"p>{t("local_client_footer")}</p>
        </div>
        <div className="Client-Menu-right">
          <div className="Menu-right-top">
            <table className="Menu-right-top-table">
              <tr>
                <td className="Menu-right-top-header cmrth-client">{t("local_client")}: {clientName}</td>
                <td className="Menu-right-top-header cmrth-general"><a className="Menu-right-top-header-link menu-header-link-selected" href="./client">{t("local_general")}</a></td>
                <td className="Menu-right-top-header cnmrth-recipe"><button className="Menu-right-top-header-link btn btn-link btn-link-nopad" onClick={() => navigateToRecipes(navigate)}>
                  <a className="Menu-right-top-header-link2" href="./recipes">{t("local_recipes")}</a>
                </button></td>
                {/*<td className="Menu-right-top-header cmrth-progress"><a className="Menu-right-top-header-link" href="./progress">{t("local_progress")}</a></td>*/}
              </tr>
            </table>
          </div>
          <span className="Menu-line"></span>
          <div className="Menu-right-bottom-title">
            <table className="Menu-right-bottom-table-upper">
              <th className="Clients-Menu-right-bottom-header1">{t("local_client_user_details")}</th>
              {/*<td className="Clients-Menu-right-bottom-header2">Status:</td>
              <td className="Clients-Menu-right-bottom-header3">Active</td>*/}
            </table>
          </div>
          <div className="Clients-Menu-right-bottom">
            <table className="Menu-right-bottom-table-lower">
              {/*<tr>
                <td className="ud1-row1-item1">Title</td>
                <td className="ud1-row2-item1">Unknown</td>
              </tr>*/}
              {/*<tr>
                <td className="ud1-row1-item2">First name</td>
                <td className="ud1-row2-item2">{clientFirstName}</td>
              </tr>
              <tr>
                <td className="ud1-row1-item3">Last name</td>
                <td className="ud1-row2-item3">{clientLastName}</td>
              </tr>*/}
              <tr className="clientFlexRow">
                <td className="ud1-row1-item1">{t("local_full_name")}</td>
                <td className="ud-row2-item1">{clientName}</td>
              </tr>
              {/*<tr>
                <td className="ud1-row1-item4">Date of birth</td>
                <td className="ud1-row2-item4">Unknown</td>
              </tr>
              <tr>
                <td className="ud1-row1-item5">Gender</td>
                <td className="ud1-row2-item5">Unknown</td>
              </tr>*/}
              <tr>
                <br />
              </tr>
              <tr className="clientFlexRow">
                <td className="ud2-row1-item1">{t("local_email_username")}</td>
                { (clientEmail === null || clientEmail === "null") ? (
                  <td className="ud2-row2-item1 btn btn-link btn-link-nopad">{clientUsername}</td>
                ) : (
                  <td className="ud2-row2-item1 btn btn-link btn-link-nopad">{clientEmail}</td>
                )}
              </tr>
              <tr className="clientFlexRow">
                <td className="ud2-row1-item2">{t("local_password")}</td>
                <td><button className="ud2-row2-item2 underline btn btn-link btn-link-nopad" onClick={sendPwdReset}>
                  {t("local_reset_your_password2")}
                </button></td>
              </tr>
              {/*<tr>
                <br />
              </tr>
              <tr>
                <td className="ud3-row1-item1">Area of Interest</td>
                <td className="ud3-row2-item1">Unknown</td>
              </tr>*/}
              <tr>
                <br />
              </tr>
              <tr className="clientFlexRow">
                <td className="ud3-row1-item2">{t("local_activated_by")}</td>
                <td className="ud3-row2-item2">{t("local_activation_date")}</td>
                <td className="ud3-row3-item1">{t("local_release_date")}</td>
              </tr>
              <tr>
                <br />
              </tr>
              <tr className="clientFlexRow">
                <td className="ud3-row1-item3 underline">{username}</td>
                <td className="ud3-row2-item3">{clientEulaAccepted}</td>
                <td className="ud3-row3-item2 underline">{clientExpires}</td>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <td><button className="editClientLink btn btn-link btn-link-nopad" onClick={() => editClient(navigate, setNavigateContext)}>
                  {t("local_edit_client")}
                </button></td>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <td><button className="deleteClientLink btn btn-link btn-link-nopad" onClick={() => deleteClient(navigate, setNavigateContext)}>
                  {t("local_delete_client")}
                </button></td>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <td><button className="assignProsLink btn btn-link btn-link-nopad" onClick={() => assignPros(navigate, setNavigateContext)}>
                  {t("local_assign_pros")}
                </button></td>
              </tr>
              { clientDeviceLock === "true" && (
                <React.Fragment>
                  <tr>
                    <br />
                  </tr>
                  <tr>
                    <td><button className="releaseDeviceLockLink btn btn-link btn-link-nopad" onClick={() => confirmDeviceLockRelease()}>
                      {t("local_release_device_lock")}
                    </button></td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <br />
              </tr>
              <tr>
                <td><button className="searchResultsLink btn btn-link btn-link-nopad" onClick={() => searchResultRecipe(navigate, setNavigateContext)}>
                  {t("local_view_results_by_recipe")}
                </button></td>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <td><button className="searchResultsLink btn btn-link btn-link-nopad" onClick={() => searchResultGame(navigate, setNavigateContext)}>
                  {t("local_view_results_by_game")}
                </button></td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="Loading-icon-div">
          <img src={require("../images/loading-icon.png")} className="Loading-icon" alt = "Loading..." border = "0"/>
        </div>
      </div>

    </div>
  )
}

export default Client;
